import React, { useEffect, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  },
  "& input": {
    "&::placeholder": {
      fontSize: "10px"
    }
  }
}));

export const SearchFilterBar = ({
  products,
  filteredProducts,
  setFilteredProducts,
  setSearchValue,
  clearFilters
}) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const closeFilter = () => setFilterOpen(false);

  const searchRef = useRef(null);
  const handleSearch = () => setSearchValue(searchRef.current.value);

  const clear = () => {
    clearFilters()
    searchRef.current.value = ''
    setSearchValue('')
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by product, merchant, address"
              inputProps={{ "aria-label": "search" }}
              inputRef={searchRef}
            />
          </Search>
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button color="error" variant="contained" onClick={clear}>
            Reset Filters
          </Button>
          <div className="relative">
            <IconButton size="large" color="inherit" onClick={() => setFilterOpen((filterOpen) => !filterOpen)}>
              <TuneIcon />
            </IconButton>
            {filterOpen && (
              <Filter
                products={products}
                filteredProducts={filteredProducts}
                setFilteredProducts={setFilteredProducts}
                closeFilter={closeFilter}
              />
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default SearchFilterBar;

const Filter = ({ products, filteredProducts, setFilteredProducts, closeFilter }) => (
  <div className="right-0 rounded bg-blue-50 absolute z-50 w-72 h-96 text-black pt-4">
    <h4 style={{ textAlign: "center", fontWeight: 600 }}>Filters</h4>
    <div className="p-4">
      <h6 style={{ textAlign: "left" }}>Filter by Product</h6>
      <ProductDropdown
        products={products}
        filteredProducts={filteredProducts}
        setFilteredProducts={setFilteredProducts}
        closeFilter={closeFilter}
      />
    </div>
  </div>
);

const ProductDropdown = ({ products, filteredProducts, setFilteredProducts, closeFilter }) => {
  const [productName, setProductName] = useState(filteredProducts);

  useEffect(() => {
    if (!filteredProducts.length > 0) setProductName([]);
  }, [filteredProducts]);

  const productIdToNameMap = products && products.reduce((prev, curr) => ({ ...prev, [curr.value]: curr.label }), {});
  const getProductName = (value) => productIdToNameMap[value];

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    setProductName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleClick = () => {
    setFilteredProducts(productName);
    closeFilter();
  };

  return (
    products && (
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Select Product/s</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={productName}
          onChange={handleChange}
          input={<OutlinedInput label="Select Product/s" />}
          renderValue={(productName) => productName.map((pn) => getProductName(pn)).join(", ")}
        >
          {products.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={productName.indexOf(value) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
        <Button onClick={() => handleClick()}>Apply filters</Button>
      </FormControl>
    )
  );
};
