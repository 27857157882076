import React from "react";
import * as Yup from "yup";

const merchantFormFields = (merchantUser, country, role) => {
  return {
    firstName: merchantUser ? merchantUser.firstName : "",
    lastName: merchantUser ? merchantUser.lastName : "",
    phone: merchantUser ? merchantUser.phone : "",
    email: merchantUser ? merchantUser.email : "",
    address: {
      street: merchantUser ? merchantUser.address.street : "",
      city: merchantUser ? merchantUser.address.city : "",
      state: merchantUser ? merchantUser.address.state : "",
      postcode: merchantUser ? merchantUser.address.postcode : "",
      country: country ? country : {}
    },
    role: role ? role : {}
  };
};

export default merchantFormFields;

export const merchantValidation = () => {
  return {
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phone: Yup.string()
      .matches(/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/, "Invalid Australian phone number")
      .required("Phone number is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    // address: Yup.object().shape({
    //   street: Yup.string().required("Street is required"),
    //   city: Yup.string().required("City is required"),
    //   state: Yup.string().required("State is required"),
    //   postcode: Yup.string().required("Postcode is required"),
    //   country: Yup.object().required("Country is required"),
    // }),
    role: Yup.object().required("Role is required")
  };
};

export const merchantStoreFormFields = (merchantStore, country) => {
  return {
    name: merchantStore ? merchantStore.name : "",
    address: {
      street: merchantStore ? merchantStore.address.street : "",
      city: merchantStore ? merchantStore.address.city : "",
      state: merchantStore ? merchantStore.address.state : "",
      postcode: merchantStore ? merchantStore.address.postcode : "",
      country: country ? country : {}
    },
    storeManager: merchantStore ? merchantStore.storeManager : "",
    active: merchantStore ? merchantStore.active : "",
  };
};

export const merchantStoreValidation = () => {
  return {
    name: Yup.string().required("Store Name is required"),
    storeManager: Yup.string().required("Store Manager Name is required"),
    // lastName: Yup.string().required("Last Name is required"),
    // phone: Yup.string()
    //   .matches(
    //     /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
    //     "Invalid Australian phone number"
    //   )
    //   .required("Phone number is required"),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
    address: Yup.object().shape({
      street: Yup.string().required("Street is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      postcode: Yup.string().required("Postcode is required"),
      country: Yup.object().required("Country is required")
    }),
    active: Yup.string()
    // role: Yup.object().required("Role is required"),
  };
};
