import React from "react";
import { Fallback, emptyStateMapping } from ".";

const EmptyState = ({ type, text, action }) => {
  const { img, alt } = emptyStateMapping[type] || Fallback
  return (
    <div className="max-w-[700px] flex flex-col align-center">
      {img && <img src={img} alt={alt} />}
      {text && <p className="text-[30px] text-center">{text}</p>}
      {action && <div className="m-auto">{action}</div>}
    </div>
  );
};

export default EmptyState;
