import { useAuth0 } from "@auth0/auth0-react";
import { React, useEffect, useState } from "react";
import { FaChevronCircleDown, FaChevronDown, FaUser, FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { api } from "../utils/api";
import * as Images from "../themes/images";

const Profile = ({ userPortals, currentPortal, setCurrentPortal }) => {
  let navigate = useNavigate();
  const { user, isAuthenticated, loginWithRedirect, loginWithPopup, logout } = useAuth0();
  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const signupPageBgImage = mobile ? Images.signupPageBgMobile : Images.signupPageBgDesktop;
  // const signupPageBgTablet = Images.signupPageBgTablet;
  // const signupPageBgMobile = Images.signupPageBgMobile;

  const location = useLocation();

  // Extracting URL parameters to check if we want to show Register or Login ( for welcome email will have ?action=register)
  const urlParams = new URLSearchParams(location.search);
  const isNewUser = urlParams.get("new");
  const idCustomer = urlParams.get("cid");
  const [userEmail, setUserEmail] = useState();

  useEffect(() => {
    const fetchCustomerEmail = async () => {
      try {
        if (idCustomer) {
          const response = await api(`v1/customer/${idCustomer}`, "GET", null);
          if (response.status === 200) {
            const customerEmail = response.data.data.customer.email;
            setUserEmail(customerEmail);
          }
        }
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    fetchCustomerEmail();
  }, [idCustomer]);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  function toggleItem(itemPortal) {
    const items = document.querySelectorAll("#expand-content .optionItem");
    items.forEach((otherItem) => otherItem.classList.remove("font-bold"));

    items.forEach((item) => {
      if (item.textContent.includes(itemPortal)) {
        item.classList.add("font-bold");
      }
    });

    sessionStorage.setItem("selectedPortal", itemPortal); // Set the selected portal here
    setCurrentPortal(itemPortal); // Set the selected portal here
    toggleExpand();
    navigate("/");
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // Add an event listener to the document to handle clicks outside of app-header
  document.addEventListener("click", (event) => {
    let target = event.target;
    while (target) {
      if (target.classList.contains("app-header")) {
        // Clicked on or inside an element with class 'app-header', do nothing
        return;
      }
      target = target.parentElement;
    }
    const expandContent = document.getElementById("expand-content");
    // Clicked outside of app-header, hide expandContent
    expandContent && expandContent.classList.add("hidden");
  });

  function toggleExpand() {
    const expandContent = document.getElementById("expand-content");
    expandContent.classList.toggle("hidden");
  }

  console.log("Is Logged in?", [user, isAuthenticated]);

  if (isAuthenticated) {
    let width = mobile ? "w-[300px] right-[2px]" : "";

    return (
      <div className="profile flex flex-column justify-end items-end cursor-pointer">
        <span className="flex gap-2 items-center justify-end text-lg px-3 h-[48px]" onClick={toggleExpand}>
          <span>
            <FaUserCircle />{" "}
          </span>{" "}
          {!mobile && <span className="text-[#6D6D6D] flex">Welcome, {user.nickname.toUpperCase()}</span>}
          <FaChevronDown />
        </span>
        <div
          className={`flex flex-column border border-slate-600 justify-end hidden px-4 py-2 gap-2 absolute top-[50px] z-10 bg-white ${width}`}
          id="expand-content"
        >
          {mobile && (
            <div className={`mb-2 optionItem text-[#6D6D6D]`}>
              <p className="font-bold">Welcome, {user.nickname.toUpperCase()}</p>
            </div>
          )}

          {userPortals && userPortals.includes("Administrator") && (
            <div
              className={`mb-2 optionItem text-[#6D6D6D] ${currentPortal === "Administrator" ? "font-bold" : ""}`}
              onClick={() => toggleItem("Administrator")}
            >
              Admin View {currentPortal === "Administrator" && "(Current)"}
            </div>
          )}
          {userPortals && userPortals.includes("Partner") && (
            <div
              className={`mb-2 optionItem text-[#6D6D6D] ${currentPortal === "Partner" ? "font-bold" : ""}`}
              onClick={() => toggleItem("Partner")}
            >
              Partner View {currentPortal === "Partner" && "(Current)"}
            </div>
          )}
          {userPortals && userPortals.includes("Customer") && (
            <div
              className={`mb-2 optionItem text-[#6D6D6D] ${currentPortal === "Customer" ? "font-bold" : ""}`}
              onClick={() => toggleItem("Customer")}
            >
              Customer View {currentPortal === "Customer" && "(Current)"}
            </div>
          )}
          {userPortals && userPortals.includes("Merchant") && (
            <div
              className={`mb-2 optionItem text-[#6D6D6D] ${currentPortal === "Merchant" ? "font-bold" : ""}`}
              onClick={() => toggleItem("Merchant")}
            >
              Merchant View {currentPortal === "Merchant" && "(Current)"}
            </div>
          )}
          <div
            className="text-[#6D6D6D] logoutButton"
            id="logoutButton"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            LogOut <small>({user.email})</small>
          </div>
        </div>
      </div>
    );
  } else {
    console.log("Is Logged in?", [user, isAuthenticated]);
    // !isAuthenticated && loginWithPopup();

    return (
      <div className="flex flex-column w-full justify-center items-center loginAndSignupWrapper" style={{
        backgroundImage: 'url("' + signupPageBgImage + '")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className="flex flex-column w-full justify-center items-center">
          <button
            className="btn btn-primary p-3"
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "login",
                  login_hint: userEmail
                }
              })
            }
          >
            Log In to Continue
          </button>
          <div className="font-black opacity-50 my-3">OR</div>
          <button
            className="btn btn-primary p-3"
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: "signup",
                  login_hint: userEmail
                }
              })
            }
          >
            Sign Up to Continue
          </button>
          {userEmail && (
            <p className="mt-3 max-w-sm text-center">
              Note: You must use the same email as where you received your activation invite ({userEmail}).{" "}
            </p>
          )}
        </div>
      </div>
    );
  }
};

export default Profile;
