import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import PromotionForm from "./Forms/PromotionForm";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { DATA_GRID_STYLES } from "../../components/BasicUserInfo";
import { COLORS } from "../../utils/colors";

const PromotionSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editPromotionState, setEditPromotionState] = useState(false);

  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    const fetchPromotionDetails = async () => {
      try {
        const response = await api("v1/promotion/" + params.idPromotion, "GET", null);
        if (response.data.success) {
          const { promotion } = response.data.data;
          setSelectedPromotion(promotion);
          fetchProductDetails(promotion);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };
    fetchPromotionDetails();
  }, []);

  const fetchProductDetails = async (promotions) => {
    // Fetch product details for each valid product
    if (promotions && promotions.validProducts.length > 0) {
      const result = await Promise.all(
        promotions.validProducts.map(async (product) => {
          const response = await api(`v1/product/${product}`, "GET");
          if (response.data.success) {
            console.log("response.data.data", response.data.data);
            const { product } = response.data.data;
            return product;
          } else {
            return undefined;
          }
        })
      );
      setProductDetails([...result]);
    }
  };

  //   const fetchProductById = async (productId) => {
  //     try {
  //       const response = await api(`v1/product/${productId}`, "GET");
  //       if (response.data.success) {
  //         const { product } = response.data.data;
  //         setProductDetails((prevState) => [...prevState, product]);
  //       }
  //     } catch (error) {
  //       console.error("API error:", error);
  //     }
  //   };

  const handleDeletePromotionConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/promotion/" + selectedPromotion._id, "DELETE", null);
      if (response.data.success) {
        navigate(`/promotion`);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editPromotion = () => {
    setEditPromotionState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadPromotionDetail = (updateCompanyDetail) => {
    setEditPromotionState((prevValue) => !prevValue);
    setSelectedPromotion(updateCompanyDetail);
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

    return formattedDate;
  }

  return (
    selectedPromotion && (
      <div className="page partner-user-single w-full lflex flex-column items-center min-h-h-screen p-8 ">
        {loading && <GlobalLoadingSpinner />}
        <div className="pageTitle w-full flex flex-row justify-between">
          <h1>Promotion Details</h1>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {/* Promotion Single Content */}
        <div className="w-full">
          <h3 className="font-semibold mb-4">{selectedPromotion.title}</h3>
          <div className={`${DATA_GRID_STYLES}  w-[600px]`}>
            <span className="font-semibold text-lg">Title</span>
            <span className="font-semibold text-lg">{selectedPromotion.title}</span>
            <span className="font-semibold">Code</span>
            <span>{selectedPromotion.code}</span>

            {selectedPromotion.comment && (
              <>
                <span className="font-semibold">Comment</span>
                <span>{selectedPromotion.comment}</span>
              </>
            )}
            <span className="font-semibold">Promotion Type</span>
            <span>{selectedPromotion.type}</span>
            <span className="font-semibold">Promotion Value</span>
            <span>${selectedPromotion.value}</span>
            <span className="font-semibold">Promotion Expiry Date</span>
            <span>{selectedPromotion.expiresOnUTC ? formatDate(selectedPromotion.expiresOnUTC) : "n/a"}</span>
            <span className="font-semibold">Products</span>
            <span>
              {productDetails.length > 0
                ? productDetails.map(
                    (product) =>
                      product && (
                        <ul className="pl-0 mb-0 cursor-pointer " onClick={() => navigate(`/product/${product._id}`)}>
                          {product.name}
                        </ul>
                      )
                  )
                : "No Products"}
            </span>
            <span className="font-semibold">Active</span>
            <span className="font-semibold">{selectedPromotion.active ? "YES" : "NO"}</span>
          </div>

          {sessionStorage.getItem("selectedPortal") == "Administrator" && (
            <div className="actionItems flex gap-4">
              <Button
                size="large"
                sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                className="self-start m-4"
                variant="contained"
                onClick={editPromotion}
              >
                Edit Promotion
              </Button>
              <Button
                size="large"
                className="self-start bg-red-600 m-4"
                variant="contained"
                color="error"
                onClick={() => {
                  toggleDisplayDeleteConfirm(true);
                }}
              >
                Delete Promotion
              </Button>
            </div>
          )}

          {/* // Edit Modal HTML */}

          <ModalPopup
            showModal={editPromotionState}
            handleShowModal={editPromotion}
            popupTitle={"Edit Promotion"}
            popupContent={
              <PromotionForm
                selectedPromotion={selectedPromotion}
                callback={reloadPromotionDetail}
                cancelModal={editPromotion}
              />
            }
            cancelButtonText=""
            confirmButtonText=""
            confirmCallbackAction=""
          />

          {/* Delete Promotion Confimation Modal */}
          <ModalPopup
            showModal={deleteConfirmationIsOpen}
            handleShowModal={toggleDisplayDeleteConfirm}
            popupTitle={"Delete Promotion"}
            popupContent={
              <div>
                Are you sure you want to delete this promotion:
                <strong>
                  <em> {selectedPromotion.code} </em>
                </strong>
                ?
              </div>
            }
            cancelButtonText={"Cancel"}
            confirmButtonText={"Delete"}
            confirmCallbackAction={handleDeletePromotionConfirm}
          />
        </div>
      </div>
    )
  );
};

export default PromotionSingle;
