import React from "react";
import { useNavigate } from "react-router-dom";
const MerchantStoreList = ({ storeList, merchant = false }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {merchant &&
        storeList.map((store) => (
          <div
            key={store._id}
            className="w-1/3 cursor-pointer partnerCompanyUserListItem merchantStoreListItem"
            onClick={() => {
              navigate(`/merchant/${merchant._id}/store/${store._id}`);
            }}
          >
            {/* <h2 className="text-2xl font-bold">
              {store.name}
            </h2> */}




            <div className="flex flex-column gap-2 inline-flex w-full">
              <div className="flex flex-wrap gap-4 justify-center w-full">
                <div
                  className="entityListItem merchant-list flex gap-2 basis-0 min-w-[400px] flex-column flex-wrap rounded-lg cursor-pointer">
                  <div>
                    <div className="flex place-content-between gap-2">
                      <div className="font-bold">Store Name </div>
                      <div>{store.name}</div>
                    </div>
                  </div>
                  <div>
                    <div className="flex place-content-between gap-2">
                      <div className="font-bold">Store Manager </div>
                      <div>{store.storeManager}</div>
                    </div>
                  </div>
                  <div>
                    <div className="flex place-content-between gap-2">
                      <div className="font-bold">Store Address </div>
                      <div>
                        {store.address.street}, {store.address.city},{" "}
                        {store.address.postcode} {store.address.state}{" "}
                        {store.address.country}
                      </div>
                    </div>
                  </div>

                  {/* <div>
                  <div className="flex place-content-between gap-2">
                    <span className="font-bold">Model </span>Model
                  </div>
                </div> */}



                  {/* <div>
                    <div className="flex flex-column gap-2">
                      <span className="font-bold">Products:</span>
                      <ul>
                        <div className="flex place-content-between gap-2">
                          <span className="font-bold">Product Two </span>
                        </div>
                        <div className="flex place-content-between gap-2">
                          <span className="font-bold">Product 3 </span>
                        </div>
                      </ul>
                    </div>
                  </div> */}


                  <div>
                    <div className="flex place-content-between gap-2">
                      <span className="font-bold">Active </span> <strong>{store.active ? 'Yes' : 'No'} </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        ))}
    </div>
  );
};

export default MerchantStoreList;
