import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Box, Button, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";

const validationSchema = Yup.object().shape({
    // partnerCompany: Yup.object().required("Partner Company is required"),
    // product: Yup.object().required("Product is required"),
    price: Yup.number().required("Price is required"),
});

const PartnerPricingForm = ({ selectedPartnerPricing, callback, cancelModal, defaultCompany }) => {
    // console.log(selectedPartnerPricing);
    const [modalActive, setModalActive] = useState(true);
    const [companyOptions, setCompanyOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [partnerCompany, setPartnerCompany] = useState('');
    const [product, setProduct] = useState('');

    const getAllPartnerCompanies = async () => {
        try {
            const response = await api("v1/partner/company", "GET", null);
            if (response.data.success) {
                const allCompanies = response.data.data.allCompanies;
                const result = allCompanies.map((company) => ({
                    value: company._id,
                    label: company.name,
                }));

                if (result) {
                    setCompanyOptions(result);
                    if (selectedPartnerPricing) {
                        const selectedCompany = result.find(
                            (company) => company.value === selectedPartnerPricing.company._id
                        );
                        setPartnerCompany(selectedCompany);
                    }
                    if (defaultCompany) {
                        const selectedCompany = result.find(
                            (company) => company.value === defaultCompany
                        );
                        setPartnerCompany(selectedCompany);
                    }
                }
            }
        } catch (error) {
            console.error("Error loading partner companies:", error);
        }
    };

    const getAllProducts = async () => {
        try {
            const response = await api("v1/product", "GET", null);
            if (response.data.success) {
                const allProducts = response.data.data.allProducts;
                const result = allProducts.map((product) => ({
                    value: product._id,
                    label: product.name,
                }));

                if (result) {
                    setProductOptions(result);
                    if (selectedPartnerPricing) {
                        const selectedProduct = result.find(
                            (product) => product.value === selectedPartnerPricing.product._id
                        );
                        setProduct(selectedProduct);
                    }
                }
            }
        } catch (error) {
            console.error("Error loading partner companies:", error);
        }
    };

    useEffect(() => {
        getAllPartnerCompanies();
        getAllProducts();
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("Submitted Data", values);
        const dataToSend = {
            partnerCompany: partnerCompany.value,
            product: product.value,
            price: values.price,
        };
        console.log("Data to Send", dataToSend);
        if (Object.keys(partnerCompany).length == 0 || Object.keys(product).length == 0) {
            // Having issue with YUP validation will use this method for now.
            alert("Required Fields not selected");
            return false;
        }
        try {
            let response;
            if (selectedPartnerPricing) {
                // Edit PartnerPricing Option
                response = await api(`v1/pricing/${selectedPartnerPricing._id}`, "PATCH", dataToSend);
            } else {
                // Add PartnerPricing Option
                response = await api(`v1/pricing`, "POST", dataToSend);
            }

            if (response.data.success) {
                if (selectedPartnerPricing) {
                    console.log("PartnerPricing Updated: ", response.data.data.updatedPartnerPricing);
                    const partnerCompany = await api(`v1/partner/company/${response.data.data.updatedPartnerPricing.partnerCompany}`, "GET");
                    const product = await api(`v1/product/${response.data.data.updatedPartnerPricing.product}`, "GET");
                    // console.log(response.data.data.partnerPricing);
                    response.data.data.updatedPartnerPricing.company = partnerCompany.data.data.partnerCompany;
                    response.data.data.updatedPartnerPricing.product = product.data.data.product;
                    callback(response.data.data.updatedPartnerPricing);
                } else {
                    if (response.data.data.newPartnerPricing) {
                        callback(response.data.data.newPartnerPricing);
                    } else {
                        alert(response.data.message);
                    }
                }
            } else {
                response?.data?.message && alert(response.data.message);
            }
        } catch (error) {
            console.error("API error:", error);
        }

        setSubmitting(false);
    };

    const loadProductOptions = async (inputValue) => {
        try {
            const filteredOptions = productOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            // callback(filteredOptions);
            return filteredOptions;
        } catch (error) {
            console.error("Error loading product options:", error);
            callback([]);
        }
    };


    const handleProductChange = (selectedOptions) => {
        if (selectedOptions) {
            // const { value, label } = selectedOptions;
            // setProduct({ value, label });
            setProduct(selectedOptions);
        } else {
            setProduct(null);
        }
    };

    const loadCompanyOptions = async (inputValue) => {
        try {
            const filteredOptions = companyOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            // callback(filteredOptions);
            return filteredOptions;
        } catch (error) {
            console.error("Error loading company options:", error);
            callback([]);
        }
    };

    const handleCompanyChange = (selectedOptions) => {
        if (selectedOptions) {
            setPartnerCompany(selectedOptions);
        } else {
            setPartnerCompany(null);
        }
    };




    const selectStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "white",
            borderColor: "#9e9e9e",
            zIndex: 2,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 3,
        }),
    };

    console.log({
        price: selectedPartnerPricing ? selectedPartnerPricing.price : "",
        partnerCompany: partnerCompany,
        product: product,
    });

    return (
        <Formik
            initialValues={{
                price: selectedPartnerPricing ? selectedPartnerPricing.price : 0,
                partnerCompany: selectedPartnerPricing ? selectedPartnerPricing.partnerCompany : '',
                product: selectedPartnerPricing ? selectedPartnerPricing.product : '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
                <div className="form-input-wrap flex gap-2 w-full">
                    <div className="w-full flex gap-5">
                        <div className="form-group w-full">
                            <label className="label" htmlFor="partnerCompany">
                                Partner Company:
                            </label>
                            {/* <Field name="partnerCompany" className="z-2">
                                {({ field }) => (
                                    <AsyncSelect
                                        name="partnerCompany"
                                        {...field}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={loadCompanyOptions}
                                        styles={selectStyles}
                                        onChange={handleCompanyChange}
                                        value={partnerCompany}
                                    />
                                )}
                            </Field> */}
                            <AsyncSelect
                                name="partnerCompany"
                                cacheOptions
                                defaultOptions={companyOptions}
                                loadOptions={loadCompanyOptions}
                                styles={selectStyles}
                                onChange={handleCompanyChange}
                                value={partnerCompany}
                            />
                            <ErrorMessage
                                name="partnerCompany"
                                component="span"
                                className="error-message"
                            />
                        </div>
                    </div>
                    <div className="w-full flex gap-5">
                        <div className="form-group w-full">
                            <label className="label" htmlFor="product">
                                Product:
                            </label>
                            {/* <Field name="product" className="z-2">
                                {({ field }) => (
                                    <AsyncSelect
                                        name="product"
                                        {...field}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={loadProductOptions}
                                        styles={selectStyles}
                                        onChange={handleProductChange}
                                        value={product}
                                    />
                                )}
                            </Field> */}
                            <Field name="product">
                                {({ field }) => (
                                    <AsyncSelect
                                        name="product"
                                        {...field}
                                        cacheOptions
                                        defaultOptions={productOptions}
                                        loadOptions={loadProductOptions}
                                        styles={selectStyles}
                                        onChange={handleProductChange}
                                        value={product}
                                    />
                                )}
                            </Field>
                            <ErrorMessage
                                name="product"
                                component="span"
                                className="error-message"
                            />
                        </div>
                    </div>
                    <div className="w-full flex gap-5">
                        <div className="form-group w-full">
                            {/* <label htmlFor="price">Price:</label> */}
                            <Field name="price">
                                {({ field }) => (
                                    <TextField
                                        {...field}
                                        id="price"
                                        type="number"
                                        label="Price (Including GST)"
                                        className="z-2"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            </Field>
                            <ErrorMessage
                                name="price"
                                component="span"
                                className="error-message"
                            />
                        </div>
                    </div>
                </div>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Button variant="contained" onClick={cancelModal}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                        Submit
                    </Button>
                </Box>
            </Form>
        </Formik>
    );
};

export default PartnerPricingForm;
