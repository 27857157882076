/*
Partner User Form Component, Used to render Add Partner User and Edit Partner user form
*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Box, Button, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import countriesData from "../../constants/countries.json";
import roleOptions from "../../constants/roleOptions.json";
import merchantFormFields, { merchantValidation } from "../../constants/merchantFormFields";
import { api } from "../../utils/api";

const validationSchema = Yup.object().shape(merchantValidation());

const MerchantUserForm = ({
  merchant,
  merchantUser, // if this is set we assume Edit User else Add User
  handleDisplayModal,
  fetchUserDetails,
  getMerchantUsers,
  setMerchantUsers,
  setMerchantUserLoading,
  callback,
  hideRoleSelector
}) => {
  const [modalActive, setModalActive] = useState(true);
  const [country, setBillingCountry] = useState(null);
  const [role, setRole] = useState(null);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser")) || null;
  const selectedPortal = sessionStorage.getItem("selectedPortal") || null;

  // Add state for selected country
  const handleCountryChange = (selectedOption) => {
    console.log("country select value: ", selectedOption);
    setBillingCountry(selectedOption); // Set the selected country value to country state
  };

  const handleRoleChange = (selectedOption) => {
    console.log("role select value: ", selectedOption);
    setRole(selectedOption);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("values: ", values);
    const dataToSend = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.trim().toLowerCase(),
      phone: values.phone,
      address: {
        ...values.address,
        country: country ? country.label : ""
      },
      role: role.value
    };
    console.log("Data to Send", dataToSend);
    try {
      var response = null;
      if (merchantUser) {
        response = await api(`v1/merchant/${merchant._id}/user/${merchantUser._id}`, "PATCH", dataToSend);
      } else {
        response = await api(`v1/merchant/${merchant._id}/user`, "POST", dataToSend);
      }

      if (response !== null) {
        if (response.data.success) {
          console.log("Merchant User Add or Successfully", response.data.data);
          handleDisplayModal();
          if (getMerchantUsers) {
            let users = await getMerchantUsers();
            setMerchantUsers(users);
            setMerchantUserLoading(false);
          }
          if (fetchUserDetails) {
            fetchUserDetails();
          }
        }
      }
    } catch (error) {
      console.error("API error:", error);
    }

    setSubmitting(false);
  };

  const loadCountryOptions = async (inputValue) => {
    const filteredCountries = countriesData.filter((country) =>
      country.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    // Return the filtered countries as options
    return filteredCountries;
  };

  const loadRoleOptions = async (inputValue) => {
    const filteredOptions = roleOptions.filter((role) => role.label.toLowerCase().includes(inputValue.toLowerCase()));
    // Return the filtered countries as options
    return filteredOptions;
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#9e9e9e",
      zIndex: 2
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3 // Higher zIndex for the dropdown menu
    })
  };

  const onLoadSetRole = (user) => {
    if (role == null) {
      if (user && user.role) {
        let theRole = user.role;
        setRole({
          label: theRole.charAt(0).toUpperCase() + theRole.slice(1),
          value: theRole
        });
      }
    }
  };

  const onLoadSetCountry = (user) => {
    console.log("USER: ", user);
    if (country == null) {
      let theCountry = user.address.country;
      for (let i = 0; i < countriesData.length; i++) {
        const element = countriesData[i];
        console.log("element: ", element.value);
        if (element.label == theCountry) {
          setBillingCountry({
            label: element.label,
            value: element.value
          });
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (merchantUser) {
      onLoadSetRole(merchantUser);
      onLoadSetCountry(merchantUser);
    }
  }, [merchantUser]);

  return (
    <Formik
      initialValues={merchantFormFields(merchantUser, country, role)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
        <div className="form-input-wrap flex gap-2 w-full">
          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="firstName">
                {({ field }) => <TextField {...field} label="First Name" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="firstName" component="span" className="error-message" />
            </div>

            <div className="form-group w-full">
              <Field name="lastName">
                {({ field }) => <TextField {...field} label="Last Name" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="lastName" component="span" className="error-message" />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="email">
                {({ field }) => <TextField {...field} label="Email Address" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="email" component="span" className="error-message" />
            </div>

            <div className="form-group w-full">
              <Field name="phone">
                {({ field }) => <TextField {...field} label="Phone" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="phone" component="span" className="error-message" />
            </div>
          </div>

          {/* <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="address.street">
                {({ field }) => <TextField {...field} label="Street" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="address.street" component="span" className="error-message" />
            </div>

            <div className="form-group w-full">
              <Field name="address.city">
                {({ field }) => <TextField {...field} label="City" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="address.city" component="span" className="error-message" />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="address.state">
                {({ field }) => <TextField {...field} label="State" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="address.state" component="span" className="error-message" />
            </div>

            <div className="form-group w-full">
              <Field name="address.postcode">
                {({ field }) => <TextField {...field} label="Postcode" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="address.postcode" component="span" className="error-message" />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="address.country">
                {({ field }) => (
                  <AsyncSelect
                    placeholder="Select country..."
                    name="address.country"
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCountryOptions}
                    styles={selectStyles}
                    onChange={handleCountryChange}
                    value={country}
                  />
                )}
              </Field>
              <ErrorMessage name="address.country" component="span" className="error-message" />
            </div>
          </div> */}
          {(selectedPortal === "Administrator" || currentUser?.role?.toLowerCase() != "sales") && (
            <div className="w-full flex gap-5">
              <div className="form-group w-full">
                <Field name="role">
                  {({ field }) => (
                    <AsyncSelect
                      placeholder="Select user role..."
                      name="role"
                      {...field}
                      cacheOptions
                      defaultOptions
                      loadOptions={loadRoleOptions}
                      styles={selectStyles}
                      onChange={handleRoleChange}
                      value={role}
                    />
                  )}
                </Field>
                <ErrorMessage name="role" component="span" className="error-message" />
              </div>
            </div>
          )}
        </div>
        <div className="form-actions-wrap flex gap-20 justify-between">
          <Button type="submit" variant="contained" color="primary" className="submit-button">
            Submit
          </Button>
          {/* <Button
            type="submit"
            variant="contained"
            color="error"
            className="submit-button"
            onClick={() => {
              if (callback) {
                callback();
              }
            }}
          >
            Cancel
          </Button> */}
        </div>
      </Form>
    </Formik>
  );
};

export default MerchantUserForm;
