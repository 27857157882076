import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Box, Button, Select, MenuItem, FormControl, InputLabel, debounce } from "@mui/material";
import { InputOutlined } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import the CSS for the phone input
import { TextField } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomSnackbar from "../../components/CustomSnackbar";
import { api } from "../../utils/api";
import reformatPhoneNumber from "../../utils/reformatPhoneNumber";
import { initial } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchVehicleDetails } from "../../utils/fetchVehicleDetails";
const CryptoJS = require("crypto-js");


const validationSchema = Yup.object().shape({
    personalDetails: Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        mobile: Yup.string()
            .matches(/^(?:\+61|0)[1-9]\d{8}$/i, "Invalid Australian mobile number")
            .required("Mobile is required"),
        email: Yup.string().email("Invalid email address").required("Email is required")
    }),
    vehicleDetails: Yup.object().shape({
        vehicleState: Yup.string().required("Vehicle State is required"),
        vehicleRegistration: Yup.string().required("Vehicle Registration is required"),
        vin: Yup.string()
            .matches(/^[a-zA-Z0-9]{17}$/, "VIN must be exactly 17 alphanumeric characters")
            .required("VIN is required"),
        make: Yup.string().required("Make is required"),
        model: Yup.string().required("Model is required"),
        color: Yup.string().required("Color is required")
    }),
    verification: Yup.object().shape({
        enterCode: Yup.string().required("Enter Code is required"),
        // verificationMethodInput: Yup.string().email("Invalid email address").required("Email is required"),
        // verificationMethodSMS: Yup.string().matches(/^(?:\+61|0)[1-9]\d{8}$/i, "Invalid Australian mobile number").required("Mobile is required")
    })
});

const steps = [
    {
        title: "Welcome!",
        subHeading: isMobile ? "Thank you for downloading the app." : "Personal Details are"
    },
    {
        title: "Vehicle Info",
        subHeading: ""
    },
    {
        title: "Verification",
        subHeading: ""
    },
    {
        title: "Success",
        subHeading: ""
    }
];

const ActivateMembershipWithInviteCode = () => {
    let navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [verified, setVerified] = useState(false);
    const [verificationMethod, setVerificationMethod] = useState("");
    const [queryParameters] = useSearchParams();
    const [hasRetrieved, setHasRetrieved] = useState(false);
    const [customerId, setCustomerId] = useState(null);
    const [vehicleId, setVehicleId] = useState(null);
    const [customerVehicle, setCustomerVehicle] = useState([]);
    const [userExists, setUserExists] = useState(false);
    const [sentOTP, updateSentOTP] = useState(false);
    const [askVehicleDetails, setAskVehicleDetails] = useState(false);
    const { isAuthenticated, logout } = useAuth0();
    const [snackbar, setSnackbar] = useState({ message: "", variant: "" });


    const [inviteCode, setInviteCode] = useState(queryParameters.has("code") ? queryParameters.get("code").toUpperCase() : '');
    const [initialValues, setInitialValues] = useState({
        personalDetails: {
            firstName: "",
            lastName: "",
            mobile: "",
            email: ""
        },
        vehicleDetails: {
            vehicleState: "", // Default state
            vehicleRegistration: "",
            vin: "",
            make: "",
            model: "",
            color: ""
        },
        verification: {
            enterCode: "",
            email: "",
            phone: ""
        }
    });

    const updatePersonalDetails = async (key, value) => {
        const dataToSend = {};

        if (key && value) {
            dataToSend[key] = value;
        }
        await api(`v1/customer/${customerId}/${key}`, "PATCH", dataToSend)
    }

    const updateVehicleDetails = async (key, value) => {
        const dataToSend = {};

        if (key && value) {
            dataToSend[key] = value;
        }
        await api(`v1/vehicle/${vehicleId}/updatedVehicleDetail`, "PATCH", dataToSend)
    }

    const getCode = async () => {

        if (queryParameters.has("code")) {
            const getCodeInfo = await api(`v1/inviteCode/code/${inviteCode}`, "GET", null);

            console.log("Get customer details with code: ", getCodeInfo);
            if (getCodeInfo.status == 200) {
                const data = getCodeInfo.data.data;
                const success = getCodeInfo.data.success;
                if (success) {
                    const customer = data.customer;
                    var values = { ...initialValues };

                    setCustomerVehicle(data.vehicle);
                    setCustomerId(customer._id);
                    setVehicleId(data.vehicle._id);

                    values.personalDetails = {
                        firstName: customer.firstName,
                        lastName: customer.lastName,
                        // mobile: reformatPhoneNumber(customer.phone),
                        mobile: customer.phone,
                        email: customer.email
                    };

                    setInitialValues(values);
                }
            } else {
                setInitialValues({
                    personalDetails: {
                        firstName: "",
                        lastName: "",
                        mobile: "",
                        email: ""
                    },
                    vehicleDetails: {
                        vehicleRegistration: "",
                        vehicleState: "",
                        vin: "",
                        make: "",
                        model: "",
                        color: ""
                    },
                    verification: {
                        enterCode: "",
                        email: "",
                        phone: ""
                    }
                });
            }

            setHasRetrieved(true);
        }
    };

    const getCustomerVehicles = async () => {
        const vehicleDetails = await api(`v1/vehicle/${customerVehicle._id}`, "GET", null);
        console.log("vehicleDetails: ", vehicleDetails);
        if (vehicleDetails.status == 200) {
            const data = vehicleDetails.data.data;
            const success = vehicleDetails.data.success;
            if (success) {
                var values = { ...initialValues };
                let vehicle = data.vehicle;
                values.vehicleDetails = {
                    vehicleRegistration: vehicle.rego,
                    // vehicleState: vehicle.state,
                    vin: vehicle.vin,
                    make: vehicle.make,
                    model: vehicle.model,
                    color: vehicle.color
                };

                const vin = vehicle.vin;
                const isCustomVIN = /^(\d{14})-([a-fA-F0-9]{32})$/.test(vin);
                if (isCustomVIN) {
                    setAskVehicleDetails(true);
                    values.vehicleDetails.vin = '';
                    // TODO: WHEN ASKING FOR VEHICLE DETAILS NEED TO FETCH FROM SAME API WE'RE USING ON CLIPPED. SEE THIS PAGE: https://my.clippedassist.com.au/register-and-book-repair-job 
                }
                setInitialValues(values);
            }
        }
    };

    const checkAuth0Exists = async () => {
        console.log("get customer from auth0");
        const userExists = await api(`v1/initialFetch/Customer/${initialValues.personalDetails.email}`, "GET", null);
        console.log("userExists: ", userExists);
        if (userExists.status == 200) {
            setUserExists(true);
        }
    };

    const sendOTP = async (selectedOTP, type) => {
        console.log("selecrted OTP: ", selectedOTP);
        if (customerId !== null) {
            const OTP = await api(`v1/authentication/otp`, "POST", {
                type: type,
                value: selectedOTP,
                customerId: customerId
            });
            console.log("OTP Request: ", OTP.data);
            if (OTP.status == 200) {
                const success = OTP.data.success;
                if (success) {
                    updateSentOTP(true);
                }
            }
        } else {
            alert("Cannot send OTP request, please refresh and try again.");
        }
    };

    const validateOneTimeCode = async (codeInput) => {
        const isOtpValid = await api(`v1/authentication/otp/validate`, "POST", { OTP: codeInput, customerId: customerId, vehicleId: vehicleId });
        if (isOtpValid.status == 200) {
            alert("Activation Complete. Redirecting....");
            window.location = `/my-subscriptions?new=yes&cid=${customerId}`;
        } else {
            alert(isOtpValid.data.message);
        }
    };

    const nextStep = () => {
        if (step === 0) {
            checkAuth0Exists();
            getCustomerVehicles();
        }

        if (step === 1 && askVehicleDetails) {
            const vehicleDetails = initialValues.vehicleDetails;
            console.log("VEHICLE DETAILS: ", vehicleDetails);
            const isVehicleDetailsEntered = Object.values(vehicleDetails).every(value => value != null && value !== "");
            if (!isVehicleDetailsEntered) {
                alert("Please enter all vehicle details.");
                return; // Stop further execution
            }
        }

        setStep(step + 1);
    };


    const prevStep = () => {
        setStep(step - 1);
    };


    const handleVehicleStateChange = debounce(async (setFieldValue, rego, state) => {
        if (rego && state) {
            const vehicleDetails = await fetchVehicleDetails(rego, state);
            if (vehicleDetails) {

                console.log('Fetched From NDVIS:', vehicleDetails);
                setInitialValues(prevValues => ({
                    ...prevValues,
                    vehicleDetails: {
                        ...prevValues.vehicleDetails,
                        vehicleState: state,
                        vin: vehicleDetails.vin,
                        make: vehicleDetails.make,
                        model: vehicleDetails.model
                    }
                }));

                updateVehicleDetails("vin", vehicleDetails.vin);
                updateVehicleDetails("make", vehicleDetails.make);
                updateVehicleDetails("model", vehicleDetails.model);
                setSnackbar({ variant: "success", message: "Vehicle Details fetched for respective state and rego!" });

            } else {
                setSnackbar({ variant: "error", message: `Vehicle Details not found for rego: ${rego.toUpperCase()} in ${state}` });

                var values = { ...initialValues };
                const emailHash = CryptoJS.MD5(values.personalDetails.email).toString();

                const currentDate = new Date();
                const formattedDate = currentDate.toISOString().replace(/[-:.]/g, "").replace("T", "");
                const customVIN = formattedDate.substring(0, 14) + "-" + emailHash;

                setInitialValues(prevValues => ({
                    ...prevValues,
                    vehicleDetails: {
                        ...prevValues.vehicleDetails,
                        vin: '',
                        make: '',
                        model: ''
                    }
                }));

                updateVehicleDetails("vin", customVIN);
                updateVehicleDetails("make", ' ');
                updateVehicleDetails("model", ' ');


            }
        }
    }, 500);

    const isLastStep = step === steps.length - 1;

    useEffect(() => {
        // TODO: REMOVE A SESSION ON ACTIVATION TO MAKE SURE YOU'RE STARTING FRESH

        //if (isAuthenticated) {
        // Perform a quiet logout without redirection
        //logout({ returnTo: window.location.href });
        //} else {
        if (hasRetrieved == false) {
            getCode();
        }
        //}
    }, [hasRetrieved, getCode/*, isAuthenticated*/]);

    const handleInviteCodeChange = (event) => {
        let value = event.target.value;
        // Remove any non-alphanumeric characters and convert to uppercase
        value = value.replace(/[^A-Za-z0-9]/g, '');

        setInviteCode(value.toUpperCase());
    }

    const handleFirstNameChange = (event) => {
        // initialValues.personalDetails.firstName = event.target.value;
        var values = { ...initialValues };
        values.personalDetails.firstName = event.target.value;

        setInitialValues(values);
        updatePersonalDetails('firstName', event.target.value);
    }

    const handleLastNameChange = (event) => {
        // initialValues.personalDetails.lastName = event.target.value;
        var values = { ...initialValues };
        values.personalDetails.lastName = event.target.value;

        setInitialValues(values);
        updatePersonalDetails('lastName', event.target.value);
    }

    const handleVehicleRegistrationChange = (event) => {
        const { value } = event.target;
        setInitialValues(prevValues => ({
            ...prevValues,
            vehicleDetails: {
                ...prevValues.vehicleDetails,
                vehicleRegistration: value
            }
        }));
        updateVehicleDetails('rego', value);
    };
    const handleVinChange = (event) => {
        const { value } = event.target;
        setInitialValues(prevValues => ({
            ...prevValues,
            vehicleDetails: {
                ...prevValues.vehicleDetails,
                vin: value
            }
        }));
        updateVehicleDetails('vin', value);

    };
    const handleMakeChange = (event) => {
        const { value } = event.target;
        setInitialValues(prevValues => ({
            ...prevValues,
            vehicleDetails: {
                ...prevValues.vehicleDetails,
                make: value
            }
        }));
        updateVehicleDetails('make', value);

    };
    const handleModelChange = (event) => {
        const { value } = event.target;
        setInitialValues(prevValues => ({
            ...prevValues,
            vehicleDetails: {
                ...prevValues.vehicleDetails,
                model: value
            }
        }));
        updateVehicleDetails('model', value);

    };
    const handleColorChange = (event) => {
        const { value } = event.target;
        setInitialValues(prevValues => ({
            ...prevValues,
            vehicleDetails: {
                ...prevValues.vehicleDetails,
                color: value
            }
        }));
        updateVehicleDetails('color', value);

    };


    return (hasRetrieved ?
        <div className="container activateAccount min-h-screen flex flex-column items-center justify-center gap-8 w-full">
            <h2>{steps[step].title}</h2>
            <p>{steps[step].subHeading}</p>

            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        setSubmitting(false);
                        if (!isLastStep) {
                            nextStep();
                        }
                    }, 400);
                }}
            >
                {({ errors, touched, values, setFieldValue }) => (
                    <Form className="flex flex-column gap-[20px] w-full max-w-[600px]">
                        {step === 0 && (
                            <div className="form-group w-full flex flex-column gap-4">
                                <TextField
                                    label="Invite Code"
                                    name="inviteCode"
                                    variant="outlined"
                                    fullWidth
                                    value={inviteCode}
                                    onChange={handleInviteCodeChange}
                                    onBlur={getCode}
                                    inputProps={{
                                        pattern: '^[A-Z0-9]*$', // Regular expression pattern for uppercase letters and numbers
                                    }}
                                />
                                <TextField
                                    label="First Name"
                                    name="personalDetails.firstName"
                                    variant="outlined"
                                    fullWidth
                                    value={initialValues.personalDetails.firstName}
                                    onChange={handleFirstNameChange}
                                    InputProps={{
                                        // readOnly: true
                                    }}
                                />
                                <TextField
                                    label="Last Name"
                                    name="personalDetails.lastName"
                                    variant="outlined"
                                    fullWidth
                                    value={initialValues.personalDetails.lastName}
                                    onChange={handleLastNameChange}
                                    InputProps={{
                                        // readOnly: true
                                    }}
                                />
                                <FormControl fullWidth variant="outlined">
                                    <Field
                                        as={PhoneInput}
                                        onlyCountries={["au"]}
                                        country={"au"}
                                        name="personalDetails.mobile"
                                        disableCountryCode={true}
                                        inputProps={{ id: "mobile", readOnly: true }}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <TextField
                                    label="Email"
                                    name="personalDetails.email"
                                    variant="outlined"
                                    fullWidth
                                    value={initialValues.personalDetails.email}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                                <div className="flex justify-evenly items-center w-full">
                                    <Button variant="contained" color="primary" type="submit" onClick={nextStep}>
                                        Next
                                    </Button>
                                </div>
                            </div>
                        )}
                        {step === 1 && (

                            <div className="form-group w-full flex flex-column gap-4">


                                <TextField
                                    label="Vehicle Registration"
                                    name="vehicleDetails.vehicleRegistration"
                                    variant="outlined"
                                    fullWidth
                                    value={initialValues.vehicleDetails.vehicleRegistration}
                                    onChange={handleVehicleRegistrationChange}
                                    InputProps={{
                                        readOnly: !askVehicleDetails
                                    }}
                                />
                                <div>
                                    <label htmlFor="vehicleState">State</label>
                                    <Field
                                        as="select"
                                        id="vehicleState"
                                        name="vehicleDetails.vehicleState"
                                        onChange={(e) => {
                                            const state = e.target.value;
                                            setFieldValue("vehicleDetails.vehicleState", state);
                                            handleVehicleStateChange(setFieldValue, values.vehicleDetails.vehicleRegistration, state);
                                        }}
                                        className="block w-full text-center px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        <option value="">---SELECT STATE---</option>
                                        <option value="NSW">NSW</option>
                                        <option value="VIC">VIC</option>
                                        <option value="QLD">QLD</option>
                                        <option value="SA">SA</option>
                                        <option value="WA">WA</option>
                                        <option value="TAS">TAS</option>
                                        <option value="ACT">ACT</option>
                                        <option value="NT">NT</option>
                                    </Field>
                                    <ErrorMessage name="vehicleDetails.vehicleState" component="div" />
                                </div>

                                <TextField
                                    label="VIN"
                                    name="vehicleDetails.vin"
                                    variant="outlined"
                                    fullWidth
                                    value={initialValues.vehicleDetails.vin}
                                    onChange={handleVinChange}
                                    InputProps={{
                                        readOnly: !askVehicleDetails
                                    }}
                                />
                                <TextField
                                    label="Make"
                                    name="vehicleDetails.make"
                                    variant="outlined"
                                    fullWidth
                                    value={initialValues.vehicleDetails.make}
                                    onChange={handleMakeChange}
                                    InputProps={{
                                        readOnly: !askVehicleDetails
                                    }}
                                />
                                <TextField
                                    label="Model"
                                    name="vehicleDetails.model"
                                    variant="outlined"
                                    fullWidth
                                    value={initialValues.vehicleDetails.model}
                                    onChange={handleModelChange}
                                    InputProps={{
                                        readOnly: !askVehicleDetails
                                    }}
                                />
                                <TextField
                                    label="Color"
                                    name="vehicleDetails.color"
                                    variant="outlined"
                                    fullWidth
                                    value={initialValues.vehicleDetails.color}
                                    onChange={handleColorChange}
                                    InputProps={{
                                        readOnly: !askVehicleDetails
                                    }}
                                />

                                <div className="flex justify-evenly items-center w-full">
                                    <Button variant="contained" color="primary" type="submit" onClick={prevStep}>
                                        Previous
                                    </Button>
                                    <Button variant="contained" color="primary" type="submit" onClick={nextStep}>
                                        Next
                                    </Button>
                                </div>
                            </div>
                        )}
                        {step === 2 && (
                            <div className="form-group w-full flex flex-column gap-4">
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="verificationMethod">Select Verification Method</InputLabel>

                                    <Select
                                        variant="outlined"
                                        label="Select Verification Method"
                                        id="verificationMethod"
                                        name="verificationMethod"
                                        value={verificationMethod}
                                        onChange={(e) => {
                                            setVerificationMethod(e.target.value);
                                        }}
                                    >
                                        <MenuItem value="" disabled>Select Option</MenuItem>
                                        <MenuItem value="Email">Email</MenuItem>
                                        <MenuItem value="Phone">Phone</MenuItem>
                                    </Select>
                                </FormControl>
                                {verificationMethod && verificationMethod != "" && (
                                    <div className="flex flex-column">
                                        <div className="flex flex-row">
                                            {sentOTP ? (
                                                <Field
                                                    className="w-full"
                                                    label="Enter Code"
                                                    name="verification.enterCode"
                                                    variant="outlined"
                                                />
                                            ) : (
                                                <>
                                                    <Field
                                                        className="w-full"
                                                        label=""
                                                        name="verification.emailOrPhone"
                                                        variant="outlined"
                                                        readOnly
                                                        value={verificationMethod === 'Phone' ? initialValues.personalDetails.mobile : initialValues.personalDetails.email}
                                                    // validate={validateEmail}
                                                    />
                                                    {errors.verificationMethodInput && touched.verificationMethodInput && (
                                                        <div>
                                                            <label className="error">{errors.verificationMethodInput}</label>{" "}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            <Button
                                                className="w-40"
                                                variant="outlined"
                                                onClick={() => {
                                                    if (sentOTP) {
                                                        validateOneTimeCode(values.verification.enterCode); // Pass the value directly
                                                    } else {
                                                        const type = verificationMethod === 'Phone' ? 'sms' : 'email';
                                                        const phoneOrEmail = verificationMethod === 'Phone' ? values.personalDetails.mobile : values.personalDetails.email;
                                                        sendOTP(phoneOrEmail, type);
                                                    }
                                                }}
                                            >
                                                {sentOTP ? "Confirm Code" : "Send OTP"}
                                            </Button>
                                        </div>
                                        {errors.verificationMethodInput && touched.verificationMethodInput && (
                                            <div>
                                                <label className="error">{errors.verificationMethodInput}</label>{" "}
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className="flex justify-evenly items-center w-full">
                                    <Button variant="contained" color="primary" type="submit" onClick={prevStep}>
                                        Previous
                                    </Button>
                                    {verified && (
                                        <Button variant="contained" color="primary" type="submit" onClick={nextStep}>
                                            Next
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        {step === 3 && (
                            <div className="form-group w-full flex flex-column gap-4 activate-step3">
                                <h2>Success Page</h2>
                                <p>Show two CTA HERE</p>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
            {snackbar && <CustomSnackbar {...snackbar} />}
        </div >
        :
        <div className="flex justify-center items-center h-screen w-full ">
            <p>Looks like you are lost!</p>
        </div>
    );
};

export default ActivateMembershipWithInviteCode;
