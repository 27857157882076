import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Box, Button, Typography } from "@mui/material";
import { api } from "../../../utils/api";
import DynamicFieldsGenerator from "../../../utils/dynamicFieldsGenerator";
import ProductCard from "../ProductCard";
import PromotionCard from "../PromotionCard";
import GlobalLoadingSpinner from "../../../components/GlobalLoadingSpinner";
import CustomSnackbar from "../../../components/CustomSnackbar";

const SignupCustomerForm = () => {
  let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const CryptoJS = require("crypto-js");
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // object where key is product id, and value is an array of the promotions relating to that product that have been selected
  const [selectedPromotions, setSelectedPromotions] = useState({});
  // object where key is product id and value is is an array of all of its available promotions
  const [productPromotions, setProductPromotions] = useState({});
  const [snackbar, setSnackbar] = useState({ message: "", variant: "" });

  const resetPage = () => {
    setSelectedProducts([]);
    setSelectedPromotions({});
    setProductPromotions({});
  };

  const [selectedInputFieldGroup, setSelectedInputFieldGroup] = useState([
    "Basic Details"
    // "Address",
    // "Vehicle Details"
  ]);
  const selectedPartnerCompany = sessionStorage.getItem("selectedCompany");

  const getAllProducts = async () => {
    try {
      const response = await api(`v1/pricing/company/${selectedPartnerCompany}`, "GET", null);
      // api call to partner pricing when endpoint is created.
      const productPricings = response.data.data.allProducts;
      const products = await Promise.all(
        productPricings.map(async (product) => {
          const response = await api(`v1/product/${product._id}`, "GET", null);
          if (response.data.success) {
            return response.data.data.product;
          }
        })
      );
      setProductOptions(products);
    } catch (error) {
      console.error("Error loading Products:", error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const addProductToCart = async (product) => {
    setSelectedProducts([...selectedProducts, product]);
    addProductPromotions(product);
    const { requiredFields } = product;
    console.log("selectedInputFieldGroup", selectedInputFieldGroup);
    setSelectedInputFieldGroup([...selectedInputFieldGroup, ...requiredFields]);
    console.log("requiredFields", requiredFields);
    console.log("product", product);
  };

  const removeProductFromCart = async (product) => {
    setSelectedProducts([...selectedProducts.filter((p) => p._id !== product._id)]);
    removeProductPromotions(product);
    const { requiredFields } = product;
    const inputFieldGroup = [...selectedInputFieldGroup];
    requiredFields.forEach((field) => {
      const idx = inputFieldGroup.findIndex((inputField) => inputField === field);
      inputFieldGroup.splice(idx, 1);
    });
    setSelectedInputFieldGroup([...inputFieldGroup]);
  };

  const isProductSelected = (product) => selectedProducts.some((p) => p._id === product._id);

  const handleProductClick = (product) =>
    isProductSelected(product) ? removeProductFromCart(product) : addProductToCart(product);

  const getProductName = (productId) =>
    productOptions.find((product) => product._id === productId).name || "Name not found";

  const addProductPromotions = async (product) => {
    const response = await api(`v1/promotion/product/${product._id}`, "GET", null);
    if (response.data.success) {
      const promotions = response.data.data.promotions;
      setProductPromotions((productPromotions) => ({ ...productPromotions, [product._id]: promotions }));
    }
  };

  const removeProductPromotions = async (product) => {
    const productPromotionsCopy = { ...productPromotions };
    delete productPromotionsCopy[product._id];
    setProductPromotions(productPromotionsCopy);

    // if any promotions have previously been selected, remove them.
    if (selectedPromotions.hasOwnProperty(product._id)) {
      const selectedPromotionsCopy = { ...selectedPromotions };
      delete selectedPromotionsCopy[product._id];
      setSelectedPromotions(selectedPromotionsCopy);
    }
  };

  const isPromotionSelected = (productId, promotion) => {
    if (!selectedPromotions) return false;
    if (!selectedPromotions[productId]) return false;
    return selectedPromotions[productId] === promotion._id;
  };

  const handlePromotionClick = (productId, promotion) =>
    isPromotionSelected(productId, promotion)
      ? removePromotionFromCart(productId, promotion)
      : addPromotionToCart(productId, promotion);

  const removePromotionFromCart = async (productId) => {
    const selectedPromotionsCopy = { ...selectedPromotions };
    delete selectedPromotionsCopy[productId];
    setSelectedPromotions(selectedPromotionsCopy);
  };

  const addPromotionToCart = async (productId, promotion) =>
    setSelectedPromotions((prev) => ({ ...prev, [productId]: promotion._id }));

  {
    /*
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsSubmitting(true);

    const dataToSend = {
      // add users timezone
      createdOnUTC: new Date().getTime(),
      email: values.email,
      phone: values.mobile,
      firstName: values.firstName,
      ...(values.lastName && { lastName: values.lastName }),
      address: {
        street: values.street,
        city: values.city,
        state: values.state,
        country: "Australia",
        postcode: values.postcode
      },
      vin: values.vin,
      rego: values.rego,
      make: values.make,
      model: values.model,
      modelYear: values.modelYear,
      color: values.color,
      age: values.age,
      poNumber: values.poNumber,
      stockCode: values.stockCode,
      products: selectedProducts,
      promotions: selectedPromotions,
      idPartnerCompany: sessionStorage.getItem("selectedCompany") || null
    };

    try {
      let response = await api(`v1/signupCustomer`, "POST", dataToSend);
      if (response.data.success) {
        setSnackbar({ variant: "success", message: "Customer successfully signed up!" });
      } else {
        response?.data?.message && setSnackbar({ variant: "error", message: response.data.message });
      }
    } catch (error) {
      console.error("API error:", error);
      setSnackbar({ type: "error", message: "An unexpected error occured. Please try again." });
    }
    setIsSubmitting(false);
    resetForm();
    resetPage();
    setSubmitting(false);
  };
*/
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log('HERE');
    setIsSubmitting(true);

    const dataToSend = {
      // add users timezone
      createdOnUTC: new Date().getTime(),
      email: values.email,
      phone: values.mobile,
      firstName: values.firstName,
      ...(values.lastName && { lastName: values.lastName }),
      products: selectedProducts,
      promotions: selectedPromotions,
      idPartnerCompany: sessionStorage.getItem("selectedCompany") || null,
      idPartnerUser: currentUser._id || null
    };

    // Generate default VIN to be used if vehicle details are not passed, else will use the passed vehicle details.
    const emailHash = CryptoJS.MD5(values.email).toString();
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().replace(/[-:.]/g, "").replace("T", "");
    const customVIN = formattedDate.substring(0, 14) + "-" + emailHash;
    dataToSend.vin = customVIN;

    // Include address fields only if they exist in the form values
    if (selectedInputFieldGroup.includes("Address")) {
      dataToSend.address = {
        street: values.street,
        city: values.city,
        state: values.state,
        country: "Australia",
        postcode: values.postcode
      };
    }
    // Include vehicle fields only if they exist in the form values
    if (selectedInputFieldGroup.includes("Vehicle Details")) {
      dataToSend.vin = values.vin;
      dataToSend.rego = values.rego;
      dataToSend.make = values.make;
      dataToSend.model = values.model;
      dataToSend.modelYear = values.modelYear;
      dataToSend.color = values.color;
      dataToSend.age = values.age;
      dataToSend.poNumber = values.poNumber;
      dataToSend.stockCode = values.stockCode;
    }

    try {
      console.log("SUBMITTING NOW", dataToSend);

      let response = await api(`v1/signupCustomer`, "POST", dataToSend);
      if (response.data.success) {
        setSnackbar({ variant: "success", message: "Customer successfully signed up!" });
      } else {
        response?.data?.message && setSnackbar({ variant: "error", message: response.data.message });
      }
    } catch (error) {
      console.error("API error:", error);
      setSnackbar({ variant: "error", message: "An unexpected error occurred. Please try again." });
    }
    setIsSubmitting(false);
    resetForm();
    resetPage();
    setSubmitting(false);
  };

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string().email().required("Email is required"),
  //   mobile: Yup.string()
  //     .matches(/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/, "Invalid Australian phone number")
  //     .required("Phone number is required"),
  //   firstName: Yup.string().required("First Name is required"),
  //   street: Yup.string().required("Street Address is required"),
  //   city: Yup.string().required("City is required"),
  //   state: Yup.string().required("State is required"),
  //   postcode: Yup.string().required("Postcode is required"),
  //   vin: Yup.string().required("VIN is required").min(17, "VIN must be 17 character in length."),
  //   rego: Yup.string().required("REGO is required"),
  //   make: Yup.string().required("Make is required"),
  //   model: Yup.string().required("Model is required"),
  //   modelYear: Yup.string().test("No characters", "Model year should be a number", (value) => /^\d+$/.test(value)),
  //   color: Yup.string().required("Car Colour is required"),
  //   age: Yup.string().required("Vehicle age must be selected")
  // });
  let validationSchemaFields = {
    email: Yup.string().email().required("Email is required"),
    mobile: Yup.string()
      .matches(/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/, "Invalid Australian phone number")
      .required("Phone number is required"),
    firstName: Yup.string().required("First Name is required")
    // rego: Yup.string().required("REGO is required"),
    // make: Yup.string().required("Make is required"),
    // model: Yup.string().required("Model is required"),
    // modelYear: Yup.string().test("No characters", "Model year should be a number", (value) => /^\d+$/.test(value)),
    // color: Yup.string().required("Car Colour is required"),
    // age: Yup.string().required("Vehicle age must be selected")
  };

  // const initialValues = {
  //   email: "",
  //   mobile: "",
  //   firstName: "",
  //   lastName: "",
  //   street: "",
  //   city: "",
  //   state: "",
  //   postcode: "",
  //   vin: "",
  //   rego: "",
  //   make: "",
  //   model: "",
  //   modelYear: "",
  //   color: "",
  //   age: "",
  //   poNumber: "",
  //   stockCode: ""
  // };
  let initialValues = {
    email: "",
    mobile: "",
    firstName: "",
    // lastName: ""
    // vin: "",
    // rego: "",
    // make: "",
    // model: "",
    // modelYear: "",
    // color: "",
    // age: "",
    // poNumber: "",
    // stockCode: ""
  };

  // If "Address" is in the selectedInputFieldGroup, include address fields in validation and initial values
  if (selectedInputFieldGroup.includes("Address")) {
    validationSchemaFields = {
      ...validationSchemaFields,
      street: Yup.string().required("Street Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      postcode: Yup.string().required("Postcode is required")
    };
    // Update initial values object
    initialValues = {
      ...initialValues,
      street: "",
      city: "",
      state: "",
      postcode: ""
    };
  }

  // If "Vehicle Details" is in the selectedInputFieldGroup, include respective fields in validation and initial values
  if (selectedInputFieldGroup.includes("Vehicle Details")) {
    validationSchemaFields = {
      ...validationSchemaFields,
      vin: Yup.string().required("VIN is required").min(17, "VIN must be 17 character in length."),
      rego: Yup.string().required("REGO is required"),
      make: Yup.string().required("Make is required"),
      model: Yup.string().required("Model is required"),
      modelYear: Yup.string().test("No characters", "Model year should be a number", (value) => /^\d+$/.test(value)),
      // color: Yup.string().required("Car Colour is required")
      // age: Yup.string().required("Vehicle age must be selected")
    };
    // Update initial values object
    initialValues = {
      ...initialValues,
      vin: "",
      rego: "",
      make: "",
      model: "",
      modelYear: "",
      // color: "",
      // age: "",
      // poNumber: "",
      // stockCode: ""
    };
  }

  // Construct validation schema
  const validationSchema = Yup.object().shape(validationSchemaFields);

  function countItemsInObject(object) {
    let count = 0;

    // Iterate over the values of the object
    for (const value of Object.values(object)) {
      // Check if the value is an array
      if (Array.isArray(value)) {
        // Add the length of the array to the count
        count += value.length;
      }
    }

    return count;
  }

  console.log("Validatino Schema:", validationSchema);
  console.log("Innitial Values:", initialValues);


  return isSubmitting ? (
    <GlobalLoadingSpinner />
  ) : (
    <div className="signupCustomerWrap flex w-full flex-column gap-2">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="form w-full flex flex-column justify-center items-center priorityoneform min-h-[20vh] p-2 px-4">
            <div className="form-input-wrap flex gap-2 w-full justify-center items-center mb-2">
              <div className="w-full flex gap-5 mb-4">
                <div className="form-group w-full">
                  <h3 className="font-bold text-center mb-4">Choose Products for your Customer</h3>
                  <Field name="products">
                    {({ field }) => (
                      <div className="flex flex-wrap gap-2 justify-center">
                        {productOptions.map((product, index) => (
                          <ProductCard
                            key={product._id + index}
                            product={product}
                            isSelected={isProductSelected(product)}
                            handleClick={() => handleProductClick(product)}
                          />
                        ))}
                      </div>
                    )}
                  </Field>
                  <ErrorMessage name="products" component="span" className="error-message" />
                </div>
              </div>

              <h3 className="font-bold">Enter your customer's details</h3>
              <div className="w-full flex flex-column gap-2">
                {selectedInputFieldGroup && (
                  <DynamicFieldsGenerator
                    selectedInputFieldGroup={selectedInputFieldGroup}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                )}
                <h3>Add Ons</h3>
                {productOptions.map((product, index) => (
                  <ProductCard
                    key={index + product._id}
                    product={product}
                    isSelected={isProductSelected(product)}
                    handleClick={() => handleProductClick(product)}
                    isLandscape
                  />
                ))}
              </div>
              {/* Render Promotions based on selected products */}
              <div className="w-full flex flex-column gap-2">
                {/* {selectedPromotionsFieldGroup &&
                <DynamicFieldsGenerator
                  selectedPromotionsFieldGroup={selectedPromotionsFieldGroup}
                />
              } */}
                {countItemsInObject(productPromotions) > 0 && (
                  <>
                    <h3>Promotions</h3>
                    <div className="flex gap-x-2">
                      {Object.entries(productPromotions).map(([productId, promotions]) => (
                        <div className="p-2 flex text-center flex-column w-96 border-2 border-sky-500">
                          <Typography sx={{ marginBottom: "10px" }} variant="h6">
                            {getProductName(productId)}
                          </Typography>
                          <div className="flex flex-column gap-y-2">
                            {promotions.map((promotion) => (
                              <PromotionCard
                                key={promotion._id}
                                promotion={promotion}
                                isSelected={isPromotionSelected(productId, promotion)}
                                handleClick={() => handlePromotionClick(productId, promotion)}
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Button type="submit" variant="contained" disabled={selectedProducts.length === 0}>
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {snackbar && <CustomSnackbar {...snackbar} />}
    </div>
  );
};

export default SignupCustomerForm;
