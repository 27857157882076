import React, { useState } from "react";
import * as Images from "../themes/images";
import IframeModal from "./IframeModal"; // Import the IframeModal component

let footerSections = [
  {
    sectionTitle: "For You",
    sectionItems: [
      {
        label: "Login",
        url: "/loginPath"
      },
      {
        label: "Register",
        url: "/registerPath"
      }
    ]
  },
  {
    sectionTitle: "For Business",
    sectionItems: [
      {
        label: "Login",
        url: "/loginPath"
      },
      {
        label: "1800 574 241",
        url: "tel:1800574241"
      }
    ]
  },
  {
    sectionTitle: "Support",
    sectionItems: [
      {
        label: "Contact Us",
        url: "/contact-us"
      }
    ]
  },
  {
    sectionTitle: "The App",
    sectionItems: [
      {
        label: "Download",
        url: "/downloadUrl"
      }
    ]
  }
];

function GlobalFooter() {
  const footerLogo = Images.globalLogoSmall;
  const termsAndConditionsUrl = "https://priorityonewashclub.com/terms-and-conditions/";
  const privacyPolicyUrl = "https://priorityonewashclub.com/privacy-policy/";
  const websiteURL = "https://priorityone.com.au/";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalUrl, setModalUrl] = useState("");

  const goToHome = () => {
    window.location.href = websiteURL;
  };

  const openModal = (url) => {
    setModalUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalUrl("");
  };

  return (
    <div className="globalFooter flex flex-column gap-4 bg-darkblue text-white justify-center items-center w-full bg-blue py-10">
      <img src={footerLogo} alt="" onClick={() => goToHome()} />

      <div className="globalFooterContent flex flex-row flex-wrap md:flex-column justify-evenly w-full gap-4">
        {footerSections.map((element, index) => {
          return (
            <div key={"footerdiv" + index} className="footerSectionCol gap-2 items-center flex flex-column">
              <div className="footerSectionTitle text-teal-400">{element.sectionTitle}</div>
              <nav className="navbar navbar-expand-lg bg-none flex flex-column gap-2">
                {element.sectionItems.map((menuItems, index) => {
                  return (
                    <a key={"footerlink" + index} className="text-teal-100" href={menuItems.url}>
                      {menuItems.label}
                    </a>
                  );
                })}
              </nav>
            </div>
          );
        })}
      </div>
      <div className="globalFooterBottom flex justify-evenly items-center w-full max-w-[320px]">
        <a className="text-teal-400" href="#" onClick={() => openModal(termsAndConditionsUrl)}>
          Terms and Conditions
        </a>
        <a className="text-teal-400" href="#" onClick={() => openModal(privacyPolicyUrl)}>
          Privacy Policy
        </a>
      </div>
      <IframeModal isOpen={isModalOpen} url={modalUrl} onClose={closeModal} />
    </div>
  );
}

export default GlobalFooter;
