import React from "react";

const VehicleImageUpload = ({ handleChange, handleSubmit }) => (
  <form method="post" enctype="multipart/form-data">
    <input className="pt-1" name="image" type="file" onChange={handleChange} />
    &nbsp;
    <button className="p-2 rounded-sm hover:bg-grey" type="submit" onClick={handleSubmit}>
      Upload
    </button>
  </form>
);

export default VehicleImageUpload;
