import React, { Component } from "react";

const InfoTable = ({ data }) => {
  if (data.billing) {
    data = data.billing;
  }
  return (
    <div>
      <div className="billingDetailsWrap flex items-center gap-4">
        <span className="font-semibold">Email: </span>
        <span className="font-semibold">
          <a href={"mailto:" + data.email}>{data.email}</a>{" "}
        </span>
      </div>

      <div className="billingDetailsWrap flex items-center gap-4">
        <span className="font-semibold">Phone: </span>
        <span className="font-semibold">
          <a href={"tel:" + data.phone}>{data.phone} </a>
        </span>
      </div>

      <div className="billingDetailsWrap flex items-center gap-4">
        <span className="font-semibold">Billing Address: </span>
        <div>
          <p>{data.address.street}</p>
          <p>{data.address.city}</p>
          <p>{data.address.state}</p>
          <p>{data.address.postcode}</p>
          <p>{data.address.country.label}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoTable;
