import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import RedemptionForm from "./Forms/RedemptionForm";
import EntityCardDisplay from "../../components/EntityCardDisplay";
import { api } from "../../utils/api";
import RedemptionTable from "./RedemptionTable";
import RedemptionScanner from "./Widget/RedemptionScanner";

const Redemption = () => {
  const [redemptionItems, setRedemption] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showRedemptionForm, setShowRedemptionForm] = useState(false);
  const [showRedemptionScanner, setShowRedemptionScanner] = useState(false);

  useEffect(() => {
    fetchRedemptions();
  }, []);

  const fetchRedemptions = async () => {
    try {
      let endpoint = '';
      if (sessionStorage.getItem("selectedPortal") == 'Merchant' && sessionStorage.getItem("selectedMerchantStore")) {
        endpoint = `v1/merchantStore/${sessionStorage.getItem("selectedMerchantStore")}/redemptions`;
      } else if (sessionStorage.getItem("selectedPortal") == 'Administrator') {
        endpoint = `v1/redemption`;
      }
      const response = await api(endpoint, "GET");
      if (response.data.success) {
        const redemptions = response.data.data.allRedemptions;
        /*
        const updatedRedemptions = await Promise.all(
          redemptions.map(async (redemption) => {
            try {
              const vehicleResponse = await api(
                `v1/vehicle/${redemption.vehicle}`,
                "GET"
              );
              const productResponse = await api(
                `v1/product/${redemption.product}`,
                "GET"
              );
              const merchantStoreResponse = await api(
                `v1/merchantStore/${redemption.merchantStore}`,
                "GET"
              );
              const merchantUserResponse = await api(
                `v1/merchantUser/${redemption.merchantUser}`,
                "GET"
              );
              const invoiceDetailsResponse = await api(
                `v1/redemption/${redemption._id}/invoiceDetails`,
                "GET"
              );
              // console.log("INVOICE DETAILS:", invoiceDetailsResponse.data.data.invoiceDetails[0].Status);

              if (
                vehicleResponse.data.success &&
                productResponse.data.success
              ) {
                return {
                  ...redemption,
                  // vehicle: [
                  //   'REGO: ' + rego,
                  //   'VIN: ' + vin,
                  // ],
                  vehicle:
                    vehicleResponse.data.data.vehicle.vin +
                    " (" +
                    vehicleResponse.data.data.vehicle.rego +
                    ")",
                  product: productResponse.data.data.product.name,
                  merchantStore: merchantStoreResponse.data.success
                    ? merchantStoreResponse.data.data.merchantStore.name
                    : "",
                  merchantUser: merchantUserResponse.data.success
                    ? merchantUserResponse.data.data.merchantUser.firstName +
                    " " +
                    merchantUserResponse.data.data.merchantUser.lastName
                    : "",
                  invoiceStatus: invoiceDetailsResponse.data.success ? invoiceDetailsResponse.data.data.invoiceDetails[0].Status : 'NO STATUS'
                  // product: [
                  //   'Name: ' + name,
                  //   'Type: ' + type,
                  //   'Duration: ' + redemptionDuration,
                  //   'Price: $' + price,
                  //   'RRP: $' + rrp,
                  //   'Active: ' + active,
                  // ],
                };
              }
            } catch (error) {
              console.error("API error:", error);
            }
            return redemption;
          })
        );

        setRedemption(updatedRedemptions);
        */
        setRedemption(redemptions);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const triggerRedemptionListUpdate = (redemption) => {
    console.log("New/Updated Redemption: ", redemption);
    setShowRedemptionForm(false);
    setLoading(false);
    fetchRedemptions();
  };

  const fieldsToDisplay = [
    { key: "vehicle", label: "Vehicle", className: "font-bold" },
    { key: "product", label: "Product", className: "font-bold" },
    { key: "merchantStore", label: "Merchant Store", className: "font-bold" },
    { key: "merchantUser", label: "Merchant User", className: "font-bold" },
    {
      key: "redemptionDateUTC",
      label: "Redemption Date",
      className: "font-bold"
    }
  ];

  console.log("redemptionItems", redemptionItems);

  return (
    <div className="page redemption w-full flex flex-column items-center p-4">
      {loading && <GlobalLoadingSpinner />}
      <div className="pageTitle">
        <h2>Redemptions</h2>
      </div>
      <div className="flex w-full">
        <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
          <div className="flex flex-column items-end gap-4 items-center">
            {sessionStorage.getItem("selectedPortal") == "Administrator" && (
              <div className="pageActions">
                <Button
                  variant="contained"
                  onClick={() => setShowRedemptionForm(true)}
                >
                  Add New Redemption
                </Button>
                <ModalPopup
                  className="w-full"
                  showModal={showRedemptionForm}
                  handleShowModal={() => setShowRedemptionForm(false)}
                  popupTitle="Add New Redemption"
                  popupContent={
                    <RedemptionForm
                      callback={triggerRedemptionListUpdate}
                      closeModal={() => setShowRedemptionForm(false)}
                    />
                  }
                />
              </div>
            )}
            {sessionStorage.getItem("selectedPortal") == "Merchant" && (
              <div className="pageActions">
                <Button
                  variant="contained"
                  onClick={() => setShowRedemptionScanner(true)}
                >
                  Redeem Redemption
                </Button>
                <ModalPopup
                  className="w-full"
                  showModal={showRedemptionScanner}
                  handleShowModal={() => setShowRedemptionScanner(false)}
                  popupTitle="Redeem Redemption"
                  popupContent={
                    <RedemptionScanner
                      callback={triggerRedemptionListUpdate}
                      closeModal={() => setShowRedemptionScanner(false)}
                    />
                  }
                />
              </div>
            )}
            {redemptionItems && (
              <RedemptionTable redemptions={redemptionItems} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redemption;
