const fetchVehicleDetails = async (registration, state = 'NSW') => {
    const query = {
        query: `query {
      nevdisPlateSearch_v2(plate: "${registration}", state: ${state}) {
        vin
        plate {
          number
          state
        }
        make
        model
        compliance_plate
      }
    }`,
        variables: {}
    };

    const url = 'https://ubuxgyols2.execute-api.ap-southeast-2.amazonaws.com/prod/';
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxYmU2OGIwMC00OTk5LTQ3MjktYTQ0Mi0yYmVkZWI4YzY2NWYiLCJuYW1lIjoiQ2xpcHBlZCBBc3Npc3QgUHR5IEx0ZCIsImlhdCI6MTUxNjIzOTAyMn0.5Pb_XPU8eSRmm8Ou5u1Z5iEuzwTdtQVGkSlunpMkoAI'
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(query)
        });

        const data = await response.json();

        if (!response.ok || !data || !data.data || !data.data.nevdisPlateSearch_v2) {
            console.error('Failed to fetch vehicle details:', data);
            throw new Error('Failed to fetch vehicle details');
        }

        const { nevdisPlateSearch_v2 } = data.data;

        if (nevdisPlateSearch_v2) {
            // const { vin, make, model, compliance_plate } = nevdisPlateSearch_v2[0];
            return nevdisPlateSearch_v2[0];
        } else {
            console.error('Vehicle state information not available:', data);
            throw new Error('Vehicle state information not available');
        }
    } catch (error) {
        console.error('Error fetching vehicle details:', error.message);
        throw new Error('Failed to fetch vehicle details');
    }
};

export { fetchVehicleDetails };
