import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { COLORS } from "../../utils/colors";

const AdministratorList = ({ admins }) => {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, borderCollapse: "separate", "td, th": { borderBottom: "none" }, th: { fontSize: "18px" } }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {admins.map((admin) => (
            <TableRow
              onClick={() => navigate(`/administrator/${admin._id}`)}
              key={admin._id}
              sx={{
                boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                "&:hover": { cursor: "pointer" }
              }}
            >
              <TableCell scope="row">{admin.firstName}</TableCell>
              <TableCell>{admin.lastName}</TableCell>
              <TableCell>{admin.email}</TableCell>
              <TableCell>{admin.phone}</TableCell>
              <TableCell>{admin.role}</TableCell>
              <TableCell>{admin.active ? "Yes" : "No"}</TableCell>
              <TableCell>
                <Button variant="contained" sx={{ backgroundColor: COLORS.priorityBlue.hex }}>
                  View Profile
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdministratorList;
