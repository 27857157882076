import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Logout = () => {
  const { isAuthenticated, logout, user } = useAuth0();

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleLogout();
    }
  }, []);

  return <></>;
};

export default Logout;
