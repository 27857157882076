import React from "react";
import { Checkbox, Typography } from "@mui/material";

const PromotionCard = ({ promotion, isSelected, handleClick }) => (
  <div className="flex w-full border border-black content-center">
    <Checkbox checked={isSelected} onChange={handleClick} inputProps={{ "aria-label": "controlled" }} />
    <Typography sx={{ marginTop: "auto", marginBottom: "auto" }} variant="button" className="font-bold">
      {promotion.title}
    </Typography>
  </div>
);

export default PromotionCard;
