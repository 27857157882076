import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button, TextField } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import AdministratorForm from "./Forms/AdministratorForm";
import { api } from "../../utils/api";
import AdministratorList from "./AdministratorList";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import AddIcon from "@mui/icons-material/Add";
import { COLORS } from "../../utils/colors";
import EmptyState from "../EmptyStates/EmptyState";

const Administrator = () => {
  const [administratorItems, setAdministrators] = useState([]);
  const { filteredData, search, setSearch } = useBasicSearch(administratorItems);

  const [loading, setLoading] = useState(true);
  const [showAdministratorForm, setShowAdministratorForm] = useState(false);

  useEffect(() => {
    fetchAdministrators();
  }, []);

  const fetchAdministrators = async () => {
    try {
      const response = await api("v1/administrator", "GET");
      if (response.data.success) {
        const updatedAdministrators = response.data.data.allAdministrators.map((administrator) => {
          return {
            ...administrator
          };
        });

        setAdministrators(updatedAdministrators);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const triggerAdministratorListUpdate = (administrator) => {
    setShowAdministratorForm(false);
    setLoading(false);
    setAdministrators((prev) => [...prev, administrator]);
  };

  const AddNewAdmin = () => (
    <Button
      sx={{ backgroundColor: COLORS.priorityBlue.hex }}
      variant="contained"
      onClick={() => {
        setSearch("");
        setShowAdministratorForm(true);
      }}
      startIcon={<AddIcon />}
    >
      Add New Admin
    </Button>
  );

  return (
    <div className="page administrator w-full flex flex-column items-center p-4">
      <div className="flex pageTitle flex-col items-center md:flex-row md:w-full md:justify-between">
        <h1>Administrators</h1>
        <div className="flex flex-col gap-y-2 items-center md:flex-row md:gap-x-2">
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: "250px" }}
            placeholder="Search for an admin"
          />
          <AddNewAdmin />
        </div>
      </div>
      {loading ? (
        <GlobalLoadingSpinner />
      ) : (
        <div className="flex w-full">
          <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
            <div className="flex flex-column items-end gap-4 items-center">
              <div className="pageActions">
                <ModalPopup
                  showModal={showAdministratorForm}
                  handleShowModal={() => setShowAdministratorForm(false)}
                  popupTitle="Add New Administrator"
                  popupContent={<AdministratorForm callback={triggerAdministratorListUpdate} />}
                />
              </div>
              {administratorItems.length === 0 ? (
                <EmptyState type="admin" text="There are no administrators" action={<AddNewAdmin />} />
              ) : filteredData.length === 0 ? (
                <EmptyState type="admin" text="No administrators match the search" />
              ) : (
                <AdministratorList admins={filteredData} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Administrator;
