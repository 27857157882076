import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import PartnerPricingForm from "./Forms/PartnerPricingForm";
import { useNavigate } from "react-router-dom";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { COLORS } from "../../utils/colors";

const PartnerPricingSingle = () => {
  let navigate = useNavigate();
  const { idPartnerPricing } = useParams();
  const [loading, setLoading] = useState(true);
  const [editPartnerPricingState, setEditPartnerPricingState] = useState(false);

  const [selectedPartnerPricing, setSelectedPartnerPricing] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    fetchPartnerPricingDetails();
  }, []);

  const fetchPartnerPricingDetails = async () => {
    try {
      const response = await api(`v1/pricing/${idPartnerPricing}`, "GET", null);
      if (response.data.success) {
        // console.log(response.data.data.partnerCompany);
        const partnerCompany = await api(
          `v1/partner/company/${response.data.data.partnerPricing.partnerCompany}`,
          "GET"
        );
        const product = await api(`v1/product/${response.data.data.partnerPricing.product}`, "GET");
        // console.log(response.data.data.partnerPricing);
        response.data.data.partnerPricing.company = partnerCompany.data.data.partnerCompany;
        response.data.data.partnerPricing.product = product.data.data.product;

        setSelectedPartnerPricing(response.data.data.partnerPricing);
        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    console.log("Edit State: ", editPartnerPricingState);
  }, [editPartnerPricingState]);

  const handleDeletePartnerPricingConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/pricing/" + selectedPartnerPricing._id, "DELETE", null);
      if (response.data.success) {
        window.location.replace("/partnerPricing");
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editPartnerPricing = () => {
    setEditPartnerPricingState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadPartnerPricingDetail = (updatedPartnerPricingDetails) => {
    setEditPartnerPricingState((prevValue) => !prevValue);
    setSelectedPartnerPricing(updatedPartnerPricingDetails);
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

    return formattedDate;
  }
  console.log(selectedPartnerPricing);
  return (
    selectedPartnerPricing && (
      <div className="page partner-user-single w-full flex flex-column items-center min-h-h-screen pt-4 gap-4 p-4">
        {loading && <GlobalLoadingSpinner />}
        <div className="w-full flex flex-row justify-between items-center">
          <div>
            <h1>Pricing Option Details</h1>
          </div>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {/* PartnerPricing Single Content */}
        <div className="w-full">
          <div className="w-full flex flex-col shadow-md p-4 rounded-md border-1 max-w-[400px]">
            <h2>
              <a
                className="text-darkblue font-bold"
                href="javascript:void(0);"
                onClick={() => navigate("/partner/" + selectedPartnerPricing.company._id)}
              >
                {selectedPartnerPricing.company.name}
              </a>
            </h2>
            <a
              className="text-darkblue font-medium"
              href="javascript:void(0);"
              onClick={() => navigate("/product/" + selectedPartnerPricing.product._id)}
            >
              {selectedPartnerPricing.product.type[0].toUpperCase()}
              {selectedPartnerPricing.product.type.slice(1)} - {selectedPartnerPricing.product.name}
            </a>
            <p>Price -${selectedPartnerPricing.price} (Including GST)</p>
            <p>Created On - {formatDate(selectedPartnerPricing.createdOnUTC)}</p>

            {sessionStorage.getItem("selectedPortal") == "Administrator" && (
              <div className="flex flex-row justify-self-end gap-2">
                <Button
                  sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                  variant="contained"
                  onClick={editPartnerPricing}
                >
                  Edit Pricing
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    toggleDisplayDeleteConfirm(true);
                  }}
                >
                  Delete Pricing
                </Button>
              </div>
            )}

            {/* // Edit Modal HTML */}

            <ModalPopup
              showModal={editPartnerPricingState}
              handleShowModal={editPartnerPricing}
              popupTitle={"Edit Partner Pricing"}
              popupContent={
                <PartnerPricingForm
                  selectedPartnerPricing={selectedPartnerPricing}
                  callback={reloadPartnerPricingDetail}
                  cancelModal={editPartnerPricing}
                />
              }
              cancelButtonText=""
              confirmButtonText=""
              confirmCallbackAction=""
            />

            {/* Delete Partner Pricing Confimation Modal */}
            <ModalPopup
              showModal={deleteConfirmationIsOpen}
              handleShowModal={toggleDisplayDeleteConfirm}
              popupTitle={"Delete Partner Pricing"}
              popupContent={
                <div>
                  Are you sure you want to delete the partnerPricing option for:
                  <strong>
                    <em> {selectedPartnerPricing.company.name} </em>
                  </strong>
                  ?
                </div>
              }
              cancelButtonText={"Cancel"}
              confirmButtonText={"Delete"}
              confirmCallbackAction={handleDeletePartnerPricingConfirm}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default PartnerPricingSingle;
