import ModalPopup from "./ModalPopup";
import { useCookies } from 'react-cookie';
import React, { useState, useEffect } from "react";

function SelectCompanyDD({ setCurrentCompany }) {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser') ?? null)
    let userCompanies = currentUser ? (currentUser.partnercompanies ?? null) : null;
    console.log("Current User", currentUser);

    const handleCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        setCurrentCompany(selectedCompany);
        sessionStorage.setItem('selectedCompany', selectedCompany);
    };

    // Check if the sessionObject is already set when the component mounts
    useEffect(() => {
        if (sessionStorage.getItem('selectedCompany') && (userCompanies && userCompanies.includes(sessionStorage.getItem('selectedCompany')))) {
            console.log("Company is selected: ", sessionStorage.getItem('selectedCompany'));
            setCurrentCompany(sessionStorage.getItem('selectedCompany'));
        }
    }, [sessionStorage.getItem('selectedCompany'), userCompanies]);

    const availableCompaniesCount = userCompanies?.length || 0;

    if (availableCompaniesCount == 1) {
        setCurrentCompany(userCompanies[0].value);
        sessionStorage.setItem('selectedCompany', userCompanies[0].value);
        return (
            <div className="partner_select_company_dd flex flex-column  mb-4 items-center h-[60px] justify-between w-full p-2 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                <div className="dropdown bg-blue-800 text-white  block max-w-[250px] px-2 py-1 rounded-lg text-gray-900 border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleCompanyChange} value={sessionStorage.getItem('selectedPortal')}>
                    <div className="">{currentUser.partnercompanies[0].label}</div>
                </div>
            </div>
        );

    } else {
        console.log("User associated with multiple companies: ", userCompanies);
        return (
            <div className="partner_select_company_dd flex flex-column  mb-4 items-center h-[60px] justify-between w-full p-2 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                <label>Switch Company</label>
                <select className="dropdown bg-blue-800 text-white  block max-w-[250px] px-2 py-1 rounded-lg text-gray-900 border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleCompanyChange} value={sessionStorage.getItem('selectedCompany')}>
                    <option disabled className="bg-blue-200 text-black">Switch Portal</option>
                    {currentUser.partnercompanies && currentUser.partnercompanies.map((associatedCompany) => (
                        <option className="bg-blue-200 text-black" key={associatedCompany.value} value={associatedCompany.value}>
                            {associatedCompany.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

}
export default SelectCompanyDD;