import { React, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { COLORS } from "../../utils/colors";
import { api } from "../../utils/api";

const VehicleList = ({ vehicles }) => {
  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const [inviteCodes, setInviteCodes] = useState({});

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };
  const fetchInviteCode = async (vehicleId, customerId) => {
    try {
      const getInviteCode = await api("v1/inviteCode/search", "POST", { vehicle: vehicleId, customer: customerId });
      if (getInviteCode.data.success) {
        return getInviteCode.data.data.searchResult[0].code;
      }
    } catch (error) {
      console.error('Error fetching invite code:', error);
    }
    return null;
  };

  const handleRowClick = async (vehicle) => {
    if (vehicle.active) {
      navigate(`/vehicle/${vehicle._id}`);
    } else {
      if (window.sessionStorage.selectedPortal == 'Customer') {
        let inviteCode = inviteCodes[vehicle._id];
        if (!inviteCode) {
          inviteCode = await fetchInviteCode(vehicle._id, vehicle.customer._id);
          if (inviteCode) {
            setInviteCodes((prevCodes) => ({ ...prevCodes, [vehicle._id]: inviteCode }));
          }
        }
        window.location.href = `/activate?code=${inviteCode}`;
      } else {
        navigate(`/vehicle/${vehicle._id}`);
      }

    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const navigate = useNavigate();
  return vehicles.length > 0 && mobile ? (
    <div className="flex flex-col justify-center">
      {vehicles.map((vehicle) => (
        <VehicleCard {...vehicle} />
      ))}
    </div>
  ) : (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          borderCollapse: "separate",
          "td, th": { borderBottom: "none" },
          th: { fontSize: "18px" }
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Rego</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Vin</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicles.map((vehicle) => (
            <TableRow
              // onClick={() => navigate(`/vehicle/${vehicle._id}`)}
              onClick={() => handleRowClick(vehicle)}
              key={vehicle._id}
              sx={{
                boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                "&:hover": { cursor: "pointer" }
              }}
            >
              <TableCell scope="row">{vehicle.rego}</TableCell>
              <TableCell>
                {vehicle.customer && vehicle.customer.address ? vehicle.customer.address.state : "N/A"}
              </TableCell>
              <TableCell>{vehicle.vin}</TableCell>
              <TableCell>{vehicle.customer ? `${vehicle.customer.firstName} ${vehicle.customer.lastName}` : "N/A"}</TableCell>
              <TableCell>{vehicle.active ? "Yes" : "No"}</TableCell>
              <TableCell>
                <Button variant="contained" sx={{ backgroundColor: vehicle.active ? COLORS.priorityBlue.hex : 'red' }}>
                  {vehicle.active ? 'View Vehicle' : (window.sessionStorage.selectedPortal == 'Customer' ? 'Finish Setup to Activate' : 'View Vehicle')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const VehicleCard = ({
  age,
  color,
  customer,
  createdOnUTC,
  active,
  _id,
  make,
  model,
  modelYear,
  products,
  rego,
  vin
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col border border-1 shadow-sm w-[300px] p-4">
      <div className="grid grid-cols-2 mb-4">
        <span className="font-semibold">Rego</span>
        <span>{rego}</span>
        <span className="font-semibold">State</span>
        <span>{customer ? customer[4].address.state : "N/A"}</span>
        <span className="font-semibold">Vin</span>
        <span>{vin}</span>
        <span className="font-semibold">Customer</span>
        <span>{customer ? customer[0].name : "N/A"}</span>
        <span className="font-semibold">Active</span>
        <span>{active ? "YES" : "NO"}</span>
      </div>
      <Button
        onClick={() => navigate(`/vehicle/${_id}`)}
        sx={{ backgroundColor: COLORS.priorityBlue.hex }}
        variant="contained"
      >
        View Vehicle
      </Button>
    </div>
  );
};

export default VehicleList;
