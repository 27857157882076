import { React, useEffect, useMemo, useState } from "react";
import { GoogleMap, useLoadScript, Marker, MarkerF, InfoWindowF } from "@react-google-maps/api";
import "./Locations/globals.css";
import { api } from "../utils/api";
import { Box } from "@mui/material";
import SearchFilterBar from "./Locations/SearchFilterBar";
import MerchantInfoWindow from "./Locations/MerchantInfoWindow";
import customMarkerIcon from "./Locations/icon-washclub.png";
import {
    accessUsersLocation,
    getAllAvailableProducts,
    getMerchantStoreAccountNames,
    getMerchantStoresWithProducts,
    getMerchantsWithCoordinates,
    groupMerchantStoresByAccount,
    matchMerchantProducts,
    matchSearchToMerchant
} from "./Locations/utils";

const CENTER_OF_SYDNEY = { lat: -33.8688, lng: 151.2093 };


const CurrentWashLocationsMap = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY
    });

    const [merchants, setMerchants] = useState([]);
    const [filteredMerchants, setFilteredMerchants] = useState([]);

    const [zoom, setZoom] = useState(11);
    const [center, setCenter] = useState(CENTER_OF_SYDNEY);
    const [openStore, setOpenStore] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const toggleOpen = (id) => (openStore === id ? setOpenStore("") : setOpenStore(id));
    const closeStore = () => setOpenStore("");

    /** Not used for now. We'd like to group merchant stores by merchant account by customising the Marker on the 
        map, e.g. all merchant stores owned by Merchant X will have a green color, and so on. */
    const merchantStoresByAccount = merchants && groupMerchantStoresByAccount(merchants);

    const allProducts = useMemo(() => getAllAvailableProducts(merchants), [merchants]);


    useEffect(() => {
        accessUsersLocation(accessUsersLocationSuccess, accessUsersLocationError);

        const getMerchantStores = async () => {
            const response = await api(`v1/merchantStore`);
            if (response.data.success) {
                const merchantsWithCoordinates = await getMerchantsWithCoordinates(response.data.data.allMerchantStores);
                console.log("merchantsWithCoordinates", merchantsWithCoordinates);
                const merchantsWithAccountName = await getMerchantStoreAccountNames(merchantsWithCoordinates);
                const merchantsWithProducts = await getMerchantStoresWithProducts(merchantsWithAccountName);
                setMerchants(merchantsWithProducts);
                setFilteredMerchants(merchantsWithProducts);
            }
        };
        getMerchantStores();
    }, []);

    const accessUsersLocationSuccess = (position) => {
        const { latitude, longitude } = position.coords;
        setCenter({ lat: latitude, lng: longitude });
    };

    const accessUsersLocationError = () => {
        console.log("Unable to retrieve your location");
    };

    useEffect(() => {
        if (!searchValue && filteredProducts.length === 0) {
            setFilteredMerchants(merchants);
        } else if (!!searchValue && filteredProducts.length === 0) {
            setFilteredMerchants(merchants.filter((merchant) => matchSearchToMerchant(searchValue, merchant)));
        } else if (filteredProducts.length > 0 && !searchValue) {
            setFilteredMerchants(merchants.filter((merchant) => matchMerchantProducts(merchant, filteredProducts)));
        } else {
            setFilteredMerchants(
                merchants.filter(
                    (merchant) =>
                        matchMerchantProducts(merchant, filteredProducts) && matchSearchToMerchant(searchValue, merchant)
                )
            );
        }
    }, [filteredProducts, searchValue]);

    const clearFilters = () => setFilteredProducts([]);

    return !isLoaded ? (
        <div>Loading...</div>
    ) : (
        <div className="h-screen w-screen overflow-hidden">
            {/* <Box sx={{ marginTop: "12px", marginBottom: "18px" }}>
      </Box> */}
            <SearchFilterBar
                products={allProducts}
                filteredProducts={filteredProducts}
                setFilteredProducts={setFilteredProducts}
                setSearchValue={setSearchValue}
                clearFilters={clearFilters}
            />
            <GoogleMap zoom={zoom} center={center} mapContainerClassName="map-container">
                <Marker position={center} />
                {filteredMerchants &&
                    filteredMerchants.map((merchant) => (
                        <MarkerF
                            key={`merchant-marker-f-${merchant._id}`}
                            position={{ lat: merchant.coordinates.lat, lng: merchant.coordinates.lng }}
                            icon={{ url: customMarkerIcon, scaledSize: new window.google.maps.Size(40, 40) }}
                            onClick={() => {
                                toggleOpen(merchant._id);
                            }}
                        >
                            {openStore === merchant._id && (
                                <InfoWindowF onCloseClick={closeStore}>
                                    <MerchantInfoWindow merchant={merchant} key={`merchant-info-window-${merchant._id}`} />
                                </InfoWindowF>
                            )}
                        </MarkerF>
                    ))}
            </GoogleMap>
        </div>
    );
};

export default CurrentWashLocationsMap;
