import { React, useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import {
  Select,
  MenuItem,
  Input,
  Chip,
  Box,
  Button,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";
import { fetchVehicleDetails } from "../../../utils/fetchVehicleDetails";

const VehicleForm = ({ selectedVehicle, callback, closeModal }) => {
  const currentPortal = sessionStorage.getItem("selectedPortal");
  // const [modalActive, setModalActive] = useState(true);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [customer, setCustomer] = useState({});
  const [customerAddressState, setCustomerAddressState] = useState({});
  // const [state, setState] = useState({});
  const [products, setProducts] = useState([]);

  // const [vin, setVin] = useState(selectedVehicle?.vin || "");
  // const [make, setMake] = useState(selectedVehicle?.make || "");
  // const [model, setModel] = useState(selectedVehicle?.model || "");
  // const [modelYear, setModelYear] = useState(selectedVehicle?.modelYear || "");

  const getAllCustomers = async () => {
    try {
      const response = await api("v1/customer/", "GET", null); // using user endpoint for now, will need to update to customer once customer is done
      if (response.data.success) {
        const allCustomers = response.data.data.allCustomers;
        const result = allCustomers.map((customer) => ({
          value: customer._id,
          label: `<div className="flex flex-column">
                                <p>${customer.firstName} ${customer.lastName}</p>
                                <p>${customer.email}</p>                               
                                <p>${customer.phone}</p>                               
                                <p>${customer.address?.street} ${customer.address?.city} ${customer.address?.postcode}, ${customer.address?.country} ${customer.address?.state}</p>                               
                                <p>${customer.email}</p>                               
                            </div>`,
          state: `${customer.address?.state}`
        }));
        console.log("ALL CUSTOMERS", allCustomers);
        console.log("FORMATTED CUSTOMER OPTIONS", result);

        if (result) {
          setCustomerOptions(result);
          if (selectedVehicle) {
            const selectedCustomer = result.find((customer) => customer.value === selectedVehicle.customer);
            setCustomer(selectedCustomer);
            setCustomerAddressState(selectedCustomer.state);
          }
        }
      }
    } catch (error) {
      console.error("Error loading partner companies:", error);
    }
  };

  const getAllProducts = async () => {
    try {
      const response = await api("v1/product", "GET", null);
      if (response.data.success) {
        const allProducts = response.data.data.allProducts;
        const result = allProducts.map((product) => ({
          value: product._id,
          label: product.name
        }));
        if (result) {
          setProductOptions(result);

          // if (selectedVehicle) {
          //     const selectedProducts = result.find(
          //         (product) => product.value === selectedVehicle.product._id
          //     );
          //     setProduct(selectedProducts);
          // }

          if (selectedVehicle) {
            // Check back to debug in Edit Mode
            const selectedProductIds = selectedVehicle.products.map((product) => product._id);
            const selectedProducts = result.filter((product) => selectedProductIds.includes(product.value));
            // console.log("SELECTED VEHICLES", selectedProductIds)
            // console.log("SELECTED VEHICLES", result)
            setProducts(selectedProducts);
          }
        }
      }
    } catch (error) {
      console.error("Error loading Products:", error);
    }
  };

  useEffect(() => {
    getAllCustomers();
    getAllProducts();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("Submitted Data", values);
    const dataToSend = {
      vin: values.vin,
      rego: values.rego,
      make: values.make.toUpperCase(),
      model: values.model.toUpperCase(),
      color: values.color,
      modelYear: values.modelYear,
      age: values.age,
      poNumber: values.poNumber,
      stockCode: values.stockCode,
      products: products.map((product) => product.value),
      customer: customer,
      active: values.active
    };
    try {
      let response;
      if (selectedVehicle) {
        // Edit Vehicle Option
        response = await api(`v1/vehicle/${selectedVehicle._id}`, "PATCH", dataToSend);
      } else {
        // Add Vehicle Option
        response = await api(`v1/vehicle`, "POST", dataToSend);
      }

      if (response.data.success) {
        if (selectedVehicle) {
          console.log("Vehicle Updated: ", response.data.data.vehicle);
          callback(response.data.data.vehicle);
        } else {
          if (response.data.data.newVehicle) {
            callback(response.data.data.newVehicle);
          } else {
            alert(response.data.message);
          }
        }
      } else {
        response?.data?.message && alert(response.data.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }

    setSubmitting(false);
  };

  const loadProductOptions = async (inputValue) => {
    try {
      const filteredOptions = productOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      // callback(filteredOptions);
      return filteredOptions;
    } catch (error) {
      console.error("Error loading product options:", error);
      // callback([]);
    }
  };

  const handleProductChange = (selectedOptions) => {
    setProducts(selectedOptions || null);
  };

  const loadCustomerOptions = async (inputValue) => {
    const filteredOptions = customerOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return filteredOptions;
  };

  const handleCustomerChange = (selectedOptions) => {
    if (selectedOptions) {
      console.log(selectedOptions);
      setCustomer(selectedOptions);
      setCustomerAddressState(selectedOptions.state);
    } else {
      setCustomer(null);
      setCustomerAddressState("NSW");
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#9e9e9e",
      zIndex: 2
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3
    })
  };

  // Validation function for Model year
  const validateModelYear = (value) => {
    const validYearPattern = /^\d{4}$/;
    if (!validYearPattern.test(value)) {
      return "Please enter a valid model year in YYYY format.";
    }
  };

  const validationSchema = Yup.object().shape({
    // rego: Yup.string().required("REGO is required"),
    vin: Yup.string().required("VIN is required"),
    make: Yup.string().required("Make is required"),
    model: Yup.string().required("Model is required"),
    color: Yup.string().required("Color is required"),
    modelYear: Yup.string().required("Model Year is required"),
    // age: Yup.string().required("Vehicle Age is required"),
    // products: Yup.array()
    //     .of(
    //         Yup.object().shape({
    //             label: Yup.string().required(),
    //             value: Yup.string().required()
    //         })
    //     )
    //     .min(1, "At least one product is required"),
    // customer: Yup.object().required("Select the owner of this Vehicle"),
    active: Yup.boolean().required("Active field is required")
  });

  const fetchVehicleInformation = async (registration, setFieldValue) => {
    console.log(setFieldValue);

    try {
      console.log("ADDRESS STATE", customerAddressState);
      const vehicleDetails = await fetchVehicleDetails(registration, customerAddressState);
      if (vehicleDetails) {
        const compliance_plate_raw = vehicleDetails.compliance_plate.split("-");

        setFieldValue("vin", vehicleDetails.vin);
        // setVin(vehicleDetails.vin);
        setFieldValue("make", vehicleDetails.make);
        // setMake(vehicleDetails.make);
        setFieldValue("model", vehicleDetails.model);
        // setModel(vehicleDetails.model);
        setFieldValue("modelYear", compliance_plate_raw[0]);
        // setModelYear(compliance_plate_raw[0] || "");
      }
    } catch (error) {
      console.error("Error fetching vehicle details:", error.message);
      // Handle error (e.g., display an error message)
    }
  };

  console.log("Vehicle Details on Render:", {
    vin: selectedVehicle?.vin || "",
    rego: selectedVehicle?.rego || "",
    make: selectedVehicle?.make || "",
    model: selectedVehicle?.model || "",
    color: selectedVehicle?.color || "",
    modelYear: selectedVehicle?.modelYear || "",
    age: selectedVehicle?.age || "",
    products: products || null,
    customer: customer || null,
    active: selectedVehicle?.active || false
  });

  return (
    <Formik
      initialValues={{
        rego: selectedVehicle?.rego || "",
        vin: selectedVehicle?.vin || "",
        make: selectedVehicle?.make || "",
        model: selectedVehicle?.model || "",
        modelYear: selectedVehicle?.modelYear || "2022",
        color: selectedVehicle?.color || "",
        stockCode: selectedVehicle?.stockCode || "",
        poNumber: selectedVehicle?.poNumber || "",
        age: selectedVehicle?.age || "",
        products: products || null,
        customer: customer || null,
        active: selectedVehicle?.active || false
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh] p-2">
          <div className="form-input-wrap flex gap-2 w-full">
            <div className="w-full flex gap-5">
              <div className="form-group w-full">
                <label className="label" htmlFor="customer">
                  Customer:
                </label>
                <Field name="customer">
                  {({ field }) => (
                    <AsyncSelect
                      name="customer"
                      cacheOptions
                      defaultOptions={customerOptions}
                      loadOptions={loadCustomerOptions}
                      styles={selectStyles}
                      onChange={handleCustomerChange}
                      value={customer}
                      formatOptionLabel={function (data) {
                        return <span dangerouslySetInnerHTML={{ __html: data.label }} />;
                      }}
                      isDisabled={currentPortal != "Administrator"}
                    />
                  )}
                </Field>
                <ErrorMessage name="customer" component="span" className="error-message" />
              </div>
            </div>
            <div className="w-full flex gap-5">
              <div className="form-group w-full">
                <label className="label" htmlFor="products">
                  Product:
                </label>

                <Field name="products">
                  {({ field }) => (
                    <AsyncSelect
                      isMulti
                      name="products"
                      {...field}
                      cacheOptions
                      defaultOptions={productOptions}
                      loadOptions={loadProductOptions}
                      styles={selectStyles}
                      onChange={handleProductChange}
                      value={products}
                      isDisabled={currentPortal != "Administrator"}
                    />
                  )}
                </Field>
                <ErrorMessage name="products" component="span" className="error-message" />
              </div>
            </div>

            <div className="w-full flex flex-col gap-3 mb-2 md:flex-row md:gap-5">
              <div className="form-group w-full">
                {/* <label htmlFor="rego">Registration Number:</label> */}
                <Field name="rego">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="rego"
                      type="text"
                      label="REGO"
                      className="z-2"
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      // onBlur={fetchVehicleInformation}
                      onBlur={(e) => fetchVehicleInformation(e.target.value, setFieldValue)}
                    // onChange={(e) => {
                    //     setFieldValue('rego', e.target.value);
                    //     fetchVehicleInformation(e.target.value, setFieldValue);
                    // }}
                    />
                  )}
                </Field>
                <ErrorMessage name="rego" component="span" className="error-message" />
              </div>

              <div className="form-group w-full">
                {/* <label htmlFor="vin">VIN Number:</label> */}
                <Field name="vin">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="vin"
                      type="text"
                      label="VIN Number"
                      className="z-2"
                      // value={vin ?? ""}
                      // onChange={(e) => setFieldValue('vin', e.target.value)}
                      // onChange={(e) => setVin(e.target.value)}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      disabled={currentPortal != "Administrator"}
                    />
                  )}
                </Field>
                <ErrorMessage name="vin" component="span" className="error-message" />
              </div>
            </div>

            <div className="w-full flex flex-col gap-3 mb-2 md:flex-row md:gap-5">
              <div className="form-group w-full">
                {/* <label htmlFor="make">Vehicle Make:</label> */}
                <Field name="make">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="make"
                      type="text"
                      label="Vehicle Make"
                      className="z-2"
                      // value={make}
                      // onChange={(e) => setFieldValue('make', e.target.value)}
                      // onChange={(e) => setMake(e.target.value)}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  )}
                </Field>
                <ErrorMessage name="make" component="span" className="error-message" />
              </div>

              <div className="form-group w-full">
                {/* <label htmlFor="model">Vehicle Model:</label> */}
                <Field name="model">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="model"
                      type="text"
                      label="Vehicle Model"
                      // value={model ?? ""}
                      // onChange={(e) => setModel(e.target.value)}
                      // onChange={(e) => setFieldValue('model', e.target.value)}
                      className="z-2"
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  )}
                </Field>
                <ErrorMessage name="model" component="span" className="error-message" />
              </div>
            </div>

            <div className="w-full flex flex-col gap-3 mb-2 md:flex-row md:gap-5">
              <div className="form-group w-full">
                {/* <label htmlFor="color">Vehicle Color:</label> */}
                <Field name="color">
                  {({ field }) => <TextField {...field} id="color" type="text" label="Vehicle Color" className="z-2" />}
                </Field>
                <ErrorMessage name="color" component="span" className="error-message" />
              </div>

              <div className="form-group w-full">
                {/* <label htmlFor="modelYear">Model Year:</label> */}
                <Field name="modelYear" validate={validateModelYear}>
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="modelYear"
                      type="text" // Updated to accept alphanumeric input
                      label="Model Year"
                      className="z-2"
                      // value={modelYear ?? ""}
                      // onChange={(e) => setModelYear(e.target.value)}
                      // onChange={(e) => setFieldValue('modelYear', e.target.value)}
                      inputProps={{ pattern: "\\d{4}", maxLength: 4 }} // Added pattern and maxLength attributes
                    />
                  )}
                </Field>
                <ErrorMessage name="modelYear" component="span" className="error-message" />
              </div>
            </div>

            <div className="w-full flex flex-col gap-3 mb-2 md:flex-row md:gap-5">
              <div className="form-group w-full">
                {/* <label htmlFor="stockCode">Vehicle Color:</label> */}
                <Field name="stockCode">
                  {({ field }) => (
                    <TextField {...field} id="stockCode" type="text" label="Vehicle Stock Code" className="z-2" />
                  )}
                </Field>
                <ErrorMessage name="stockCode" component="span" className="error-message" />
              </div>

              <div className="form-group w-full">
                {/* <label htmlFor="poNumber">Model Year:</label> */}
                <Field name="poNumber">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="poNumber"
                      type="text" // Updated to accept alphanumeric input
                      label="PO Number"
                      className="z-2"
                    />
                  )}
                </Field>
                <ErrorMessage name="poNumber" component="span" className="error-message" />
              </div>
            </div>

            <div className="w-full flex flex-col gap-3 mb-2 md:flex-row md:gap-5">
              {/* <div className="form-group w-full">
                <Field name="age">
                  {({ form }) => (
                    <FormControl fullWidth>
                      <InputLabel id="age-label">Vehicle Age</InputLabel>
                      <Select
                        label="Vehicle Age"
                        variant="outlined"
                        labelId="age-label"
                        value={form.values.age}
                        onChange={(event) => {
                          form.setFieldValue("age", event.target.value);
                        }}
                        renderValue={(selected) => (
                          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            <Chip key={selected} label={selected} sx={{ m: 0.5 }} />
                          </Box>
                        )}
                      >
                        <MenuItem value="Less than 6 Months">Less than 6 Months</MenuItem>
                        <MenuItem value="Less than 12 Months">Less than 12 Months</MenuItem>
                        <MenuItem value="1 Year">1 Year</MenuItem>
                        <MenuItem value="2 Years">2 Years</MenuItem>
                        <MenuItem value="More than 2 Years">More than 2 Years</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <ErrorMessage name="age" component="span" className="error-message" />
              </div> */}
              <div className="form-group w-full">
                <label className="label" htmlFor="active">
                  Active:
                </label>
                <Field name="active" type="checkbox">
                  {({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label={field.value ? "Active" : "Inactive"}
                    />
                  )}
                </Field>
                <ErrorMessage name="active" component="span" className="error-message" />
              </div>
            </div>
          </div>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Button variant="contained" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default VehicleForm;
