import React, { useState, useEffect } from "react";
import { api } from "../../../utils/api";
import "./style.css";
import EntityCardDisplay from "../../../components/EntityCardDisplay";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { COLORS } from "../../../utils/colors";
import ModalPopup from "../../../components/ModalPopup";
import PartnerPricingForm from "../../PartnerPricing/Forms/PartnerPricingForm";

const fieldsToDisplay = [
  { key: "partnerCompany.name", label: "Partner Company", className: "font-bold" },
  { key: "product.name", label: "Product", className: "font-bold" },
  { key: "price", label: "Price", className: "font-bold" }
];

const PartnerPricing = ({ selectedCompany }) => {
  const [loading, setLoading] = useState(true);
  const [triggerUserListUpdate, setTriggerUserListUpdate] = useState(false);
  const [pricingOption, setPricingOption] = useState(false);
  const [showPartnerPricingForm, setShowPartnerPricingForm] = useState(false);

  const getCompanyPricingOptions = async () => {
    try {
      const response = await api("v1/partner/" + selectedCompany._id + "/pricing");
      if (response.data.success) {
        const modifiedPricingOptions = await Promise.all(
          response.data.data.pricingOptions.map(async (pricing) => {
            const productInfoResponse = await api(`v1/product/${pricing.product}`, "GET");
            const companyResponse = await api(`v1/partner/company/${pricing.partnerCompany}`, "GET");
            if (productInfoResponse.data.success && companyResponse.data.success) {
              return {
                ...pricing,
                product: productInfoResponse.data.data.product,
                partnerCompany: companyResponse.data.data.partnerCompany
              };
            }
          })
        );
        // setPricingOption(response.data.data.pricingOptions);
        setPricingOption(modifiedPricingOptions);
        console.log(modifiedPricingOptions);
      }
    } catch (error) {
      console.error("API Error", error);
    }
  };

  useEffect(() => {
    getCompanyPricingOptions();
  }, []);

  return (
    <div className="flex flex-column gap-4 justify-center">
      <div className="self-center">
        {sessionStorage.getItem("selectedPortal") == "Administrator" && (
          <Button
            sx={{ backgroundColor: COLORS.priorityBlue.hex }}
            variant="contained"
            onClick={() => setShowPartnerPricingForm(true)}
          >
            Add New Partner Pricing
          </Button>
        )}
        <ModalPopup
          showModal={showPartnerPricingForm}
          handleShowModal={() => setShowPartnerPricingForm(false)}
          popupTitle="Add New Partner Pricing Option"
          popupContent={
            <PartnerPricingForm
              callback={getCompanyPricingOptions}
              cancelModal={() => {
                setShowPartnerPricingForm(false);
              }}
              defaultCompany={selectedCompany._id}
            />
          }
        />
      </div>
      <div className="mt-4 flex flex-row flex-wrap gap-4 justify-center lg:justify-normal">
        {pricingOption.length > 0 ? (
          pricingOption.map((option) => (
            <PricingSingle companyId={selectedCompany._id} name={selectedCompany.name} {...option} />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PartnerPricing;

const PricingSingle = ({ name, product, _id: pricingId, price }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/partnerPricing/${pricingId}`)}
      className="w-[300px] flex border flex-col border-sky-500 p-6 shadow-sm hover:cursor-pointer"
    >
      <PricingInfo name={name} product={product} price={price} />
    </div>
  );
};

export const PricingInfo = ({ name, product, price }) => (
  <>
    <h5 className="font-bold">{name}</h5>
    <span className="font-semibold">
      {product.type[0].toUpperCase()}
      {product.type.slice(1)} - {product.name}
    </span>
    <span>Price - ${price}</span>
  </>
);
