import React from "react";
import { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@mui/material";

function ModalPopup({
  showModal,
  handleShowModal,
  popupTitle = "",
  popupContent = "",
  cancelButtonText = "",
  confirmButtonText = "",
  confirmCallbackAction = ""
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={handleShowModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {popupTitle && (
        <DialogTitle className="flex justify-center color-primary" id="alert-dialog-title" sx={{ m: 0, p: 4 }}>
          {popupTitle}
        </DialogTitle>
      )}

      {popupContent && <DialogContent>{popupContent}</DialogContent>}
      {confirmCallbackAction && (
        <DialogActions>
          <Button variant="outlined" onClick={handleShowModal}>
            {cancelButtonText}
          </Button>
          <Button variant="contained" onClick={confirmCallbackAction} autoFocus>
            {confirmButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
export default ModalPopup;
