import React, { useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import "./App.css";
import "./MainMenu.css";
import TagManager from "react-gtm-module";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalLoadingSpinner from "./components/GlobalLoadingSpinner";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import * as Images from "./themes/images";

//VIEWS
import Home from "./views/Home";
import Dashboard from "./views/Dashboard";

// import AdminDashboard from "./views/AdminDashboard";

import Administrator from "./views/Administrator";
import AdministratorSingle from "./views/Administrator/AdministratorSingle";

import PartnerCompany from "./views/PartnerCompany";
import PartnerCompanySingle from "./views/PartnerCompany/PartnerCompanySingle";

import PartnerUsers from "./views/PartnerCompany/PartnerUser";
// import PartnerPricing from "./views/PartnerCompany/PartnerPricing";
import MerchantAccount from "./views/MerchantAccount";
import MerchantAccountSingle from "./views/MerchantAccount/SingleAccount";
import MerchantUserSingle from "./views/MerchantAccount/SingleAccount/merchantUsers/User";
import Maintenance from "./views/Maintenance";
import NotFound from "./views/404";
import ReactGA from "react-ga4";
import { api } from "./utils/api";
import axios from "axios";
// import { Dashboard } from "@mui/icons-material";
import SelectPortalDD from "./components/SelectPortalDD";
import SelectPortalModal from "./components/SelectPortalModal";
import SelectCompanyModal from "./components/SelectCompanyModal";
// import SelectCompanyDD from "./components/SelectCompanyDD";
import MainMenu from "./components/MainMenu";
import GlobalFooter from "./components/GlobalFooter";
import { Profile } from "./components";
import PartnerUserSingle from "./views/PartnerCompany/PartnerUser/PartnerUserSingle";
import Product from "./views/Product";
import ProductSingle from "./views/Product/ProductSingle";
import PartnerPricing from "./views/PartnerPricing";
import PartnerPricingSingle from "./views/PartnerPricing/PartnerPricingSingle";
import MerchantStorePricing from "./views/MerchantStorePricing";
import MerchantStorePricingSingle from "./views/MerchantStorePricing/MerchantStorePricingSingle";
import Subscription from "./views/Subscription";
import SubscriptionSingle from "./views/Subscription/SubscriptionSingle";
import Vehicle from "./views/Vehicle";
import VehicleSingle from "./views/Vehicle/VehicleSingle";
import Customer from "./views/Customer";
import CustomerSingle from "./views/Customer/CustomerSingle";
import Logout from "./views/Customer/Logout";
import Login from "./views/Customer/Login";
import InviteCode from "./views/InviteCode";
import InviteCodeSingle from "./views/InviteCode/InviteCodeSingle";
import Transaction from "./views/Transaction";
import TransactionSingle from "./views/Transaction/TransactionSingle";
import Promotion from "./views/Promotion";
import PromotionSingle from "./views/Promotion/PromotionSingle";
import Redemption from "./views/Redemption";
import RedemptionSingle from "./views/Redemption/RedemptionSingle";
import MerchantStoreSingle from "./views/MerchantAccount/SingleAccount/merchantStores/Store";
// import useCookies from "react-cookie/cjs/useCookies";
import SignupCustomer from "./views/SignupCustomer";
import ActivateMembershipWithInviteCode from "./views/ActivateMembershipWithInviteCode";
import MerchantStoreMap from "./views/Locations/MerchantStoreMap";
import MySubscriptions from "./views/MySubscritptions";
import MyAccount from "./views/MyAccount";
import { ErrorMessage } from "formik";
import CurrentWashLocationsMap from "./views/CurrentWashLocationsMap";
import MerchantStore from "./views/MerchantStore";

const App = () => {
  // useEffect(() => {
  //   const xeroApiRecord = api(`v1/xeroapi`);
  // });
  // const xeroApiRecord = api(`v1/xeroapi`);
  const blueLogo = Images.blueLogo;
  const P1LogoLight = Images.globalLogoSmall;
  const [currentUser, setCurrentUser] = useState();
  const { isLoading, user, logout } = useAuth0();

  useEffect(() => {
    // TODO: REMOVE A SESSION ON ACTIVATION TO MAKE SURE YOU'RE STARTING FRESH

    // console.log("LOC: ", window.location.href);
    // if (window.location.href.indexOf("/activate") > -1) {
    //   console.log("WILL LOG OUT");
    //   sessionStorage.removeItem("currentUser");
    //   logout({ returnTo: window.location.href });
    // }

    if (sessionStorage.getItem("currentUser")) {
      console.log("Session ma: ", JSON.parse(sessionStorage.getItem("currentUser")), "CURRENTUSER:", currentUser);
      setCurrentUser(JSON.parse(sessionStorage.getItem("currentUser")));
    }
  }, [sessionStorage.getItem("currentUser")]);

  const [isOffline, setIsOffline] = useState(false);

  if (!user) {
    sessionStorage.removeItem("currentUser");
  }
  const [userPortals, setUserPortals] = useState([]);

  const [currentPortal, setCurrentPortal] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [currentMerchantStore, setCurrentMerchantStore] = useState(null);

  useEffect(() => {
    const checkHealth = async () => {
      try {
        const domain =
          window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"
            ? "http://localhost:8094/"
            : "https://api.priorityonewashclub.com/";
        let health = await axios.get(domain);
        // console.log("Health :", health);
        if (health.status !== 200) {
          setIsOffline(true);
        }
      } catch (error) {
        setIsOffline(true);
      }
    };

    const fetchUserPortals = async () => {
      try {
        const { email } = user;
        const response = await api(`v1/initialFetch/portals/${email}`, "GET");
        if (response.data.success) {
          if (response.data.data.portals.length > 0) {
            setUserPortals(response.data.data.portals);
          } else {
            console.log(response);
            alert(
              `There are no accounts associated with the email you are currently using (${email}), please contact support.`
            );
            logout({ logoutParams: { returnTo: window.location.origin } });
          }
        }
      } catch (error) {
        setIsOffline(true);
        console.log("No data received");
      }
    };

    // Fetch the user's portals when the component mounts
    fetchUserPortals();
    checkHealth();
  }, [user]); // Depend on the 'user' object to trigger the fetch

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const { email } = user;
        const response = await api(`v1/initialFetch/${currentPortal}/${email}`, "GET");
        if (response.data.success) {
          setIsOffline(false);
          setCurrentUser(JSON.stringify(response.data.data.userInfo));
          sessionStorage.setItem("currentUser", JSON.stringify(response.data.data.userInfo));
          console.log("Session Set", JSON.stringify(response.data.data.userInfo));
        }
      } catch (error) {
        setIsOffline(true);
        console.log("No data received");
      }
    };
    fetchUserInfo();
  }, [currentPortal]);

  // Create a function to generate routes dynamically based on available portals
  const generateRoutes = () => {
    const routes = [];
    const addPortalRoutes = (portal, portalRoutes) => {
      routes.push(portalRoutes.map((route) => <Route key={route.props.path} {...route.props} />));
    };
    // Check available portals and add corresponding routes
    if (userPortals.includes("Administrator") && currentPortal === "Administrator") {
      addPortalRoutes("Administrator", [
        <Route
          exact
          path="/dashboard"
          Component={withAuthenticationRequired(Dashboard, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,

        // <Route
        //   exact
        //   path="/dashboard"
        //   element={withAuthenticationRequired(AdminDashboard, {
        //     onRedirecting: () => <GlobalLoadingSpinner />
        //   })}
        // />,
        <Route
          exact
          path="/administrator"
          Component={withAuthenticationRequired(Administrator, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/administrator/:idAdministrator"
          Component={withAuthenticationRequired(AdministratorSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/product"
          Component={withAuthenticationRequired(Product, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/product/:idProduct"
          Component={withAuthenticationRequired(ProductSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partnerPricing"
          Component={withAuthenticationRequired(PartnerPricing, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partnerPricing/:idPartnerPricing"
          Component={withAuthenticationRequired(PartnerPricingSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchantStorePricing"
          Component={withAuthenticationRequired(MerchantStorePricing, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchantStorePricing/:idMerchantStorePricing"
          Component={withAuthenticationRequired(MerchantStorePricingSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/subscription"
          Component={withAuthenticationRequired(Subscription, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/subscription/:idSubscription"
          Component={withAuthenticationRequired(SubscriptionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/vehicle"
          Component={withAuthenticationRequired(Vehicle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/vehicle/:idVehicle"
          Component={withAuthenticationRequired(VehicleSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partner"
          Component={withAuthenticationRequired(PartnerCompany, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partner/:id"
          Component={withAuthenticationRequired(PartnerCompanySingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partner/:idPartnerCompany/user/:idUser"
          Component={withAuthenticationRequired(PartnerUserSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchant"
          Component={withAuthenticationRequired(MerchantAccount, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchantStore"
          Component={withAuthenticationRequired(MerchantStore, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchant/:id"
          Component={withAuthenticationRequired(MerchantAccountSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchant/:idMerchant/user/:idUser"
          Component={withAuthenticationRequired(MerchantUserSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchant/:idMerchant/store/:idStore"
          Component={withAuthenticationRequired(MerchantStoreSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/customer"
          Component={withAuthenticationRequired(Customer, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/customer/:idCustomer"
          Component={withAuthenticationRequired(CustomerSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/promotion"
          Component={withAuthenticationRequired(Promotion, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/promotion/:idPromotion"
          Component={withAuthenticationRequired(PromotionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/redemption"
          Component={withAuthenticationRequired(Redemption, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/redemption/:idRedemption"
          Component={withAuthenticationRequired(RedemptionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/transaction"
          Component={withAuthenticationRequired(Transaction, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/transaction/:idTransaction"
          Component={withAuthenticationRequired(TransactionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/inviteCode"
          Component={withAuthenticationRequired(InviteCode, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/inviteCode/:idInviteCode"
          Component={withAuthenticationRequired(InviteCodeSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/my-account"
          Component={withAuthenticationRequired(MyAccount, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />
        // Add other routes for the Administrator portal here
      ]);
    }

    if (userPortals.includes("Merchant") && currentPortal === "Merchant") {
      addPortalRoutes("Merchant", [
        <Route
          exact
          path="/dashboard"
          Component={withAuthenticationRequired(Dashboard, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        // <Route
        //   exact
        //   path="/dashboard"
        //   element={withAuthenticationRequired(<AdminDashboard />, {
        //     onRedirecting: () => <GlobalLoadingSpinner />
        //   })}
        // />,
        <Route
          exact
          path="/customer"
          Component={withAuthenticationRequired(Customer, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/customer/:idCustomer"
          Component={withAuthenticationRequired(CustomerSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/vehicle"
          Component={withAuthenticationRequired(Vehicle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/vehicle/:idVehicle"
          Component={withAuthenticationRequired(VehicleSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchantStore"
          Component={withAuthenticationRequired(MerchantStore, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchantStore/:idStore"
          Component={withAuthenticationRequired(MerchantStoreSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/merchantStore/:idStore"
          Component={withAuthenticationRequired(MerchantStoreSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/redemption"
          Component={withAuthenticationRequired(Redemption, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/redemption/:idRedemption"
          Component={withAuthenticationRequired(RedemptionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/product/:idProduct"
          Component={withAuthenticationRequired(ProductSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/my-account"
          Component={withAuthenticationRequired(MyAccount, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />
        // Add other routes for the Administrator portal here
      ]);
      if (currentUser.role == "admin") {
        addPortalRoutes("Merchant", [
          <Route
            exact
            path="/merchant"
            Component={withAuthenticationRequired(MerchantAccount, {
              onRedirecting: () => <GlobalLoadingSpinner />
            })}
          />,
          <Route
            exact
            path="/merchant/:id"
            Component={withAuthenticationRequired(MerchantAccountSingle, {
              onRedirecting: () => <GlobalLoadingSpinner />
            })}
          />,
          <Route
            exact
            path="/merchant/:idMerchant/user/:idUser"
            Component={withAuthenticationRequired(MerchantUserSingle, {
              onRedirecting: () => <GlobalLoadingSpinner />
            })}
          />,
          <Route
            exact
            path="/merchant/:idMerchant/store/:idStore"
            Component={withAuthenticationRequired(MerchantStoreSingle, {
              onRedirecting: () => <GlobalLoadingSpinner />
            })}
          />,
          <Route
            exact
            path="/product"
            Component={withAuthenticationRequired(Product, {
              onRedirecting: () => <GlobalLoadingSpinner />
            })}
          />,
          <Route
            exact
            path="/product/:idProduct"
            Component={withAuthenticationRequired(ProductSingle, {
              onRedirecting: () => <GlobalLoadingSpinner />
            })}
          />
        ]);
      }
    }

    if (userPortals.includes("Partner") && currentPortal === "Partner") {
      addPortalRoutes("Partner", [
        <Route
          exact
          path="/dashboard"
          Component={withAuthenticationRequired(Dashboard, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/signup-customer"
          Component={withAuthenticationRequired(SignupCustomer, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        // <Route
        //   exact
        //   path="/dashboard"
        //   element={withAuthenticationRequired(<AdminDashboard />, {
        //     onRedirecting: () => <GlobalLoadingSpinner />
        //   })}
        // />,
        <Route
          exact
          path="/product"
          Component={withAuthenticationRequired(Product, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/product/:idProduct"
          Component={withAuthenticationRequired(ProductSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partnerPricing"
          Component={withAuthenticationRequired(PartnerPricing, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partnerPricing/:idPartnerPricing"
          Component={withAuthenticationRequired(PartnerPricingSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/subscription"
          Component={withAuthenticationRequired(Subscription, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/subscription/:idSubscription"
          Component={withAuthenticationRequired(SubscriptionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/vehicle"
          Component={withAuthenticationRequired(Vehicle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/vehicle/:idVehicle"
          Component={withAuthenticationRequired(VehicleSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partner"
          Component={withAuthenticationRequired(PartnerCompany, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partner/:id"
          Component={withAuthenticationRequired(PartnerCompanySingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/partner/:idPartnerCompany/user/:idUser"
          Component={withAuthenticationRequired(PartnerUserSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/customer"
          Component={withAuthenticationRequired(Customer, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/customer/:idCustomer"
          Component={withAuthenticationRequired(CustomerSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/promotion"
          Component={withAuthenticationRequired(Promotion, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/promotion/:idPromotion"
          Component={withAuthenticationRequired(PromotionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/redemption/:idRedemption"
          Component={withAuthenticationRequired(RedemptionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/transaction"
          Component={withAuthenticationRequired(Transaction, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/transaction/:idTransaction"
          Component={withAuthenticationRequired(TransactionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/my-account"
          Component={withAuthenticationRequired(MyAccount, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />
        // Add other routes for the Administrator portal here
      ]);
    }

    if (userPortals.includes("Customer")) {
      addPortalRoutes("Customer", [
        <Route
          exact
          path="/Login"
          Component={withAuthenticationRequired(Login, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/my-subscriptions"
          Component={withAuthenticationRequired(MySubscriptions, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/map"
          element={withAuthenticationRequired(<MerchantStoreMap />, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/subscription/:idSubscription"
          Component={withAuthenticationRequired(SubscriptionSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/vehicle"
          Component={withAuthenticationRequired(Vehicle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/vehicle/:idVehicle"
          Component={withAuthenticationRequired(VehicleSingle, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/logout"
          Component={withAuthenticationRequired(Logout, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />,
        <Route
          exact
          path="/my-account"
          Component={withAuthenticationRequired(MyAccount, {
            onRedirecting: () => <GlobalLoadingSpinner />
          })}
        />
        // Add other routes for the Customer portal here
      ]);
    }

    // Add other portal routes here based on the available portals

    return routes;
  };

  const currentPage = window.location.pathname;
  console.log("CURRENT PAGE", currentPage);
  let showActivationPage = false;
  let showCurrentWashLocationsMap = false;
  let showDefaultPage = false;
  switch (currentPage) {
    case "/activate":
      showActivationPage = true;
      break;
    case "/current-wash-locations-map":
      showCurrentWashLocationsMap = true;
      break;
    default:
      showDefaultPage = true;
      break;
  }

  return showCurrentWashLocationsMap ? (
    <CurrentWashLocationsMap />
  ) : (
    <>
      <div className="flex justity-between bg-[#10073E]">
        <img
          className="max-w-[480px] p-2"
          src={P1LogoLight}
          alt=""
          onClick={() => {
            window.location.href = "/";
          }}
        />
      </div>

      {/* For not logged in user, show the login button */}

      {showActivationPage && <ActivateMembershipWithInviteCode />}
      {!user && !showActivationPage && (
        <Profile
          userPortals={userPortals}
          currentPortal={currentPortal}
          setCurrentPortal={(data) => {
            setCurrentPortal(data);
          }}
        />
      )}

      {showDefaultPage && userPortals.length > 0 && (
        <div className="app-priorityone flex flex-column">
          {/* {user && userPortals && <SelectPortalDD userPortals={userPortals} currentPortal={currentPortal} setCurrentPortal={(data) => { setCurrentPortal(data) }} />} */}
          <div className="app-inner sm:flex-column w-full flex-column divide divide-y-2">
            {!currentPortal && user && userPortals && (
              <SelectPortalModal
                userPortals={userPortals}
                setCurrentPortal={(data) => {
                  setCurrentPortal(data);
                }}
              />
            )}
            {currentPortal == "Partner" && !currentCompany && (
              <SelectCompanyModal
                setCurrentCompany={(data) => {
                  setCurrentCompany(data);
                }}
              />
            )}
            {/* {currentPortal == 'Partner' && currentCompany && <SelectCompanyDD setCurrentCompany={(data) => { setCurrentCompany(data) }} />} */}
            <div className="app-header userDropDown flex justify-between absolute top-1 right-5 max-h-[48px] bg-white">
              {/* <div className="header-logo flex justify-center items-center max-w-[250px]">
                <img
                  src={blueLogo}
                  alt=""
                  onClick={() => {
                    window.location.href = "/";
                  }}
                />
              </div> */}
              <div></div>

              {/* <div className="header-logo bg-[url('./assets/images/blueLogo.png')]">

          </div> */}
              {user && (
                <Profile
                  userPortals={userPortals}
                  currentPortal={currentPortal}
                  setCurrentPortal={(data) => {
                    setCurrentPortal(data);
                  }}
                />
              )}
            </div>
            <main className="flex w-full flex-col divide-x-2 divide-slate-200 sm:flex-column md:flex-row">
              {currentUser && (
                <>
                  {user && (
                    <MainMenu
                      setCurrentMerchantStore={(data) => {
                        setCurrentMerchantStore(data);
                      }}
                      userPortals={userPortals}
                      currentPortal={currentPortal}
                      setCurrentPortal={(data) => {
                        setCurrentPortal(data);
                      }}
                      setCurrentCompany={(data) => {
                        setCurrentCompany(data);
                      }}
                    />
                  )}
                  <Routes>
                    {isOffline && <Route exact path="/" element={<Maintenance ReactGA={ReactGA} GTM={TagManager} />} />}
                    <Route exact path="/" element={<Home />} />

                    {generateRoutes()}

                    {/* <Route path="*" element={<NotFound />} /> */}
                  </Routes>
                </>
              )}
            </main>
            <GlobalFooter />
          </div>
        </div>
      )}
    </>
  );
};

export default App;
