import axios from 'axios';
export const api = async function (endpoint, type, data, useFormData = false) {
    const domain = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? 'http://localhost:8094/' : 'https://api.priorityonewashclub.com/';

    let headers = {};

    var token = "";

    if (useFormData) {
        headers = {
            authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        };
    } else {
        headers = {
            authorization: `Bearer ${token}`,
        };
    }

    try {
        let request = await axios({
            method: type,
            url: domain + endpoint,
            headers,
            data: data,
            maxContentLength: 100000000,
            maxBodyLength: 1000000000
        });
        return request;
    } catch (error) {
        console.log('Domain: ', domain);
        console.log('Endpoint: ', endpoint);
        console.log('Type: ', endpoint);
        console.log('API Error: ', error);
        // console.log('API response: ', error.response);
        return error.response;
    }
}

export const googleEvent = async function (ReactGA, GTM, event, value) {
    if (event == "booking_confirmed") {
        const booking = await api(`booking/${value.bookingNumber}`, "GET", null);
        console.log("get booking: ", booking);
        if (booking.status == 200) {
            let data = booking.data.booking;
            let items = [];
            for (let i = 0; i < data.cart.sessionData.selectedServiceId.length; i++) {
                const element = data.cart.sessionData.selectedServiceId[i];
                let obj = {
                    item_name: element.name,
                    item_id: element.id,
                    quantity: element.quantity
                }
                items.push(obj)
            }
            GTM.dataLayer({
                dataLayer: {
                    event: event,
                    eventProps: {
                        value: value
                    }
                }
            });
            GTM.dataLayer({
                dataLayer: {
                    event: "ecommerce",
                    ecommerce: {
                        purchase: {
                            currency: "AUD",
                            value: data.cart.sessionData.estimatedTotal,
                            discount: data.cart.sessionData.discount,
                            coupon: data.cart.sessionData.couponCode,
                            reward: data.cart.sessionData.rewardCode,
                            offer: data.cart.sessionData.offerCode,
                            transaction_id: value.bookingNumber,
                            items: items
                        }
                    }
                }
            });
        }
    } else {
        ReactGA.event(event, value);
    }
}