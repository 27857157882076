import { React, useState, useEffect } from "react";
import ModalPopup from "../../components/ModalPopup";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { api } from "../../utils/api";
// import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
// import SubscriptionForm from "./Forms/SubscriptionForm";
// import { useNavigate } from "react-router-dom";
// import { DATA_GRID_STYLES } from "../../components/BasicUserInfo";
import { COLORS } from "../../utils/colors";
// import IconButton from "@mui/material/IconButton";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BasicUserInfo from "../../components/BasicUserInfo";
import CustomerForm from "../Customer/Forms/CustomerForm";
import AdministratorForm from "../Administrator/Forms/AdministratorForm";
import PartnerUserForm from "../PartnerCompany/PartnerUser/Forms/PartnerUserForm";
import MerchantUserForm from "../../components/Forms/MerchantUserForm";

function MyAccount() {
  const [currentUser, setCurrentUser] = useState(JSON.parse(sessionStorage.getItem("currentUser")));
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [merchant, setMerchant] = useState(null);
  const [mySubscriptionsLoading, setMySubscriptionsLoading] = useState(null);
  const [myVehiclesLoading, setMyVehiclesLoading] = useState(null);
  const [activeTab, setActiveTab] = useState("basicDetails");
  const [displayModal, handleDisplayModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [editUserState, setEditUserState] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    window.history.replaceState(null, "", window.location.pathname + "?tab=" + tab);
  };

  const editUser = () => {
    setEditUserState((prevValue) => !prevValue);
    setModalContent("editBasicDetails");
  };

  const reloadCustomerDetail = (data) => {
    if (data) {
      fetchUserDetails();
      setEditUserState(false);
    } else {
      setEditUserState(false);
    }
  };

  const getCustomerDetails = async () => {
    try {
      const response = await api("v1/customer/" + currentUser._id, "GET", null);
      if (response.data.success) {
        const updatedVehicleInfo = await Promise.all(
          response.data.data.customer.vehicles.map(async (vehicle) => {
            const fetchVehicleInfo = await api("v1/vehicle/" + vehicle, "GET");
            let vehicleData = null;
            if (fetchVehicleInfo.data.success) {
              vehicleData = fetchVehicleInfo.data.data.vehicle;
              console.log("Fetching active subs of vehicle: ", vehicle);
              // Fetch all Active Subscription of Vehicle
              const activeSubscriptions = await api(`v1/subscription/search`, "POST", { query: { vehicle: vehicle } });
              if (activeSubscriptions.data.success) {
                vehicleData.activeSubscriptions = activeSubscriptions.data.data.searchResult;
              } else {
                vehicleData.activeSubscriptions = null;
              }
            }
            return vehicleData;
          })
        );

        response.data.data.customer.vehicles = updatedVehicleInfo;

        setSelectedUser(response.data.data.customer);
        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };
  const getAdminDetails = async () => {
    try {
      const response = await api("v1/administrator/" + currentUser._id, "GET", null);
      if (response.data.success) {
        setSelectedUser(response.data.data.administrator);
        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };
  const getMerchantDetails = async () => {
    try {
      const response = await api(`v1/merchant/${currentUser.merchantAccount}/user/${currentUser._id}`, "GET", null);
      if (response.data.success) {
        setSelectedUser(response.data.data.merchantUser);
        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };
  const getPartnerDetails = async () => {
    try {
      const response = await api("v1/user/" + currentUser._id, "GET", null);
      if (response.data.success) {
        // console.log(response.data.data.partnerCompany);
        setSelectedUser(response.data.data.partnerUser);
        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      switch (detectUserType()) {
        case "merchant":
          getMerchantDetails();
          break;
        case "partner":
          getPartnerDetails();
          break;
        case "admin":
          getAdminDetails();
          break;
        case "customer":
          getCustomerDetails();
          break;

        default:
          break;
      }
    } catch (error) {
      console.error("API error:", error);
    }
    console.log();
  };

  function detectUserType() {
    if (currentUser.merchantAccount) {
      return "merchant";
    } else if (currentUser.partnercompanies) {
      return "partner";
    } else if (currentUser.role) {
      return "admin";
    } else {
      return "customer";
    }
  }
  function renderEditForm() {
    var form = null;
    switch (detectUserType()) {
      case "merchant":
        form = (
          <MerchantUserForm
            handleDisplayModal={reloadCustomerDetail}
            merchant={{ _id: selectedUser.merchantAccount }}
            merchantUser={selectedUser}
            callback={reloadCustomerDetail}
          />
        );
        break;
      case "partner":
        const selectedCompany = sessionStorage.getItem("selectedCompany");
        form = (
          <PartnerUserForm
            defaultCompany={selectedCompany}
            partnerUser={selectedUser}
            callback={reloadCustomerDetail}
          />
        );
        break;
      case "admin":
        form = <AdministratorForm administrator={selectedUser} callback={reloadCustomerDetail} />;
        break;
      case "customer":
        form = <CustomerForm customer={selectedUser} callback={reloadCustomerDetail} />;
        break;

      default:
        break;
    }

    return form;
  }

  useEffect(() => {
    if (sessionStorage.getItem("currentUser")) {
      console.log("Session ma: ", JSON.parse(sessionStorage.getItem("currentUser")), "CURRENTUSER:", currentUser);
      setCurrentUser(JSON.parse(sessionStorage.getItem("currentUser")));
    }
    fetchUserDetails();
  }, []);

  return (
    selectedUser && (
      <div className="my-account">
        <div className="page flex w-full flex-column items-center p-4">
          <div className="w-full flex flex-row justify-between items-center">
            <div>
              <h1 className="font-semibold">Welcome</h1>
            </div>
          </div>

          <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
            <div className="content">
              <div className="flex flex-column items-end">
                <div className="w-full overflow-auto">
                  <div className="mt-4">
                    <div className="flex">
                      <button
                        className={`mr-2 p-2 ${activeTab === "basicDetails" ? "bg-darkblue text-white" : ""}`}
                        onClick={() => handleTabChange("basicDetails")}
                      >
                        Basic Details
                      </button>
                      {/* {detectUserType() == "customer" && (
                        <button
                          className={`mr-2 p-2 ${activeTab === "subscriptions" ? "bg-darkblue text-white" : ""}`}
                          onClick={() => handleTabChange("subscriptions")}
                        >
                          My Subscriptions
                        </button>
                      )}
                      {detectUserType() == "customer" && (
                        <button
                          className={`p-2 ${activeTab === "vehicles" ? "bg-darkblue text-white" : ""}`}
                          onClick={() => handleTabChange("vehicles")}
                        >
                          My Vehicles
                        </button>
                      )} */}
                    </div>
                    {loading ? (
                      <GlobalLoadingSpinner />
                    ) : activeTab === "basicDetails" ? (
                      <>
                        <BasicUserInfo user={selectedUser} hideResendWelcomeEmail />
                        <div className="actionItems flex gap-4">
                          <Button
                            sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                            className="self-start m-4"
                            variant="contained"
                            onClick={editUser}
                          >
                            Edit Details
                          </Button>
                        </div>
                      </>
                    ) : activeTab === "subscriptions" ? (
                      mySubscriptionsLoading == true ? (
                        <GlobalLoadingSpinner />
                      ) : (
                        <h2>Show Subscriptions List</h2>
                      )
                    ) : (
                      activeTab === "vehicles" &&
                      (myVehiclesLoading == true ? <GlobalLoadingSpinner /> : <h2>Show Vehicles List</h2>)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalPopup
            showModal={editUserState}
            // handleShowModal={() => handleDisplayModal(!displayModal)}
            popupTitle="Edit Personal Details"
            popupContent={modalContent == "editBasicDetails" ? renderEditForm() : <></>}
            cancelButtonText="Cancel"
            confirmButtonText="Confirm"
            confirmCallbackAction=""
          />
        </div>
      </div>
    )
  );
}

export default MyAccount;
