import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import SignupCustomerForm from "./Forms/SignupCustomerForm";
import EntityCardDisplay from "../../components/EntityCardDisplay";
import { api } from "../../utils/api";

const SignupCustomer = () => {
  const [productItems, setProductOptions] = useState([]);
  const [clippedProducts, setClippedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSignupCustomerForm, setShowSignupCustomerForm] = useState(false);

  useEffect(() => {
    fetchProducts();
    fetchClippedProducts();
  }, [sessionStorage.getItem('currentUser')]);

  const fetchProducts = async () => {
    try {
      let endpoint = "v1/product";
      const response = await api(endpoint, "GET");
      if (response.data.success) {
        setProductOptions(response.data.data.allProducts);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const fetchClippedProducts = async () => {
    try {

      const dataToSend = {
        idPartnerCompany: sessionStorage.getItem("selectedCompany") || null
      };

      let response = await api(`v1/clippedassist/getPricingOptions`, "POST", dataToSend);
      console.log(response);

      if (response.data.success) {
        setClippedProducts(response.data.data.clippedProducts);
      } else {
        console.log("Result of Fetch Pricing Options from Clipped", response);
      }

    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };


  console.log("Signup customer");
  return (
    <div className="page product flex flex-column items-center p-4 w-full">
      {loading && <GlobalLoadingSpinner />}
      <div className="pageTitle">
        {/* <h2>Signup Customer</h2> */}
      </div>
      <div className="flex w-full">
        <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
          <div className="flex flex-column items-end gap-4 items-center">
            <SignupCustomerForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupCustomer;



