import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button, TextField } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import PartnerPricingForm from "./Forms/PartnerPricingForm";
import EntityCardDisplay from "../../components/EntityCardDisplay";
import { api } from "../../utils/api";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import { COLORS } from "../../utils/colors";
import { useNavigate } from "react-router-dom";
import EmptyState from "../EmptyStates/EmptyState";

const PartnerPricing = () => {
  const [partnerPricingOption, setPartnerPricingOption] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPartnerPricingForm, setShowPartnerPricingForm] = useState(false);
  const { filteredData, search, setSearch } = useBasicSearch(partnerPricingOption);

  useEffect(() => {
    fetchPartnerPricings();
  }, []);

  const fetchPartnerPricings = async () => {
    try {
      const response = await api("v1/pricing", "GET");
      if (response.data.success) {
        const partnerPricingOptions = response.data.data.allPartnerPricings;
        // console.log("All PartnerPricing", partnerPricingOptions);
        // const updatedPartnerPricingOptions = await Promise.all(
        //   partnerPricingOptions.map(async (partnerPricingOption) => {
        //     try {
        //       const companyResponse = await api(`v1/partner/company/${partnerPricingOption.partnerCompany}`, "GET");
        //       console.log(companyResponse);
        //       const productResponse = await api(`v1/product/${partnerPricingOption.product}`, "GET");
        //       if (companyResponse.data.success && productResponse.data.success) {
        //         return {
        //           ...partnerPricingOption,
        //           partner: companyResponse.data.data.partnerCompany.name,
        //           product: productResponse.data.data.product.name
        //         };
        //       }
        //     } catch (error) {
        //       console.error("API error:", error);
        //     }
        //   })
        // );
        // setPartnerPricingOption(updatedPartnerPricingOptions);
        setPartnerPricingOption(partnerPricingOptions);
        console.log("partnerPricingOptions", partnerPricingOptions);
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
    }
  };

  const triggerPartnerPricingListUpdate = (partnerPricing) => {
    console.log("New/Updated Partner Pricing: ", partnerPricing);
    setShowPartnerPricingForm(false);
    setLoading(false);
    fetchPartnerPricings();
  };
  const navigate = useNavigate();

  const AddNewPartner = () =>
    sessionStorage.getItem("selectedPortal") == "Administrator" ? (
      <Button
        sx={{ backgroundColor: COLORS.priorityBlue.hex }}
        variant="contained"
        onClick={() => setShowPartnerPricingForm(true)}
      >
        Add New Partner Pricing
      </Button>
    ) : null;

  return (
    <div className="page partnerPricing w-full flex flex-column items-center p-4">
      <div className="pageTitle flex flex-col w-full text-center lg:flex-row lg:text-left lg:justify-between mb-8">
        <h2>Partner Pricing</h2>
        <div>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: "350px" }}
            placeholder="Search for a pricing option"
          />
          <AddNewPartner />
          <ModalPopup
            showModal={showPartnerPricingForm}
            handleShowModal={() => setShowPartnerPricingForm(false)}
            popupTitle="Add New Partner Pricing Option"
            popupContent={
              <PartnerPricingForm
                callback={triggerPartnerPricingListUpdate}
                cancelModal={() => {
                  setShowPartnerPricingForm(false);
                }}
              />
            }
          />
        </div>
      </div>
      <div className="flex w-full justify-center">
        {loading ? (
          <GlobalLoadingSpinner />
        ) : partnerPricingOption.length === 0 ? (
          <EmptyState type="partnerPricing" text="You have no partner pricings available" action={<AddNewPartner />} />
        ) : filteredData.length === 0 ? (
          <EmptyState type="parnerPricing" text="No partner pricings match your search." />
        ) : (
          <div className="flex w-full justify-center flex-wrap gap-4 lg:justify-start">
            {filteredData.map((data) => (
              <div
                className="flex flex-col shadow-md p-4 rounded-md border-1 w-[450px] hover:cursor-pointer "
                onClick={() => navigate(`/partnerPricing/${data._id}`)}
              >
                <div className="grid grid-cols-2 gap-x-4">
                  <span className="font-semibold">Partner Company</span>
                  <span>{data.partnerCompany.name}</span>
                  <span className="font-semibold">Product</span>
                  <span>{data.product?.name}</span>
                  <span className="font-semibold">Price</span>
                  <span>${data.price} (Including GST)</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerPricing;
