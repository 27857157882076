import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import InviteCodeForm from "./Forms/InviteCodeForm";
import EntityCardDisplay from "../../components/EntityCardDisplay";
import { api } from "../../utils/api";

const InviteCode = () => {
    const [inviteCodeOption, setInviteCodeOption] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showInviteCodeForm, setShowInviteCodeForm] = useState(false);

    useEffect(() => {
        fetchInviteCodes();
    }, []);

    const fetchInviteCodes = async () => {
        try {
            const response = await api("v1/inviteCode", "GET");
            if (response.data.success) {
                const inviteCodeOptions = response.data.data.allInviteCodes;
                // console.log("All InviteCode", inviteCodeOptions);
                const updatedInviteCodeOptions = await Promise.all(inviteCodeOptions.map(async (inviteCodeOption) => {
                    try {
                        const customerResponse = await api(`v1/customer/${inviteCodeOption.customer}`, 'GET');
                        if (customerResponse.data.success) {
                            return {
                                ...inviteCodeOption,
                                customer: customerResponse.data.data.customer,
                            }
                        } else {
                            return {
                                ...inviteCodeOption,
                                customer: null,
                            }
                        }
                    } catch (error) {
                        console.error("API error:", error);
                    }
                }));
                setInviteCodeOption(updatedInviteCodeOptions);

            }
        } catch (error) {
            console.error("API error:", error);
        }
        setLoading(false);
    };

    const triggerInviteCodeListUpdate = (inviteCode) => {
        console.log("New/Updated InviteCode: ", inviteCode);
        setShowInviteCodeForm(false);
        setLoading(false);
        fetchInviteCodes();
    };

    const fieldsToDisplay = [
        { key: "customer.firstName", label: "First Name", className: "font-bold" },
        { key: "customer.lastName", label: "Last Name", className: "font-bold" },
        { key: "customer.email", label: "Email", className: "font-bold" },
        { key: "code", label: "Invite Code", className: "font-bold" },
        { key: "active", label: "Active", className: "font-bold" },
    ];

    return (
        <div className="page inviteCode flex flex-column items-center p-4">
            {loading && <GlobalLoadingSpinner />}
            <div className="pageTitle">
                <h2>Invite Codes</h2>
            </div>
            <div className="flex w-full">
                <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
                    <div className="flex flex-column items-end gap-4 items-center">
                        <div className="pageActions">
                            <Button variant="contained" onClick={() => setShowInviteCodeForm(true)}>
                                Add New Invite Code
                            </Button>
                            <ModalPopup
                                showModal={showInviteCodeForm}
                                handleShowModal={() => setShowInviteCodeForm(false)}
                                popupTitle="Add New Invite Code"
                                popupContent={<InviteCodeForm callback={triggerInviteCodeListUpdate} cancelModal={() => { setShowInviteCodeForm(false) }} />}
                            />
                        </div>
                        {inviteCodeOption && <EntityCardDisplay data={inviteCodeOption} fields={fieldsToDisplay} route={"/inviteCode/"} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteCode;
