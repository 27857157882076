import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  FaHome,
  FaBuilding,
  FaUser,
  FaStore,
  FaChevronDown,
  FaChevronUp,
  FaProductHunt,
  FaDollarSign,
  FaTag,
  FaRegBuilding,
  FaUserAlt,
  FaLock,
  FaCarAlt,
  FaSearchDollar,
  FaGifts,
  FaGift,
  FaList,
  FaHamburger,
  FaBars,
  FaCross
} from "react-icons/fa";
import SelectCompanyDD from "./SelectCompanyDD";
import SelectMerchantStoreDD from "./SelectStoreDD";

// import { useCookies } from "react-cookie";

const MenuItem = ({ icon, text, to, subItems, onClick }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  // const handleMouseEnter = () => {
  //   setIsExpanded(true);
  // };

  // const handleMouseLeave = () => {
  //   // setIsExpanded(false);
  // };

  return (
    <div>
      <div
        className={`menu-item flex ${subItems ? "has-children" : ""}`}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      >
        <NavLink
          to={to}
          activeclassname="active"
          className=" w-full 
                      flex
                      flex-column
                      justify-center
                      items-center
                      p-2                                             
                      text-[#6D6D6D] 
                      hover:font-bold 
                      _hover:bg-[#ececec]
                      hover:opacity-50 
                      hover:text-[#6D6D6D]
                    "
          onClick={() => {
            onClick();
          }}
        >
          {icon}
          <span className="text-[18px] text-center">{text}</span>
        </NavLink>
        {subItems && (
          <button
            className="toggle-btn"
            onClick={() => setIsExpanded(!isExpanded)}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {/* {isExpanded ? <FaChevronUp className="text-[1.2em]" /> : <FaChevronDown className="text-[1.2em]" />} */}
          </button>
        )}
      </div>
      {isExpanded && subItems && (
        <ul className="sub-menu">
          {subItems.map((subItem) => (
            <MenuItem key={subItem.to} {...subItem} />
          ))}
        </ul>
      )}
    </div>
  );
};

const MainMenu = ({ userPortals, currentPortal, setCurrentPortal, setCurrentCompany, setCurrentMerchantStore }) => {
  // const [cookies, setCookie, removeCookie] = useCookies(['selectedPortal']);
  let [currentUser] = useState(JSON.parse(sessionStorage.getItem("currentUser")));
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    // Add an event listener to check the viewport width on resize
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
      setMobileMenuOpen(false);
      setMobile(isMobile);
    };

    // Call the handler initially and add the event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentUser]);

  const menuItems = [
    {
      to: "/",
      text: "Dashboard",
      icon: <FaHome className="text-[1.2em]" />
    },
    {
      to: "/signup-customer",
      text: "Signup Customer",
      icon: <FaHome className="text-[1.2em]" />
    },
    {
      to: "/administrator",
      text: "Administrators",
      icon: <FaUser className="text-[1.2em]" />
    },
    {
      to: "/partner",
      text: "Partner Company",
      icon: <FaRegBuilding className="text-[1.2em]" />
    },
    {
      to: "/merchant",
      text: "Merchant Account" + `${sessionStorage.getItem("selectedPortal") == "Administrator" ? "s" : ""}`,
      icon: <FaUserAlt className="text-[1.2em]" />
    },
    {
      to: "/merchantStore",
      text: "Merchant Stores",
      icon: <FaUserAlt className="text-[1.2em]" />
    },
    // {
    //   to: "/merchant/user",
    //   text: "Merchant Users",
    //   icon: <FaUserAlt className="text-[1.2em]" />,
    // },
    {
      to: "/customer",
      text: "Customers",
      icon: <FaUser className="text-[1.2em]" />
    },
    {
      to: "/vehicle",
      text: "Vehicles",
      icon: <FaCarAlt className="text-[1.2em]" />
    },
    {
      to: "/product",
      text: "Products",
      icon: <FaTag className="text-[1.2em]" />
    },
    {
      to: "/partnerPricing",
      text: "Partner Pricing",
      icon: <FaDollarSign className="text-[1.2em]" />
    },
    {
      to: "/subscription",
      text: "Subscriptions",
      icon: <FaLock className="text-[1.2em]" />
    },
    {
      to: "/my-subscriptions",
      text: "My Subscriptions",
      icon: <FaLock className="text-[1.2em]" />
    },
    {
      to: "/promotion",
      text: "Promotions",
      icon: <FaSearchDollar className="text-[1.2em]" />
    },
    {
      to: "/redemption",
      text: "Merchant Redemptions",
      icon: <FaDollarSign className="text-[1.2em]" />
    },
    {
      to: "/merchantStorePricing",
      text: "Store Pricing",
      icon: <FaDollarSign className="text-[1.2em]" />
    },
    {
      to: "/transaction",
      text: "Partner Transactions",
      icon: <FaList className="text-[1.2em]" />
    },
    {
      to: "/inviteCode",
      text: "Invite Code",
      icon: <FaGift className="text-[1.2em]" />
    },
    {
      to: "/my-account",
      text: "My Account",
      icon: <FaUser className="text-[1.2em]" />
    },
  ];

  // Filter menu items based on the available portals
  const filteredMenuItems = menuItems.filter((menuItem) => {
    if (userPortals.includes("Administrator") && sessionStorage.getItem("selectedPortal") == "Administrator") {
      return (
        menuItem.to === "/" ||
        menuItem.to === "/dashboard" ||
        menuItem.to === "/administrator" ||
        menuItem.to === "/partner" ||
        menuItem.to === "/product" ||
        menuItem.to === "/partnerPricing" ||
        menuItem.to === "/subscription" ||
        menuItem.to === "/vehicle" ||
        menuItem.to === "/merchant" ||
        menuItem.to === "/merchantStore" ||
        menuItem.to === "/merchantStorePricing" ||
        menuItem.to === "/customer" ||
        menuItem.to === "/promotion" ||
        menuItem.to === "/redemption" ||
        menuItem.to === "/transaction" ||
        menuItem.to === "/inviteCode" ||
        menuItem.to === "/my-account"
      );
    }

    if (userPortals.includes("Merchant") && sessionStorage.getItem("selectedPortal") == "Merchant") {
      const currentMerchantUser = JSON.parse(sessionStorage.getItem("currentUser"));
      return (
        menuItem.to === "/" ||
        menuItem.to === "/dashboard" ||
        (menuItem.to === "/merchant" && currentMerchantUser.role == "admin") ||
        // menuItem.to === "/customer" ||
        // menuItem.to === "/vehicle" ||
        // (menuItem.to === "/product" && currentMerchantUser.role == "admin") ||
        menuItem.to === "/merchantStore" ||
        menuItem.to === "/redemption" ||
        menuItem.to === "/my-account"
      );
    }

    if (userPortals.includes("Partner") && sessionStorage.getItem("selectedPortal") == "Partner") {
      return (
        menuItem.to === "/" ||
        menuItem.to === "/dashboard" ||
        menuItem.to === "/signup-customer" ||
        menuItem.to === "/partner" ||
        // menuItem.to === "/product" ||
        // menuItem.to === "/vehicle" ||
        menuItem.to === "/transaction" ||
        menuItem.to === "/subscription" ||
        menuItem.to === "/my-account"
      );
    }
    if (userPortals.includes("Customer") && sessionStorage.getItem("selectedPortal") == "Customer") {
      return (
        menuItem.to === "/" || // Dashboard is available to all
        menuItem.to === "/my-subscriptions" ||
        menuItem.to === "/vehicle" ||
        menuItem.to === "/my-account"
      );
    }

    // Add conditions for other portals as needed

    return false;
  });

  var mobileDisplay = isMobile
    ? isMobileMenuOpen
      ? "absolute top-[105px] w-full z-50 bg-white bottomBorderMenu"
      : "hidden"
    : "";

  return (
    <aside className={`flex flex-col w-full ${isMobile ? "max-w-full" : "max-w-[150px]"}`}>
      {sessionStorage.getItem("selectedPortal") == "Partner" && (
        <SelectCompanyDD
          setCurrentCompany={(data) => {
            setCurrentCompany(data);
          }}
        />
      )}
      {sessionStorage.getItem("selectedPortal") == "Merchant" && (
        <SelectMerchantStoreDD
          setCurrentMerchantStore={(data) => {
            setCurrentMerchantStore(data);
          }}
        />
      )}

      <div
        className={`menu flex flex-column text-xl divide-y-2 divide-slate-200 ${isMobile ? "mobile-menu-open" : "desktop-menu"
          }`}
      >
        {/* Hamburger Icon */}
        <div className={`hamburger-icon flex justify-center items-center`} onClick={toggleMobileMenu}>
          {isMobile && !isMobileMenuOpen && <FaBars className="text-[2em]" />}
          {isMobile && isMobileMenuOpen && <FaCross className="text-[2em]" />}
        </div>

        {/* Menu Items */}
        <div className={`menuItemSection ${mobileDisplay} `}>
          {filteredMenuItems.map((menuItem) => (
            <MenuItem className="" key={menuItem.to} {...menuItem} onClick={toggleMobileMenu} />
          ))}
        </div>
      </div>
    </aside>

    // <aside className={`flex flex-column w-full ${isMobileMenuOpen ? 'max-w-full' : 'max-w-[150px]'}`}>
    //   <div className={`menu flex flex-column text-xl divide-y-2 divide-slate-200 ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>

    //     {/* Hamburger Icon */}
    //     <div className="hamburger-icon flex justify-center items-center" onClick={toggleMobileMenu}>
    //       <FaBars className="text-[2em]" />
    //     </div>

    //     {/* Menu Items */}
    //     {filteredMenuItems.map((menuItem) => (
    //       <MenuItem key={menuItem.to} {...menuItem} />
    //     ))}
    //   </div>
    // </aside>
  );
};

export default MainMenu;
