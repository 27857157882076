import React, { useEffect, useState } from 'react';
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import MerchantAccountDrillDown from './MerchantAccountDrillDown';
import MerchantStoreDrillDown from './MerchantStoreDrillDown';
import { fetchRedemptionData } from './RedemptionChartUtilities';
import { api } from '../../utils/api';
import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MerchantRedemptionsCharts = ({ dataSource, appliedFilter }) => {
    const [loading, setLoading] = useState(true);
    const [redemptionData, setRedemptionsData] = useState([]);
    const [monthlySalesData, setMonthlySalesData] = useState({ labels: [], datasets: [] });

    const currentPortal = sessionStorage.getItem("selectedPortal");
    const selectedMerchantStore = sessionStorage.getItem("selectedMerchantStore");

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowClick = (redemptionId) => {
        const url = `/redemption/${redemptionId}`;
        window.open(url, '_blank');
    };

    const countTotalSalesByMonth = (salesData) => {
        const totalSalesByMonth = {};
        salesData.forEach(sale => {
            const saleDate = new Date(sale.createdOnUTC);
            if (!isNaN(saleDate.getTime())) {
                const monthYear = `${saleDate.getFullYear()}-${saleDate.getMonth()}`;
                if (!totalSalesByMonth[monthYear]) {
                    totalSalesByMonth[monthYear] = { count: 0, totalPrice: 0 };
                }
                totalSalesByMonth[monthYear].count += 1;
                totalSalesByMonth[monthYear].totalPrice += parseFloat(sale.price) || 0;
            } else {
                console.error('Invalid Date:', sale.createdOnUTC);
            }
        });
        return totalSalesByMonth;
    };

    const getMonthName = (monthIndex) => {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return monthNames[monthIndex];
    };

    const sortMonths = (monthYearKeys) => {
        return monthYearKeys.sort((a, b) => {
            const [yearA, monthA] = a.split('-').map(Number);
            const [yearB, monthB] = b.split('-').map(Number);
            if (yearA === yearB) {
                return monthA - monthB;
            }
            return yearA - yearB;
        });
    };

    const calculateMonthlySales = (data) => {
        const totalSalesByMonth = countTotalSalesByMonth(data);
        const sortedMonthYears = sortMonths(Object.keys(totalSalesByMonth));

        setMonthlySalesData({
            labels: sortedMonthYears.map(monthYear => {
                const [year, month] = monthYear.split('-');
                return `${getMonthName(parseInt(month))} ${year}`;
            }),
            datasets: [
                {
                    label: 'Total Sales by Month',
                    data: sortedMonthYears.map(monthYear => totalSalesByMonth[monthYear].totalPrice),
                    backgroundColor: 'rgba(153, 102, 255, 0.6)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    fill: false,
                },
            ],
        });

        return { totalSalesByMonth, sortedMonthYears };
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let modifiedFilter = { ...appliedFilter };
                switch (currentPortal) {
                    case "Merchant":
                        if (currentPortal === "Merchant") {
                            modifiedFilter.merchantStore = selectedMerchantStore;
                        }
                        break;
                    default:
                        break;
                }

                const data = await fetchRedemptionData(dataSource, modifiedFilter);
                const sortedData = data.sort((a, b) => new Date(b.createdOnUTC) - new Date(a.createdOnUTC));
                setRedemptionsData(sortedData);
                const { totalSalesByMonth, sortedMonthYears } = calculateMonthlySales(sortedData);
                setMonthlySalesData(prevData => ({
                    ...prevData,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const monthYear = sortedMonthYears[tooltipItem.dataIndex];
                                    const salesData = totalSalesByMonth[monthYear];
                                    const totalPrice = salesData.totalPrice || 0;
                                    return [
                                        `Total Price: $${typeof totalPrice === 'number' ? totalPrice.toFixed(2) : totalPrice}`,
                                        `Count: ${salesData.count}`
                                    ];
                                }
                            }
                        }
                    }
                }));
                setLoading(false);
            } catch (error) {
                console.error("Error fetching redemption data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [appliedFilter, dataSource, currentPortal, selectedMerchantStore]);

    return (
        <>
            {loading && <GlobalLoadingSpinner />}
            <div className="flex flex-wrap justify-center">
                {redemptionData.length > 0 ? (
                    <div className='divide divide-y gap-4 flex flex-column py-2'>
                        <div className='w-full flex justify-center items-center flex-wrap gap-2'>

                            <MerchantAccountDrillDown redemptionData={redemptionData} />

                            {/* <MerchantStoreDrillDown redemptionData={redemptionData} /> */}
                        </div>
                        <div className='w-full flex flex-column max-w-[800px] justify-center items-center flex-wrap gap-2'>
                            <h2>Sales By Month</h2>
                            <Line
                                data={monthlySalesData}
                                options={{
                                    plugins: {
                                        tooltip: {
                                            callbacks: {
                                                label: function (tooltipItem) {
                                                    const [month, year] = tooltipItem.label.split(' ');
                                                    const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
                                                    const sortedMonthYears = sortMonths(Object.keys(countTotalSalesByMonth(redemptionData)));
                                                    const monthYearKey = `${year}-${monthIndex}`;
                                                    const salesData = countTotalSalesByMonth(redemptionData)[monthYearKey];
                                                    const totalPrice = salesData.totalPrice || 0;
                                                    return [
                                                        `Total Price: $${typeof totalPrice === 'number' ? totalPrice.toFixed(2) : totalPrice}`,
                                                        `Count: ${salesData.count}`
                                                    ];
                                                }
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>

                        <div className='w-full redemptionRecords'>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearch}
                                placeholder="Search Redemption"
                                className="w-full mb-4 px-4 py-2 border rounded"
                            />
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Verified</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created On</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Merchant Store</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Merchant User</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice Status</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {redemptionData.map((redemption, index) => {
                                        const customerName = `${redemption.vehicle?.customer?.firstName} ${redemption.vehicle?.customer?.lastName}`;
                                        const createdDate = new Date(redemption.createdOnUTC).toLocaleDateString();
                                        const productName = redemption.product?.name;
                                        const merchantStoreName = redemption.merchantStore ? `${redemption.merchantStore?.name}` : '';
                                        const merchantUserName = redemption.merchantUser ? `${redemption.merchantUser?.firstName} ${redemption.merchantUser?.lastName}` : '';
                                        const invoiceStatus = redemption.invoiceStatus;

                                        return (
                                            <tr className='cursor-pointer' onClick={() => handleRowClick(redemption._id)} key={redemption._id}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{redemption.verified ? <CheckCircleOutlined className='success' /> : <CancelOutlined className='error' />}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{createdDate}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{customerName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{productName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{merchantStoreName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{merchantUserName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoiceStatus}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <p>No redemption data available</p>
                )}
            </div>
        </>
    );
};

export default MerchantRedemptionsCharts;
