import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Box, Button, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";

const validationSchema = Yup.object().shape({
  // merchant: Yup.object().required("Merchant is required"),
  // product: Yup.object().required("Product is required"),
  price: Yup.number().required("Price is required")
});

const MerchantStorePricingForm = ({ selectedMerchantStorePricing, callback, cancelModal, onlyStore }) => {
  // console.log(selectedMerchantStorePricing);
  const [modalActive, setModalActive] = useState(true);
  const [merchantOptions, setMerchantOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [merchantStore, setMerchantStore] = useState("");
  const [product, setProduct] = useState("");

  const getAllMerchantStores = async () => {
    try {
      console.log("SELECTING: ", onlyStore);
      const response = await api("v1/merchantStore", "GET", null);
      if (response.data.success) {
        const allMerchantStores = response.data.data.allMerchantStores;
        const result = allMerchantStores.map((merchant) => ({
          value: merchant._id,
          label: merchant.name
        }));

        if (result) {
          setMerchantOptions(result);
          if (selectedMerchantStorePricing) {
            const selectedMerchant = result.find(
              (merchant) => merchant.value === selectedMerchantStorePricing?.merchantStore?._id
            );
            setMerchantStore(selectedMerchant);
          }
          if (onlyStore) {
            const selectedMerchantStore = result.find((merchant) => merchant.value === onlyStore);
            setMerchantStore(selectedMerchantStore);
          }
        }
      }
    } catch (error) {
      console.error("Error loading merchants:", error);
    }
  };

  const getAllProducts = async () => {
    try {
      const response = await api("v1/product", "GET", null);
      if (response.data.success) {
        const allProducts = response.data.data.allProducts;
        const result = allProducts.map((product) => ({
          value: product._id,
          label: product.name
        }));

        if (result) {
          setProductOptions(result);
          if (selectedMerchantStorePricing) {
            const selectedProduct = result.find(
              (product) => product.value === selectedMerchantStorePricing.product._id
            );
            setProduct(selectedProduct);
          }
        }
      }
    } catch (error) {
      console.error("Error loading merchants:", error);
    }
  };

  useEffect(() => {
    getAllMerchantStores();
    getAllProducts();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("Submitted Data", values);
    const dataToSend = {
      merchantStore: merchantStore.value,
      product: product.value,
      price: values.price,
      adminFee: values.adminFee
    };
    console.log("Data to Send", dataToSend);
    if (Object.keys(merchantStore).length == 0 || Object.keys(product).length == 0) {
      // Having issue with YUP validation will use this method for now.
      alert("Required Fields not selected");
      return false;
    }
    try {
      let response;
      if (selectedMerchantStorePricing) {
        // Edit MerchantStorePricing Option
        response = await api(`v1/merchantStorePricing/${selectedMerchantStorePricing._id}`, "PATCH", dataToSend);
      } else {
        // Add MerchantStorePricing Option
        response = await api(`v1/merchantStorePricing`, "POST", dataToSend);
      }

      if (response.data.success) {
        if (selectedMerchantStorePricing) {
          console.log("Store Pricing Updated: ", response.data.data.updatedMerchantStorePricing);
          const merchantStore = await api(
            `v1/merchantStore/${response.data.data.updatedMerchantStorePricing.merchantStore}`,
            "GET"
          );
          const product = await api(`v1/product/${response.data.data.updatedMerchantStorePricing.product}`, "GET");
          response.data.data.updatedMerchantStorePricing.merchant = merchantStore.data.data.merchantStore;
          response.data.data.updatedMerchantStorePricing.product = product.data.data.product;
          callback(response.data.data.updatedMerchantStorePricing);
        } else {
          if (response.data.data.newMerchantStorePricing) {
            callback(response.data.data.newMerchantStorePricing);
          } else {
            alert(response.data.message);
          }
        }
      } else {
        response?.data?.message && alert(response.data.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }

    setSubmitting(false);
  };

  const loadProductOptions = async (inputValue) => {
    try {
      const filteredOptions = productOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      // callback(filteredOptions);
      return filteredOptions;
    } catch (error) {
      console.error("Error loading product options:", error);
      callback([]);
    }
  };

  const handleProductChange = (selectedOptions) => {
    if (selectedOptions) {
      // const { value, label } = selectedOptions;
      // setProduct({ value, label });
      setProduct(selectedOptions);
    } else {
      setProduct(null);
    }
  };

  const loadMerchantOptions = async (inputValue) => {
    try {
      const filteredOptions = merchantOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      // callback(filteredOptions);
      return filteredOptions;
    } catch (error) {
      console.error("Error loading merchant options:", error);
      callback([]);
    }
  };

  const handleMerchantChange = (selectedOptions) => {
    if (selectedOptions) {
      setMerchantStore(selectedOptions);
    } else {
      setMerchantStore(null);
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#9e9e9e",
      zIndex: 2
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3
    })
  };

  console.log({
    price: selectedMerchantStorePricing ? selectedMerchantStorePricing.price : "",
    merchantStore: merchantStore,
    product: product
  });

  return (
    <Formik
      initialValues={{
        price: selectedMerchantStorePricing ? selectedMerchantStorePricing.price : 0,
        adminFee: selectedMerchantStorePricing ? selectedMerchantStorePricing.adminFee : 0,
        merchantStore: selectedMerchantStorePricing ? selectedMerchantStorePricing.merchantStore : "",
        product: selectedMerchantStorePricing ? selectedMerchantStorePricing.product : ""
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
        <div className="form-input-wrap flex gap-2 w-full mb-2">
          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="merchantStore">
                Store Name:
              </label>
              {/* <Field name="merchantStore" className="z-2">
                                {({ field }) => (
                                    <AsyncSelect
                                        name="merchantStore"
                                        {...field}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={loadMerchantOptions}
                                        styles={selectStyles}
                                        onChange={handleMerchantChange}
                                        value={merchantStore}
                                    />
                                )}
                            </Field> */}
              <AsyncSelect
                name="merchantStore"
                cacheOptions
                defaultOptions={merchantOptions}
                loadOptions={loadMerchantOptions}
                styles={selectStyles}
                onChange={handleMerchantChange}
                value={merchantStore}
              />
              <ErrorMessage name="merchantStore" component="span" className="error-message" />
            </div>
          </div>
          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="product">
                Product:
              </label>
              {/* <Field name="product" className="z-2">
                                {({ field }) => (
                                    <AsyncSelect
                                        name="product"
                                        {...field}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={loadProductOptions}
                                        styles={selectStyles}
                                        onChange={handleProductChange}
                                        value={product}
                                    />
                                )}
                            </Field> */}
              <Field name="product">
                {({ field }) => (
                  <AsyncSelect
                    name="product"
                    {...field}
                    cacheOptions
                    defaultOptions={productOptions}
                    loadOptions={loadProductOptions}
                    styles={selectStyles}
                    onChange={handleProductChange}
                    value={product}
                  />
                )}
              </Field>
              <ErrorMessage name="product" component="span" className="error-message" />
            </div>
          </div>
          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              {/* <label htmlFor="price">Price:</label> */}
              <Field name="price">
                {({ field }) => (
                  <TextField
                    {...field}
                    id="price"
                    type="number"
                    label="Price (Including GST)"
                    className="z-2"
                    inputProps={{ min: 0 }}
                  />
                )}
              </Field>
              <ErrorMessage name="price" component="span" className="error-message" />
            </div>
          </div>
          {/* Admin Fee */}
          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              {/* <label htmlFor="adminFee">Admin Fee:</label> */}
              <Field name="adminFee">
                {({ field }) => (
                  <TextField
                    {...field}
                    id="adminFee"
                    type="number"
                    label="Admin Fee (%)"
                    className="z-2"
                    inputProps={{ min: 5 }}
                  />
                )}
              </Field>
              <ErrorMessage name="adminFee" component="span" className="error-message" />
            </div>
          </div>
        </div>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Button variant="contained" onClick={cancelModal}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};

export default MerchantStorePricingForm;
