import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import ProductForm from "./Forms/ProductForm";
import { useNavigate } from "react-router-dom";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { DATA_GRID_STYLES } from "../../components/BasicUserInfo";
import { COLORS } from "../../utils/colors";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ProductSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editProductState, setEditProductState] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await api("v1/product/" + params.idProduct, "GET", null);
        if (response.data.success) {
          // console.log(response.data.data.partnerCompany);
          setSelectedProduct(response.data.data.product);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchProductDetails();
  }, []);

  const handleDeleteProductConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/product/" + selectedProduct._id, "DELETE", null);
      if (response.data.success) {
        console.log(response.data);
        // window.location.replace("/product");
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editProduct = () => {
    setEditProductState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadProductDetail = (updateCompanyDetail) => {
    setEditProductState((prevValue) => !prevValue);
    setSelectedProduct(updateCompanyDetail);
  };

  console.log("editProductState", editProductState);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

    return formattedDate;
  }

  return (
    selectedProduct && (
      <div className="page partner-user-single w-full flex flex-column min-h-h-screen p-8">
        {loading && <GlobalLoadingSpinner />}

        <div className="pageTitle w-full flex flex-row justify-between">
          <h1>Product Details</h1>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
        <h3 className="font-semibold mb-4">{selectedProduct.name}</h3>
        {/* Product Single Content */}
        <div className="w-[600px]">
          <div className={DATA_GRID_STYLES}>
            <span>Name</span>
            <span> {selectedProduct.name}</span>
            <span>Type</span>
            <span> {selectedProduct.type.toUpperCase()}</span>

            {selectedProduct.type == "package" && (
              <>
                <span>Subscription Duration</span>
                <span>
                  {selectedProduct.subscriptionDuration}{" "}
                  {parseInt(selectedProduct.subscriptionDuration) === 1 ? "Month" : "Months"}
                </span>
              </>
            )}

            {selectedProduct.type == "package" && (
              <>
                <span>Package Count</span>
                <span>{selectedProduct.packageCount}</span>
              </>
            )}
            <span>Price</span>
            <span> ${selectedProduct.price}</span>
            {/* <span>Redemption Price</span> */}
            {/* <span> ${selectedProduct.redemptionPrice}</span> */}
            <span>RRP</span>
            <span>${selectedProduct.rrp}</span>
            <span>Required Fields</span>
            <span>{selectedProduct.requiredFields.join(", ")}</span>
            <span>ACTIVE</span>
            <span> {selectedProduct.active ? "Yes" : "No"}</span>
            <span>Created On</span>
            <span> {formatDate(selectedProduct.createdOnUTC)}</span>
          </div>

          {sessionStorage.getItem("selectedPortal") == "Administrator" && (
            <div className="actionItems flex gap-4">
              <Button
                size="large"
                sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                className="self-start m-4"
                variant="contained"
                onClick={editProduct}
              >
                Edit Product
              </Button>
              <Button
                size="large"
                className="self-start bg-red-600 m-4"
                variant="contained"
                color="error"
                onClick={() => {
                  toggleDisplayDeleteConfirm(true);
                }}
              >
                Delete Product
              </Button>
            </div>
          )}
        </div>

        {/* // Edit Modal HTML */}

        <ModalPopup
          showModal={editProductState}
          handleShowModal={editProduct}
          popupTitle={"Edit Product"}
          popupContent={<ProductForm product={selectedProduct} callback={reloadProductDetail} />}
          cancelButtonText=""
          confirmButtonText=""
          confirmCallbackAction=""
        />

        {/* Delete Product Confimation Modal */}
        <ModalPopup
          showModal={deleteConfirmationIsOpen}
          handleShowModal={toggleDisplayDeleteConfirm}
          popupTitle={"Delete Product"}
          popupContent={
            <div>
              Are you sure you want to delete product:
              <strong>
                <em> {selectedProduct.name} </em>
              </strong>
              ?
            </div>
          }
          cancelButtonText={"Cancel"}
          confirmButtonText={"Delete"}
          confirmCallbackAction={handleDeleteProductConfirm}
        />
      </div>
    )
  );
};

export default ProductSingle;
