import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import MerchantStorePricingForm from "./Forms/MerchantStorePricingForm";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { COLORS } from "../../utils/colors";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";

const MerchantStorePricingSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editMerchantStorePricingState, setEditMerchantStorePricingState] = useState(false);

  const [selectedMerchantStorePricing, setSelectedMerchantStorePricing] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    const fetchMerchantStorePricingDetails = async () => {
      try {
        const response = await api("v1/merchantStorePricing/" + params.idMerchantStorePricing, "GET", null);
        if (response.data.success) {
          /*
          const merchantStore = await api(
            `v1/merchantStore/${response.data.data.merchantStorePricing.merchantStore}`,
            "GET"
          );
          const product = await api(`v1/product/${response.data.data.merchantStorePricing.product}`, "GET");
          response.data.data.merchantStorePricing.merchant = merchantStore.data.data.merchantStore;
          response.data.data.merchantStorePricing.product = product.data.data.product;

          setSelectedMerchantStorePricing(response.data.data.merchantStorePricing);
          */
          setSelectedMerchantStorePricing(response.data.data.merchantStorePricing);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchMerchantStorePricingDetails();
  }, []);

  const handleDeleteMerchantStorePricingConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/merchantStorePricing/" + selectedMerchantStorePricing._id, "DELETE", null);
      if (response.data.success) {
        navigate(-1);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editMerchantStorePricing = () => {
    setEditMerchantStorePricingState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadMerchantStorePricingDetail = (updatedMerchantStorePricingDetails) => {
    setEditMerchantStorePricingState((prevValue) => !prevValue);
    setSelectedMerchantStorePricing(updatedMerchantStorePricingDetails);
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

    return formattedDate;
  }

  return (
    selectedMerchantStorePricing && (
      <div className="page partner-user-single w-full flex flex-column items-center min-h-h-screen p-4 gap-8">
        {loading && <GlobalLoadingSpinner />}

        <div className="w-full flex flex-row justify-between items-center">
          <div>
            <h2>Store Pricing Option Details</h2>
          </div>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {/* MerchantStorePricing Single Content */}
        {selectedMerchantStorePricing.merchantStore && (
          <div className="w-full">
            <div className="w-full flex flex-col shadow-md p-4 rounded-md border-1 max-w-[400px]">
              <p>
                Store Name:{" "}
                <a
                  href="javascript:void(0);"
                  onClick={() =>
                    navigate(
                      `/merchant/${selectedMerchantStorePricing.merchantStore.merchantAccount}/store/${selectedMerchantStorePricing.merchantStore._id}`
                    )
                  }
                >
                  {selectedMerchantStorePricing.merchantStore.name}
                </a>
              </p>
              <p>
                Product:{" "}
                <a
                  href="javascript:void(0);"
                  onClick={() => navigate("/product/" + selectedMerchantStorePricing.product._id)}
                >
                  {selectedMerchantStorePricing.product.name}
                </a>
              </p>
              <p>Price: ${selectedMerchantStorePricing.price} (Including GST)</p>
              <p>Created On: {formatDate(selectedMerchantStorePricing.createdOnUTC)}</p>

              {sessionStorage.getItem("selectedPortal") == "Administrator" && (
                <div className="actionItems flex gap-4">
                  <Button
                    sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                    className="self-start m-4"
                    variant="contained"
                    onClick={editMerchantStorePricing}
                  >
                    Edit Pricing
                  </Button>
                  <Button
                    className="self-start bg-red-600 m-4"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      toggleDisplayDeleteConfirm(true);
                    }}
                  >
                    Delete Pricing
                  </Button>
                </div>
              )}
            </div>

            {/* // Edit Modal HTML */}

            <ModalPopup
              showModal={editMerchantStorePricingState}
              handleShowModal={editMerchantStorePricing}
              popupTitle={"Edit Store Pricing"}
              popupContent={
                <MerchantStorePricingForm
                  selectedMerchantStorePricing={selectedMerchantStorePricing}
                  callback={reloadMerchantStorePricingDetail}
                  cancelModal={editMerchantStorePricing}
                />
              }
              cancelButtonText=""
              confirmButtonText=""
              confirmCallbackAction=""
            />

            {/* Delete Store Pricing Confimation Modal */}
            <ModalPopup
              showModal={deleteConfirmationIsOpen}
              handleShowModal={toggleDisplayDeleteConfirm}
              popupTitle={"Delete Store Pricing"}
              popupContent={
                <div>
                  Are you sure you want to delete the merchantStorePricing option for:
                  <strong>
                    <em> {selectedMerchantStorePricing.merchantStore.name} </em>
                  </strong>
                  ?
                </div>
              }
              cancelButtonText={"Cancel"}
              confirmButtonText={"Delete"}
              confirmCallbackAction={handleDeleteMerchantStorePricingConfirm}
            />
          </div>
        )}
      </div>
    )
  );
};

export default MerchantStorePricingSingle;
