import React from "react";
import { api } from "../../utils/api";
import Auth0LoginButton from "../../components/Auth0LoginButton";
import LogoutButton from "../../components/LogoutButton";
import Profile from "../../components/Profile";
import * as Images from "../../themes/images";
// import {  PricingCard } from "../../components";
import { CustomButton, GridSection } from "../../components";
import "./style.css";
import NewsletterWidget from "../../components/NewsletterWidget";
import GlobalFooter from "../../components/GlobalFooter";
import Dashboard from "../Dashboard";
// import AdminDashboard from "../AdminDashboard";
const howItWorkssectionItems = [
  {
    tileHeading: {
      text: "Step 1: Sign Up",
      className: "bg-green text-black font-bold tileHeadingGreenBlack"
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "1. Sign Up",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "Login",
      callback: "showLoginModal",
      className:
        "py-2 p-5 rounded-full border-solid border-2 flex flex-column items-center text-sm hover:bg-darkblue hover:text-white "
    },
    className: "flex flex-column gap-4"
  },
  {
    tileHeading: {
      text: "Step 2: Register",
      className: "bg-green text-black font-bold tileHeadingGreenBlack"
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "2. Download & Register with App",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "Register",
      callback: "showLoginModal",
      className:
        "py-2 p-5 rounded-full border-solid border-2 flex flex-column items-center text-sm hover:bg-darkblue hover:text-white "
    },
    className: "flex flex-column gap-4"
  },
  {
    tileHeading: {
      text: "Step 3: Find Location",
      className: "bg-green text-black font-bold tileHeadingGreenBlack"
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "3. Find Location",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "Find Location",
      callback: "showLoginModal",
      className:
        "py-2 p-5 rounded-full border-solid border-2 flex flex-column items-center text-sm hover:bg-darkblue hover:text-white "
    },
    className: "flex flex-column gap-4"
  },
  {
    tileHeading: {
      text: "Step 4: Enjoy",
      className: "bg-green text-black font-bold tileHeadingGreenBlack"
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "4. Redeem with QR code",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "Download App",
      callback: "showLoginModal",
      className:
        "py-2 p-5 rounded-full border-solid border-2 flex flex-column items-center text-sm hover:bg-darkblue hover:text-white "
    },
    className: "flex flex-column gap-4"
  },
  {
    tileHeading: {
      text: "Step 4: Enjoy",
      className: "bg-green text-black font-bold tileHeadingGreenBlack"
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "4. Redeem with QR code",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "Download App",
      callback: "showLoginModal",
      className:
        "py-2 p-5 rounded-full border-solid border-2 flex flex-column items-center text-sm hover:bg-darkblue hover:text-white "
    },
    className: "flex flex-column gap-4"
  },
  {
    tileHeading: {
      text: "Step 4: Enjoy",
      className: "bg-green text-black font-bold tileHeadingGreenBlack"
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "4. Redeem with QR code",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "Download App",
      callback: "showLoginModal",
      className:
        "py-2 p-5 rounded-full border-solid border-2 flex flex-column items-center text-sm hover:bg-darkblue hover:text-white "
    },
    className: "flex flex-column gap-4"
  }
];

const forBusinessSectionItems = [
  {
    tileHeading: {
      text: "",
      className: ""
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "More Customers",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "",
      callback: "",
      className: ""
    },
    className: "flex flex-column gap-4"
  },
  {
    tileHeading: {
      text: "",
      className: ""
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "Up Selling",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "",
      callback: "",
      className: ""
    },
    className: "flex flex-column gap-4"
  },
  {
    tileHeading: {
      text: "",
      className: ""
    },
    tileImage: "howItWorksImage1",
    tileSubHeading: "Cross Selling",
    tileDescription:
      "Minor car damage? Just reach out to us and follow the simple steps below! It's that easy.",
    ctaButton: {
      text: "",
      callback: "",
      className: ""
    },
    className: "flex flex-column gap-4"
  }
];

const subscribeSettings = {
  title: "",
  introText: "Sign up for priority rewards and special offers via email",
  buttonText: "Subscribe",
  className: "flex flex-column gap-4"
};
class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return true ? (
      <Dashboard />
    ) : (
      <div className="homePage">
        <div className="loggedInBar">
          {" "}
          <Profile />{" "}
        </div>
        <div className="homeBanner min-h-screen justify-center items-center flex flex-column text-center m10">
          <img src={Images.globalLogoSmall} className="mb-[32px]" alt=""></img>
          <h1 className="text-white text-h1">The Dealership Rewards Program</h1>
          <div className="bannerButtons flex w-full flex-row mt-[44px] gap-4 flex-wrap justify-center items-center">
            <Auth0LoginButton
              title="Log In"
              buttonClasses={[
                "bg-green-button",
                "max-w-[264px]",
                "w-50",
                "rounded-2xl",
                "text-xl",
                "font-bold",
                "uppercase",
                "py-3"
              ]}
            />
            <Auth0LoginButton
              title="Register"
              buttonClasses={[
                "bg-white-button",
                "max-w-[264px]",
                "w-50",
                "rounded-2xl",
                "text-xl",
                "font-bold",
                "uppercase",
                "py-3"
              ]}
            />
          </div>
        </div>

        <div className="howItWorksSection py-10">
          <GridSection
            type="carousel__"
            title="How it works"
            tileItems={howItWorkssectionItems}
          />
        </div>

        {/* Download the App Section Starts */}
        <div className="downloadAppSection py-10  bg-[#FAFAFA] flex flex-row min-h-[60%]">
          <div className="downloadAppLeft w-min-[500] flex flex-column justify-center items-center">
            <div className="bg-white h-[500px] w-[500px] max-h-[80%] max-w-[80%] rounded">
              {/* Google map script here */}
            </div>
          </div>

          <div className="downloadAppRight w-min-[500] flex flex-column justify-center items-start">
            <div className="sectionHeading">Download the App </div>
            <p className="sectionSubHeadgin">
              We have made this as easy as possible{" "}
            </p>
            <p className="sectionDesciption">
              We have made this as easy as possible
            </p>
            <CustomButton
              title="Download the App"
              buttonClasses={[
                "bg-green-button hover:bg-darkblue hover:text-white",
                "max-w-[264px]",
                "w-50",
                "rounded-2xl",
                "text-xl",
                "font-bold",
                "py-3"
              ]}
              callback={() => {
                window.location.href = "/download-app";
              }}
            />
          </div>
        </div>
        {/* Download the App Section Ends */}

        {/* Find a Location Section Starts */}
        <div className="findLocationSection py-10  bg-[#e0e1e2] flex flex-row min-h-[60%]">
          <div className="findALocationLeft  w-min-[500] flex flex-column justify-center items-center">
            <div className="sectionHeading">Find a Location </div>
            <p className="sectionSubHeadgin">
              We have made this as easy as possible{" "}
            </p>
            <p className="sectionDesciption">
              We have made this as easy as possible
            </p>
            <CustomButton
              title="Find a Location"
              buttonClasses={[
                "bg-green-button hover:bg-darkblue hover:text-white",
                "max-w-[264px]",
                "w-50",
                "rounded-2xl",
                "text-xl",
                "font-bold",
                "py-3"
              ]}
              callback={() => {
                window.location.href = "/find-location";
              }}
            />
          </div>
          <div className="findALocationRight googleMap w-min-[500] flex flex-column justify-center items-start">
            <div className="bg-white h-[500px] w-[500px] max-h-[80%] max-w-[80%] rounded">
              {/* Google map script here */}
            </div>
          </div>
        </div>
        {/* Find a Location Section Ends */}

        {/* For Business Section Starts */}

        <div className="forBusinessSection py-10 ">
          <GridSection
            title="For Business"
            tileItems={forBusinessSectionItems}
          />
        </div>

        {/* For Business Section Ends */}

        {/* Newsletter Widget Starts */}
        <div className="newsletterSubscribeSection py-10 bg-orange-300">
          <NewsletterWidget settings={subscribeSettings} />
        </div>
        {/* Newsletter Widget Ends */}

        {/* Global Footer Section Starts */}
        <GlobalFooter />
        {/* Global Footer Section Ends */}
      </div>
    );
  }
}

export default Home;
