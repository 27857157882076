import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import AdministratorForm from "./Forms/AdministratorForm";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { useNavigate } from "react-router-dom";
import BasicUserInfo from "../../components/BasicUserInfo";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { COLORS } from "../../utils/colors";

const AdministratorSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editAdministratorState, setEditAdministratorState] = useState(false);

  const [selectedAdministrator, setSelectedAdministrator] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    const fetchAdministratorDetails = async () => {
      try {
        const response = await api("v1/administrator/" + params.idAdministrator, "GET", null);
        if (response.data.success) {
          // console.log(response.data.data.partnerCompany);
          setSelectedAdministrator(response.data.data.administrator);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchAdministratorDetails();
  }, []);

  const handleDeleteAdministratorConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/administrator/" + selectedAdministrator._id, "DELETE", null);
      if (response.data.success) {
        window.location.replace("/partner/" + params.idPartnerCompany);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const toggleDisplayDeleteConfirm = () => setDeleteConfirmationIsOpen((prev) => !prev);

  const editAdministrator = () => {
    setEditAdministratorState((prevValue) => !prevValue);
  };

  const reloadAdministratorDetail = (updateCompanyDetail) => {
    setEditAdministratorState((prevValue) => !prevValue);
    setSelectedAdministrator(updateCompanyDetail);
  };

  console.log("editAdministratorState", editAdministratorState);
  return (
    selectedAdministrator && (
      <div className="page partner-administrator-single flex flex-column items-center w-full min-h-h-screen p-4 gap-8">
        {loading && <GlobalLoadingSpinner />}
        <ModalPopup
          showModal={editAdministratorState}
          handleShowModal={editAdministrator}
          popupTitle={"Edit Administrator"}
          popupContent={
            <AdministratorForm administrator={selectedAdministrator} callback={reloadAdministratorDetail} />
          }
          cancelButtonText=""
          confirmButtonText=""
          confirmCallbackAction=""
        />

        <div className="pageTitle flex flex-col text-center sm:w-full sm:flex-row sm:justify-between">
          <h1>
            Admin | {selectedAdministrator.firstName} {selectedAdministrator.lastName}
          </h1>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {selectedAdministrator && (
          <div className="w-full max-w-[500px] self-start">
            <BasicUserInfo user={selectedAdministrator} />
            <div className="actionItems flex gap-4">
              <Button
                size="large"
                sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                className="self-start-600 m-4"
                variant="contained"
                onClick={editAdministrator}
              >
                Edit Admin
              </Button>
              <Button
                size="large"
                className="self-start bg-red-600 m-4"
                variant="contained"
                color="error"
                onClick={() => {
                  toggleDisplayDeleteConfirm();
                }}
              >
                Delete Admin
              </Button>
            </div>
            <ModalPopup
              showModal={deleteConfirmationIsOpen}
              handleShowModal={toggleDisplayDeleteConfirm}
              popupTitle={"Delete Administrator"}
              popupContent={
                <div>
                  Are you sure you want to delete administrator:{" "}
                  <strong>
                    <em>
                      {selectedAdministrator.firstName} {selectedAdministrator.lastName}
                    </em>
                  </strong>
                  ?
                </div>
              }
              cancelButtonText={"Cancel"}
              confirmButtonText={"Delete"}
              confirmCallbackAction={handleDeleteAdministratorConfirm}
            />
          </div>
        )}
      </div>
    )
  );
};

export default AdministratorSingle;
