import { React, useState, useEffect } from "react";
import { api } from "../../utils/api";
import "./style.css";
import { TextField } from "@mui/material";
import AddForm from "../../components/Forms/AddForm";
// import PartnerCompanyList from "./PartnerCompanyList";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import ModalPopup from "../../components/ModalPopup";
import PartnerCompanyList from "./PartnerCompanyList";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import EmptyState from "../EmptyStates/EmptyState";

const PartnerCompany = () => {
  const [showAddPartnerCompanyForm, setShowAddPartnerCompanyForm] = useState(false); // New state variable to track the view
  const [partnerCompanies, setPartnerCompanies] = useState([]); // New state variable to track the view
  const [loading, setLoading] = useState(true); // New state variable to track the view
  const [currentUser, setcurrentUser] = useState(JSON.parse(sessionStorage.getItem("currentUser")) || null);
  const [selectedPortal, setselectedPortal] = useState(sessionStorage.getItem("selectedPortal") || null);
  const { filteredData, search, setSearch } = useBasicSearch(partnerCompanies);

  useEffect(() => {
    fetchPartnerCompanies();
  }, []);

  const fetchPartnerCompanies = async () => {
    try {
      let allCompanies = null;
      if (selectedPortal == "Administrator") {
        const response = await api("v1/partner/company", "GET");
        if (response.data.success) {
          allCompanies = response.data.data.allCompanies;
        }
      } else if (selectedPortal == "Partner" && currentUser) {
        allCompanies = await Promise.all(
          currentUser.partnercompanies.map(async (myCompany) => {
            const response = await api(`v1/partner/company/${myCompany.value}`, "GET");
            return response.data.data.partnerCompany;
          })
        );
      }

      if (allCompanies) {
        setPartnerCompanies(allCompanies);
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
    }
  };

  const updatePartnerCompanies = (newCompany) => {
    setPartnerCompanies([...partnerCompanies, newCompany]);
  };
  const showAddPartnerCompanyFormModal = () => {
    setShowAddPartnerCompanyForm(true);
  };
  const hideAddPartnerCompanyFormModal = () => {
    setShowAddPartnerCompanyForm(false);
  };

  const AddPartnerCompany = () => (
    <button
      className={`bg-darkblue text-white rounded-md py-2 px-4`}
      onClick={() => {
        setSearch("");
        showAddPartnerCompanyFormModal();
      }}
    >
      ADD NEW PARTNER
    </button>
  );

  return (
    <div className="page partnercompany w-full flex flex-column items-center p-8">
      {loading && <GlobalLoadingSpinner />}
      <div className="pageTitle flex flex-col w-full text-center lg:flex-row lg:text-left lg:justify-between">
        <h1>Partner Companies</h1>
        <div>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: "340px" }}
            placeholder="Search for a partner company"
          />
          <AddPartnerCompany />
        </div>
      </div>
      {loading ? (
        <GlobalLoadingSpinner />
      ) : (
        <div className="flex w-full">
          <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
            <div className="flex flex-column gap-4 items-center">
              {selectedPortal == "Administrator" && (
                <div className="pageActions">
                  <ModalPopup
                    showModal={showAddPartnerCompanyForm}
                    handleShowModal={hideAddPartnerCompanyFormModal}
                    popupTitle="Add New Partner Company"
                    popupContent={
                      <AddForm
                        setShowForm={setShowAddPartnerCompanyForm}
                        updateData={(data) => updatePartnerCompanies(data)}
                        type={"partner"}
                        setLoading={(data) => setLoading(data)}
                      />
                    }
                  />
                </div>
              )}
              {partnerCompanies.length === 0 ? (
                <EmptyState
                  type="partnerCompanies"
                  text="There are no Partner Companies"
                  action={<AddPartnerCompany />}
                />
              ) : filteredData.length === 0 ? (
                <EmptyState type="partnerCompanies" text="No Partner Companies match your search." />
              ) : (
                <div className="w-full">
                  <PartnerCompanyList partners={filteredData} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerCompany;
