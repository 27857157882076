import React, { useState, useEffect } from 'react';
import { api } from '../../utils/api';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Filters = ({ setFilters, config }) => {
    const currentPortal = sessionStorage.getItem("selectedPortal");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [partnerCompaniesOptions, setPartnerCompaniesOptions] = useState([]);
    const [selectedPartnerCompanies, setSelectedPartnerCompanies] = useState([]);
    const [filteredPartnerCompaniesOptions, setFilteredPartnerCompaniesOptions] = useState([]);


    const [partnerTypeOptions, setPartnerTypeOptions] = useState([{ 'label': 'Dealership', 'value': 'Dealership' }, { 'label': 'Workshop/Mechanic', 'value': 'Workshop/Mechanic' }]);
    const [selectedPartnerType, setSelectedPartnerType] = useState([]);
    const [allDealershipPartners, setAllDealershipPartners] = useState([]); // store the id of companies with partnerType as Dealership
    const [allWorkshopPartners, setAllWorkshopPartners] = useState([]); // store the id of companies with partnerType as Workshop/Mechanic

    const [merchantAccountOptions, setMerchantAccountOptions] = useState([]);
    const [selectedMerchantAccounts, setSelectedMerchantAccounts] = useState([]);

    const [merchantStoreOptions, setMerchantStoreOptions] = useState([]);
    const [selectedMerchantStores, setSelectedMerchantStores] = useState([]);

    const [productsOptions, setProductsOptions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [subscriptionStatus, setSubscriptionStatus] = useState(null); // State variable for subscription status
    const [vehicleStatus, setVehicleStatus] = useState(null); // State variable for vehicle status
    const [customerStatus, setCustomerStatus] = useState(null); // State variable for customer status

    useEffect(() => {
        const fetchPartnerCompanies = async () => {
            try {
                const response = await api('v1/partner/company', 'GET', {});
                const allCompanies = response.data.data.allCompanies.flatMap(company =>
                ({
                    label: company.name,
                    value: company._id
                })
                );
                setPartnerCompaniesOptions(allCompanies);

                const allDealershipPartnersIds = response.data.data.allCompanies
                    .filter(company => company.partnerType === 'Dealership')
                    .map(company => company._id);
                setAllDealershipPartners(allDealershipPartnersIds);

                const allWorkshopPartnersIds = response.data.data.allCompanies
                    .filter(company => company.partnerType === 'Workshop/Mechanic')
                    .map(company => company._id);
                setAllWorkshopPartners(allWorkshopPartnersIds);


            } catch (error) {
                console.error('Error fetching partner companies:', error);
            }
        };
        const fetchProducts = async () => {
            try {
                const response = await api('v1/product', 'GET', {});
                const products = response.data.data.allProducts.map(product => ({
                    label: product.name,
                    value: product._id
                }));
                // Use Set to filter out duplicates
                const uniqueProducts = Array.from(new Set(products.map(p => p.label)))
                    .map(label => products.find(p => p.label === label));
                setProductsOptions(uniqueProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        const fetchMerchantAccounts = async () => {
            try {
                const response = await api('v1/merchant', 'GET', {});
                const allMerchantAccounts = response.data.data.allMerchants.flatMap(merchantAccount =>
                ({
                    label: merchantAccount.name,
                    value: merchantAccount._id
                })
                );
                setMerchantAccountOptions(allMerchantAccounts);
            } catch (error) {
                console.error('Error fetching partner companies:', error);
            }
        };

        fetchPartnerCompanies();
        fetchProducts();
        fetchMerchantAccounts();
    }, []);

    useEffect(() => {
        const fetchMerchantStores = async () => {
            try {
                const response = await api('v1/merchantStore', 'GET', {});
                let allMerchantStores = response.data.data.allMerchantStores;

                if (selectedMerchantAccounts && selectedMerchantAccounts.length > 0) {
                    const selectedMerchantAccountValues = selectedMerchantAccounts.map(account => account.value);
                    allMerchantStores = allMerchantStores.filter(merchantStore =>
                        selectedMerchantAccountValues.includes(merchantStore.merchantAccount)
                    );
                }
                console.log('FILTERED STORES', allMerchantStores);

                const formattedMerchantStores = allMerchantStores.map(merchantStore => ({
                    label: merchantStore.name,
                    value: merchantStore._id
                }));

                setMerchantStoreOptions(formattedMerchantStores);
            } catch (error) {
                console.error('Error fetching partner companies:', error);
            }
        };
        fetchMerchantStores();
    }, [selectedMerchantAccounts])

    useEffect(() => {
        const filterPartnerCompanies = () => {
            if (selectedPartnerType.length === 0) {
                setFilteredPartnerCompaniesOptions(partnerCompaniesOptions);
            } else {
                const selectedTypes = selectedPartnerType.map(type => type.value);
                let filteredOptions = partnerCompaniesOptions.filter(company => {
                    if (selectedTypes.includes('Dealership') && allDealershipPartners.includes(company.value)) {
                        return true;
                    }
                    if (selectedTypes.includes('Workshop/Mechanic') && allWorkshopPartners.includes(company.value)) {
                        return true;
                    }
                    return false;
                });
                setFilteredPartnerCompaniesOptions(filteredOptions);
                setSelectedPartnerCompanies(prevSelected =>
                    prevSelected.filter(company => filteredOptions.some(option => option.value === company.value))
                );
            }
        };
        filterPartnerCompanies();
    }, [selectedPartnerType, partnerCompaniesOptions, allDealershipPartners, allWorkshopPartners]);


    const handlePresetRange = (range) => {
        const today = new Date();
        let newStartDate = null;
        let newEndDate = null;

        switch (range) {
            case 'today':
                newStartDate = new Date(today.setHours(0, 0, 0, 0));
                newEndDate = new Date(today.setHours(23, 59, 59, 999));
                break;
            case 'yesterday':
                newStartDate = new Date(today);
                newStartDate.setDate(today.getDate() - 1);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today);
                newEndDate.setDate(today.getDate() - 1);
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'this_week':
                newStartDate = new Date(today);
                newStartDate.setDate(today.getDate() - today.getDay());
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today.setHours(23, 59, 59, 999));
                break;
            case 'last_week':
                newStartDate = new Date(today);
                newStartDate.setDate(today.getDate() - today.getDay() - 7);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today);
                newEndDate.setDate(today.getDate() - today.getDay() - 1);
                newEndDate.setHours(23, 59, 59, 999);
                break;
            case 'last_7_days':
                newStartDate = new Date(today);
                newStartDate.setDate(today.getDate() - 6);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today.setHours(23, 59, 59, 999));
                break;
            case 'this_month':
                newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today.setHours(23, 59, 59, 999));
                break;
            case 'last_month':
                newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                newStartDate.setHours(0, 0, 0, 0);
                newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
                newEndDate.setHours(23, 59, 59, 999);
                break;
            default:
                break;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };


    const handleApplyFilters = () => {

        const filters = {};

        // Add subscription status filter if selected
        if (subscriptionStatus && subscriptionStatus !== null) {
            filters.subscriptionStatus = subscriptionStatus === 'active';
        }
        // Add vehicle status filter if selected
        if (vehicleStatus && vehicleStatus !== null) {
            filters.vehicleStatus = vehicleStatus === 'active';
        }
        // Add customer status filter if selected
        if (customerStatus && customerStatus !== null) {
            filters.CustomerStatus = customerStatus === 'active';
        }

        // Add partnerType filter if selected
        if (selectedPartnerType.length > 0) {
            // filters.partnerCompany in allDealershipPartners or allWorkshopPartners

        }

        // Prepare partnerCompany filters based on partnerType and selected partner companies
        let partnerCompanyIds = [];


        if (selectedPartnerType.length > 0) {
            const selectedTypes = selectedPartnerType.map(type => type.value);

            if (selectedTypes.includes('Dealership')) {
                partnerCompanyIds.push(...allDealershipPartners);
            }
            if (selectedTypes.includes('Workshop/Mechanic')) {
                partnerCompanyIds.push(...allWorkshopPartners);
            }
        }

        console.log("Partner IDS from Type: ", partnerCompanyIds);

        if (selectedPartnerCompanies.length > 0) {
            const selectedCompanyIds = selectedPartnerCompanies.map(company => company.value);
            partnerCompanyIds = selectedCompanyIds;

            // if (partnerCompanyIds.length > 0) {
            //     partnerCompanyIds.push(...selectedCompanyIds);
            // } else {
            //     partnerCompanyIds = selectedCompanyIds;
            // }
        }

        console.log("Partner IDS after selected companies: ", partnerCompanyIds);
        if (partnerCompanyIds.length > 0) {
            filters.partnerCompany = { $in: partnerCompanyIds };
        }

        // Add product filter if selected
        if (selectedProducts.length > 0) {
            filters.product = {
                $in: selectedProducts.map(product => product.value)
            };
        }

        // Add Merchant Account filter if selected
        // if (selectedMerchantAccounts.length > 0) {
        //     filters['merchantStore.merchantAccount'] = {
        //         $in: selectedMerchantAccounts.map(merchantAccount => merchantAccount.value)
        //     };
        // }

        // Add partnerCompany filter if selected
        if (selectedMerchantStores.length > 0) {
            filters.merchantStore = {
                $in: selectedMerchantStores.map(merchantStore => merchantStore.value)
            };
        }

        // Add createdOnUTC filter
        if (startDate && endDate) {
            filters.createdOnUTC = {
                $gte: startDate.toISOString(),
                $lte: endDate.toISOString()
            };
        } else if (startDate) {
            filters.createdOnUTC = {
                $gte: startDate.toISOString()
            };
        } else if (endDate) {
            filters.createdOnUTC = {
                $lte: endDate.toISOString()
            };
        }

        setFilters(filters);
    };

    useEffect(() => {
        handleApplyFilters();
    }, [startDate, endDate, selectedPartnerType, selectedPartnerCompanies, selectedProducts])

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: 'rgb(209 213 219)', // Tailwind's border-gray-300
            borderRadius: '0.375rem', // Tailwind's rounded-md
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', // Tailwind's shadow-sm
            padding: '0.5rem' // Tailwind's p-2
        }),
        menu: (provided) => ({
            ...provided,
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)' // Tailwind's shadow-lg
        }),
        option: (provided, state) => ({
            ...provided,
            padding: '0.5rem 1rem', // Tailwind's py-2 px-4
            backgroundColor: state.isFocused
                ? 'rgb(219 234 254)' // Tailwind's bg-blue-100
                : state.isSelected
                    ? 'rgb(59 130 246)' // Tailwind's bg-blue-500
                    : undefined,
            color: state.isSelected ? 'white' : undefined
        })
    };

    return (
        <div className="p-4">
            {config.dateRange &&

                <div className='flex flex-column gap-2'>

                    <div className='flex flex-column gap-2 w-full justify-center'>
                        <label>Date Range:</label>

                        <div className='flex justify-center justify-evenly'>
                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Start Date"
                                className="border border-gray-300 rounded-md shadow-sm p-2 mb-4"
                            />



                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="End Date"
                                className="border border-gray-300 rounded-md shadow-sm p-2 mb-4"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-center mb-4 gap-2">
                        <button
                            onClick={() => {
                                handlePresetRange('today');
                                // handleApplyFilters();
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm mr-2"
                        >
                            Today
                        </button>
                        <button
                            onClick={() => {
                                handlePresetRange('yesterday');
                                // handleApplyFilters();
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm mr-2"
                        >
                            Yesterday
                        </button>
                        <button
                            onClick={() => {
                                handlePresetRange('this_week');
                                // handleApplyFilters();
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm mr-2"
                        >
                            This Week
                        </button>
                        <button
                            onClick={() => {
                                handlePresetRange('last_week');
                                // handleApplyFilters();
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm mr-2"
                        >
                            Last Week
                        </button>
                        <button
                            onClick={() => {
                                handlePresetRange('last_7_days');
                                // handleApplyFilters();
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm mr-2"
                        >
                            Last 7 Days
                        </button>
                        <button
                            onClick={() => {
                                handlePresetRange('this_month');
                                // handleApplyFilters();
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm mr-2"
                        >
                            This Month
                        </button>
                        <button
                            onClick={() => {
                                handlePresetRange('last_month');
                                // handleApplyFilters();
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm mr-2"
                        >
                            Last Month
                        </button>
                    </div>
                </div>
            }
            <div className='flex flex-column gap-2 justify-center items-center flex-wrap'>
                <div className='flex w-full justify-center gap-10'>

                    {config.partnerCompany && currentPortal === 'Administrator' && (
                        <Select
                            value={selectedPartnerType}
                            onChange={setSelectedPartnerType}
                            options={partnerTypeOptions}
                            isMulti
                            placeholder="Select Partner Type"
                            styles={customStyles}
                            className="mb-4"
                        />
                    )}

                    {config.partnerCompany && currentPortal === 'Administrator' && (
                        <Select
                            value={selectedPartnerCompanies}
                            onChange={setSelectedPartnerCompanies}
                            options={filteredPartnerCompaniesOptions}
                            isMulti
                            placeholder="Select Partner Companies"
                            styles={customStyles}
                            className="mb-4"
                        />
                    )}

                    {config.merchantAccount && currentPortal === 'Administrator' && (
                        <Select
                            value={selectedMerchantAccounts}
                            onChange={setSelectedMerchantAccounts}
                            options={merchantAccountOptions}
                            isMulti
                            placeholder="Select Merchant Account"
                            styles={customStyles}
                            className="mb-4"
                        />
                    )}
                    {config.merchantStore && currentPortal === 'Administrator' && (
                        <Select
                            value={selectedMerchantStores}
                            onChange={setSelectedMerchantStores}
                            options={merchantStoreOptions}
                            isMulti
                            placeholder="Select Merchant Store"
                            styles={customStyles}
                            className="mb-4"
                        />
                    )}
                    {config.product && currentPortal === 'Administrator' && (
                        <Select
                            value={selectedProducts}
                            onChange={setSelectedProducts}
                            options={productsOptions}
                            isMulti
                            placeholder="Select Products"
                            styles={customStyles}
                            className="mb-4"
                        />
                    )}
                </div>
                <div className='flex justify-evenly flex-wrap'>
                    {/* Subscription Status filter */}
                    {config.subscriptionStatusFilter && (
                        <div className="flex items-center mb-4">
                            <label className="mr-2">Subscription Status:</label>
                            <select
                                value={subscriptionStatus || ''}
                                onChange={(e) => setSubscriptionStatus(e.target.value)}
                                className="border border-gray-300 rounded-md shadow-sm p-2"
                            >
                                <option value="">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                    )}

                    {/* Customer Status filter */}
                    {config.customerStatusFilter && (
                        <div className="flex items-center mb-4">
                            <label className="mr-2">Customer Status:</label>
                            <select
                                value={customerStatus || ''}
                                onChange={(e) => setCustomerStatus(e.target.value)}
                                className="border border-gray-300 rounded-md shadow-sm p-2"
                            >
                                <option value="">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                    )}

                    {/* Vehicle Status filter */}

                    {config.vehicleStatusFilter && (
                        <div className="flex items-center mb-4">
                            <label className="mr-2">Vehicle Status:</label>
                            <select
                                value={vehicleStatus || ''}
                                onChange={(e) => setVehicleStatus(e.target.value)}
                                className="border border-gray-300 rounded-md shadow-sm p-2"
                            >
                                <option value="">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                    )}

                </div>

            </div>




            <button
                onClick={handleApplyFilters}
                className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm"
            >
                Apply Filters
            </button>
        </div>
    );
};

export default Filters;
