/*
Customer Form Component, Used to render Add Customer and Edit Customer form
*/
import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Button, FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";
import countriesData from "../../../constants/countries.json";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phone: Yup.string()
    .matches(
      /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
      "Invalid Australian phone number"
    )
    .required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  address: Yup.object().shape({
    street: Yup.string().required("Street is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postcode: Yup.number().required("Postcode is required"),
    country: Yup.object().required("Country is required"),
  }),
  vehicles: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required,
        value: Yup.string().required()
      })
    )
    .required("Vehicle is required"),
  active: Yup.string().required("Status is Required"),
});

const CustomerForm = ({
  customer, // if this is set we assume Edit User else Add User 
  callback
}) => {
  const currentPortal = sessionStorage.getItem("selectedPortal");
  // const [modalActive, setModalActive] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(customer ? { label: customer.address?.country ?? '', value: customer.address?.country ?? '' } : { label: 'Australia', value: 'Australia' });
  // Add state for selected country
  const handleCountryChange = (selectedOption) => {
    // alert(selectedOption.label);
    setSelectedCountry(selectedOption); // Set the selected country value to country state
    console.log("Set Country", selectedCountry);
  };


  const [vehiclesOptions, setVehicleOptions] = useState();
  const [vehicles, setVehicles] = useState();


  const getAllVehicles = async () => {
    try {
      const response = await api("v1/vehicle", "GET", null);
      if (response.data.success) {
        const allVehicles = response.data.data.allVehicles;
        const result = allVehicles.map((vehicle) => ({
          value: vehicle._id,
          label: `<div className="flex flex-row gap-2 justify-center items-center">
                                <div>Rego: ${vehicle.rego} </div>
                                <div>VIN: ${vehicle.vin}</div>                               
                                <div>Make: ${vehicle.make}</div>                               
                                <div>Model: ${vehicle.model}</div>                               
                                <div>Model Year: ${vehicle.modelYear}</div>                               
                                <div>Color: ${vehicle.color}</div>                               
                            </div>`
        }));

        if (result) {
          setVehicleOptions(result);
          if (customer && customer.vehicles) {
            const customerVehicles = customer.vehicles.map((customerVehicleItem) => {
              console.log("ID", customerVehicleItem._id);
              // Find the respective vehicle object from allVehicles based on vehicleId
              return result.find((vehicle) => vehicle.value === customerVehicleItem._id);
            });
            console.log("SELECTED: ", customerVehicles);
            setVehicles(customerVehicles);
          }
        }
      }
    } catch (error) {
      console.error("Error loading partner companies:", error);
    }
  };

  // const setCustomerCountry = (customer) => {
  //   if (customer) {
  //     setSelectedCountry('Nepal');
  //   }
  // };

  useEffect(() => {
    getAllVehicles();
    // setCustomerCountry(customer)
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    const dataToSend = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      address: {
        ...values.address,
        country: selectedCountry.label
      },
      vehicles: vehicles,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      active: values.active
    };
    console.log("Data to Send", dataToSend);
    try {
      // Make the API call with a PATCH request
      // const response = await api(`v1/customer/${customer._id}`, 'PATCH', dataToSend);
      let response;
      if (customer) {
        // Edit Vehicle Option
        response = await api(`v1/customer/${customer._id}`, "PATCH", dataToSend);
      } else {
        // Add Vehicle Option
        response = await api(`v1/customer`, "POST", dataToSend);
      }


      if (response.data.success) {
        console.log("Customer Updated", response.data.data.customer);
        if (customer) {
          callback(response.data.data.customer);
        } else {
          callback(response.data.data.newCustomer);
        }
        // reloadUserDetail(response.data.data.customer);
      } else {
        response?.data?.message && alert(response.data.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }

    setSubmitting(false);
  };



  const loadCountryOptions = async (inputValue) => {
    const filteredCountries = countriesData.filter((country) =>
      country.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    // Return the filtered countries as options
    return filteredCountries;
  };

  const loadVehicleOptions = async (inputValue, callback) => {
    try {
      if (vehiclesOptions) {
        const filteredOptions = vehiclesOptions.filter((option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(filteredOptions);
      }
    } catch (error) {
      console.error("Error loading vehicles options:", error);
      callback([]);
    }
  };

  const handleVehicleChange = (selectedOptions) => {
    console.log(selectedOptions);
    setVehicles(selectedOptions); // Extract and store the values of selected options
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#9e9e9e",
      zIndex: 2,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3, // Higher zIndex for the dropdown menu
    }),
  };

  return (
    <Formik
      initialValues={{
        firstName: customer ? customer.firstName : '',
        lastName: customer ? customer.lastName : '',
        phone: customer ? customer.phone : '',
        email: customer ? customer.email : '',
        address: {
          street: customer ? customer.address?.street : '',
          city: customer ? customer.address?.city : '',
          state: customer ? customer.address?.state : '',
          postcode: customer ? customer.address?.postcode : '',
          country: selectedCountry,
        },
        vehicles: [],
        active: customer ? customer.active : '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
        <div className="form-input-wrap flex gap-2 w-full">
          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="vehicles">
                Vehicle:
              </label>
              <Field name="vehicles" className="z-2">
                {({ field }) => (
                  <AsyncSelect
                    name="vehicles"
                    isMulti
                    {...field}
                    cacheOptions
                    defaultOptions={vehiclesOptions}
                    loadOptions={loadVehicleOptions}
                    styles={selectStyles}
                    onChange={handleVehicleChange}
                    value={vehicles}
                    isDisabled={currentPortal == "Customer"}
                    formatOptionLabel={function (data) {
                      return (
                        <span className="capitalize" dangerouslySetInnerHTML={{ __html: data.label }} />
                      );
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="vehicles"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="firstName">
                First Name:
              </label>
              <Field name="firstName">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="firstName"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="lastName">
                Last Name:
              </label>
              <Field name="lastName">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="lastName"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="email">
                Email Address:
              </label>
              <Field name="email">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="email"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="phone">
                Phone:
              </label>
              <Field name="phone">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="phone"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.street">
                Street:
              </label>
              <Field name="address.street">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Street"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.street"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="address.city">
                City:
              </label>
              <Field name="address.city">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="City"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.city"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.state">
                State:
              </label>
              <Field name="address.state">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="State"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.state"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="address.postcode">
                Postcode:
              </label>
              <Field name="address.postcode">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Postcode"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.postcode"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.country">
                Country:
              </label>

              <Field name="address.country">
                {({ field }) => (
                  <AsyncSelect
                    name="address.country"
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCountryOptions}
                    styles={selectStyles}
                    onChange={handleCountryChange}
                    value={selectedCountry}
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.country"
                component="span"
                className="error-message"
              />
            </div>
          </div>
          <div className="form-group w-full">
            <label className="label" htmlFor="active">
              Active:
            </label>
            <Field name="active" type="checkbox">
              {({ field }) => (
                <FormControlLabel
                  disabled={currentPortal == "Customer"}
                  control={<Switch {...field} checked={field.value} />}
                  label={field.value ? "Active" : "Inactive"}
                />
              )}
            </Field>
            <ErrorMessage
              name="active"
              component="span"
              className="error-message"
            />
          </div>
        </div>
        <div className="form-actions-wrap flex gap-20 justify-between">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="submit-button"
          >
            Submit
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="error"
            className="submit-button"
            onClick={() => callback()}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default CustomerForm;
