import { QrCodeRounded } from "@mui/icons-material";
import { Button as MuiButton, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/api"; // Import your API module here
import MySubscriptionDetails from "./MySubscriptionDetails";
import ModalPopup from "../../components/ModalPopup";
import styled from "@emotion/styled";
import StarIcon from "@mui/icons-material/Star";

const Button = styled(MuiButton)`
    border-radius: 28px;
    padding: 8px 12px;
`;

const CustomerSubscriptionCard = ({ subscription }) => {
    function formatDate(dateString) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }

    const navigate = useNavigate();
    const [redemptionCount, setRedemptionCount] = useState(0); // Corrected from square brackets to parentheses
    const [showSubscriptionDetails, setShowSubscriptionDetails] = useState(false);

    useEffect(() => {
        const fetchRedemptionCount = async () => {
            try {
                const redemptionCount = await api(`v1/subscription/${subscription._id}/redemptions`, "GET"); // Assuming you need to make a GET request
                if (redemptionCount && redemptionCount.data.success) {
                    setRedemptionCount(redemptionCount.data.data.redemptions.length);

                    // If once off product, and has at least 1 redemption, set to inactive as already redeemed
                    if (subscription.product.type == 'onceoff' && redemptionCount.data.data.redemptions.length > 0) {
                        subscription.active = false;
                    }
                }
            } catch (error) {
                console.error("API error:", error);
            }
        };

        fetchRedemptionCount(); // Call the async function
    }, []); // Add 'subscription' as a dependency to avoid infinite re-renders

    return (
        <div className="flex flex-column gap-4 justify-between shadow-2xl p-4 border-slate-200 border-1 rounded-3xl w-[350px]">
            <div className='flex flex-col gap-3'>
                <div className="flex gap-4 items-center justify-center">
                    <QrCodeRounded fontSize="large" className="bg-[#0F083E] text-white rounded-full p-1" />
                    <h5 style={{ fontWeight: 700, margin: 0 }}>{subscription.product.name}</h5>
                </div>
                <div className="flex gap-2 items-center">
                    <Chip
                        className="rounded"
                        sx={{ fontSize: '16px' }}
                        label={subscription.active ? "Active" : "Inactive"}
                        color={subscription.active ? "success" : "default"}
                    />
                    Expires: {formatDate(subscription.validUntilDateUTC)}
                </div>
                {subscription.product.type === "package" && (
                    <div className="flex items-center text-lg">
                        <StarIcon fontSize="large" style={{ color: "#faaf00", verticalAlign: "middle" }} />&nbsp;{redemptionCount} of &nbsp;
                        {subscription.product.packageCount} redemptions used
                    </div>
                )}
                {/* {subscription.product.type === 'package' && <Rating className="flex flex-wrap" name="read-only" value={redemptionCount} max={subscription.product.packageCount} readOnly />} */}

                <span>{subscription.product.description}</span>
            </div>
            <div className="subsciption-card-actions w-full flex justify-end gap-2">
                <Button
                    className="rounded"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setShowSubscriptionDetails(true);
                    }}
                >
                    View
                </Button>
                <ModalPopup
                    showModal={showSubscriptionDetails}
                    handleShowModal={() => setShowSubscriptionDetails(false)}
                    popupTitle=""
                    popupContent={
                        <MySubscriptionDetails
                            subscription={subscription}
                            callback={() => {
                                setShowSubscriptionDetails(false);
                            }}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default CustomerSubscriptionCard;
