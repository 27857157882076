import React from "react";
import { Button, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { COLORS } from "../../utils/colors";

const ProductList = ({ products }) => {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, borderCollapse: "separate", "td, th": { borderBottom: "none" } }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>Type </TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Price</TableCell>
            {/* <TableCell>Redemption Price</TableCell> */}
            <TableCell>RRP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow
              className={!product.active ? "border-2 border-[#ff0000] opacity-80" : ""}
              onClick={() => navigate(`/product/${product._id}`)}
              key={product._id}
              sx={{
                boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                "&:hover": { cursor: "pointer" },
                // "backgroundColor": !product.active ? "red" : "",
              }}
            >
              <TableCell component="th" scope="row">
                {product.name} {!product.active && <Chip variant="outlined" color="error" label="Inactive" />}
              </TableCell>
              <TableCell>
                {product.type[0].toUpperCase()}
                {product.type.slice(1)}
              </TableCell>
              <TableCell>
                {product.type == 'subscription' && (<>
                  {product.subscriptionDuration}{" "}
                  {parseInt(product.subscriptionDuration) === 1 ? "Month" : "Months"}
                </>
                )}

              </TableCell>
              <TableCell>{product.price}</TableCell>
              {/* <TableCell>{product.redemptionPrice}</TableCell> */}
              <TableCell>{product.rrp}</TableCell>
              <TableCell>
                <Button sx={{ backgroundColor: COLORS.priorityBlue.hex }} variant="contained">
                  View Product
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
};

export default ProductList;
