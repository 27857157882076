import { api } from "../../utils/api";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const RedemptionHistory = ({ vehicles }) => {
    const navigate = useNavigate();
    const [redemptionHistory, setRedemptionHistory] = useState([]);

    // Function to normalize the vehicles input
    const normalizeVehicles = (vehicles) => {
        if (!vehicles) return [];
        if (Array.isArray(vehicles)) {
            return vehicles.map(vehicle => typeof vehicle === 'object' ? vehicle._id : vehicle);
        } else {
            return [typeof vehicles === 'object' ? vehicles._id : vehicles];
        }
    };

    useEffect(() => {
        const fetchRedemptionHistory = async () => {
            const vehicleIds = normalizeVehicles(vehicles);
            const response = await api(`v1/redemption/search`, 'POST', { query: { vehicle: { $in: vehicleIds } } });
            setRedemptionHistory(response.data.data.searchResult);
        };

        fetchRedemptionHistory();
    }, [vehicles]);

    const handleClick = (vehicle) => () => navigate(`/vehicle/${typeof vehicle === 'object' ? vehicle._id : vehicle}`);
    return (
        <div className="flex flex-column">
            <h3>Redemption History</h3>
            {redemptionHistory && redemptionHistory.length > 0 ? (
                <table className="table-auto w-full border-collapse border border-gray-400">
                    <thead>
                        <tr>
                            <th className="border border-gray-300 p-2">Redemption Date</th>
                            <th className="border border-gray-300 p-2">Product</th>
                            <th className="border border-gray-300 p-2">Vehicle</th>
                            <th className="border border-gray-300 p-2">Merchant Store</th>
                            <th className="border border-gray-300 p-2">Redeemed By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {redemptionHistory.map((redemption) => (
                            <tr key={redemption._id} onClick={handleClick(redemption.vehicle)} className="cursor-pointer">
                                <td className="border border-gray-300 p-2">{new Date(redemption.redemptionDateUTC).toLocaleDateString()}</td>
                                <td className="border border-gray-300 p-2">{redemption.product.name}</td>
                                <td className="border border-gray-300 p-2">{redemption.vehicle.rego}</td>
                                <td className="border border-gray-300 p-2">{redemption.merchantStore.name}</td>
                                <td className="border border-gray-300 p-2">{`${redemption.merchantUser.firstName} ${redemption.merchantUser.lastName}`}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div>No redemptions found</div>
            )}
        </div>
    );
};

export default RedemptionHistory;
