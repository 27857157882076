import React from 'react';
import { api } from "../utils/api";

const resendInvoice = async (idTransaction) => {
    // setLoading(true);
    const dataToSend = {
        idTransaction: idTransaction,
    };

    try {
        const response = await api("v1/transaction/sendTransactionInvoice", "POST", dataToSend);
        if (response.data.success) {
            alert("Invoice Email Sent")
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        console.error("API error:", error);
    }
    // setLoading(false); //once api call is finished.
};

function InvoiceDetails({ invoiceDetail, idTransaction = null }) {
    return (invoiceDetail &&
        <div className='flex flex-column w-full gap-2'>
            <div className='flex flex-column border-2 p-2 rounded-md'>
                <strong>Invoice Details</strong>
                <p>Invoice Number: {invoiceDetail.InvoiceNumber}</p>
                <p>Status: {invoiceDetail.Status}</p>
                <p>Date: {new Date(invoiceDetail.Date).toLocaleDateString()}</p>
                <p>Due Date: {new Date(invoiceDetail.DueDate).toLocaleDateString()}</p>
                <p>Amount Due: ${invoiceDetail.AmountDue}</p>
                <p>Total: ${invoiceDetail.Total}</p>
            </div>
            <div className='flex flex-column border-2 p-2 rounded-md'>
                <strong>Contact Information</strong>
                <span>{invoiceDetail.Contact.Name}</span>
                <span>{invoiceDetail.Contact.EmailAddress}</span>
                <span>{invoiceDetail.Contact.Addresses[0]?.City || 'City not set'}, {invoiceDetail.Contact.Addresses[0]?.Region || 'Region not set'}, {invoiceDetail.Contact.Addresses[0]?.Country || 'Country not set'}</span>
            </div>
            <div className='flex flex-column border-2 p-2 rounded-md'>
                <strong>Line Items</strong>
                {invoiceDetail.LineItems.map((item, index) => (
                    <div key={index}>
                        <p>Description: {item.Description}</p>
                        <p>Unit Amount: ${item.UnitAmount}</p>
                        <p>Quantity: {item.Quantity}</p>
                        <p>Line Amount: ${item.LineAmount}</p>
                    </div>
                ))}
            </div>
            {/* {idTransaction && <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded" onClick={() => resendInvoice(idTransaction)}>Resend Invoice</button>} */}
        </div>
    );
}

export default InvoiceDetails;
