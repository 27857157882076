import React from "react";
import { Button } from "@mui/material";
import InfoTable from "../../../../components/InfoTable";
import { COLORS } from "../../../../utils/colors";

const MerchantInfo = ({ merchant, setModalTitle, setModalContent, handleDisplayModal }) => {
  return (
    <div className="mt-4 flex flex-column gap-2">
      <h3 className="text-lg font-bold">Billing Details</h3>

      <InfoTable data={merchant} />
      {sessionStorage.getItem("selectedPortal") == "Administrator" && (
        <div className="flex gap-1">
          <Button
            sx={{ backgroundColor: COLORS.priorityBlue.hex }}
            variant="contained"
            onClick={() => {
              setModalTitle("Edit Merchant Details");
              setModalContent("editMerchant");
              handleDisplayModal(true);
            }}
          >
            Edit Merchant Details
          </Button>
          <Button
            className="self-start bg-red-600"
            variant="contained"
            color="error"
            onClick={() => {
              setModalTitle("Delete Merchant Account?");
              setModalContent("deleteMerchant");
              handleDisplayModal(true);
            }}
          >
            Delete Merchant
          </Button>
        </div>
      )}
    </div>
  );
};

export default MerchantInfo;
