import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Logout = () => {
  const { isAuthenticated, logout, user } = useAuth0();

  const handleNavigation = () => {
    window.location = "/";
  };

  useEffect(() => {
    if (isAuthenticated) {
      console.log("is Authenticated");
      handleNavigation();
    }
  }, []);

  return <></>;
};

export default Logout;
