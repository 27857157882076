/*
Component to show the dropdown option to allow merchant users to choose a store for the session, will select the default if current user only has 1 store.
*/
import { React, useEffect, useState } from "react";
import { api } from "../utils/api";
function SelectMerchantStoreDD({ setCurrentMerchantStore }) {

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser') ?? null)
    const [loading, setLoading] = useState([]);
    const [currentPortal, setCurrentPortal] = useState([]);
    const [availableMerchantStores, setAvailableMerchantStore] = useState([]);
    const fetchAvailableMerchantStores = async () => {

        let myStores;
        if (sessionStorage.getItem('selectedPortal') == 'Merchant' && currentUser.merchantAccount) {
            try {
                let endpoint = `v1/merchantUser/${currentUser._id}/mystores`;
                // let endpoint = `v1/merchant/${currentUser.merchantAccount}/store`;


                const response = await api(
                    endpoint,
                    "GET",
                    null
                );
                if (response.data.success) {
                    myStores = response.data.data.merchantStores;
                    // setCurrentMerchantStore(response.data.data.merchantStores[0]._id);
                    if (myStores && !sessionStorage.getItem('selectedMerchantStore')) {
                        setCurrentMerchantStore(myStores[0]._id);
                        console.log("SET HERE", myStores[0]._id);
                        sessionStorage.setItem('selectedMerchantStore', myStores[0]._id);
                        setCurrentPortal(myStores[0]._id);
                    }
                    setLoading(false);
                }
            } catch (error) {
                console.error("API error:", error);
            }
        }
        setAvailableMerchantStore(myStores);
    };

    useEffect(() => {
        fetchAvailableMerchantStores();
    }, [sessionStorage.getItem('currentUser')])

    const handleMerchantStoreChange = (event) => {
        const selectedMerchantStore = event.target.value;
        setCurrentMerchantStore(selectedMerchantStore);
        sessionStorage.setItem('selectedMerchantStore', selectedMerchantStore);
        setCurrentPortal(selectedMerchantStore);
    };


    // Check if the sessionObject is already set when the component mounts
    useEffect(() => {

        if (sessionStorage.getItem('selectedMerchantStore') && (availableMerchantStores && availableMerchantStores.find(store => store._id === sessionStorage.getItem('selectedMerchantStore')))) {
            console.log("MerchantStore is selected: ", sessionStorage.getItem('selectedMerchantStore'));
            setCurrentMerchantStore(sessionStorage.getItem('selectedMerchantStore'));
        } else {
            console.log("Merchant Store not selected Yet List of all", availableMerchantStores);
        }

    }, [sessionStorage.getItem('selectedMerchantStore'), availableMerchantStores]);

    const availableMerchantStoresCount = availableMerchantStores?.length || 0;

    if (availableMerchantStoresCount === 1) {
        console.log("Merchant has only 1 store, selecting the default one.")
        return (
            <div className="partner_select_MerchantStore_dd flex flex-column  mb-4 items-center h-[60px] justify-between w-full p-2 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                <div className="dropdown bg-blue-800 text-white  block max-w-[250px] px-2 py-1 rounded-lg text-gray-900 border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleMerchantStoreChange} value={sessionStorage.getItem('selectedPortal')}>
                    <div className="">{availableMerchantStores[0].name}</div>
                </div>
            </div>
        );

    } else {
        console.log("User associated with multiple MerchantStores or stores not found: ", availableMerchantStores);
        return (
            <div className="partner_select_MerchantStore_dd flex flex-column  mb-4 items-center h-[60px] justify-between w-full p-2 border-b border-gray-200 bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                <label>Merchat Store</label>
                <select className="dropdown bg-blue-800 text-white  block max-w-[250px] px-2 py-1 rounded-lg text-gray-900 border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={handleMerchantStoreChange} value={sessionStorage.getItem('selectedMerchantStore') ?? (availableMerchantStores ? availableMerchantStores[0]?._id : '')}>
                    <option disabled className="bg-blue-200 text-black">Switch Portal</option>
                    {availableMerchantStores && availableMerchantStores.map((associatedMerchantStore) => (
                        <option className="bg-blue-200 text-black" key={associatedMerchantStore._id} value={associatedMerchantStore._id}>
                            {associatedMerchantStore.name}
                        </option>
                    ))}
                </select>
            </div>
        );
    }


}
export default SelectMerchantStoreDD;