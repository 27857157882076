import React, { useEffect, useState } from 'react';
import Filters from './Filters';
import TransactionSalesCharts from './TransactionSalesCharts';
import MerchantSalesCharts from './MerchantRedemptionsCharts';
import MerchantRedemptionsCharts from './MerchantRedemptionsCharts';

const SalesDashboard = ({ dataSource }) => {
    const currentPortal = sessionStorage.getItem("selectedPortal");

    const [appliedFilters, setFilters] = useState({});

    const filterConfig = {
        transactions: {
            dateRange: true,
            partnerCompany: true,
            product: true,
            customerStatusFilter: true,
            subscriptionStatusFilter: true,
            vehicleStatusFilter: true,
            partnerType: true
        },
        redemptions: {
            dateRange: true,
            // merchantAccount: true,
            merchantStore: true,
            product: true,
            customerStatusFilter: true,
            subscriptionStatusFilter: true,
            vehicleStatusFilter: true
        }
    };

    return (
        <div>
            <Filters config={filterConfig[dataSource]} setFilters={setFilters} />
            {
                dataSource === 'transactions' && (currentPortal === 'Administrator' || currentPortal === 'Partner') &&
                <TransactionSalesCharts dataSource={dataSource} appliedFilter={appliedFilters} />
            }
            {
                dataSource === 'redemptions' && (currentPortal === 'Administrator' || currentPortal === 'Merchant') &&
                <MerchantRedemptionsCharts dataSource={dataSource} appliedFilter={appliedFilters} />
            }
        </div>
    );
};

export default SalesDashboard;
