import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import SalesDashboard from "../SalesDashboard";
import { api } from '../../utils/api';
import GlobalLoadingSpinner from '../../components/GlobalLoadingSpinner';
import MerchantStoreMap from "../Locations/MerchantStoreMap";

const Dashboard = () => {
    const currentPortal = sessionStorage.getItem("selectedPortal");

    const [loading, setLoading] = useState(false);

    const [productType, setProductType] = useState('');
    const [activeSubscriptionCount, setActiveSubscriptionCount] = useState(0);

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    useEffect(() => {
        const fetchActiveSubscriptions = async () => {
            try {
                setLoading(true);
                const payload = productType ? { productType } : {};

                const response = await api("v1/subscription/getAllActiveSubscriptions", "POST", payload);
                if (response.data.success) {
                    setActiveSubscriptionCount(response.data.data?.activeSubscriptionsCount ?? 'N/A');
                }
            } catch (error) {
                console.error("API error:", error);
            } finally {
                setLoading(false);
            }
        };

        if (currentPortal === 'Administrator') {
            fetchActiveSubscriptions();
        }
    }, [productType, currentPortal])


    useEffect(() => {
        setTabValue(0);
    }, [currentPortal])



    const handleProductTypeChange = (event) => {
        const selectedProductType = event.target.value;
        setProductType(selectedProductType);
    };



    if (currentPortal === "Customer") {
        return (
            <div className="w-full" >
                <div className="flex justify-center mx-auto text-center flex-column w-full">
                    <MerchantStoreMap />
                </div>
            </div>
        );
    }



    return (

        <div className="w-full" >
            {loading && <GlobalLoadingSpinner />}
            <div className="flex justify-center mx-auto text-center flex-column w-full">
                {sessionStorage.getItem("selectedPortal") === "Administrator" && (
                    <Box sx={{ marginTop: "22px", marginBottom: "12px" }}>
                        <h2>Dashboard</h2>
                        <div className="flex flex-column gap-2 justify-center items-center m-4 p-4">

                            <select className="dropdown block max-w-[250px] p-2 rounded-lg text-gray-900 border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="productType" onChange={(event) => handleProductTypeChange(event)}>
                                <option value="">All</option>
                                <option value="subscription">Subscription</option>
                                <option value="onceoff">Once Off</option>
                                <option value="package">Package</option>
                            </select>
                            <strong>Total Active Subscriptions Count: {activeSubscriptionCount}</strong>
                        </div>
                    </Box>
                )}

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="dashboard tabs">
                            {(currentPortal === 'Administrator' || currentPortal === 'Partner') && <Tab label="Transactions" />}
                            {(currentPortal === 'Administrator' || currentPortal === 'Merchant') && <Tab label="Redemptions" />}
                        </Tabs>
                    </Box>
                    {(currentPortal === 'Administrator' || currentPortal === 'Partner') &&
                        <TabPanel value={tabValue} index={0}>
                            <h1>Transactions</h1>
                            <SalesDashboard dataSource="transactions" />
                        </TabPanel>
                    }
                    {(currentPortal === 'Administrator' || currentPortal === 'Merchant') &&
                        <TabPanel value={tabValue} index={currentPortal === 'Merchant' ? 0 : 1}>
                            <h1>Redemptions</h1>
                            <SalesDashboard dataSource="redemptions" />
                        </TabPanel>
                    }
                </Box>
            </div>
        </div>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`dashboard-tabpanel-${index}`}
            aria-labelledby={`dashboard-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Typography>
    );
};

export default Dashboard;
