import React from "react";

const IframeModal = ({ isOpen, url, onClose }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="iframeModal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-darkblue w-11/12 md:w-3/4 h-3/4 relative">
                <button
                    className="absolute top-0 right-0 m-2 p-2 bg-red-500 text-white rounded w-[48px]"
                    onClick={onClose}
                >
                    X
                </button>
                <iframe src={url} className="mt-14 w-full h-full" title="Modal Content" />
            </div>
        </div>
    );
};

export default IframeModal;
