import { useEffect, useState } from "react";

/**
 *
 * Custom hook that takes in a generic data as an argument.
 * It can be an array of customers, admins, partners, etc, where each has
 * its own unique key value pairs such as firstName, email, etc.
 *
 * The hook filters the data matching the search value to any
 * one of the values in the object.
 *
 * Returns the filteredData, and the search
 * useState handlers to pass to the TextField component.
 *
 */

/** Allows us to search recursively into an object if any of its values itself is an object */
const searchObject = (d, search) =>
  d &&
  Object.values(d).some((e) =>
    typeof e === "object"
      ? searchObject(e, search)
      : e.toString().replaceAll(" ", "").toLowerCase().includes(search.replaceAll(" ", "").toLowerCase())
  );

export const useBasicSearch = (data) => {
  const [filteredData, setFilteredData] = useState(data);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    if (search) {
      const newData = data.filter((d) => searchObject(d, search));
      setFilteredData(newData);
    } else {
      setFilteredData(data);
    }
  }, [search]);

  return { filteredData, search, setSearch };
};
