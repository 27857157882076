import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Table header component
const TableHeader = ({ headers, requestSort, sortConfig }) => (
    <tr>
        {headers.map(header => (
            <th
                key={header.key}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:underline"
                onClick={() => requestSort(header.key)}
            >
                {header.label}
                {sortConfig.key === header.key && (
                    <span>{sortConfig.direction === 'asc' ? ' ▲' : ' ▼'}</span>
                )}
            </th>
        ))}
    </tr>
);

// Table row component
const TableRow = ({ store, counter }) => {
    const navigate = useNavigate();

    return (
        <tr className={store.active ? '' : 'bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'} key={store._id} onClick={() => {
            navigate(`/merchant/${store.merchantAccount}/store/${store._id}`);
        }}>
            <td>{counter} </td>
            <td className="px-6 py-4 __whitespace-nowrap">{store.name}</td>
            <td className="px-6 py-4 __whitespace-nowrap">{store.storeManager}</td>
            <td className="px-6 py-4 __whitespace-nowrap">{`${store.address.street}, ${store.address.city}, ${store.address.postcode}, ${store.address.state}, ${store.address.country}`}</td>
            <td className="px-6 py-4 __whitespace-nowrap">{store.active ? 'Active' : 'Not Active'}</td>
        </tr>
    );
};

// Card view component
const CardView = ({ data }) => {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            {data.map((store, index) => (
                <div key={store._id} className="border border-gray-200 rounded-lg p-4 shadow-md" onClick={() => {
                    navigate(`/merchant/${store.merchantAccount}/store/${store._id}`);
                }}>
                    <span>#{index + 1}</span>
                    <div className="font-bold mb-2">Store Name</div>
                    <div>{store.name}</div>
                    <div className="font-bold mt-2">Manager</div>
                    <div>{store.storeManager}</div>
                    <div className="font-bold mt-2">Address</div>
                    <div>{`${store.address.street}, ${store.address.city}, ${store.address.postcode}, ${store.address.state}, ${store.address.country}`}</div>
                    <div className="font-bold mt-2">Status</div>
                    <div>Active</div>
                </div>
            ))}
        </div>
    );
};

const StoreList = ({ data }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const headers = [
        { key: 'count', label: '#' },
        { key: 'name', label: 'Store Name' },
        { key: 'storeManager', label: 'Manager' },
        { key: 'address', label: 'Address' },
        { key: 'status', label: 'Status' }
    ];

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = [...data].sort((a, b) => {
        if (sortConfig.key !== null) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        }
        return 0;
    });

    return (
        <div className="overflow-x-auto">
            {window.innerWidth <= 640 ? (
                <CardView data={sortedData} />
            ) : (
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <TableHeader headers={headers} requestSort={requestSort} sortConfig={sortConfig} />
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {sortedData.map((store, index) => <TableRow counter={index + 1} store={store} />)}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default StoreList;
