import React from "react";
import { Button } from "@mui/material";
import MerchantStoreList from "../../../../components/MerchantStoreList";
import { COLORS } from "../../../../utils/colors";
import EmptyState from "../../../EmptyStates/EmptyState";

const MerchantStores = ({ merchant, merchantStores, setModalTitle, setModalContent, handleDisplayModal }) => {
  const AddMerchantStore = () => (
    <Button
      sx={{ backgroundColor: COLORS.priorityBlue.hex }}
      variant="contained"
      onClick={() => {
        setModalTitle("Add Merchant Store");
        setModalContent("addMerchantStore");
        handleDisplayModal(true);
      }}
    >
      Add New Store
    </Button>
  );

  return merchantStores.length == 0 ? (
    <div className="w-full flex justify-center py-8">
      <EmptyState type="merchantStores" text="Adding a merchant store is easy." action={<AddMerchantStore />} />
    </div>
  ) : (
    <div className="mt-4 flex flex-column gap-4">
      <div className="flex flex-row w-full">
        <AddMerchantStore />
      </div>
      <MerchantStoreList storeList={merchantStores} merchant={merchant} />
    </div>
  );
};

export default MerchantStores;
