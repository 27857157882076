import React, { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, PluginServiceGlobalRegistrationAndOptions, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, PieController, ArcElement } from 'chart.js';
import { api } from '../../utils/api';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { redirect } from 'react-router-dom';


// Register the required components from Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PieController,
    ArcElement,
    ChartDataLabels
);

const TransactionSalesCharts = ({ dataSource, appliedFilter }) => {
    const [loading, setLoading] = useState(true);
    const currentPortal = sessionStorage.getItem("selectedPortal");
    const selectedCompany = sessionStorage.getItem("selectedCompany");

    const [salesData, setSalesData] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };


    // console.log("appliedFilter", appliedFilter);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {

                const endpoint = dataSource === 'redemptions' ? 'v1/redemption/search' : 'v1/transaction/search';
                let query = { query: appliedFilter };

                switch (currentPortal) {
                    case "Partner":
                        const modifiedFilter = { partnerCompany: selectedCompany };
                        query = { query: { ...appliedFilter, ...modifiedFilter } };
                        break;
                    case "Administrator":
                        query = { query: appliedFilter };
                        break;
                    default:
                        break;
                }


                const response = await api(endpoint, "POST", query);
                let sortedData = response.data.data.searchResult.sort((a, b) => new Date(b.createdOnUTC) - new Date(a.createdOnUTC));
                // console.log('API Response:', response);
                setSalesData(sortedData);
                // setSalesData(response.data.data.searchResult);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching sales data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [appliedFilter, dataSource, currentPortal, selectedCompany]);

    // Function to count sales by partner company and accumulate total price
    const countSalesByCompany = () => {
        const salesByCompany = {};
        salesData.forEach(sale => {
            const companyName = sale.partnerCompany && sale.partnerCompany.name ? sale.partnerCompany.name : 'Unknown';
            if (!salesByCompany[companyName]) {
                salesByCompany[companyName] = { count: 0, totalPrice: 0 };
            }
            salesByCompany[companyName].count += 1;
            salesByCompany[companyName].totalPrice += parseInt(sale.price) || 0;
        });
        return salesByCompany;
    };

    // Count sales by company and prepare data
    const salesByCompany = countSalesByCompany();
    const salesByCompanyData = {
        labels: Object.keys(salesByCompany),
        datasets: [
            {
                label: 'Total Price',
                data: Object.values(salesByCompany).map(company => company.count),
                backgroundColor: Array.from({ length: 50 }, (_, index) => `hsl(${index * 100}, 50%, 50%)`),
            },
        ],
    };

    // Custom tooltip for sales by company to show both total price and count
    const companyOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const companyName = tooltipItem.label;
                        const data = salesByCompany[companyName];
                        const totalPrice = data.totalPrice || 0; // Default to 0 if totalPrice is not defined
                        return [
                            `Total Price: $${typeof totalPrice === 'number' ? totalPrice.toFixed(2) : totalPrice}`,
                            `Count: ${data.count}`
                        ];
                    }
                }
            }
        }
    };


    // Function to count total sales and price by month
    const countTotalSalesByMonth = () => {
        const totalSalesByMonth = {};
        salesData.forEach(sale => {
            const saleDate = new Date(sale.createdOnUTC);
            if (!isNaN(saleDate.getTime())) {
                const monthYear = `${saleDate.getFullYear()}-${saleDate.getMonth()}`;
                if (!totalSalesByMonth[monthYear]) {
                    totalSalesByMonth[monthYear] = { count: 0, totalPrice: 0 };
                }
                totalSalesByMonth[monthYear].count += 1;
                totalSalesByMonth[monthYear].totalPrice += parseInt(sale.price) || 0;
            } else {
                console.error('Invalid Date:', sale.createdOnUTC);
            }
        });
        return totalSalesByMonth;
    };

    // Function to get month name from month number
    const getMonthName = (monthIndex) => {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return monthNames[monthIndex];
    };

    // Sort the months in chronological order
    const sortMonths = (monthYearKeys) => {
        return monthYearKeys.sort((a, b) => {
            const [yearA, monthA] = a.split('-').map(Number);
            const [yearB, monthB] = b.split('-').map(Number);
            if (yearA === yearB) {
                return monthA - monthB;
            }
            return yearA - yearB;
        });
    };

    // Count and sort total sales by month
    const totalSalesByMonth = countTotalSalesByMonth();
    const sortedMonthYears = sortMonths(Object.keys(totalSalesByMonth));

    // Data for total sales by month chart
    const totalSalesByMonthData = {
        labels: sortedMonthYears.map(monthYear => {
            const [year, month] = monthYear.split('-');
            return `${getMonthName(parseInt(month))} ${year}`;
        }),
        datasets: [
            {
                label: 'Total Sales by Month',
                data: sortedMonthYears.map(monthYear => totalSalesByMonth[monthYear].totalPrice),
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
        ],
    };

    // Custom tooltip for sales by month to show both total price and count
    const monthOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const monthYear = sortedMonthYears[tooltipItem.dataIndex];
                        const data = totalSalesByMonth[monthYear];
                        const totalPrice = data.totalPrice || 0; // Default to 0 if totalPrice is not defined
                        return [
                            `Total Price: $${typeof totalPrice === 'number' ? totalPrice.toFixed(2) : totalPrice}`,
                            `Count: ${data.count}`
                        ];
                    }
                }
            }
        }
    };


    const countSalesByProduct = () => {
        const salesByProduct = {};
        salesData.forEach(sale => {
            const productName = sale.product && sale.product.name ? sale.product.name : 'Unknown';
            if (!salesByProduct[productName]) {
                salesByProduct[productName] = { count: 0, totalPrice: 0 };
            }
            salesByProduct[productName].count += 1;
            salesByProduct[productName].totalPrice += parseInt(sale.price) || 0;
        });
        return salesByProduct;
    };


    // Count sales by company and prepare data
    const salesByProduct = countSalesByProduct();
    const salesByProductData = {
        labels: Object.keys(salesByProduct),
        datasets: [
            {
                label: 'Sales by Product',
                data: Object.values(salesByProduct).map(productName => productName.count),
                backgroundColor: Array.from({ length: 50 }, (_, index) => `hsl(${index * 100}, 50%, 50%)`),
            },
        ],
    };
    // Custom tooltip for sales by company to show both total price and count
    const productOptions = {
        plugins: {
            datalabels: {
                display: true,
                align: 'end',
                anchor: 'end',
                color: 'black',
                formatter: (value, context) => {
                    return `${value}%`; // Adjust label formatting if needed
                }
            },
            legend: {
                position: 'right',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const productName = tooltipItem.label;
                        const data = salesByProduct[productName];
                        const totalPrice = data?.totalPrice || 0; // Default to 0 if totalPrice is not defined
                        return [
                            `Total Price: $${typeof totalPrice === 'number' ? totalPrice.toFixed(2) : totalPrice}`,
                            `Count: ${data?.count}`
                        ];
                    }
                }
            }
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }

        const [day, month, year] = dateString.split('/').map(Number); // Split date string into components

        // Check if components are valid numbers
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return ''; // Invalid date format
        }

        const date = new Date(year, month - 1, day); // Month is 0-indexed, so we subtract 1
        const formattedDate = date.toLocaleDateString('en-GB'); // Use 'en-GB' locale for dd/mm/yyyy format

        return formattedDate;
    };



    const formatHeader = (prefix) => {
        // console.log("APPLIED FILTERS", appliedFilter);
        const { createdOnUTC } = appliedFilter;

        if (!createdOnUTC) {
            return `${prefix}`;
        }

        let startDate = null;
        let endDate = null;

        if (createdOnUTC["$gte"]) {
            startDate = new Date(createdOnUTC["$gte"]).toLocaleDateString();
        }

        if (createdOnUTC["$lte"]) {
            endDate = new Date(createdOnUTC["$lte"]).toLocaleDateString();
        }

        if (startDate && endDate) {
            return `${prefix} from ${formatDate(startDate)} - ${formatDate(endDate)}`;
        } else if (startDate) {
            return `${prefix} from ${formatDate(startDate)}`;
        } else if (endDate) {
            return `${prefix} up to ${formatDate(endDate)}`;
        } else {
            return `${prefix}`;
        }
    };


    const filteredData = salesData.filter(transaction => {
        const customerName = transaction.customer ? `${transaction.customer?.firstName} ${transaction.customer?.lastName}`.toLowerCase() : '';
        const productName = transaction.product ? transaction.product?.name.toLowerCase() : '';
        const partnerCompanyName = transaction.partnerCompany ? transaction.partnerCompany?.name.toLowerCase() : '';
        const partnerUserName = transaction.partnerUser ? `${transaction.partnerUser?.firstName} ${transaction.partnerUser?.lastName}`.toLowerCase() : '';
        const invoiceID = transaction.invoiceID ? transaction.invoiceID?.toLowerCase() : '';

        return (
            customerName.includes(searchQuery.toLowerCase()) ||
            productName.includes(searchQuery.toLowerCase()) ||
            partnerCompanyName.includes(searchQuery.toLowerCase()) ||
            partnerUserName.includes(searchQuery.toLowerCase()) ||
            invoiceID.includes(searchQuery.toLowerCase())
        );
    });

    const handleRowClick = (transactionId) => {
        const url = `/transaction/${transactionId}`;
        window.open(url, '_blank');
    };

    // Debugging information
    // console.log('Sales Data:', salesData);
    // console.log('Sales by Company Data:', salesByCompanyData);
    // console.log('Total Sales by Month Data:', totalSalesByMonthData);
    // console.log('Sales by Product Data:', salesByProductData);

    return (
        <>
            {loading && <GlobalLoadingSpinner />}

            <div className="flex flex-wrap justify-center">
                {salesData.length > 0 ? (
                    <div className='w-full flex justify-center items-center flex-wrap gap-2'>
                        <div className="w-full max-w-[800px]">
                            <h2>{formatHeader("Sales by Partner Company")}</h2>
                            <div className="w-full max-w-screen-md">
                                <Bar data={salesByCompanyData} options={companyOptions} />
                            </div>
                        </div>
                        <div className="w-full max-w-[800px]">
                            <h2>{formatHeader("Total Sales per Month")}</h2>
                            <div className="w-full max-w-screen-md">
                                <Line data={totalSalesByMonthData} options={monthOptions} />
                            </div>
                        </div>
                        <div className="w-full max-w-[800px]">
                            <h2>{formatHeader("Sales by Products")}</h2>
                            <div className="w-full max-w-screen-md">
                                <Pie data={salesByProductData} options={productOptions} />
                            </div>
                        </div>
                        <div className='w-full transactionRecords'>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearch}
                                placeholder="Search transactions"
                                className="w-full mb-4 px-4 py-2 border rounded"
                            />
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Partner Company Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Submitted By</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice Status</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {filteredData.map((transaction, index) => {
                                        const customerInfo = transaction.customer ?
                                            <div className='flex flex-column flex-wrap max-w-lg'>
                                                {transaction.customer.firstName} {transaction.customer.lastName}
                                                <br />
                                                {transaction.customer.email}
                                                <br />
                                                {transaction.customer.phone}
                                            </div> : '';
                                        const productName = transaction.product?.name;
                                        const partnerCompanyName = transaction.partnerCompany?.name;
                                        const partnerUserName = transaction.partnerUser ? `${transaction.partnerUser?.firstName} ${transaction.partnerUser?.lastName}` : '';
                                        const invoiceStatus = transaction.invoiceStatus;
                                        const createdOnUTC = new Date(transaction.createdOnUTC).toLocaleDateString();
                                        const invoicePrice = transaction.price;

                                        return (
                                            <tr className='cursor-pointer text-left' onClick={() => handleRowClick(transaction._id)}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-lg">{index + 1}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-lg">{productName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-lg">{customerInfo}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-lg">{createdOnUTC}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-lg">{partnerCompanyName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-lg">{partnerUserName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-lg">{invoiceStatus}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-lg">${invoicePrice ?? 0}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <p>No sales data available</p>
                )}
            </div>
        </>
    );

};

export default TransactionSalesCharts;
