import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Select, MenuItem, Input, Chip, Box, Button, InputLabel, FormControl, FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";


const PromotionForm = ({ selectedPromotion, callback, cancelModal }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const utmValue = queryParams.get('utm') || 'p1_admin_portal';


    const [modalActive, setModalActive] = useState(true);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    // const [customer, setCustomer] = useState({});
    const [products, setProducts] = useState([]);
    // const getAllCustomers = async () => {
    //     try {
    //         const response = await api("v1/customer/", "GET", null); // using user endpoint for now, will need to update to customer once customer is done
    //         if (response.data.success) {
    //             const allCustomers = response.data.data.allCustomers;
    //             const result = allCustomers.map((customer) => ({
    //                 value: customer._id,
    //                 label: `<div className="flex flex-column">
    //                             <p>${customer.firstName} ${customer.lastName}</p>
    //                             <p>${customer.email}</p>                               
    //                             <p>${customer.phone}</p>                               
    //                             <p>${customer.address.street} ${customer.address.city} ${customer.address.postcode}, ${customer.address.country} ${customer.address.state}</p>                               
    //                             <p>${customer.email}</p>                               
    //                         </div>`
    //             }));
    //             console.log(allCustomers);

    //             if (result) {
    //                 setCustomerOptions(result);
    //                 if (selectedPromotion) {
    //                     const selectedCustomer = result.find(
    //                         (customer) => customer._id === selectedPromotion.customer.value
    //                     );
    //                     setCustomer(selectedCustomer);
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.error("Error loading partner companies:", error);
    //     }
    // };

    const getAllProducts = async () => {
        try {
            const response = await api("v1/product", "GET", null);
            if (response.data.success) {
                const allProducts = response.data.data.allProducts;
                const result = allProducts.map((product) => ({
                    value: product._id,
                    label: product.name,
                }));
                if (result) {
                    setProductOptions(result);

                    // if (selectedPromotion) {
                    //     const selectedProducts = result.find(
                    //         (product) => product.value === selectedPromotion.product._id
                    //     );
                    //     setProduct(selectedProducts);
                    // }

                    if (selectedPromotion) {
                        // Check back to debug in Edit Mode
                        // const selectedProductIds = selectedPromotion.validProducts.map((product) => product.value);
                        const selectedProductIds = selectedPromotion.validProducts;
                        const selectedProducts = result.filter((product) => selectedProductIds.includes(product.value));
                        setProducts(selectedProducts);
                    }


                }
            }
        } catch (error) {
            console.error("Error loading Products:", error);
        }
    };



    useEffect(() => {
        // getAllCustomers();
        getAllProducts();
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("Submitted Data", values);
        const dataToSend = {
            code: values.code,
            type: values.type,
            title: values.title,
            comment: values.comment,
            products: products.map((selectedItem) => selectedItem.value),
            value: values.value,
            expiresOnUTC: values.expiresOnUTC,
            utm: values.utm,
            active: values.active,
        };
        console.log("Data to Send", dataToSend);
        try {
            let response;
            if (selectedPromotion) {
                // Edit Promotion Option
                response = await api(`v1/promotion/${selectedPromotion._id}`, "PATCH", dataToSend);
            } else {
                // Add Promotion Option
                response = await api(`v1/promotion`, "POST", dataToSend);
            }

            if (response.data.success) {
                if (selectedPromotion) {
                    console.log("Promotion Updated: ", response.data.data.promotion);
                    callback(response.data.data.promotion);
                } else {
                    if (response.data.data.newPromotion) {
                        callback(response.data.data.newPromotion);
                    } else {
                        alert(response.data.message);
                    }
                }
            } else {
                response?.data?.message && alert(response.data.message);
            }
        } catch (error) {
            console.error("API error:", error);
        }

        setSubmitting(false);
    };

    const loadProductOptions = async (inputValue) => {
        try {
            const filteredOptions = productOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            // callback(filteredOptions);
            return filteredOptions;
        } catch (error) {
            console.error("Error loading product options:", error);
            // callback([]);
        }
    };


    const handleProductChange = (selectedOptions) => {
        setProducts(selectedOptions || null);
    };

    const loadCustomerOptions = async (inputValue) => {
        const filteredOptions = customerOptions.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return filteredOptions;
    };

    // const handleCustomerChange = (selectedOptions) => {
    //     if (selectedOptions) {
    //         setCustomer(selectedOptions);
    //     } else {
    //         setCustomer(null);
    //     }
    // };



    const selectStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "white",
            borderColor: "#9e9e9e",
            zIndex: 2,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 3,
        }),
    };

    // // Validation function for Value year 
    // const validateValueYear = (value) => {
    //     const validYearPattern = /^\d{4}$/;
    //     if (!validYearPattern.test(value)) {
    //         return "Please enter a valid value year in YYYY format.";
    //     }
    // };


    const validationSchema = Yup.object().shape({
        code: Yup.string().required("Promotion Code is required"),
        type: Yup.string().required("Type is required"),
        title: Yup.string().required("Title is required"),
        // comment: Yup.string().required("Comment is required"),
        value: Yup.string().required("Value is required"),
        expiresOnUTC: Yup.string().required("Promotion Expiry Date is required"),
        // products: Yup.array()
        //     .of(
        //         Yup.object().shape({
        //             label: Yup.string().required(),
        //             value: Yup.string().required()
        //         })
        //     )
        //     .min(1, "At least one product is required"),
        // customer: Yup.object().required("Select the owner of this Promotion"),
        // active: Yup.boolean().required("Active field is required"),
    });

    return (
        <Formik
            initialValues={{
                code: selectedPromotion ? selectedPromotion.code : '',
                type: selectedPromotion ? selectedPromotion.type : '',
                title: selectedPromotion ? selectedPromotion.title : '',
                comment: selectedPromotion ? selectedPromotion.comment : '',
                value: selectedPromotion ? selectedPromotion.value : '',
                expiresOnUTC: selectedPromotion ? new Date(selectedPromotion.expiresOnUTC) : '',
                products: null,
                active: selectedPromotion ? selectedPromotion.active : false,
                utm: selectedPromotion ? selectedPromotion.utm : utmValue,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
                    <div className="form-input-wrap flex gap-2 w-full p-4">
                        <div className="w-full flex gap-5">
                            <div className="form-group w-full">
                                {/* <label htmlFor="title">Title:</label> */}
                                <Field name="title">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            id="title"
                                            type="text"
                                            label="Title"
                                            className="z-2"
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="title"
                                    component="span"
                                    className="error-message"
                                />
                            </div>
                        </div>
                        <div className="w-full flex gap-5">

                            <div className="form-group w-full">
                                {/* <label htmlFor="code">Code:</label> */}
                                <Field name="code">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            id="code"
                                            type="text"
                                            label="Code"
                                            className="z-2"
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="code"
                                    component="span"
                                    className="error-message"
                                />
                            </div>

                            <div className="form-group w-full">
                                {/* <label htmlFor="comment">Promotion Comment:</label> */}
                                <Field name="comment">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            id="comment"
                                            type="text"
                                            label="Promotion Comment"
                                            placeholder="Enter Promotion Comment"
                                            className="z-2"
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="comment"
                                    component="span"
                                    className="error-message"
                                />
                            </div>


                        </div>
                        <div className="w-full flex gap-5">
                            <div className="form-group w-full">
                                {/* <label htmlFor="type">Type:</label> */}
                                <Field name="type">
                                    {({ form }) => (
                                        <FormControl fullWidth>
                                            <InputLabel id="type-label">Promotion Type</InputLabel>
                                            <Select
                                                variant="outlined"
                                                label="Promotion Type"
                                                placeholder="Promotion Type"
                                                labelId="type-label"
                                                value={form.values.type}
                                                onChange={(event) => {
                                                    form.setFieldValue("type", event.target.value);
                                                }}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                                        <Chip key={selected} label={selected} sx={{ m: 0.5 }} />
                                                    </Box>
                                                )}
                                            >
                                                <MenuItem value="Fixed Dollar Amount">Fixed Dollar Amount Discount</MenuItem>
                                                <MenuItem value="Percentage">Percentage Discount</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="type"
                                    component="span"
                                    className="error-message"
                                />
                            </div>

                            <div className="form-group w-full">
                                {/* <label htmlFor="value">Promotion Value:</label> */}
                                <Field name="value">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            id="value"
                                            type="number"
                                            label="Promotion Value"
                                            placeholder="Enter the promotion value"
                                            className="z-2"
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="value"
                                    component="span"
                                    className="error-message"
                                />
                            </div>

                        </div>
                        <div className="w-full flex gap-5">
                            <div className="w-full flex gap-5">
                                <div className="form-group w-full">
                                    <label className="label" htmlFor="products">
                                        Valid Products:
                                    </label>
                                    <Field name="products">
                                        {({ field }) => (
                                            <AsyncSelect
                                                isMulti
                                                name="products"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={productOptions}
                                                loadOptions={loadProductOptions}
                                                styles={selectStyles}
                                                onChange={handleProductChange}
                                                value={products}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="products"
                                        component="span"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex gap-5">
                            <div className="w-full flex gap-5">
                                <div className="form-group w-full">
                                    <label htmlFor="value">Promotion Expiry Date:</label>
                                    <Field name="expiresOnUTC">
                                        {({ field }) => (
                                            <DatePicker
                                                {...field}
                                                // disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-expiresOnUTC"
                                                label="Select a expiresOnUTC"
                                                placeholderText="Select the Promotion Expriry Date"
                                                value={values.expiresOnUTC}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                selected={values.expiresOnUTC}
                                                onChange={(date) =>
                                                    setFieldValue('expiresOnUTC', date)
                                                }
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="expiresOnUTC"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>



                                <div className="form-group w-full">
                                    <label className="label" htmlFor="active">
                                        Active:
                                    </label>
                                    <Field name="active" type="checkbox">
                                        {({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Switch {...field} checked={field.value} />
                                                }
                                                label={field.value ? "Active" : "Inactive"}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="active"
                                        component="span"
                                        className="error-message"
                                    />


                                    <input type="hidden" name="utm" value={values.utm} />
                                    {/* Need to revisit what and how utm is handled and passed on to backend */}

                                </div>
                            </div>
                        </div>


                    </div>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <Button
                            variant="contained"
                            onClick={cancelModal}
                        >
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained">
                            Submit
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default PromotionForm;
