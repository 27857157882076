/*
Administrator Form Component, Used to render Add Administrator and Edit Administrator form
*/
import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Button, FormControl, InputLabel, FormControlLabel, Select, Switch, Box, MenuItem, Chip } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";
import countriesData from "../../../constants/countries.json";
import { COLORS } from "../../../utils/colors";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phone: Yup.string()
    .matches(
      /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
      "Invalid Australian phone number"
    )
    .required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  address: Yup.object().shape({
    street: Yup.string().required("Street is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postcode: Yup.number().required("Postcode is required"),
    country: Yup.object().required("Country is required"),
  }),
  role: Yup.string().required("Role is required"),
  active: Yup.boolean().required("Active field is required"),
});

const AdministratorForm = ({
  administrator, // if this is set we assume Edit User else Add User 
  callback
}) => {
  // const [modalActive, setModalActive] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(administrator ? { label: administrator.address.country, value: administrator.address.country } : { label: 'Australia', value: 'Australia' });
  // Add state for selected country
  const handleCountryChange = (selectedOption) => {
    // alert(selectedOption.label);
    setSelectedCountry(selectedOption); // Set the selected country value to country state
    console.log("Set Country", selectedCountry);
  };


  useEffect(() => {
    // setAdministratorCountry(administrator)
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    const dataToSend = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      address: {
        ...values.address,
        country: selectedCountry.label
      },
      role: values.role,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      active: values.active
    };
    console.log("Data to Send", dataToSend);
    try {
      // Make the API call with a PATCH request
      // const response = await api(`v1/administrator/${administrator._id}`, 'PATCH', dataToSend);
      let response;
      if (administrator) {
        // Edit Vehicle Option
        response = await api(`v1/administrator/${administrator._id}`, "PATCH", dataToSend);
      } else {
        // Add Vehicle Option
        response = await api(`v1/administrator`, "POST", dataToSend);
      }


      if (response.data.success) {
        console.log("Administrator Updated", response.data.data.administrator);
        if (administrator) {
          callback(response.data.data.administrator);
        } else {
          if (response.data.data.existingAdministrator) {
            alert(response.data.message);
          } else {
            callback(response.data.data.newAdministrator);
          }
        }
        // reloadUserDetail(response.data.data.administrator);
      } else {
        response?.data?.message && alert(response.data.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }

    setSubmitting(false);
  };



  const loadCountryOptions = async (inputValue) => {
    const filteredCountries = countriesData.filter((country) =>
      country.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    // Return the filtered countries as options
    return filteredCountries;
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#9e9e9e",
      zIndex: 2,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3, // Higher zIndex for the dropdown menu
    }),
  };

  return (
    <Formik
      initialValues={{
        firstName: administrator ? administrator.firstName : '',
        lastName: administrator ? administrator.lastName : '',
        phone: administrator ? administrator.phone : '',
        email: administrator ? administrator.email : '',
        address: {
          street: administrator ? administrator.address.street : '',
          city: administrator ? administrator.address.city : '',
          state: administrator ? administrator.address.state : '',
          postcode: administrator ? administrator.address.postcode : '',
          country: selectedCountry,
        },
        role: administrator ? administrator.role : '',
        active: administrator ? administrator.active : false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
        <div className="form-input-wrap flex gap-2 w-full">
          <div className="form-group w-full">
            <label htmlFor="role">Role:</label>
            <FieldArray name="role">
              {({ form, push, remove }) => (
                <FormControl fullWidth>
                  <InputLabel id="role-label">Select Role</InputLabel>
                  <Select
                    // multiple
                    variant="outlined"
                    labelId="role-label"
                    value={form.values.role}
                    onChange={(event) => {
                      form.setFieldValue("role", event.target.value);
                    }}
                  >
                    {/* <MenuItem value="">Not Selected</MenuItem> */}
                    <MenuItem value="Super Admin">Super Admin</MenuItem>
                    <MenuItem value="Normal Admin">Normal Admin</MenuItem>
                    <MenuItem value="Sales Admin">Sales Admin</MenuItem>

                  </Select>
                </FormControl>
              )}
            </FieldArray>
            <ErrorMessage
              name="role"
              component="span"
              className="error-message"
            />
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="firstName">
                First Name:
              </label>
              <Field name="firstName">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="firstName"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="lastName">
                Last Name:
              </label>
              <Field name="lastName">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="lastName"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="email">
                Email Address:
              </label>
              <Field name="email">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="email"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="phone">
                Phone:
              </label>
              <Field name="phone">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="phone"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.street">
                Street:
              </label>
              <Field name="address.street">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Street"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.street"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="address.city">
                City:
              </label>
              <Field name="address.city">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="City"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.city"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.state">
                State:
              </label>
              <Field name="address.state">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="State"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.state"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="address.postcode">
                Postcode:
              </label>
              <Field name="address.postcode">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Postcode"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.postcode"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.country">
                Country:
              </label>

              <Field name="address.country">
                {({ field }) => (
                  <AsyncSelect
                    name="address.country"
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCountryOptions}
                    styles={selectStyles}
                    onChange={handleCountryChange}
                    value={selectedCountry}
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.country"
                component="span"
                className="error-message"
              />
            </div>
          </div>
          <div className="form-group w-full">
            <label className="label" htmlFor="active">
              Active:
            </label>
            <Field name="active" type="checkbox">
              {({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label={field.value ? "Active" : "Inactive"}
                />
              )}
            </Field>
            <ErrorMessage
              name="active"
              component="span"
              className="error-message"
            />
          </div>
        </div>
        <div className="form-actions-wrap flex gap-20 justify-between">
          <Button
            sx={{ backgroundColor: COLORS.priorityBlue.hex }}
            type="submit"
            variant="contained"
            color="primary"
            className="submit-button"
          >
            Submit
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="error"
            className="submit-button"
            onClick={() => callback()}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default AdministratorForm;
