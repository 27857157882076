import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { fetchRedemptionData, countRedemptionsByMerchantAccount, countRedemptionsByMerchantStore, countRedemptionsByProduct } from './RedemptionChartUtilities';


const MerchantAccountDrillDown = ({ redemptionData }) => {
    const [currentLevel, setCurrentLevel] = useState('MerchantAccount');
    const [selectedMerchantAccount, setSelectedMerchantAccount] = useState(null);
    const [selectedMerchantStore, setSelectedMerchantStore] = useState(null);

    const handleBarClick = (elements, label) => {
        if (currentLevel === 'MerchantAccount') {
            setSelectedMerchantAccount(label);
            setCurrentLevel('MerchantStore');
        } else if (currentLevel === 'MerchantStore') {
            setSelectedMerchantStore(label);
            setCurrentLevel('Product');
        }
    };

    const handleBackClick = () => {
        if (currentLevel === 'Product') {
            setCurrentLevel('MerchantStore');
            setSelectedMerchantStore(null);
        } else if (currentLevel === 'MerchantStore') {
            setCurrentLevel('MerchantAccount');
            setSelectedMerchantAccount(null);
        }
    };

    let chartData;
    let chartOptions;
    let title;

    if (currentLevel === 'MerchantAccount') {
        const redemptionByMerchantAccount = countRedemptionsByMerchantAccount(redemptionData);
        chartData = {
            labels: Object.keys(redemptionByMerchantAccount),
            datasets: [{
                label: 'Redemptions by Merchant Account',
                data: Object.values(redemptionByMerchantAccount).map(account => account.count),
                backgroundColor: 'rgba(75,192,192,0.4)',
            }],
        };
        chartOptions = {
            onClick: (evt, elements) => {
                if (elements.length > 0) {
                    const label = chartData.labels[elements[0].index];
                    handleBarClick(elements, label);
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const merchantName = tooltipItem.label;
                            const data = redemptionByMerchantAccount[merchantName];
                            const totalPrice = data.totalPrice || 0;
                            return [`Total Price: $${totalPrice.toFixed(2)}`, `Count: ${data.count}`];
                        }
                    }
                }
            }
        };
        title = "Merchant Accounts";
    } else if (currentLevel === 'MerchantStore') {
        const redemptionByMerchantStore = countRedemptionsByMerchantStore(redemptionData, selectedMerchantAccount);
        chartData = {
            labels: Object.keys(redemptionByMerchantStore),
            datasets: [{
                label: `Redemptions by Merchant Store (${selectedMerchantAccount})`,
                data: Object.values(redemptionByMerchantStore).map(store => store.count),
                backgroundColor: 'rgba(153,102,255,0.6)',
            }],
        };
        chartOptions = {
            onClick: (evt, elements) => {
                if (elements.length > 0) {
                    const label = chartData.labels[elements[0].index];
                    handleBarClick(elements, label);
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const storeName = tooltipItem.label;
                            const data = redemptionByMerchantStore[storeName];
                            const totalPrice = data.totalPrice || 0;
                            return [`Total Price: $${totalPrice.toFixed(2)}`, `Count: ${data.count}`];
                        }
                    }
                }
            }
        };
        title = `Redemptions by Merchant Store (${selectedMerchantAccount})`;
    } else if (currentLevel === 'Product') {
        const redemptionByProduct = countRedemptionsByProduct(redemptionData, selectedMerchantStore);
        chartData = {
            labels: Object.keys(redemptionByProduct),
            datasets: [{
                label: `Redemptions by Product (${selectedMerchantStore})`,
                data: Object.values(redemptionByProduct).map(product => product.count),
                backgroundColor: 'rgba(255,159,64,0.6)',
            }],
        };
        chartOptions = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const productName = tooltipItem.label;
                            const data = redemptionByProduct[productName];
                            const totalPrice = data.totalPrice || 0;
                            return [`Total Price: $${totalPrice.toFixed(2)}`, `Count: ${data.count}`];
                        }
                    }
                }
            }
        };
        title = `Redemptions by Product (${selectedMerchantStore})`;
    }
    console.log(chartData);

    return (
        <div className='w-full flex justify-center items-center flex-wrap gap-2'>
            <div className="w-full max-w-[1024px]">
                <h2>{title}</h2>
                {currentLevel !== 'MerchantAccount' && (
                    <button onClick={handleBackClick} className="mb-4 px-4 py-2 bg-blue-500 text-white rounded">
                        Back
                    </button>
                )}
                <div className="w-full max-w-screen-md">
                    <Bar data={chartData} options={chartOptions} />
                </div>
                <h4>Total Count:  {chartData.datasets[0].data.reduce((sum, value) => sum + value, 0)} </h4>
            </div>
        </div>
    );
};

export default MerchantAccountDrillDown;
