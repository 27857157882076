import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button, TextField } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import MerchantStoreForm from "../../components/Forms/MerchantStoreForm";
// import MerchantStoreForm from "./Forms/MerchantStoreForm";
// import EntityCardDisplay from "../../components/EntityCardDisplay";
import StoreIcon from "@mui/icons-material/Store";
import { api } from "../../utils/api";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import { COLORS } from "../../utils/colors";
import { useNavigate } from "react-router-dom";
import EmptyState from "../EmptyStates/EmptyState";
import NewEntityCardDisplay from "../../components/NewEntityCardDisplay";
import MerchantStoreList from "../../components/MerchantStoreList";
import StoreList from "./StoreList";

const MerchantStore = () => {
  const [merchantStoreOption, setMerchantStoreOption] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inactiveStoresFilter, setInactiveStoreFilter] = useState(true);
  const [showMerchantStoreForm, setShowMerchantStoreForm] = useState(false);
  const { filteredData, search, setSearch } = useBasicSearch(merchantStoreOption);
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser') ?? null)

  const toggleInactiveStoresFilter = () => {
    setInactiveStoreFilter(prevFilter => !prevFilter);
  };

  useEffect(() => {
    fetchMerchantStores();
  }, [inactiveStoresFilter]);

  const fetchMerchantStores = async () => {
    try {

      let response;
      switch (sessionStorage.getItem("selectedPortal")) {
        case "Administrator":
          if (inactiveStoresFilter) {
            response = await api("v1/merchantStore", "GET");
          } else {
            response = await api("v1/merchantStore/inactive", "GET");
          }
          break;
        case "Merchant":

          // if (currentUser?.role === 'admin') {
          response = await api(`v1/merchant/${currentUser.merchantAccount}`, "GET");
          // }
          break;
        default:
          response = null;
          break;
      }

      // const response = await api("v1/merchantStore", "GET");
      if (response.data.success) {

        let merchantStoreOptions = response.data.data.allMerchantStores || response.data.data.merchant.stores;


        setMerchantStoreOption(merchantStoreOptions);
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
    }
  };

  const triggerMerchantStoreListUpdate = (merchantStore) => {
    console.log("New/Updated Merchant Store: ", merchantStore);
    setShowMerchantStoreForm(false);
    setLoading(false);
    fetchMerchantStores();
  };
  const navigate = useNavigate();

  const AddNewStore = () => (
    <Button
      sx={{ backgroundColor: COLORS.priorityBlue.hex }}
      variant="contained"
      onClick={() => setShowMerchantStoreForm(true)}
    >
      Add New Merchant Store
    </Button>
  );

  return (
    <div className="page merchantStore w-full flex flex-column items-center p-4">
      <div className="pageTitle flex flex-col w-full text-center lg:flex-row lg:text-left lg:justify-between mb-8">
        <h2>Merchant Store - <Button variant="contained" color={inactiveStoresFilter ? 'success' : 'error'} onClick={toggleInactiveStoresFilter}>{inactiveStoresFilter ? 'Active' : 'Inactive'}</Button></h2>
        <div>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: "350px" }}
            placeholder="Search for a merchant store"
          />
          <AddNewStore />
          <ModalPopup
            showModal={showMerchantStoreForm}
            handleShowModal={() => setShowMerchantStoreForm(false)}
            popupTitle="Add New Merchant Store"
            popupContent={
              <MerchantStoreForm
                handleDisplayModal={triggerMerchantStoreListUpdate}
                cancelModal={() => {
                  setShowMerchantStoreForm(false);
                }}
              />
            }
          />
        </div>
      </div>
      <div className="flex w-full justify-center container">
        {loading ? (
          <GlobalLoadingSpinner />
        ) : merchantStoreOption.length === 0 ? (
          <EmptyState type="merchantStore" text="Looks like there are no store yet." action={<AddNewStore />} />
        ) : filteredData.length === 0 ? (
          <EmptyState type="parnerPricing" text="No merchant store match your search." />
        ) : (
          <StoreList data={filteredData} />
        )}
      </div>
    </div>
  );
};

export default MerchantStore;
