/*
Merchant Store Form Component, Used to render Add Merchant Store and Edit Merchant Store form
*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Box, Modal, Typography, Button, FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import countriesData from "../../constants/countries.json";
import roleOptions from "../../constants/roleOptions.json";
import { merchantStoreFormFields, merchantStoreValidation } from "../../constants/merchantFormFields";
import { api } from "../../utils/api";

const validationSchema = Yup.object().shape(merchantStoreValidation());

const MerchantStoreForm = ({
  merchant,
  merchantStore, // if this is set we assume Edit Store else Add Store
  handleDisplayModal,
  fetchStoreDetails,
  getMerchantStores,
  setMerchantStores,
  setMerchantStoreLoading,
}) => {
  const [modalActive, setModalActive] = useState(true);
  // const [country, setBillingCountry] = useState([]);
  const [country, setBillingCountry] = useState();
  // const [role, setRole] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [storeStaffs, setStoreStaff] = useState(merchantStore ? merchantStore.staff : []);
  const [storeProducts, setStoreProducts] = useState([]);
  const [merchantAccountOptions, setMerchantAccountOptions] = useState();
  const [selectedMerchantAccount, setSelectedMerchantAccount] = useState();
  // const [active, setActive] = useState(true);

  const currentPortal = sessionStorage.getItem("selectedPortal");

  // Add state for selected country
  const handleCountryChange = (selectedOption) => {
    console.log("country select value: ", selectedOption);
    setBillingCountry(selectedOption); // Set the selected country value to country state
  };

  // const handleRoleChange = (selectedOption) => {
  //   console.log("role select value: ", selectedOption);
  //   setRole(selectedOption);
  // };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("values: ", values);
    const dataToSend = {
      name: values.name,
      storeManager: values.storeManager,
      // products: storeProducts, /*storeProducts.map((product) => { return product.value }),*/
      products: storeProducts.map((product) => { return product.value }),
      staff: storeStaffs,
      // staff: storeStaffs.map((staff) => { return staff.value }),
      // phone: values.phone,
      address: {
        ...values.address,
        country: country.label,
      },
      active: values.active ?? false
      // role: role.value,
    };
    console.log("Data to Send", dataToSend);
    console.log("Current Merchant Account: ", selectedMerchantAccount);
    try {
      var response = null;
      if (merchantStore) {
        response = await api(
          `v1/merchant/${selectedMerchantAccount.value}/store/${merchantStore._id}`,
          "PATCH",
          dataToSend
        );
      } else {
        response = await api(
          `v1/merchant/${selectedMerchantAccount.value}/store`,
          "POST",
          dataToSend
        );
      }

      if (response !== null) {
        if (response.data.success) {
          console.log("Merchant Store Add or Successfully", response.data.data);
          handleDisplayModal();
          if (getMerchantStores) {
            let users = await getMerchantStores();
            setMerchantStores(users);
            setMerchantStoreLoading(false);
          }
          if (fetchStoreDetails) {
            fetchStoreDetails();
          }
        }
      }
    } catch (error) {
      console.error("API error:", error);
    }

    setSubmitting(false);
  };

  const loadCountryOptions = async (inputValue) => {
    const filteredCountries = countriesData.filter((country) =>
      country.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    // Return the filtered countries as options
    return filteredCountries;
  };

  const loadRoleOptions = async (inputValue) => {
    const filteredOptions = roleOptions.filter((role) =>
      role.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    // Return the filtered countries as options
    return filteredOptions;
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#9e9e9e",
      zIndex: 2,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3, // Higher zIndex for the dropdown menu
    }),
  };

  // const onLoadSetRole = (user) => {
  //   if (role == null) {
  //     if (user && user.role) {
  //       let theRole = user.role;
  //       setRole({
  //         label: theRole.charAt(0).toUpperCase() + theRole.slice(1),
  //         value: theRole,
  //       });
  //     }
  //   }
  // };

  const onLoadSetCountry = (store) => {
    if (store && country == null) {
      let theCountry = store.address?.country;
      for (let i = 0; i < countriesData.length; i++) {
        const element = countriesData[i];
        // console.log("element: ", element.value);
        if (element.label == theCountry) {
          setBillingCountry({
            label: element.label,
            value: element.value,
          });
          break;
        }
      }
    }
  };

  const onLoadSetProduct = (store) => {
    if (storeProducts == null) {
      let allProducts = store.products;
      // for (let i = 0; i < countriesData.length; i++) {
      //   const element = countriesData[i];
      //   console.log("element: ", element.value);
      //   if (element.label == theCountry) {
      //     setBillingCountry({
      //       label: element.label,
      //       value: element.value,
      //     });
      //     break;
      //   }
      // }
    }
  };

  useEffect(() => {
    // onLoadSetRole(merchantUser);
    // onLoadSetCountry(merchantUser);
    onLoadSetCountry(merchantStore);
    // onLoadSetStaff(merchantStore);
    onLoadSetProduct(merchantStore);
  });

  const getAllProducts = async () => {
    try {
      const response = await api("v1/product", "GET", null);
      if (response.data.success) {
        const allProducts = response.data.data.allProducts;
        const result = allProducts.map((product) => ({
          value: product._id,
          label: product.name,
        }));

        if (result) {
          setProductOptions(result);
          if (merchantStore) {
            // const selectedProduct = result.find(
            //   (product) => product._id === merchantStore.product.value
            // );
            // setStoreProducts(selectedProduct);
            // setStoreProducts(merchantStore.products.map((product) => { label: product.name, value: product._id }));
            setStoreProducts(result.filter(product => merchantStore.products.includes(product.value)));
            // setStoreProducts(merchantStore.products);
          }
        }
      }
    } catch (error) {
      console.error("Error loading partner companies:", error);
    }
  };

  const getAllStaffs = async () => {
    try {
      const response = await api(`v1/merchant/${selectedMerchantAccount.value}/user`, "GET", null);
      if (response.data.success) {
        const allStaffs = response.data.data.merchantUsers;
        const result = allStaffs.map((staff) => ({
          value: staff._id,
          label: `${staff.firstName} ${staff.lastName}`,
        }));

        if (result) {
          setStaffOptions(result);
          console.log("Set Options for Staff Options", result);
          if (merchantStore) {
            // const selectedStaff = result.find(
            //   (staff) => staff._id === merchantStore.staff.value
            // );
            setStoreStaff(merchantStore.staff);
          }
        }
      }
    } catch (error) {
      console.error("Error loading partner companies:", error);
    }
  };

  const getAllMerchantAccounts = async () => {
    try {
      const response = await api("v1/merchant", "GET", null);
      if (response.data.success) {
        const allMerchantAccounts = response.data.data.allMerchants;
        const result = allMerchantAccounts.map((merchantAccount) => ({
          value: merchantAccount._id,
          label: merchantAccount.name,
        }));

        if (result) {
          setMerchantAccountOptions(result);
          if (merchant) {
            const selectedMerchantAccount = result.find(account => account.value === merchant._id);
            setSelectedMerchantAccount(selectedMerchantAccount)
            // setMerchantAccountOptions(selectedMerchantAccount);
          }
        }
      }
    } catch (error) {
      console.error("Error loading partner companies:", error);
    }
  };

  const loadMerchantAccountOptions = async (inputValue) => {
    try {
      const filteredOptions = merchantAccountOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } catch (error) {
      console.error("Error loading Merchant Account options:", error);
      return [];
    }
  };

  const handleMerchantAccountChange = (selectedOption) => {
    setSelectedMerchantAccount(selectedOption);
  };


  useEffect(() => {
    getAllMerchantAccounts();
    // getAllProducts();
    // getAllStaffs();
  }, []);

  useEffect(() => {
    setStoreStaff([]);
    getAllProducts();
    getAllStaffs();
  }, [selectedMerchantAccount]);

  const loadProductOptions = async (inputValue) => {
    try {
      const filteredOptions = productOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } catch (error) {
      console.error("Error loading product options:", error);
      return [];
    }
  };
  const loadStaffOptions = async (inputValue) => {
    try {
      const filteredOptions = staffOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return filteredOptions;
    } catch (error) {
      console.error("Error loading Staff options:", error);
      return [];
    }
  };
  const handleProductChange = (selectedOptions) => {
    if (selectedOptions) {
      setStoreProducts(selectedOptions);
    } else {
      setStoreProducts(null);
    }
  };
  const handleStaffChange = (selectedOptions) => {
    if (selectedOptions) {
      setStoreStaff(selectedOptions);
    } else {
      setStoreStaff(null);
    }
  };

  console.log("Merchant Account Options: ", merchantAccountOptions);
  return (
    <Formik
      initialValues={merchantStoreFormFields(merchantStore, country, storeStaffs, storeProducts)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
        <div className="form-input-wrap flex gap-2 w-full">

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="merchantAccount">
                {({ field }) => (
                  <AsyncSelect
                    // isMulti
                    placeholder="Select Merchant Account..."
                    name="merchantAccount"
                    {...field}
                    cacheOptions
                    defaultOptions={merchantAccountOptions}
                    loadOptions={loadMerchantAccountOptions}
                    styles={selectStyles}
                    onChange={handleMerchantAccountChange}
                    value={selectedMerchantAccount}
                  />
                )}
              </Field>
              <ErrorMessage
                name="merchantStore"
                component="span"
                className="error-message"
              />
            </div>
          </div>



          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="name">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Store Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="name"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <Field name="storeManager">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Store Manager"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="storeManager"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          {/* <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="email">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="email"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <Field name="phone">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="phone"
                component="span"
                className="error-message"
              />
            </div>
          </div> */}

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="address.street">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Street"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.street"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <Field name="address.city">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="City"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.city"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="address.state">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="State"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.state"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <Field name="address.postcode">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Postcode"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.postcode"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="address.country">
                {({ field }) => (
                  <AsyncSelect
                    placeholder="Select country..."
                    name="address.country"
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCountryOptions}
                    styles={selectStyles}
                    onChange={handleCountryChange}
                    value={country}
                  />
                )}
              </Field>
              <ErrorMessage
                name="address.country"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="products">
                {({ field }) => (
                  <AsyncSelect
                    isMulti
                    placeholder="Select Products..."
                    name="products"
                    {...field}
                    cacheOptions
                    defaultOptions={productOptions}
                    loadOptions={loadProductOptions}
                    styles={selectStyles}
                    onChange={handleProductChange}
                    value={storeProducts}
                  />
                )}
              </Field>
              <ErrorMessage
                name="products"
                component="span"
                className="error-message"
              />
            </div>
          </div>
          <div className="w-full flex gap-5">
            <div className="flex flex-row justify-betweenform-group w-full">

              <div className="w-full flex-column">
                <Field name="staff">
                  {({ field }) => (
                    <AsyncSelect
                      isMulti
                      placeholder="Select Staffs..."
                      name="staff"
                      {...field}
                      cacheOptions
                      defaultOptions={staffOptions}
                      loadOptions={loadStaffOptions}
                      styles={selectStyles}
                      onChange={handleStaffChange}
                      value={storeStaffs}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="staff"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
          </div>

          <div className="form-group w-full">
            <label className="label" htmlFor="active">
              Active:
            </label>
            <Field name="active" type="checkbox">
              {({ field }) => (
                <FormControlLabel
                  disabled={currentPortal != "Administrator"}
                  control={<Switch {...field} checked={field.value} />}
                  label={field.value ? "Active" : "Inactive"}
                />
              )}
            </Field>
            <ErrorMessage
              name="active"
              component="span"
              className="error-message"
            />
          </div>


          {/* <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <Field name="role">
                {({ field }) => (
                  <AsyncSelect
                    placeholder="Select user role..."
                    name="role"
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadRoleOptions}
                    styles={selectStyles}
                    onChange={handleRoleChange}
                    value={role}
                  />
                )}
              </Field>
              <ErrorMessage
                name="role"
                component="span"
                className="error-message"
              />
            </div>
          </div> */}
        </div>
        <div className="form-actions-wrap">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="submit-button"
          >
            Submit
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default MerchantStoreForm;
