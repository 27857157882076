import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { React, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";

const RedemptionTable = ({ redemptions }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [filteredRedemptions, setFilteredRedemptions] = useState([]);

  useEffect(() => {
    redemptions && setFilteredRedemptions(redemptions);
    console.log(redemptions);
  }, [redemptions]);

  const navigate = useNavigate();

  const rows = filteredRedemptions.reduce(
    (acc, currVal) => [
      ...acc,
      {
        id: currVal._id,
        vehicle: `${currVal.vehicle.vin} (${currVal.vehicle.rego}) `,
        product: currVal.product.name,
        merchantStore: currVal.merchantStore.name,
        merchantUser: `${currVal.merchantUser?.firstName} ${currVal.merchantUser?.lastName}`,
        redemptionDate: new Date(currVal.redemptionDateUTC).toLocaleDateString(),
        invoiceStatus: currVal.invoiceStatus,
        verified: currVal.verified
      }
    ],
    []
  );

  const columns = [
    { field: "invoiceStatus", headerName: "Invoice Status", minWidth: 50 },
    { field: "vehicle", headerName: "Vehicle", minWidth: 250 },
    { field: "product", headerName: "Product", minWidth: 250 },
    { field: "merchantStore", headerName: "Merchant Store", minWidth: 150 },
    { field: "merchantUser", headerName: "Merchant User", minWidth: 150 },
    { field: "redemptionDate", headerName: "Redemption Date", minWidth: 150 },
    {
      field: "verified",
      headerName: "Verified?",
      minWidth: 50,
      renderCell: (params) => (
        <IconButton>
          {params.value ? <CheckBoxIcon sx={{ color: "green" }} /> : <CancelIcon sx={{ color: "red" }} />}
        </IconButton>
      )
    }
  ];

  useEffect(() => {
    const filteredList = redemptions.filter((redemption) => {
      const redemptionDateUTC = new Date(redemption.redemptionDateUTC);
      const from = fromDate ? new Date(fromDate) : null;
      const to = toDate ? new Date(toDate) : null;

      if (!!from && !!to) {
        if (redemptionDateUTC >= from && redemptionDateUTC <= to) return redemption;
      }
      if (!!from && !to && redemptionDateUTC >= from) return redemption;
      if (!!to && !from && redemptionDateUTC <= to) return redemption;
      if (!from && !to) return redemption;
    }).sort((a, b) => new Date(b.createdOnUTC) - new Date(a.createdOnUTC));

    setFilteredRedemptions(filteredList);
  }, [fromDate, toDate]);

  const handleEvent = (params) => navigate(`/redemption/${params.row.id}`);

  return (
    <div className="w-full flex flex-column items-center text-center">
      <Typography variant="h6">Filter by a custom redemption date range</Typography>
      <br />
      <div className="w-full flex justify-evenly gap-4 mb-4">
        <Box sx={{ marginBottom: "4px" }}>
          From:{" "}
          <DatePicker
            variant="inline"
            dateFormat="dd/MM/yyyy"
            id="date-picker-fromDate"
            margin="normal"
            label="From date"
            placeholderText="dd/mm/YYYY"
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            maxDate={toDate || ""}
          />
        </Box>
        <div>
          To:{" "}
          <DatePicker
            variant="inline"
            dateFormat="dd/MM/yyyy"
            id="date-picker-toDate"
            margin="normal"
            label="To date"
            placeholderText="dd/mm/YYYY"
            selected={toDate}
            onChange={(date) => setToDate(date)}
            minDate={fromDate || ""}
          />
        </div>
        <Button
          variant="contained"
          color="error"
          disabled={!fromDate && !toDate}
          onClick={() => {
            setToDate("");
            setFromDate("");
          }}
        >
          Reset
        </Button>
      </div>
      {rows.length != 0 ? (
        <div className="flex flex-column flex-wrap justify-center items-center w-full">
          <DataGrid onRowClick={handleEvent} rows={rows} columns={columns} />
        </div>
      ) : (
        <p className="error">No records found</p>
      )}
    </div>
  );
};

export default RedemptionTable;
