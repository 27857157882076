import React, { useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from "@mui/material";
import { api } from "../../utils/api";
import CustomSnackbar from "../../components/CustomSnackbar";

const MerchantStorePricing = () => {
  const [merchantStorePricingOption, setMerchantStorePricingOption] = useState([]);
  const [merchantStoreOption, setMerchantStoreOption] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [selectedMerchantStores, setSelectedMerchantStores] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ message: "", variant: "" });

  useEffect(() => {
    const fetchData = async () => {
      await fetchMerchantStorePricings();
      await fetchProducts();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchMerchantStores();
    };
    fetchData();
  }, [merchantStorePricingOption]);

  const fetchMerchantStorePricings = async () => {
    try {
      const response = await api("v1/merchantStorePricing", "GET");
      if (response.data.success) {
        const merchantStorePricingOptions = response.data.data.allMerchantStorePricings;
        setMerchantStorePricingOption(merchantStorePricingOptions);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const fetchMerchantStores = async () => {
    try {
      const response = await api("v1/merchantStore", "GET");
      if (response.data.success) {
        const merchantStoreOptions = response.data.data.allMerchantStores;
        setMerchantStoreOption(merchantStoreOptions);

        // Log for debugging
        console.log("Fetched Merchant Stores:", merchantStoreOptions);
        console.log("Fetched Merchant Store Pricing Options:", merchantStorePricingOption);

        // Select merchant stores without any pricing options by default
        const storesWithoutPricing = merchantStoreOptions.filter(store =>
          !merchantStorePricingOption.some(option => option.merchantStore?._id === store._id)
        );
        setSelectedMerchantStores(storesWithoutPricing.map(store => store._id));
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await api("v1/product", "GET");
      if (response.data.success) {
        const productOptions = response.data.data.allProducts;
        setProductOption(productOptions);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const handlePricingChange = async (storeId, productId, price, adminFee) => {
    setLoading(true);
    try {
      const existingRecordIndex = merchantStorePricingOption.findIndex(option => option.merchantStore?._id === storeId && option.product._id === productId);
      const dataToSend = {
        'merchantStore': storeId,
        'product': productId,
        'price': price,
        'adminFee': adminFee || 5
      };

      let response = null;
      if (price > 0) { // Ensure price is not negative
        if (existingRecordIndex !== -1) {
          // Update existing record
          const existingRecord = merchantStorePricingOption[existingRecordIndex];
          response = await api(`v1/merchantStorePricing/${existingRecord._id}`, "PATCH", dataToSend);

          // Existing Pricing Option updated, update the current options state with the new values
          setMerchantStorePricingOption(prevOptions =>
            prevOptions.map(option =>
              option._id === response.data.data.merchantStorePricing?._id ? response.data.data.merchantStorePricing : option
            )
          );
          setSnackbar({ variant: "success", message: `Pricing Option Updated. Price: ${price}, Admin Fee: ${adminFee} for ${storeId}` });

        } else {
          // Add new pricing option
          response = await api("v1/merchantStorePricing", "POST", dataToSend);

          // New pricing added, update the current options state with the new pricing added
          setMerchantStorePricingOption(prevOptions => [...prevOptions, response.data.data.newMerchantStorePricing]);
          console.log("SET NEW ENTRY", merchantStorePricingOption);
          setSnackbar({ variant: "success", message: `Pricing Option Created. Price: ${price}, Admin Fee: ${adminFee} for ${storeId}` });

        }
      } else {
        if (existingRecordIndex !== -1) {
          // Delete existing record if price is set to 0
          const existingRecord = merchantStorePricingOption[existingRecordIndex];
          response = await api(`v1/merchantStorePricing/${existingRecord._id}`, "DELETE");
          setMerchantStorePricingOption(prevOptions =>
            prevOptions.filter(option => option._id !== existingRecord._id)
          );
          setSnackbar({ variant: "success", message: "Pricing Option Deleted!" });
        }
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const handleInputChange = (storeId, productId, field, value) => {
    setMerchantStorePricingOption(prevOptions =>
      prevOptions.map(option =>
        option.merchantStore?._id === storeId && option.product._id === productId
          ? { ...option, [field]: value }
          : option
      )
    );
  };

  const handleMerchantStoreSelectChange = (selected) => {
    if (selected.includes('all')) {
      if (selected.length === merchantStoreOption.length + 1) {
        // If all options including "ALL" are selected, deselect all
        setSelectedMerchantStores([]);
      } else {
        // Select all options including "ALL"
        setSelectedMerchantStores(['all', ...merchantStoreOption.map(store => store._id)]);
      }
    } else {
      // Remove "ALL" if it's present and any individual option is deselected
      if (selected.length < merchantStoreOption.length) {
        setSelectedMerchantStores(selected.filter(option => option !== 'all'));
      } else {
        setSelectedMerchantStores(selected);
      }
    }
  };

  const filteredMerchantStores = selectedMerchantStores.length ?
    merchantStoreOption.filter(store => selectedMerchantStores.includes(store._id)) :
    merchantStoreOption;

  const filteredProducts = selectedProducts.length ?
    productOption.filter(product => selectedProducts.includes(product._id)) :
    productOption;

  return (
    <div className="page merchantStorePricing w-full flex flex-column items-center p-4 overflow-auto">
      {loading && <GlobalLoadingSpinner />}

      <div className="pageTitle flex flex-col w-full text-center lg:flex-row lg:text-left lg:justify-between mb-8">
        <h2>Store Pricing Options</h2>
      </div>

      <div className="filters mb-4 flex flex-wrap gap-4">
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="merchant-store-select-label">Merchant Store</InputLabel>
          <Select
            labelId="merchant-store-select-label"
            multiple
            value={selectedMerchantStores}
            onChange={(e) => handleMerchantStoreSelectChange(e.target.value)}
            renderValue={(selected) => {
              const selectedStores = selected.map(id => merchantStoreOption.find(store => store._id === id)?.name);
              if (selected.includes('all')) {
                return 'All';
              } else if (selectedStores.length <= 2) {
                return selectedStores.join(', ');
              } else {
                return selectedStores.slice(0, 2).join(', ') + ` +${selectedStores.length - 2}`;
              }
            }}
          >
            <MenuItem value="all">
              <Checkbox checked={selectedMerchantStores.includes('all')} />
              <ListItemText primary="ALL" />
            </MenuItem>
            {merchantStoreOption.map(store => (
              <MenuItem key={store._id} value={store._id}>
                <Checkbox checked={selectedMerchantStores.includes(store._id)} />
                <ListItemText primary={store.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="product-select-label">Product</InputLabel>
          <Select
            labelId="product-select-label"
            multiple
            value={selectedProducts}
            onChange={(e) => setSelectedProducts(e.target.value)}
            renderValue={(selected) => selected.map(id => productOption.find(product => product._id === id)?.name).join(', ')}
          >
            {productOption.map(product => (
              <MenuItem key={product._id} value={product._id}>
                <Checkbox checked={selectedProducts.indexOf(product._id) > -1} />
                <ListItemText primary={product.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="table-container w-full">
        <table className="store-pricing-table w-full">
          <thead>
            <tr className="flex w-full flex-nowrap border">
              <th className="min-w-[400px] border p-1">Merchant Store</th>
              {filteredProducts.map((product) => (
                <th className="border p-1 w-[200px]" key={product._id}>{product.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredMerchantStores.map((store) => (
              <tr key={store._id} className="flex w-full flex-nowrap border-bottom">
                <td className="w-[400px] border-x p-4">{store.name}</td>
                {filteredProducts.map((product) => {
                  const currentOption = merchantStorePricingOption.find(option => option.merchantStore?._id === store._id && option.product._id === product._id) || {};
                  return (
                    <td className="border w-[200px]" key={product._id}>
                      <div className="flex flex-column justify-center items-center gap-2">
                        <div className="flex flex-column divide-y">
                          <div className="flex gap-2 justify-between items-center">
                            <label>Price</label>
                            <input
                              className="max-w-[100px]"
                              type="number"
                              placeholder="Price"
                              value={currentOption.price || 0} // Default price to 0
                              onChange={(e) => {
                                const price = parseFloat(e.target.value);
                                handleInputChange(store._id, product._id, 'price', price);
                                handlePricingChange(store._id, product._id, price, currentOption.adminFee || 5);
                              }}
                            />
                          </div>
                          <div className="flex gap-2 justify-between items-center">
                            <label>Admin Fee</label>
                            <input
                              className="max-w-[100px]"
                              type="number"
                              placeholder="Admin Fee (%)"
                              value={currentOption.adminFee || 5} // Default admin fee to 5
                              min={5} // Ensure admin fee is not less than 5
                              onChange={(e) => {
                                const adminFee = parseFloat(e.target.value);
                                handleInputChange(store._id, product._id, 'adminFee', adminFee);
                                handlePricingChange(store._id, product._id, currentOption.price || 0, adminFee);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {snackbar && <CustomSnackbar {...snackbar} />}
    </div>
  );
};

export default MerchantStorePricing;
