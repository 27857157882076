import React, { useState, useEffect } from "react";
import { api } from "../../../utils/api";
import { useParams } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import ModalPopup from "../../../components/ModalPopup";
import PartnerUserForm from "./Forms/PartnerUserForm";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditPartnerUserForm from "./Forms/EditPartnerUserForm";
import GlobalLoadingSpinner from "../../../components/GlobalLoadingSpinner";
import BasicUserInfo, { DATA_GRID_STYLES } from "../../../components/BasicUserInfo";
import { COLORS } from "../../../utils/colors";

const PartnerUserSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editPartnerUserState, setEditPartnerUserState] = useState(false);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser")) || null;
  const selectedPortal = sessionStorage.getItem("selectedPortal") || null;

  // const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    //    const fetchCompanyDetails = async () => {
    //     try {
    //       const response = await api('v1/partner/company/'+params.idPartnerCompany, 'GET', null);
    //       if (response.data.success) {
    //         // console.log(response.data.data.partnerCompany);
    //         setSelectedCompany(response.data.data.partnerCompany);
    //         setLoading(false);
    //       }
    //     } catch (error) {
    //       console.error('API error:', error);
    //     }
    //   };

    // fetchCompanyDetails();

    const fetchUserDetails = async () => {
      try {
        const response = await api("v1/user/" + params.idUser, "GET", null);
        if (response.data.success) {
          // console.log(response.data.data.partnerCompany);
          setSelectedUser(response.data.data.partnerUser);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleDeleteUserConfirm = async () => {
    setLoading(true);
    try {
      const response = await api(
        `v1/partner/company/${params.idPartnerCompany}/user/${selectedUser._id}`,
        "DELETE",
        null
      );
      if (response.data.success) {
        navigate("/partner/" + params.idPartnerCompany + "?tab=partnerUsers");
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const resendWelcomeEmail = async () => {
    setLoading(true);
    const dataToSend = {
      idUser: selectedUser._id
    };

    try {
      const response = await api("v1/user/sendWelcomeEmail", "POST", dataToSend);
      if (response.data.success) {
        alert("Email sent to Partner User!");
        // window.location.replace(`/partner/${params.idPartnerCompany}/user/${selectedUser._id}`);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false); //once api call is finished.
  };

  const handleDeleteUserCancel = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };
  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editPartnerUser = () => {
    setEditPartnerUserState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadUserDetail = (updateCompanyDetail) => {
    setEditPartnerUserState((prevValue) => !prevValue);
    setSelectedUser(updateCompanyDetail);
  };

  console.log("editPartnerUserState", editPartnerUserState);
  return (
    selectedUser && (
      <div className="page partner-user-single flex flex-column items-center min-h-h-screen p-4 gap-4 w-full">
        {loading && <GlobalLoadingSpinner />}
        {/* {editPartnerUserState && (
          <EditPartnerUserForm
            partnerUser={selectedUser}
            reloadUserDetail={reloadUserDetail}
            editPartnerUser={editPartnerUser}
          />
        )} */}

        {/* // Edit Modal HTML */}

        <ModalPopup
          showModal={editPartnerUserState}
          handleShowModal={editPartnerUser}
          popupTitle={"Edit Partner User"}
          popupContent={<PartnerUserForm partnerUser={selectedUser} callback={reloadUserDetail} />}
          cancelButtonText=""
          confirmButtonText=""
          confirmCallbackAction=""
        />

        {/* // Delete User Confimation Modal */}

        <div className="pageTitle flex justify-between w-full">
          <h2>
            {selectedUser.firstName} {selectedUser.lastName}
          </h2>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {selectedUser && (
          <div className="flex justify-evenly w-full flex-wrap">
            <div className="w-full max-w-[500px] self-start">
              <div className={DATA_GRID_STYLES}>
                <span className="font-semibold border-none">Email</span>
                <span className="flex flex-column font-semibold border-none">
                  <a href={"mailto:" + selectedUser.email}>{selectedUser.email}</a>
                  {selectedPortal == "Administrator" && (
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded"
                      onClick={resendWelcomeEmail}
                    >
                      Resend Welcome Email
                    </button>
                  )}
                </span>
                <span className="font-semibold">Phone</span>
                <span className="font-semibold">
                  {selectedUser.phone ? (
                    <a href={"tel:" + selectedUser.phone}>{selectedUser.phone}</a>
                  ) : (
                    "No phone available"
                  )}
                </span>
                <span>STREET</span>
                <span>{selectedUser?.address?.street || "-"}</span>
                <span>CITY</span>
                <span>{selectedUser?.address?.city || ""}</span>
                <span>STATE</span>
                <span>{selectedUser?.address?.state || ""}</span>
                <span>POSTCODE</span>
                <span>{selectedUser?.address?.postcode || ""}</span>
                <span>COUNTRY</span>
                <span>{selectedUser?.address?.country.label || ""}</span>
                {selectedUser?.role && (
                  <>
                    <span>Role</span>
                    <span>{selectedUser.role}</span>
                  </>
                )}
                <span className="font-semibold">Status</span>
                <span className="flex flex-column box-content">{selectedUser.active ? "Active" : "Not Active"}</span>
              </div>

              {(selectedPortal == "Administrator" || currentUser?.role?.toLowerCase() != "sales") && (
                <div className="actionItems flex gap-4">
                  <Button
                    sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                    className="self-start m-4"
                    variant="contained"
                    onClick={editPartnerUser}
                  >
                    Edit Partner User
                  </Button>
                  {/* <Button className='self-start bg-red-600 m-4' variant="contained" color="error" onClick={toggleDisplayDeleteConfirm}>Delete Partner User</Button> */}
                  <Button
                    className="self-start bg-red-600 m-4"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      toggleDisplayDeleteConfirm(true);
                    }}
                  >
                    Delete Partner User
                  </Button>
                </div>
              )}
              <ModalPopup
                showModal={deleteConfirmationIsOpen}
                handleShowModal={toggleDisplayDeleteConfirm}
                popupTitle={"Delete User"}
                popupContent={
                  <div>
                    Are you sure you want to delete user:
                    <strong>
                      <em>
                        {selectedUser.firstName} {selectedUser.lastName}
                      </em>
                    </strong>
                    ?
                  </div>
                }
                cancelButtonText={"Cancel"}
                confirmButtonText={"Delete"}
                confirmCallbackAction={handleDeleteUserConfirm}
              />
            </div>
            <div className="flex flex-column self-start">
              <h3>Associated Partner Companies</h3>
              <div className="flex justify-evenly">
                {selectedUser.partnercompanies.map((item) =>
                  <Button className="self-center m-2 p-2" variant="contained" onClick={() => navigate("/partner/" + item.value)}>
                    {item.label}
                  </Button>
                )}
              </div>

            </div>
          </div>
        )}
      </div>
    )
  );
};

export default PartnerUserSingle;
