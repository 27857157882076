import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Button, Link } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import SubscriptionForm from "./Forms/SubscriptionForm";
import { useNavigate } from "react-router-dom";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { DATA_GRID_STYLES } from "../../components/BasicUserInfo";
import { COLORS } from "../../utils/colors";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalImage from "react-modal-image";
import RedemptionHistory from "../Customer/RedemptionHistory";

const SubscriptionSingle = () => {
    let navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [editSubscriptionState, setEditSubscriptionState] = useState(false);

    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
    const [redemptionHistory, setRedemptionHistory] = useState([]);
    const [redemptionImages, setRedemptionImages] = useState({});

    useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            try {
                const response = await api("v1/subscription/" + params.idSubscription, "GET", null);
                console.log(response);
                if (response.data.success) {
                    const imagePromises = response.data.data.subscription.redemptions.map(redemption =>
                        api(`v1/fetchFileFromCloud/url/${encodeURIComponent(redemption.image)}`, "GET")
                    );
                    const imageResponses = await Promise.all(imagePromises);
                    const images = {};
                    imageResponses.forEach((res, index) => {
                        images[response.data.data.subscription.redemptions[index]._id] = res.data.data.url;
                    });

                    setRedemptionImages(images);


                    setSelectedSubscription(response.data.data.subscription);
                    setLoading(false);
                }
            } catch (error) {
                console.error("API error:", error);
            }
        };

        fetchSubscriptionDetails();
    }, []);

    const handleDeleteSubscriptionConfirm = async () => {
        setLoading(true);
        try {
            const response = await api("v1/subscription/" + selectedSubscription?._id, "DELETE", null);
            if (response.data.success) {
                window.location.replace("/subscription");
            }
        } catch (error) {
            console.error("API error:", error);
        }
        toggleDisplayDeleteConfirm();
        setLoading(false); //once api call is finished.
    };

    const toggleDisplayDeleteConfirm = () => {
        setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
    };

    const editSubscription = () => {
        setEditSubscriptionState((prevValue) => !prevValue);
        // setOpenModal(true);
    };

    const reloadSubscriptionDetail = (updateSubscriptionDetail) => {
        setEditSubscriptionState((prevValue) => !prevValue);
        setSelectedSubscription(updateSubscriptionDetail);
    };

    function formatDate(dateString) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

        return formattedDate;
    }

    return (
        selectedSubscription && (
            <div className="page partner-user-single w-full flex flex-column items-center min-h-h-screen p-8 gap-8">
                {loading && <GlobalLoadingSpinner />}

                {/* Page Title */}
                <div className="pageTitle w-full flex justify-between">
                    <h2>Subscription Details</h2>
                    <div>
                        <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                </div>

                {/* Subscription Single Content */}
                <div className="w-full flex flex-wrap gap-4 justify-center">
                    {selectedSubscription?.qrcode && (
                        <div className="subscriptionQrCode flex flex-column w-full justify-center items-center">
                            <img src={selectedSubscription?.qrcode} />
                            {/* <p>ID: {selectedSubscription._id}</p> */}
                        </div>
                    )}
                    <div className="flex self-center">
                        <h4 className="font-bold"></h4>
                        <div className={DATA_GRID_STYLES}>
                            <span>Start Date</span>
                            <span>
                                {selectedSubscription.startDateUTC
                                    ? formatDate(selectedSubscription.startDateUTC)
                                    : "n/a"}
                            </span>
                            <span>Valid Until: </span>
                            <span>
                                {selectedSubscription.validUntilDateUTC
                                    ? formatDate(selectedSubscription.validUntilDateUTC)
                                    : "n/a"}
                            </span>
                            <span>Next Billing Date: </span>
                            <span>
                                {selectedSubscription.nextBillingDateUTC
                                    ? formatDate(selectedSubscription.nextBillingDateUTC)
                                    : "n/a"}
                            </span>
                            <span>Created On</span>
                            <span>
                                {selectedSubscription.createdOnUTC
                                    ? formatDate(selectedSubscription.createdOnUTC)
                                    : "n/a"}{" "}
                            </span>
                            <span>Active: </span>
                            <span>{selectedSubscription.active ? "Yes" : "No"} </span>
                        </div>

                    </div>

                    {sessionStorage.getItem("selectedPortal") == "Administrator" && (
                        <div className="actionItems w-full flex gap-4 justify-center">
                            <Button
                                sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                                className="self-start m-4"
                                variant="contained"
                                onClick={editSubscription}
                            >
                                Edit Subscription
                            </Button>
                            <Button
                                className="self-start bg-red-600 m-4"
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    toggleDisplayDeleteConfirm(true);
                                }}
                            >
                                Delete Subscription
                            </Button>
                        </div>
                    )}

                    <div className="flex w-full gap-4 flex-wrap justify-start">

                        {selectedSubscription.vehicle && (
                            <div className="w-[350px] sm:w-[480px] cursor-pointer">
                                <h4 className="font-bold">Vehicle</h4>
                                <div
                                    onClick={() => navigate(`/vehicle/${selectedSubscription.vehicle._id}`)}
                                    className={`${DATA_GRID_STYLES} cursor-pointer`}
                                >
                                    <span>Rego</span>
                                    <span>{selectedSubscription.vehicle.rego}</span>
                                    <span>VIN</span>
                                    <span>{selectedSubscription.vehicle.vin}</span>
                                    <span>Make</span>
                                    <span>{selectedSubscription.vehicle.make}</span>
                                    <span>Model</span>
                                    <span>{selectedSubscription.vehicle.rego}</span>
                                    <span>Model Year</span>
                                    <span>{selectedSubscription.vehicle.modelYear}</span>
                                    <span>Color</span>
                                    <span>{selectedSubscription.vehicle.color}</span>
                                    <span>AGE</span>
                                    <span>{selectedSubscription.vehicle.age}</span>
                                    <span>ACTIVATED</span>
                                    <span>{selectedSubscription.vehicle.active ? "Yes" : "No"}</span>
                                    <span>CREATED ON</span>
                                    <span>
                                        {selectedSubscription.vehicle.createdOnUTC
                                            ? formatDate(selectedSubscription.vehicle.createdOnUTC)
                                            : "n/a"}
                                    </span>
                                </div>

                            </div>
                        )}


                        {selectedSubscription.product && (
                            <div className="w-[350px] sm:w-[480px] cursor-pointer">
                                <h4 className="font-bold">Product</h4>
                                <div className={DATA_GRID_STYLES}>
                                    <span>Name</span>
                                    <span>{selectedSubscription.product.name}</span>
                                    <span>Type</span>
                                    <span>{selectedSubscription.product.type}</span>
                                    <span>Subscription Duration</span>
                                    <span>
                                        {selectedSubscription.product.subscriptionDuration}{" "}
                                        {parseInt(selectedSubscription.product.subscriptionDuration) == 1
                                            ? "month"
                                            : "months"}
                                    </span>
                                    <span>Price</span>
                                    <span>{selectedSubscription.product.price}</span>
                                    <span>RRP</span>
                                    <span>{selectedSubscription.product.rrp}</span>
                                </div>
                            </div>
                        )}

                        {selectedSubscription.vehicle &&
                            <div className="cursor-pointer">
                                <RedemptionHistory vehicles={selectedSubscription.vehicle} />
                            </div>
                        }
                    </div>

                </div>

                {/* // Edit Modal HTML */}

                <ModalPopup
                    showModal={editSubscriptionState}
                    handleShowModal={editSubscription}
                    popupTitle={"Edit Subscription"}
                    popupContent={
                        <SubscriptionForm
                            selectedSubscription={selectedSubscription}
                            callback={reloadSubscriptionDetail}
                            cancelModal={editSubscription}
                        />
                    }
                    cancelButtonText=""
                    confirmButtonText=""
                    confirmCallbackAction=""
                />

                {/* Delete Subscription Confimation Modal */}
                <ModalPopup
                    showModal={deleteConfirmationIsOpen}
                    handleShowModal={toggleDisplayDeleteConfirm}
                    popupTitle={"Delete Subscription"}
                    popupContent={<div>Are you sure you want to delete this subscription?</div>}
                    cancelButtonText={"Cancel"}
                    confirmButtonText={"Delete"}
                    confirmCallbackAction={handleDeleteSubscriptionConfirm}
                />
            </div>
        )
    );
};

export default SubscriptionSingle;
