/*
Form to add product. Used by Partner Company to add Product or Admin to Add them under Company
*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Box, Button, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FormControlLabel, Switch, Select, MenuItem, FormControl, Input, InputLabel, Chip } from "@mui/material";
import { FieldArray } from "formik";

import { api } from "../../../utils/api";
// import countriesData from "../../../../contants/countries.json";

const validationSchema = Yup.object().shape({
  active: Yup.boolean().required("Active field is required"),
  name: Yup.string().required("Product Name is required"),
  type: Yup.string().required("Product Type is required"),
  subscriptionDuration: Yup.number()
    .required("Subscription Duration is required")
    .positive("Subscription Duration must be a positive number")
    .integer("Subscription Duration must be an integer")
    .min(1, "Subscription Duration must be at least 1"),
  packageCount: Yup.number()
    .required("Package Count is required")
    .positive("Package Count must be a positive number")
    .integer("Package Count must be an integer")
    .min(1, "Package Count must be at least 1"),
  price: Yup.number()
    .typeError("Price must be a valid number")
    .required("Price is required")
    .positive("Price must be a positive number")
    .min(0, "Price must be greater than or equal to 0"),
  // redemptionPrice: Yup.number()
  //   .typeError("Redemption Price must be a valid number")
  //   .required("Redemption Price is required")
  //   .positive("Redemption Price must be a positive number")
  //   .min(0, "Price must be greater than or equal to 0"),
  rrp: Yup.number()
    .typeError("RRP must be a valid number")
    .required("RRP is required")
    .positive("RRP must be a positive number")
    .min(0, "RRP must be greater than or equal to 0"),
  rrp: Yup.number().required("RRP is required"),
  requiredFields: Yup.array().required("At least one field is required").min(1, "At least one field is required"),
});




const ProductForm = ({
  product, // if this is set we assume Edit User else Add User 
  defaultCompany,
  callback
}) => {
  const [productType, setProductType] = useState(product?.type ?? '');
  useEffect(() => {
    //
  }, []);



  const handleSubmit = async (values, { setSubmitting }) => {
    const dataToSend = {
      name: values.name,
      description: values.description,
      image: values.image,
      type: values.type,
      subscriptionDuration: values.subscriptionDuration || 48,
      packageCount: values.packageCount || 1,
      price: values.price,
      // redemptionPrice: values.redemptionPrice,
      rrp: values.rrp,
      requiredFields: values.requiredFields,
      active: values.active,
    };

    try {
      let response;
      if (product) {
        // Edit Product
        response = await api(`v1/product/${product._id}`, 'PATCH', dataToSend);
      } else {
        // Add Product
        response = await api(`v1/product`, 'POST', dataToSend);
      }

      if (response.data.success) {
        console.log("Product Added/Updated", response.data.data.product);
        if (product) {
          callback(response.data.data.product);
        } else {
          callback(response.data.data.newProduct);
        }
      } else {
        response?.data?.message && alert(response.data.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }

    setSubmitting(false);
  };



  return (
    <Formik
      initialValues={{
        name: product ? product.name : '',
        image: product ? product.image : '',
        description: product ? product.description : '',
        type: product ? product.type : '',
        subscriptionDuration: product ? product.subscriptionDuration : 48,
        packageCount: product ? product.packageCount : 1, // Add packageCount field
        // redemptionPrice: product ? product.redemptionPrice : '',
        price: product ? product.price : '',
        rrp: product ? product.rrp : '',
        requiredFields: product ? product.requiredFields : [],
        active: product ? product.active : false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh] mt-2">
          <div className="form-input-wrap form-group w-full flex flex-column gap-4">

            <div className="w-full flex gap-5">

              {/* Product Name */}
              <div className="form-group w-full">
                {/* <label className="label" htmlFor="name">
                Product Name:
              </label> */}
                <Field name="name">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Product Name"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="name"
                  component="span"
                  className="error-message"
                />
              </div>

            </div>

            <div className="w-full flex gap-5">

              {/* Product Image */}
              <div className="form-group w-full">
                {/* <label className="label" htmlFor="image">
                Product Image:
              </label> */}
                <Field name="image">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Product Image URL"
                      type="url"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="image"
                  component="span"
                  className="error-message"
                />
              </div>

            </div>

            <div className="w-full flex gap-5">

              {/* Product Description */}
              <div className="form-group w-full">
                {/* <label className="label" htmlFor="description">
                Product Description:
              </label> */}
                <Field name="description">
                  {({ field }) => (
                    <TextField
                      {...field}
                      label="Product Description"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="description"
                  component="span"
                  className="error-message"
                />
              </div>

            </div>

            <div className="w-full flex gap-5">
              {/* Product Type */}
              <div className="form-group w-full">
                <label className="label" htmlFor="type">
                  Product Type:
                </label>
                <Field name="type">
                  {({ field }) => (
                    <Select
                      {...field}
                      label="Product Type"
                      variant="outlined"
                      placeholder="Product Type"
                      fullWidth
                      onChange={(event) => {
                        setFieldValue("type", event.target.value);
                        setProductType(event.target.value);
                      }}
                    >
                      <MenuItem value="onceoff">Once Off</MenuItem>
                      <MenuItem value="subscription">Subscription</MenuItem>
                      <MenuItem value="package">Package</MenuItem>
                    </Select>
                  )}
                </Field>
                <ErrorMessage
                  name="type"
                  component="span"
                  className="error-message"
                />
              </div>
            </div>


            <div className="w-full flex gap-5">
              {/* Subscription Duration */}
              {productType != "onceoff" && <div className="form-group w-full">
                <label className="label" htmlFor="subscriptionDuration">
                  {/* Duration (in Months): */}
                </label>
                <Field name="subscriptionDuration">
                  {({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label="Subscription Duration (Months)"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="subscriptionDuration"
                  component="span"
                  className="error-message"
                />
              </div>}

              {/* Package Count */}
              {productType == "package" && <div className="form-group w-full">
                <label className="label" htmlFor="packageCount">
                  {/* Package Count: */}
                </label>
                <Field name="packageCount">
                  {({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label="Package Count"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="packageCount"
                  component="span"
                  className="error-message"
                />
              </div>}

            </div>

            <div className="w-full flex gap-5">

              {/* Subscription Price */}
              <div className="form-group w-full">
                <label htmlFor="price">Price:</label>
                <Field name="price">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="price"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="Enter price"
                    />
                  )}
                </Field>
                <ErrorMessage name="price" component="span" className="error-message" />
              </div>

              {/* Redemption Price */}
              {/* <div className="form-group w-full">
                <label htmlFor="redemptionPrice">Redemption Price:</label>
                <Field name="redemptionPrice">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="redemptionPrice"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="Redemption Price"
                    />
                  )}
                </Field>
                <ErrorMessage name="redemptionPrice" component="span" className="error-message" />
              </div> */}

              {/* Subscription RRP */}
              <div className="form-group w-full">
                <label htmlFor="rrp">RRP:</label>
                <Field name="rrp">
                  {({ field }) => (
                    <TextField
                      {...field}
                      id="rrp"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="Enter RRP"
                    />
                  )}
                </Field>
                <ErrorMessage name="rrp" component="span" className="error-message" />
              </div>

            </div>

            <div className="w-full flex gap-5">

              {/* Required Fields */}
              <div className="form-group w-full">
                {/* <label htmlFor="requiredFields">Required Fields:</label> */}
                <FieldArray name="requiredFields">
                  {({ form, push, remove }) => (
                    <FormControl fullWidth>
                      <InputLabel id="requiredFields-label">Select Fields</InputLabel>
                      <Select
                        multiple
                        variant="outlined"
                        labelId="requiredFields-label"
                        value={form.values.requiredFields}
                        onChange={(event) => {
                          form.setFieldValue("requiredFields", event.target.value);
                        }}
                        renderValue={(selected) => (
                          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} sx={{ m: 0.5 }} />
                            ))}
                          </Box>
                        )}
                      >
                        <MenuItem value="Basic Details">Basic Details</MenuItem>
                        <MenuItem value="Vehicle Details">Vehicle Details</MenuItem>
                        <MenuItem value="Address">Address</MenuItem>
                        {/*
                        <MenuItem value="Mobile Number">Mobile Number</MenuItem>
                        <MenuItem value="Shipping Address">Shipping Address</MenuItem>
                            */}
                      </Select>
                    </FormControl>
                  )}
                </FieldArray>
                <ErrorMessage
                  name="requiredFields"
                  component="span"
                  className="error-message"
                />
              </div>


            </div>
            <div className="w-full flex gap-5">
              {/* Product Status Fields */}
              <div className="form-group w-full">
                <label className="label" htmlFor="active">
                  Active:
                </label>
                <Field name="active" type="checkbox">
                  {({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label={field.value ? "Active" : "Inactive"}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="active"
                  component="span"
                  className="error-message"
                />
              </div>
            </div>

          </div>
          <div className="form-actions-wrap">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;
