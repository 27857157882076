import React from "react";
import { Button as MuiButton } from "@mui/material";
import { COLORS } from "../../utils/colors";

const Button = ({ handleClick, isSelected }) => (
  <MuiButton variant="contained" onClick={handleClick} color={isSelected ? "error" : "primary"}>
    {isSelected ? "Remove Product" : "Add Product"}
  </MuiButton>
);

const ProductCard = ({ product, isSelected, handleClick, isLandscape }) => {
  return isLandscape ? (
    <div className={`flex flex-row w-full justify-between items-center ${isSelected && "bg-blue"}`}>
      <div className="flex flex-row">
        <div className="p-4 bg-blue-100 w-[150px]"></div>
        <div>
          <h5 className="pl-4 font-bold text-left">{product.name}</h5>
          <p className="pl-4 text-center">{product.description}</p>
        </div>
      </div>
      <Button isSelected={isSelected} handleClick={handleClick} />
    </div>
  ) : (
    <div
      className={`flex flex-column pb-4 w-[250px] h-[450px] justify-between items-center text-center shadow box-border border product-card ${
        isSelected ? "bg-blue-100" : ""
      }`}
    >
      <div className="bg-blue-100 w-full h-[100px] px-2"></div>
      <h5 className="font-bold px-2">{product.name}</h5>
      <p className="px-2">{product.description}</p>
      <p className="px-2">{product.requiredFields.join(", ")}</p>
      <Button isSelected={isSelected} handleClick={handleClick} />
    </div>
  );
};

export default ProductCard;
