import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { api } from "../../utils/api";
import CustomerSubscriptionCard from "./CustomerSubscriptionCard";

const MySubscriptions = () => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const [mySubscriptions, setMySubscriptions] = useState([]);
  const [loading, setLoading] = useState([true]);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await api(`v1/subscription/customer/${currentUser._id}`, "GET");
      if (response.data.success) {
        const subscriptions = response.data.data.allSubscriptions.filter((element) => element !== null);
        setMySubscriptions(subscriptions);
        /*
        if (subscriptions.length > 0) {
          const updatedSubscriptions = await Promise.all(
            subscriptions.map(async (subscription) => {
              try {
                const vehicleResponse = await api(`v1/vehicle/${subscription.vehicle}`, "GET");
                const productResponse = await api(`v1/product/${subscription.product}`, "GET");
                const redemptionIds = subscription.redemptions; // Assuming vehicle.products is an array of product IDs
                const redemptionPromises = redemptionIds.map(async (redemptionId) => {
                  const fetchRedemptionInfo = await api("v1/redemption/" + redemptionId, "GET");
                  if (fetchRedemptionInfo.data.success) {
                    let redemption = fetchRedemptionInfo.data.data.redemption;

                    const fetchRedemptionMerchantUser = await api(`v1/merchantUser/${redemption.merchantUser}`, "GET");
                    const fetchRedemptionMerchantStore = await api(
                      `v1/merchantStore//${redemption.merchantStore._id}`,
                      "GET"
                    );
                    redemption.merchantUser = fetchRedemptionMerchantUser.data.success
                      ? fetchRedemptionMerchantUser.data.data.merchantUser
                      : null;
                    redemption.merchantStore = fetchRedemptionMerchantStore.data.success
                      ? fetchRedemptionMerchantStore.data.data.merchantStore
                      : null;

                    return redemption;
                  } else {
                    return null;
                  }
                });
                const updatedRedemptions = await Promise.all(redemptionPromises);
                if (vehicleResponse.data.success && productResponse.data.success) {
                  const { rego, vin } = vehicleResponse.data.data.vehicle;
                  const { name, type, subscriptionDuration, price, rrp, active } = productResponse.data.data.product;
                  return {
                    ...subscription,
                    vehicle: vehicleResponse.data.data.vehicle,
                    product: productResponse.data.data.product,
                    redemptions: updatedRedemptions
                  };
                }
              } catch (error) {
                console.error("API error:", error);
              }
              return subscription;
            })
          );
          setMySubscriptions(updatedSubscriptions);
        }
        */
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  return (
    <div className="page subscription flex flex-column items-center p-4 w-full">
      {loading && <GlobalLoadingSpinner />}
      <div className="pageTitle mb-6 text-center">
        <h2>Redeem your Subscription</h2>
        <span>Thank you for registering</span>
      </div>
      <div className="flex flex-col justify-center md:flex-row flex-wrap gap-4">
        {mySubscriptions.length > 0 ? mySubscriptions.map((subscription) => (
          <CustomerSubscriptionCard key={subscription._id} subscription={subscription} />
        )) : 'We could not find any active subscriptions linked to your account yet!'}
      </div>
    </div>
  );
};

export default MySubscriptions;
