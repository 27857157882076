import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InvoiceDetails from "../../utils/invoiceDetails";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button } from "@mui/material";
import { isObject } from "formik";

const TransactionSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactionCustomer, setTransactionCustomer] = useState(null);
  const [transactionProduct, setTransactionProduct] = useState(null);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const response = await api("v1/transaction/" + params.idTransaction, "GET", null);
        if (response.data.success) {
          console.log(response.data.data.transaction);
          setSelectedTransaction(response.data.data.transaction);

          const invoiceDetails = response.data.data.transaction.invoiceID
            ? await api(`v1/transaction/${params.idTransaction}/invoiceDetails`, "GET")
            : null;

          invoiceDetails && await api(`v1/transaction/${params.idTransaction}/updateInvoiceStatusAndPrice`, "PATCH", { invoiceStatus: invoiceDetails.data.data.invoiceDetails[0].Status, price: invoiceDetails.data.data.invoiceDetails[0].Total })


          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchTransactionDetails();
  }, []);

  useEffect(() => {
    const fetchTransactionCustomerDetails = async () => {
      if (selectedTransaction != null) {
        setTransactionCustomer(selectedTransaction.customer);
        // try {
        //   const response = await api("v1/customer/" + selectedTransaction.customer, "GET", null);
        //   if (response.data.success) {
        //     console.log(response.data.data);
        //     setTransactionCustomer(response.data.data.customer);
        //     setLoading(false);
        //   }
        // } catch (error) {
        //   console.error("API error:", error);
        // }
      }
    };
    const fetchTransaactionProductDetails = async () => {
      if (selectedTransaction != null) {
        setTransactionProduct(selectedTransaction.product);

        // try {
        //   const response = await api("v1/product/" + selectedTransaction.product, "GET", null);
        //   if (response.data.success) {
        //     console.log(response.data.data);
        //     setTransactionProduct(response.data.data.product);
        //     setLoading(false);
        //   }
        // } catch (error) {
        //   console.error("API error:", error);
        // }
      }
    };
    fetchTransactionCustomerDetails();
    fetchTransaactionProductDetails();
  }, [selectedTransaction]);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

    return formattedDate;
  }



  console.log(selectedTransaction);
  return (
    selectedTransaction && (
      <div className="page transaction-single w-full flex flex-column items-center min-h-h-screen p-4 gap-4">
        {loading && <GlobalLoadingSpinner />}

        <div className="flex w-full justify-between">
          <h2>Transaction Details </h2>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {/* Transaction Single Content */}
        <div className="w-full transactionSingleInfo">
          <div className="billingDetailsWrap flex items-center gap-4">
            <div className="flex gap-4">
              <div className="font-bold">Customer</div>
              {transactionCustomer && (
                <div>
                  <div>
                    <a
                      href="javascipt:void(0);"
                      onClick={() => navigate("/customer/" + transactionCustomer._id)}
                      className={
                        (transactionCustomer.active ? "active " : "bg-red-500 text-white p-2 rounded opacity-50") +
                        " flex flex-column"
                      }
                    >
                      <span>
                        {transactionCustomer.firstName}
                        {transactionCustomer.lastName}
                      </span>
                      <span>{transactionCustomer.email}</span>
                      <span>{transactionCustomer.phone}</span>
                      <span>
                        {transactionCustomer.address?.street}, {transactionCustomer.address?.city}{" "}
                        {transactionCustomer.address?.postcode} {transactionCustomer.address?.state}
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="billingDetailsWrap flex items-center gap-4">
            <div className="flex gap-4">
              <div className="font-bold">Product</div>
              {transactionProduct && (
                <div>
                  <a
                    href="javascript:void(0);"
                    onClick={() => navigate("/product/" + transactionProduct._id)}
                    className={
                      (transactionProduct.active ? "active " : "bg-red-500 text-white p-2 rounded opacity-50") +
                      " flex flex-column"
                    }
                  >
                    <span>{transactionProduct.name}</span>
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="billingDetailsWrap flex items-center gap-4">
            <div className="flex gap-4">
              <span className="font-bold">Transaction Details </span>
              {selectedTransaction.invoiceDetails && (
                <InvoiceDetails
                  invoiceDetail={selectedTransaction.invoiceDetails}
                  idTransaction={selectedTransaction._id}
                />
              )}
              {/* {JSON.stringify(selectedTransaction.invoiceDetails, null, 4)} */}
            </div>
          </div>

          <div className="billingDetailsWrap flex flex-column gap-4">
            <div className="flex gap-4">
              <span className="font-bold">Active </span>
              {selectedTransaction.active ? "Yes" : "No"}
            </div>
            {selectedTransaction.note && isObject(selectedTransaction.note) &&
              <div className="billingDetailsWrap flex items-center gap-4">
                <div className="flex flex-column gap-1">
                  {/* <span className="font-bold">Deleted Note </span> */}
                  {Object.entries(selectedTransaction.note).map(([key, value]) => <div key={key}><strong>{key.toUpperCase()}:</strong> {value}</div>)}
                </div>
              </div>
            }
          </div>



          {selectedTransaction.partnerUser && (
            <div className="billingDetailsWrap flex items-center gap-4">
              <div className="flex gap-4">
                <span className="font-bold">Partner User </span>
                {selectedTransaction.partnerUser.firstName + " " + selectedTransaction.partnerUser.lastName}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default TransactionSingle;
