import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  icon: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    padding: 0,
    "& svg": {
      fontSize: 65
    }
  }
}));

const NewEntityCardDisplay = ({ displayName, email, onClick, customIcon, className = '' }) => {
  const classes = useStyles();
  return (
    <div className={className + " flex w-[340px] shadow-md p-2 rounded-md border-1 gap-2 hover:cursor-pointer"} onClick={onClick}>
      <IconButton className={classes.icon}>{customIcon}</IconButton>
      <div className="flex flex-column py-4 w-full">
        <h5>{displayName}</h5>
        <span className="overflow-hidden whitespace-nowrap">{email}</span>
      </div>
    </div>
  );
};

export default NewEntityCardDisplay;
