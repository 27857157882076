import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import InviteCodeForm from "./Forms/InviteCodeForm";

import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { useNavigate } from "react-router-dom";

const InviteCodeSingle = () => {
    let navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [editInviteCodeState, setEditInviteCodeState] = useState(false);

    const [selectedInviteCode, setSelectedInviteCode] = useState(null);
    const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

    useEffect(() => {
        const fetchInviteCodeDetails = async () => {
            try {
                const response = await api("v1/inviteCode/" + params.idInviteCode, "GET", null);
                console.log(response);
                if (response.data.success) {
                    const customer = response.data.data.inviteCode.customer ? await api(`v1/customer/${response.data.data.inviteCode.customer}`, "GET") : null;
                    if (customer.data.success) {
                        response.data.data.inviteCode.customer = customer.data.data.customer;
                    } else {
                        response.data.data.inviteCode.customer = null;
                    }
                    setSelectedInviteCode(response.data.data.inviteCode);
                    setLoading(false);
                }
            } catch (error) {
                console.error("API error:", error);
            }
        };

        fetchInviteCodeDetails();
    }, []);

    const handleDeleteInviteCodeConfirm = async () => {
        setLoading(true);
        try {
            const response = await api("v1/inviteCode/" + selectedInviteCode._id, "DELETE", null);
            if (response.data.success) {
                window.location.replace("/inviteCode");
            }
        } catch (error) {
            console.error("API error:", error);
        }
        toggleDisplayDeleteConfirm();
        setLoading(false); //once api call is finished.
    };


    const toggleDisplayDeleteConfirm = () => {
        setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
    };

    const editInviteCode = () => {
        setEditInviteCodeState((prevValue) => !prevValue);
        // setOpenModal(true);
    };

    const reloadInviteCodeDetail = (updateInviteCodeDetail) => {
        setEditInviteCodeState((prevValue) => !prevValue);
        setSelectedInviteCode(updateInviteCodeDetail);
    };

    console.log("setSelectedInviteCode", setSelectedInviteCode);


    return (
        selectedInviteCode && (
            <div className="page partner-user-single flex flex-column items-center min-h-h-screen pt-4 gap-8">
                {loading && <GlobalLoadingSpinner />}

                {/* Page Title */}
                <div className="pageTitle">
                    <h2>
                        Invite Code Details
                    </h2>
                </div>

                {/* Page Main Buttons (Back, Exit etc) */}
                <div className="breadcrumb flex flex-row justify-end w-full">
                    <Button variant="contained" onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </div>

                {/* InviteCode Single Content */}
                <div className="w-full">


                    <div className="
                        customer-single
                        max-w-[580px]
                        flex
                        flex-column
                        bg-[#EEEE]
                        m-2
                        p-3
                        rounded-lg
                    ">
                        <div className="flex flex-nowrap items-center justify-between w-full border-b-[2px]">
                            <p>Invite Code : </p>
                            <p><strong> {selectedInviteCode.code}</strong></p>
                        </div>

                        {
                            selectedInviteCode.customer &&
                            <div className="flex flex-nowrap items-center justify-between w-full border-b-[2px]">
                                <p>Customer: </p>

                                <a href={"/customer/" + selectedInviteCode.customer._id}>
                                    <ul>
                                        <li>Name: <strong> {selectedInviteCode.customer.firstName + " " + selectedInviteCode.customer.lastName}</strong></li>
                                        <li>Email: <strong> {selectedInviteCode.customer.email}</strong></li>
                                        <li>Phone: <strong> {selectedInviteCode.customer.phone} </strong></li>
                                    </ul>
                                </a>
                            </div>
                        }
                        <div className="flex flex-nowrap items-center justify-between w-full border-b-[2px]">
                            <p>Active:  </p>
                            <p> <strong>{selectedInviteCode.active ? 'Yes' : 'No'} </strong></p>
                        </div>

                        {sessionStorage.getItem('selectedPortal') == 'Administrator' &&
                            <div className='actionItems flex gap-4'>
                                <Button className='self-start bg-green-600 m-4' variant='contained' onClick={editInviteCode}>Edit InviteCode </Button>
                                <Button className='self-start bg-red-600 m-4' variant="contained" color="error" onClick={() => { toggleDisplayDeleteConfirm(true) }}>Delete InviteCode</Button>
                            </div>
                        }

                    </div>



                    {/* // Edit Modal HTML */}

                    <ModalPopup
                        showModal={editInviteCodeState}
                        handleShowModal={editInviteCode}
                        popupTitle={"Edit Invite Code"}
                        popupContent={
                            <InviteCodeForm
                                selectedInviteCode={selectedInviteCode}
                                callback={reloadInviteCodeDetail}
                                cancelModal={editInviteCode}
                            />
                        }
                        cancelButtonText=''
                        confirmButtonText=''
                        confirmCallbackAction=''
                    />

                    {/* Delete InviteCode Confimation Modal */}
                    <ModalPopup
                        showModal={deleteConfirmationIsOpen}
                        handleShowModal={toggleDisplayDeleteConfirm}
                        popupTitle={"Delete Invite Code"}
                        popupContent={
                            <div>
                                Are you sure you want to delete this inviteCode for Customer
                                <strong><em> {selectedInviteCode.customer?.firstName + " " + selectedInviteCode.customer?.lastName + " ( Phone:" + selectedInviteCode.customer?.phone + ")"} </em></strong>?
                            </div>
                        }
                        cancelButtonText={'Cancel'}
                        confirmButtonText={'Delete'}
                        confirmCallbackAction={handleDeleteInviteCodeConfirm}
                    />
                </div>

            </div >
        )
    );
};

export default InviteCodeSingle;
