import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { TextField } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import CustomerForm from "./Forms/CustomerForm";
import { api } from "../../utils/api";
import CustomerList from "./CustomerList";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import EmptyState from "../EmptyStates/EmptyState";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const { filteredData, search, setSearch } = useBasicSearch(customers);

  const [loading, setLoading] = useState(true);
  const [showCustomerForm, setShowCustomerForm] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      let response;
      switch (sessionStorage.getItem("selectedPortal")) {
        case "Administrator":
          response = await api("v1/customer", "GET");
          break;
        case "Partner":
          let idPartnerCompany = sessionStorage.getItem("selectedCompany");
          response = await api(`v1/partner/company/${idPartnerCompany}/customers`, "GET");
          break;
        case "Merchant":
          const currentUser = JSON.parse(sessionStorage.getItem("currentUser") ?? null);
          // if (currentUser?.role === 'admin') {
          const selectedMerchantStore = sessionStorage.getItem("selectedMerchantStore");
          if (selectedMerchantStore) {
            response = await api(`v1/merchantStore/${selectedMerchantStore}/customers`, "GET");
          }
          // }
          break;
        default:
          response = null;
          break;
      }

      if (response?.data?.success) {
        setCustomers(response.data.data.allCustomers);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const triggerCustomerListUpdate = (newCustomer) => {
    setShowCustomerForm(false);
    setCustomers((prev) => [...prev, newCustomer]);
  };

  const AddNewCustomer = () => (
    <button
      className={`bg-darkblue text-white rounded-md py-2 px-4`}
      onClick={() => {
        setShowCustomerForm(true);
        setSearch("");
      }}
    >
      ADD NEW CUSTOMER
    </button>
  );

  return (
    <div className="page customer w-full flex flex-column items-center p-8">
      <div className="pageTitle flex flex-col w-full text-center lg:flex-row lg:text-left lg:justify-between mb-4">
        <h1>Customers</h1>
        <div>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ marginRight: "6px", width: "400px" }}
            variant="outlined"
            size="small"
            placeholder="Search for a customer"
          />
          {sessionStorage.getItem("selectedPortal") == "Administrator" && <AddNewCustomer />}
        </div>
      </div>
      {loading ? (
        <GlobalLoadingSpinner />
      ) : (
        <div className="w-full flex justify-center">
          {sessionStorage.getItem("selectedPortal") == "Administrator" && (
            <div className="pageActions">
              <ModalPopup
                showModal={showCustomerForm}
                handleShowModal={() => setShowCustomerForm(false)}
                popupTitle="Add New Customer"
                popupContent={<CustomerForm callback={triggerCustomerListUpdate} />}
              />
            </div>
          )}
          {customers.length === 0 ? (
            <EmptyState type="customers" text="Adding customers is easy" action={<AddNewCustomer />} />
          ) : filteredData.length === 0 ? (
            <EmptyState type="customers" text="No customers match the search" />
          ) : (
            <CustomerList customers={filteredData} />
          )}
        </div>
      )}
    </div>
  );
};

export default Customer;
