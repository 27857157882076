import { React, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage, useFormikContext } from "formik";
import TextField from "@mui/material/TextField";
import { Accordion, FormControl, InputLabel, Select, MenuItem, FormControlLabel } from "@mui/material";
import { fetchVehicleDetails } from "./fetchVehicleDetails";

const allFieldsByCategory = {
  "Basic Details": [
    {
      email: {
        label: "Email",
        placeholder: "Customer's Email",
        type: "email",
        required: true
      },
      mobile: {
        label: "Mobile Number",
        placeholder: "Customer's Mobile Number",
        type: "text",
        inputProps: { inputMode: "numeric" },
        required: true
      },
      firstName: {
        label: "First Name",
        placeholder: "First Name or Business Name",
        type: "text",
        required: true
      },
      lastName: {
        label: "Last Name",
        placeholder: "Customer's Last Name",
        type: "text",
        // required: true
      }
      // ... Define more fields here
    }
  ],
  Address: [
    {
      street: {
        label: "Street Address",
        placeholder: "Street Address",
        type: "text",
        required: true
      },
      city: {
        label: "City",
        placeholder: "City",
        type: "text",
        required: true
      },
      state: {
        label: "State",
        placeholder: "State",
        type: "text",
        inputProps: { maxLength: 3, style: { textTransform: "uppercase" } },
        required: true
      },
      postcode: {
        label: "Postcode",
        placeholder: "Postcode",
        type: "number",
        required: true
      }
    }
  ],
  "Vehicle Details": [
    {
      rego: {
        label: "Registration",
        placeholder: "Enter Rego",
        type: "text",
        required: true
      },
      vin: {
        label: "VIN",
        placeholder: "VIN Number (17 Characters)",
        type: "text",
        inputProps: { maxLength: 17 },
        required: true
      },
      vehicleState: {
        label: "State",
        placeholder: "Select State",
        type: "select",
        options: [
          { 'label': 'ACT', value: 'ACT' },
          { 'label': 'NSW', value: 'NSW' },
          { 'label': 'NT', value: 'NT' },
          { 'label': 'QLD', value: 'QLD' },
          { 'label': 'SA', value: 'SA' },
          { 'label': 'TAS', value: 'TAS' },
          { 'label': 'VIC', value: 'VIC' },
          { 'label': 'WA', value: 'WA' },
        ],
        defaultValue: 'NSW',
        required: true
      },
      make: {
        label: "Vehicle Make",
        placeholder: "Vehicle Make (e.g. BMW)",
        type: "text",
        required: true
      },
      model: {
        label: "Vehicle Model",
        placeholder: "Vehicle Model (e.g. X5) ",
        type: "text",
        required: true
      },
      modelYear: {
        label: "Model Year",
        placeholder: "Model Year (e.g. 2023) ",
        type: "text",
        inputProps: { maxLength: 4 },
        required: true
      },
      color: {
        label: "Vehicle Color",
        placeholder: "Vehicle Color",
        type: "text",
        // required: true
      },
      poNumber: {
        label: "Vehicle Purchase Order Number",
        placeholder: "Purchase Order Number",
        type: "text"
      },
      stockCode: {
        label: "Vehicle Stock Code",
        placeholder: "Stock Code",
        type: "text"
      }
      // age: {
      //   label: 'Vehicle Age',
      //   placeholder: 'Select Vehicle Age',
      //   type: 'select',
      //   options: [
      //     { value: 'Less than 6 months', label: 'Less than 6 months' },
      //     { value: 'Less than 12 months', label: 'Less than 12 months' },
      //     { value: '1 Year', label: '1 Year' },
      //     { value: '2 Years', label: '2 Years' },
      //     { value: 'Over 2 Years', label: 'Over 2 Years' },
      //   ],
      // }
      // ... Define more fields here
    }
  ]
  // ... Add more categories and fields here
};

const sortInputFields = (a, b) => {
  const customOrder = Object.keys(allFieldsByCategory);

  const indexA = customOrder.indexOf(a);
  const indexB = customOrder.indexOf(b);

  // Compare the indices to determine the order
  if (indexA === -1 && indexB === -1) {
    return a.localeCompare(b); // If both are not in customOrder, use default comparison
  } else if (indexA === -1) {
    return 1; // Put items not in customOrder after those that are
  } else if (indexB === -1) {
    return -1; // Put items not in customOrder before those that are
  } else {
    return indexA - indexB; // Compare based on customOrder indices
  }
};
const DynamicFieldsGenerator = ({ selectedInputFieldGroup, setFieldValue, values }) => {
  const setOfInputFields = [...new Set(selectedInputFieldGroup)];
  setOfInputFields.sort(sortInputFields); // sort the array so that the input fields stay in the same order

  const handleRegistrationBlur = async (e) => {
    let stateValue = "NSW";
    if (values.state) {
      stateValue = values.state.toUpperCase();
    } else {
      if (values.vehicleState) {
        stateValue = values.vehicleState.toUpperCase();
      }
    }
    const registration = e.target.value;

    try {
      const vehicleDetails = await fetchVehicleDetails(registration, stateValue);
      if (vehicleDetails) {
        const { vin, make, model, compliance_plate } = vehicleDetails;
        const compliance_plate_raw = compliance_plate.split("-");
        setFieldValue("vin", vin);
        setFieldValue("make", make);
        setFieldValue("model", model);
        setFieldValue("modelYear", compliance_plate_raw[0]);
      }
    } catch (error) {
      console.error("Error fetching vehicle details:", error.message);
      // Handle error (e.g., display an error message)
    }
  };

  console.log("setOfInputFields: ", setOfInputFields);
  console.log("allFieldsByCategory: ", allFieldsByCategory);

  return (
    <div>
      {setOfInputFields &&
        setOfInputFields.map((category) => (
          <div key={category}>
            <h3>{category}</h3>
            {allFieldsByCategory[category] &&
              allFieldsByCategory[category].map((fieldObject) =>
                Object.entries(fieldObject).map(([fieldName, field]) => {
                  return (
                    <div key={fieldName} className="form-group mb-2">
                      {field.type === "select" ? (
                        <Field name={fieldName}>
                          {({ field: formikField }) => (
                            <FormControl fullWidth variant={field.variant ?? "outlined"}>
                              <InputLabel>{field.placeholder}</InputLabel>
                              <Select
                                {...formikField}
                                label={field.placeholder}
                                value={formikField.value || field.defaultValue || ""}
                              >
                                {field.options.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                      ) : field.type === "checkbox" ? (
                        <FormControlLabel
                          control={<Field type="checkbox" name={fieldName} />}
                          label={field.placeholder}
                        />
                      ) : field.type === "radio" ? (
                        field.options.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            control={<Field type="radio" name={fieldName} value={option.value} />}
                            label={option.label}
                          />
                        ))
                      ) : (
                        <Field name={fieldName}>
                          {({ field: formikField }) => (
                            <TextField
                              {...formikField}
                              type={field.type}
                              label={field.placeholder}
                              placeholder={field.placeholder}
                              variant={field.variant ?? "outlined"}
                              fullWidth
                              required={field.required}
                              inputProps={field.inputProps}
                              onBlur={fieldName === "rego" ? handleRegistrationBlur : formikField.onBlur ?? ''}
                              value={formikField.value || ""}
                            />
                          )}
                        </Field>
                      )}
                      <ErrorMessage name={fieldName} component="span" className="error-message" />
                    </div>
                  );
                })
              )}
          </div>
        ))}
    </div>
  );
};

export default DynamicFieldsGenerator;
