import { React, useState, useEffect } from "react";
import { api } from "../../../../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../../../../components/ModalPopup";

import GlobalLoadingSpinner from "../../../../../components/GlobalLoadingSpinner";
import InfoTable from "../../../../../components/InfoTable";
import MerchantUserForm from "../../../../../components/Forms/MerchantUserForm";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { COLORS } from "../../../../../utils/colors";

const MerchantUserSingle = () => {
  const [selectedPortal, setselectedPortal] = useState(sessionStorage.getItem("selectedPortal") || null);
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editMerchantUserState, setEditMerchantUserState] = useState(false);

  // const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    console.log("params: ", params);
    try {
      const response = await api(`v1/merchant/${params.idMerchant}/user/${params.idUser}`, "GET", null);
      if (response.data.success) {
        setSelectedUser(response.data.data.merchantUser);
        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const handleDeleteUserConfirm = async () => {
    setLoading(true);
    try {
      const response = await api(`v1/merchant/${params.idMerchant}/user/${params.idUser}`, "DELETE", null);
      if (response.data.success) {
        window.location.replace("/merchant/" + params.idMerchant);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const handleDeleteUserCancel = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };
  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editMerchantUser = () => {
    setEditMerchantUserState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadUserDetail = (updateCompanyDetail) => {
    setEditMerchantUserState((prevValue) => !prevValue);
    setSelectedUser(updateCompanyDetail);
  };

  const resendWelcomeEmail = async () => {
    setLoading(true);
    const dataToSend = {
      idUser: selectedUser._id
    };

    try {
      const response = await api("v1/merchantUser/sendWelcomeEmail", "POST", dataToSend);
      if (response.data.success) {
        alert("Email sent to Partner User!");
        // window.location.replace(`/partner/${params.idPartnerCompany}/user/${selectedUser._id}`);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false); //once api call is finished.
  };

  console.log("editMerchantUserState", editMerchantUserState);
  return (
    selectedUser && (
      <div className="page partner-user-single  w-full flex flex-column items-center min-h-h-screen p-4 gap-8">
        {loading && <GlobalLoadingSpinner />}
        <ModalPopup
          showModal={editMerchantUserState}
          handleShowModal={editMerchantUser}
          popupTitle={"Edit Merchant User"}
          popupContent={
            <MerchantUserForm
              merchant={{ _id: params.idMerchant }}
              merchantUser={selectedUser}
              fetchUserDetails={fetchUserDetails}
              handleDisplayModal={editMerchantUser}
            />
          }
        />

        <div className="w-full flex flex-row justify-between items-center">
          <div>
            <h1 className="font-bold">
              {selectedUser.firstName} {selectedUser.lastName}
            </h1>
          </div>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {selectedUser && (
          <div className="w-full">
            <div className="flex flex-column gap-2">
              <InfoTable data={selectedUser} />
              {/* {selectedPortal == "Administrator" && (
                <button className="self-center bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded" onClick={resendWelcomeEmail}>Resend Welcome Email</button>
              )} */}
            </div>

            <div className="actionItems flex gap-4">
              <Button
                sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                className="self-start m-4"
                variant="contained"
                onClick={editMerchantUser}
              >
                Edit Merchant User{" "}
              </Button>
              <Button
                className="self-start bg-red-600 m-4"
                variant="contained"
                color="error"
                onClick={() => {
                  toggleDisplayDeleteConfirm(true);
                }}
              >
                Delete Merchant User
              </Button>
            </div>
            <ModalPopup
              showModal={deleteConfirmationIsOpen}
              handleShowModal={toggleDisplayDeleteConfirm}
              popupTitle={"Delete User"}
              popupContent={
                <div>
                  Are you sure you want to delete user:
                  <strong>
                    <em>
                      {selectedUser.firstName} {selectedUser.lastName}
                    </em>
                  </strong>
                  ?
                </div>
              }
              cancelButtonText={"Cancel"}
              confirmButtonText={"Delete"}
              confirmCallbackAction={handleDeleteUserConfirm}
            />
          </div>
        )}
      </div>
    )
  );
};

export default MerchantUserSingle;
