import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { Box, Button, FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";

const SubscriptionForm = ({ selectedSubscription, callback, cancelModal }) => {
    const today = new Date();
    const [productOptions, setProductOptions] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [vehicle, setVehicle] = useState('');
    const [product, setProduct] = useState('');
    const [active, setActive] = useState('');

    const getAllProducts = async () => {
        try {
            const response = await api("v1/product", "GET", null);
            if (response.data.success) {
                const allProducts = response.data.data.allProducts;
                const result = allProducts.map((product) => ({
                    value: product._id,
                    label: product.name,
                }));

                if (result) {
                    setProductOptions(result);
                    console.log("Set Options for Product Options", result);
                    console.log("Selected Subscription", selectedSubscription);

                    if (selectedSubscription) {
                        const selectedProduct = result.find(
                            (product) => product.value === selectedSubscription.product._id
                        );
                        setProduct(selectedProduct);
                    }
                }
            }
        } catch (error) {
            console.error("Error loading partner companies:", error);
        }
    };

    const getAllVehicles = async () => {
        try {
            const response = await api("v1/vehicle", "GET", null);
            if (response.data.success) {
                const allVehicles = response.data.data.allVehicles;
                const result = allVehicles.map((vehicle) => ({
                    value: vehicle._id,
                    label: `<div className="flex flex-column gap-0">
                                <p>Rego: ${vehicle.rego} </p>
                                <p>VIN: ${vehicle.vin}</p>                               
                                <p>Make: ${vehicle.make}</p>                               
                                <p>Model: ${vehicle.model}</p>                               
                                <p>Model Year: ${vehicle.modelYear}</p>                               
                                <p>Color: ${vehicle.color}</p>                               
                            </div>`
                }));

                if (result) {
                    setVehicleOptions(result);
                    // console.log("Set Options for Vehicle Options", result);
                    // console.log("Selected Subscription", selectedSubscription);
                    if (selectedSubscription) {
                        const selectedVehicle = result.find(
                            (vehicle) => vehicle.value === selectedSubscription.vehicle._id
                        );
                        setVehicle(selectedVehicle);
                        // console.log("Selected Vehicle", selectedVehicle);

                    }
                }
            }
        } catch (error) {
            console.error("Error loading partner companies:", error);
        }
    };

    useEffect(() => {
        getAllProducts();
        getAllVehicles();
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("Submitted Data", values);
        const dataToSend = {
            vehicle: Object(vehicle.value),
            product: Object(product.value),
            startDateUTC: values.startDateUTC,
            validUntilDateUTC: values.validUntilDateUTC,
            nextBillingDateUTC: values.nextBillingDateUTC,
            // redemptions: values.redemptions, // Convert dates to strings,
            active: values.active,
            createdOnUTC: values.createdOnUTC,
        };
        console.log("Data to Send", dataToSend);
        if (Object.keys(vehicle).length === 0 || Object.keys(product).length === 0) {
            // Having issue with YUP validation will use this method for now.
            alert("Required Fields not selected for vehicle or product");
            return false;
        }
        try {
            let response;
            if (selectedSubscription) {
                // Edit Subscription Option
                response = await api(`v1/subscription/${selectedSubscription._id}`, "PATCH", dataToSend);
            } else {
                // Add Subscription Option
                response = await api(`v1/subscription`, "POST", dataToSend);
            }

            if (response.data.success) {
                var updatedData = null;

                if (selectedSubscription) {
                    console.log("Subscription Updated: ", response.data.data.subscription);
                    updatedData = response.data.data.subscription;
                } else {
                    if (response.data.data.newSubscription) {
                        console.log("New Subscription Created: ", response.data.data.newSubscription);
                        updatedData = response.data.data.newSubscription;
                    } else {
                        alert(response.data.message);
                    }
                }
                if (updatedData) {
                    console.log(updatedData);

                    const vehicle = await api(`v1/vehicle/${updatedData.vehicle}`, "GET");
                    const product = await api(`v1/product/${updatedData.product}`, "GET");
                    // console.log(updatedData);
                    updatedData.vehicle = vehicle.data.data.vehicle;
                    updatedData.product = product.data.data.product;
                    callback(updatedData);
                }

            } else {
                response?.data?.message && alert(response.data.message);
            }
        } catch (error) {
            console.error("API error:", error);
        }

        setSubmitting(false);
    };

    const loadProductOptions = async (inputValue) => {
        try {
            const filteredOptions = productOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return filteredOptions;
        } catch (error) {
            console.error("Error loading product options:", error);
            return [];
        }
    };



    const loadVehicleOptions = async (inputValue) => {
        try {
            const filteredOptions = vehicleOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return filteredOptions;
        } catch (error) {
            console.error("Error loading Vehicle options:", error);
            return [];
        }
    };

    const handleProductChange = (selectedOptions) => {
        if (selectedOptions) {
            setProduct(selectedOptions);
        } else {
            setProduct(null);
        }
    };
    const handleVehicleChange = (selectedOptions) => {
        if (selectedOptions) {
            setVehicle(selectedOptions);
        } else {
            setVehicle(null);
        }
    };


    const selectStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "white",
            borderColor: "#9e9e9e",
            zIndex: 2,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 3,
        }),
    };

    // const DatePickerField = ({ name, value, onChange }) => {
    //     return (
    //         <DatePicker
    //             selected={(value && new Date(value)) || null}
    //             onChange={(val) => {
    //                 onChange(name, val);
    //             }}
    //         />
    //     );
    // };

    const validationSchema = Yup.object().shape({
        startDateUTC: Yup.date()
            .nullable()
            // .min(new Date(), "Start Date must be later than today"),
            .required("Start Date is required"),
        validUntilDateUTC: Yup.date()
            .nullable()
            .required("Valid Until Date is required")
            .min(new Date(), "Valid Until Date must be later than today"),
        nextBillingDateUTC: Yup.date()
            .nullable()
            .required("Next Billing Date is required")
            .min(new Date(), "Next Billing Date must be later than today"),
        // redemptions: Yup.array()
        //     .of(Yup.date())
        //     .required("Redemptions is required"),
        // active: Yup.boolean().required("Active field is required"),
    });

    return (
        <Formik
            initialValues={{
                vehicle: selectedSubscription ? selectedSubscription?.vehicle?._id : '',
                product: selectedSubscription ? selectedSubscription?.product?._id : '',
                startDateUTC: selectedSubscription ? new Date(selectedSubscription.startDateUTC) : '',
                validUntilDateUTC: selectedSubscription ? new Date(selectedSubscription.validUntilDateUTC) : '',
                nextBillingDateUTC: selectedSubscription ? new Date(selectedSubscription.nextBillingDateUTC) : '',
                // redemptions: selectedSubscription ? selectedSubscription.redemptions.map((redemption) => new Date(redemption)) : [],
                active: selectedSubscription ? selectedSubscription.active : false,
                createdOnUTC: selectedSubscription ? selectedSubscription.createdOnUTC : '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form className="form w-full flex flex-column justify-between priorityoneform form-subscription min-h-[20vh]">
                    <div className="form-input-wrap flex gap-2 w-full">
                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="vehicle">
                                        Vehicle:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="vehicle">
                                        {({ field }) => (
                                            <AsyncSelect
                                                name="vehicle"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={vehicleOptions}
                                                loadOptions={loadVehicleOptions}
                                                styles={selectStyles}
                                                onChange={handleVehicleChange}
                                                value={vehicle}
                                                formatOptionLabel={function (data) { return (<span className="capitalize" dangerouslySetInnerHTML={{ __html: data.label }} />) }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="vehicle"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="product">
                                        Select Product:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="product">
                                        {({ field }) => (
                                            <AsyncSelect
                                                name="product"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={productOptions}
                                                loadOptions={loadProductOptions}
                                                styles={selectStyles}
                                                onChange={handleProductChange}
                                                value={product}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="product"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="redemptions">
                                        Redemptions:
                                    </label>
                                </div>
                                <div className="w-full flex-column">
                                    <FieldArray name="redemptions">
                                        {({ push, remove }) => (
                                            <div>
                                                {values.redemptions.map((redemption, index) => (
                                                    <div key={index} className="flex gap-2">
                                                        <Field name={`redemptions[${index}]`}>
                                                            {({ field }) => (
                                                                <DatePicker
                                                                    {...field}
                                                                    disableToolbar
                                                                    variant="inline"
                                                                    format="dd/MM/yyyy"
                                                                    margin="normal"
                                                                    id={`date-picker-redemptions-${index}`}
                                                                    label={`Redemption ${index + 1}`}
                                                                    value={field.value}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                    selected={field.value}
                                                                    onChange={(date) =>
                                                                        setFieldValue(`redemptions[${index}]`, date)
                                                                    }
                                                                    disabled={isSubmitting}
                                                                />
                                                            )}
                                                        </Field>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => remove(index)}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                ))}
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => push(null)}
                                                >
                                                    Add Redemption
                                                </Button>
                                            </div>
                                        )}
                                    </FieldArray>
                                    <ErrorMessage
                                        name="redemptions"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div> */}

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="startDateUTC">
                                        Select Start Date (UTC):
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="startDateUTC">
                                        {({ field }) => (

                                            <DatePicker
                                                {...field}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-startDateUTC"
                                                label="Select a startDateUTC"
                                                value={values.startDateUTC}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                selected={values.startDateUTC}
                                                onChange={(date) =>
                                                    setFieldValue('startDateUTC', date)
                                                }
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="startDateUTC"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="validUntilDateUTC">
                                        Select Valid Until Date (UTC):
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="validUntilDateUTC">
                                        {({ field }) => (
                                            <DatePicker
                                                {...field}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-validUntilDateUTC"
                                                label="Select a validUntilDateUTC"
                                                value={values.validUntilDateUTC}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                selected={values.validUntilDateUTC}
                                                onChange={(date) =>
                                                    setFieldValue('validUntilDateUTC', date)
                                                }
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="validUntilDateUTC"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="nextBillingDateUTC">
                                        Select Next Billing Date (UTC):
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="nextBillingDateUTC">
                                        {({ field }) => (
                                            <DatePicker
                                                {...field}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-nextBillingDateUTC"
                                                label="Select a nextBillingDateUTC"
                                                value={values.nextBillingDateUTC}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                selected={values.nextBillingDateUTC}
                                                onChange={(date) =>
                                                    setFieldValue('nextBillingDateUTC', date)
                                                }
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="nextBillingDateUTC"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="active">
                                        Active:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="active" type="checkbox">
                                        {({ field }) => (
                                            <FormControlLabel
                                                control={<Switch {...field} checked={field.value} />}
                                                label={field.value ? "Active" : "Inactive"}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="active"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <Button variant="contained" onClick={cancelModal}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained">
                            Submit
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default SubscriptionForm;
