import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import { api } from "../../utils/api";
import PromotionForm from "./Forms/PromotionForm";
import PromotionCard from "./PromotionCard";
import { COLORS } from "../../utils/colors";

const Promotion = () => {
  const [promotionItems, setPromotion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPromotionForm, setShowPromotionForm] = useState(false);

  useEffect(() => {
    fetchPromotions();
  }, []);

  const fetchPromotions = async () => {
    try {
      const response = await api("v1/promotion", "GET");
      if (response.data.success) {
        const updatedPromotions = await Promise.all(
          response.data.data.allPromotions.map(async (promotion) => {
            const updatedProductPromises = promotion.validProducts.map(async (product) => {
              try {
                const productInfo = await api("v1/product/" + product);
                const { _id, name, active } = productInfo.data.data.product;
                return {
                  _id: _id,
                  name: name
                };
              } catch (error) {
                console.error("Error fetching product:", error);
                return null;
              }
            });

            const updatedProduct = await Promise.all(updatedProductPromises);
            const filteredProducts = updatedProduct.filter((product) => product !== null);

            return {
              ...promotion,
              products: filteredProducts
            };
          })
        );

        setPromotion(updatedPromotions);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const triggerPromotionListUpdate = (promotion) => {
    console.log("New/Updated Promotion: ", promotion);
    setShowPromotionForm(false);
    setLoading(false);
    fetchPromotions();
  };

  return (
    <div className="page promotion w-full flex flex-column items-center p-4">
      {loading && <GlobalLoadingSpinner />}
      <div className="pageTitle w-full flex justify-between mb-4">
        <h1>Promotions</h1>
        <div className="pageActions">
          <Button
            sx={{ backgroundColor: COLORS.priorityBlue.hex }}
            variant="contained"
            onClick={() => setShowPromotionForm(true)}
          >
            Add New Promotion
          </Button>
          <ModalPopup
            showModal={showPromotionForm}
            handleShowModal={() => setShowPromotionForm(false)}
            popupTitle="Add New Promotion"
            popupContent={
              <PromotionForm
                callback={triggerPromotionListUpdate}
                cancelModal={() => {
                  setShowPromotionForm(false);
                }}
              />
            }
          />
        </div>
      </div>
      <div className="flex w-full">
        <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
          <div className="flex flex-column items-end gap-4 items-center">
            <div className="w-full flex flex-wrap gap-x-2 gap-y-4">
              {promotionItems && promotionItems.map((promotion) => <PromotionCard {...promotion} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
