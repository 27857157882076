import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { Auth0Provider } from "@auth0/auth0-react";
import TagManager from 'react-gtm-module';
import Auth0ProviderWithHistory from './auth0Provider';
import { BrowserRouter as Router } from 'react-router-dom';

const tagManagerArgs = {
  gtmId: 'GTM-WNGMZTK'
}

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Auth0Provider
  //   domain="priorityone.au.auth0.com"
  //   clientId="5PIZpCNvhphXrnmhGck5Jbk6dRBMiMrz"
  //   redirectUri={window.location.origin}
  // >
  //   <CookiesProvider>
  //     <App />
  //   </CookiesProvider>
  // </Auth0Provider>

  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <Router>
        <App />
      </Router>
    </Auth0ProviderWithHistory>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
