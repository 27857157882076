import React, { useState, useEffect } from "react";
import { api } from "../../../utils/api";
import "./style.css";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import PartnerUserList from "./PartnerUserList";
import PartnerUserForm from "./Forms/PartnerUserForm";
import { useBasicSearch } from "../../../hooks/useBasicSearch";
import { COLORS } from "../../../utils/colors";
import EmptyState from "../../EmptyStates/EmptyState";

const PartnerUser = ({ selectedCompany }) => {
  const [showAddPartnerForm, setShowAddPartnerForm] = useState(false); // New state variable to track the view
  const openModal = () => setShowAddPartnerForm(true);
  const closeModal = () => setShowAddPartnerForm(false);

  const [partnerUsers, setPartnerUsers] = useState([]);
  const selectedPortal = sessionStorage.getItem("selectedPortal") || null;
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") ?? null);
  console.log("currentUser", currentUser);
  const { filteredData, search, setSearch } = useBasicSearch(partnerUsers);

  const onPartnerUpdate = (newPartnerUser) => {
    const partnerUser = partnerUsers.find((p) => p._id === newPartnerUser._id);
    if (!!partnerUser) {
      setPartnerUsers((prev) => prev.map((p) => (p._id === newPartnerUser._id ? newPartnerUser : p)));
    } else {
      setPartnerUsers([...partnerUsers, newPartnerUser]);
    }
    closeModal();
  };

  useEffect(() => {
    const fetchAllPartnerUsers = async () => {
      try {
        const response = await api("v1/partner/company/" + selectedCompany._id + "/user", "GET", null);
        if (response.data.success) {
          setPartnerUsers(response.data.data.partnerUsers);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    if (selectedCompany && selectedCompany._id) {
      fetchAllPartnerUsers();
    }
  }, []);

  const AddPartnerUser = () =>
    selectedPortal == "Administrator" || currentUser?.role?.toLowerCase() != "sales" ? (
      <Button sx={{ backgroundColor: COLORS.priorityBlue.hex }} variant="contained" onClick={openModal}>
        Add New
      </Button>
    ) : null;

  return (
    <div className="mt-4 flex flex-column gap-4">
      <div className="flex flex-row w-full gap-1">
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ width: "400px" }}
          placeholder="Search for a partner user"
        />
        <AddPartnerUser />
      </div>
      <Modal
        open={showAddPartnerForm}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-description="modal-modal-description"
      >
        <Box className="modalContent">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Partner User
          </Typography>
          <PartnerUserForm defaultCompany={selectedCompany} callback={onPartnerUpdate} />
        </Box>
      </Modal>
      <div className="w-full flex justify-center">
        {partnerUsers.length === 0 ? (
          <EmptyState type="partnerUsers" text="No partner users" action={<AddPartnerUser />} />
        ) : filteredData.length === 0 ? (
          <EmptyState type="partnerUsers" text="No partner users match your search." />
        ) : (
          <div className="w-full">
            <PartnerUserList partnerUsers={filteredData} selectedCompany={selectedCompany} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerUser;
