import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { Box, Button, FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";

const RedemptionForm = ({ selectedRedemption, callback, closeModal }) => {
    const today = new Date();
    const [productOptions, setProductOptions] = useState([]);
    const [product, setProduct] = useState('');
    const [vehicleImage, setVehicleImage] = useState();

    const [merchantAccountOptions, setMerchantAccountOptions] = useState([]);
    const [merchantAccount, setMerchantAccount] = useState('');
    const [merchantStoreOptions, setMerchantStoreOptions] = useState([]);
    const [merchantStore, setMerchantStore] = useState('');
    const [merchantUserOptions, setMerchantUserOptions] = useState([]);
    const [merchantUser, setMerchantUser] = useState('');

    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [vehicle, setVehicle] = useState('');
    const [active, setActive] = useState('');

    const getAllProducts = async () => {
        try {
            const response = await api("v1/product", "GET", null);
            if (response.data.success) {
                const allProducts = response.data.data.allProducts;
                const result = allProducts.map((product) => ({
                    value: product._id,
                    label: product.name,
                }));

                if (result) {
                    setProductOptions(result);
                    console.log("Set Options for Product Options", result);
                    if (selectedRedemption) {
                        const selectedProduct = result.find(
                            (product) => product._id === selectedRedemption.product.value
                        );
                        setProduct(selectedProduct);
                    }
                }
            }
        } catch (error) {
            console.error("Error loading partner companies:", error);
        }
    };

    const getAllVehicles = async () => {
        try {
            const response = await api("v1/vehicle", "GET", null);
            if (response.data.success) {
                const allVehicles = response.data.data.allVehicles;
                const result = allVehicles.map((vehicle) => ({
                    value: vehicle._id,
                    label: `<div className="flex flex-column gap-0">
                                <p>Rego: ${vehicle.rego} </p>
                                <p>VIN: ${vehicle.vin}</p>                               
                                <p>Make: ${vehicle.make}</p>                               
                                <p>Model: ${vehicle.model}</p>                               
                                <p>Model Year: ${vehicle.modelYear}</p>                               
                                <p>Color: ${vehicle.color}</p>                               
                            </div>`
                }));

                if (result) {
                    setVehicleOptions(result);
                    console.log("Set Options for Vehicle Options", result);
                    if (selectedRedemption) {
                        const selectedVehicle = result.find(
                            (vehicle) => vehicle._id === selectedRedemption.vehicle.value
                        );
                        setVehicle(selectedVehicle);
                    }
                }
            }
        } catch (error) {
            console.error("Error loading partner companies:", error);
        }
    };

    const getMerchantAccounts = async () => {
        try {
            const response = await api("v1/merchant", "GET", null);
            if (response.data.success) {
                console.log(response);
                const allMerchantAccounts = response.data.data.allMerchants;
                const result = allMerchantAccounts.map((merchantAccountItem) => ({
                    value: merchantAccountItem._id,
                    label: merchantAccountItem.name,
                    stores: merchantAccountItem.stores,
                }));
                // console.log("SELECTED STORE", selectedRedemption.merchantStore);
                if (result) {
                    setMerchantAccountOptions(result);
                    console.log("Set Options for MerchantAccount Options", result);
                    if (selectedRedemption) {

                        const selectedMerchantAccount = result.find((merchantAccountItem) => {
                            // console.log("STORES", selectedRedemption.merchantStore);
                            return merchantAccountItem.stores.some(
                                (store) => store._id === selectedRedemption.merchantStore?._id
                            );
                        });
                        console.log("SELECTED MERCHANT", selectedMerchantAccount);
                        setMerchantAccount(selectedMerchantAccount);
                    }
                }
            }
        } catch (error) {
            console.error("Error loading merchant accounts:", error);
        }
    };

    const getMerchantStores = async () => {
        if (!merchantAccount) {
            return;
        }
        try {
            const response = await api("v1/merchant/" + merchantAccount.value + "/store", "GET", null);
            if (response.data.success) {
                const allMerchantStores = response.data.data.merchantStores;
                const result = allMerchantStores.map((merchantStore) => ({
                    value: merchantStore._id,
                    label: merchantStore.name,
                }));

                if (result) {
                    setMerchantStoreOptions(result);
                    console.log("Set Options for Merchant Store Options", result);
                    if (selectedRedemption) {
                        const selectedMerchantStore = result.find(
                            (item) => item.value === selectedRedemption.merchantStore?._id
                        );
                        setMerchantStore(selectedMerchantStore);
                    }
                } else {
                    setMerchantStoreOptions([]);
                }
            }
        } catch (error) {
            console.error("Error loading merchant stores:", error);
        }
    };

    const getMerchantUsers = async () => {
        if (!merchantAccount) {
            return;
        }
        try {
            const response = await api("v1/merchant/" + merchantAccount.value + "/user", "GET", null);
            if (response.data.success) {
                const allMerchantUsers = response.data.data.merchantUsers;
                const result = allMerchantUsers.map((merchantUser) => ({
                    value: merchantUser._id,
                    label: merchantUser.firstName + " " + merchantUser.lastName + " (" + merchantUser.email + ")",
                }));

                if (result) {
                    setMerchantUserOptions(result);
                    console.log("Set Options for Merchant User", result);
                    if (selectedRedemption) {
                        const selectedMerchantUser = result.find(
                            (item) => item.value === selectedRedemption.merchantUser?._id
                        );
                        setMerchantUser(selectedMerchantUser);
                    }
                } else {
                    setMerchantUserOptions([]);
                }
            }
        } catch (error) {
            console.error("Error loading merchant users:", error);
        }
    };

    useEffect(() => {
        getAllProducts();
        getAllVehicles();
        getMerchantAccounts();
        getMerchantStores();
        getMerchantUsers();
    }, []);

    useEffect(() => {
        getMerchantStores();
        getMerchantUsers();
    }, [merchantAccount]);

    // useEffect(() => {
    //     getMerchantUsers();
    // }, [merchantStore]);    


    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("Submitted Data", values);

        const formData = new FormData();
        formData.append('vehicle', vehicle.value ? Object(vehicle.value) : null);
        formData.append('product', product.value ? Object(product.value) : null);
        formData.append('merchantStore', merchantStore ? Object(merchantStore.value) : null);
        formData.append('merchantUser', merchantUser ? Object(merchantUser.value) : null);
        formData.append('redemptionDateUTC', values.redemptionDateUTC);
        formData.append('active', values.active);
        formData.append('vehicleImage', values.createdOnUTC);
        formData.append("vehicleImage", vehicleImage);

        if (Object.keys(vehicle).length === 0 || Object.keys(product).length === 0) {
            // Having issue with YUP validation will use this method for now.
            alert("Required Fields not selected for vehicle or product");
            return false;
        }
        try {
            let response;
            if (selectedRedemption) {
                // Edit Redemption Option
                response = await api(`v1/redemption/${selectedRedemption._id}`, "PATCH", formData, {
                    'Content-Type': 'multipart/form-data', // Ensure proper headers for form data
                });
            } else {
                // Add Redemption Option
                response = await api(`v1/redemption`, "POST", formData, {
                    'Content-Type': 'multipart/form-data', // Ensure proper headers for form data
                });
            }

            if (response.data.success) {
                var updatedData = null;

                if (selectedRedemption) {
                    console.log("Redemption Updated: ", response.data.data.redemption);
                    updatedData = response.data.data.redemption;
                } else {
                    if (response.data.data.newRedemption) {
                        console.log("New Redemption Created: ", response.data.data.newRedemption);
                        updatedData = response.data.data.newRedemption;
                    } else {
                        alert(response.data.message);
                    }
                }
                if (updatedData) {
                    console.log(updatedData);

                    const vehicle = await api(`v1/vehicle/${updatedData.vehicle}`, "GET");
                    const product = await api(`v1/product/${updatedData.product}`, "GET");
                    const merchantStore = updatedData.merchantStore ? await api(`v1/merchantStore/${updatedData.merchantStore}`, "GET") : null;
                    const merchantUser = updatedData.merchantUser ? await api(`v1/merchantUser/${updatedData.merchantUser}`, "GET") : null;
                    // console.log(updatedData);
                    updatedData.vehicle = vehicle.data.data.vehicle;
                    updatedData.product = product.data.data.product;
                    updatedData.merchantStore = merchantStore ? merchantStore.data.data.merchantStore : null;
                    updatedData.merchantUser = merchantUser ? merchantUser.data.data.merchantUser : null;
                    callback(updatedData);
                }

            } else {
                response?.data?.message && alert(response.data.message);
            }
        } catch (error) {
            console.error("API error:", error);
        }

        setSubmitting(false);
    };

    const loadProductOptions = async (inputValue) => {
        try {
            const filteredOptions = productOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return filteredOptions;
        } catch (error) {
            console.error("Error loading product options:", error);
            return [];
        }
    };

    const loadMerchantAccountOptions = async (inputValue) => {
        try {
            const filteredOptions = merchantAccountOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return filteredOptions;
        } catch (error) {
            console.error("Error loading product options:", error);
            return [];
        }
    };
    const loadMerchantStoreOptions = async (inputValue) => {
        try {
            const filteredOptions = merchantStoreOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return filteredOptions;
        } catch (error) {
            console.error("Error loading product options:", error);
            return [];
        }
    };
    const loadMerchantUserOptions = async (inputValue) => {
        console.log("Loaded merchantUserOptions Options", merchantUserOptions);
        try {
            const filteredOptions = merchantUserOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return filteredOptions;
        } catch (error) {
            console.error("Error loading product options:", error);
            return [];
        }
    };

    const loadVehicleOptions = async (inputValue) => {
        try {
            const filteredOptions = vehicleOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return filteredOptions;
        } catch (error) {
            console.error("Error loading Vehicle options:", error);
            return [];
        }
    };

    const handleProductChange = (selectedOptions) => {
        if (selectedOptions) {
            setProduct(selectedOptions);
        } else {
            setProduct(null);
        }
    };
    const handleVehicleChange = (selectedOptions) => {
        if (selectedOptions) {
            setVehicle(selectedOptions);
        } else {
            setVehicle(null);
        }
    };

    const handleMerchantAccountChange = (selectedOptions) => {
        if (selectedOptions) {
            setMerchantAccount(selectedOptions);
        } else {
            setMerchantAccount(null);
        }
    };
    const handleMerchantStoreChange = (selectedOptions) => {
        if (selectedOptions) {
            setMerchantStore(selectedOptions);
        } else {
            setMerchantStore(null);
        }
    };
    const handleMerchantUserChange = (selectedOptions) => {
        if (selectedOptions) {
            setMerchantUser(selectedOptions);
        } else {
            setMerchantUser(null);
        }
    };


    const selectStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "white",
            borderColor: "#9e9e9e",
            zIndex: 2,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 3,
        }),
    };

    // const DatePickerField = ({ name, value, onChange }) => {
    //     return (
    //         <DatePicker
    //             selected={(value && new Date(value)) || null}
    //             onChange={(val) => {
    //                 onChange(name, val);
    //             }}
    //         />
    //     );
    // };

    const validationSchema = Yup.object().shape({
        redemptionDateUTC: Yup.date()
            .nullable()
            // .min(new Date(), "Start Date must be later than today"),
            .required("Redemption Date is required"),
        // redemptions: Yup.array()
        //     .of(Yup.date())
        //     .required("Redemptions is required"),
        // active: Yup.boolean().required("Active field is required"),
    });

    return (
        <Formik
            initialValues={{
                vehicle: selectedRedemption ? selectedRedemption.vehicle._id : '',
                product: selectedRedemption ? selectedRedemption.product._id : '',
                merchantStore: selectedRedemption && selectedRedemption.merchantStore ? selectedRedemption.merchantStore._id : '',
                merchantUser: selectedRedemption && selectedRedemption.merchantUser ? selectedRedemption.merchantUser._id : '',
                redemptionDateUTC: selectedRedemption ? new Date(selectedRedemption.redemptionDateUTC) : '',
                active: selectedRedemption ? selectedRedemption.active : true,
                createdOnUTC: selectedRedemption ? selectedRedemption.createdOnUTC : '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form className="form w-full flex flex-column justify-between priorityoneform form-redemption min-h-[20vh]">
                    <div className="form-input-wrap flex gap-2 w-full">
                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="vehicle">
                                        Vehicle:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="vehicle">
                                        {({ field }) => (
                                            <AsyncSelect
                                                name="vehicle"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={vehicleOptions}
                                                loadOptions={loadVehicleOptions}
                                                styles={selectStyles}
                                                onChange={handleVehicleChange}
                                                value={vehicle}
                                                formatOptionLabel={function (data) {
                                                    return (
                                                        <span className="capitalize" dangerouslySetInnerHTML={{ __html: data.label }} />
                                                    );
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="vehicle"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="product">
                                        Select Product:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="product">
                                        {({ field }) => (
                                            <AsyncSelect
                                                name="product"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={productOptions}
                                                loadOptions={loadProductOptions}
                                                styles={selectStyles}
                                                onChange={handleProductChange}
                                                value={product}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="product"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="merchantAccount">
                                        Select Merchant Account:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="merchantAccount">
                                        {({ field }) => (
                                            <AsyncSelect
                                                name="merchantAccount"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={merchantAccountOptions}
                                                loadOptions={loadMerchantAccountOptions}
                                                styles={selectStyles}
                                                onChange={handleMerchantAccountChange}
                                                value={merchantAccount}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="merchantAccount"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="merchantStore">
                                        Select Merchant Store:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="merchantStore">
                                        {({ field }) => (
                                            <AsyncSelect
                                                name="merchantStore"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={merchantStoreOptions}
                                                loadOptions={loadMerchantStoreOptions}
                                                styles={selectStyles}
                                                onChange={handleMerchantStoreChange}
                                                value={merchantStore}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="merchantStore"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="merchantUser">
                                        Select Merchant User:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="merchantUser">
                                        {({ field }) => (
                                            <AsyncSelect
                                                name="merchantUser"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={merchantUserOptions}
                                                loadOptions={loadMerchantUserOptions}
                                                styles={selectStyles}
                                                onChange={handleMerchantUserChange}
                                                value={merchantUser}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="merchantUser"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>






                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="redemptionDateUTC">
                                        Select Redemption Date (UTC):
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="redemptionDateUTC">
                                        {({ field }) => (

                                            <DatePicker
                                                {...field}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-redemptionDateUTC"
                                                label="Select a redemptionDateUTC"
                                                placeholderText="dd/mm/YYYY"
                                                value={values.redemptionDateUTC}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                selected={values.redemptionDateUTC}
                                                onChange={(date) =>
                                                    setFieldValue('redemptionDateUTC', date)
                                                }
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="redemptionDateUTC"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="active">
                                        Active:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="active" type="checkbox">
                                        {({ field }) => (
                                            <FormControlLabel
                                                control={<Switch {...field} checked={field.value} />}
                                                label={field.value ? "Active" : "Inactive"}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="active"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="vehicleImage">
                                        Vehicle Image:
                                    </label>
                                </div>

                                <div className="w-full flex-column">

                                    <input
                                        type="file"
                                        id="vehicleImage"
                                        name="vehicleImage"
                                        required  // Adding the required attribute
                                        accept="image/*"
                                        // capture
                                        onChange={(e) => {
                                            // Handle file upload logic here
                                            const selectedFile = e.target.files[0];
                                            setVehicleImage(selectedFile);
                                            console.log("selectedFile", selectedFile);
                                            console.log("vehicleImage", vehicleImage);
                                            // You can perform actions with the selected file
                                        }}
                                    />
                                    <ErrorMessage
                                        name="vehicleImage"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>



                    </div>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <Button variant="contained" onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained">
                            Submit
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default RedemptionForm;
