import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import CustomerForm from "./Forms/CustomerForm";
import { useNavigate } from "react-router-dom";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { COLORS } from "../../utils/colors";
import BasicUserInfo from "../../components/BasicUserInfo";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RedemptionHistory from "./RedemptionHistory";

const CustomerSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editCustomerState, setEditCustomerState] = useState(false);

  // const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    //    const fetchCompanyDetails = async () => {
    //     try {
    //       const response = await api('v1/partner/company/'+params.idPartnerCompany, 'GET', null);
    //       if (response.data.success) {
    //         // console.log(response.data.data.partnerCompany);
    //         setSelectedCompany(response.data.data.partnerCompany);
    //         setLoading(false);
    //       }
    //     } catch (error) {
    //       console.error('API error:', error);
    //     }
    //   };

    // fetchCompanyDetails();

    const fetchCustomerDetails = async () => {
      try {
        const response = await api("v1/customer/" + params.idCustomer, "GET", null);
        if (response.data.success) {


          const updatedVehicleInfo = await Promise.all(
            response.data.data.customer.vehicles.map(async (vehicle) => {
              const fetchVehicleInfo = await api("v1/vehicle/" + vehicle, "GET");
              let vehicleData = null;
              if (fetchVehicleInfo.data.success) {
                vehicleData = fetchVehicleInfo.data.data.vehicle;
                console.log("Fetching active subs of vehicle: ", vehicle);
                // Fetch all Active Subscription of Vehicle
                const activeSubscriptions = await api(`v1/subscription/search`, 'POST', { query: { vehicle: vehicle } });
                if (activeSubscriptions.data.success) {
                  vehicleData.activeSubscriptions = activeSubscriptions.data.data.searchResult;
                } else {
                  vehicleData.activeSubscriptions = null;
                }

              }
              return vehicleData;
            })
          );

          response.data.data.customer.vehicles = updatedVehicleInfo;

          setSelectedCustomer(response.data.data.customer);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchCustomerDetails();
  }, []);

  const handleDeleteCustomerConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/customer/" + selectedCustomer._id, "DELETE", null);
      if (response.data.success) {
        navigate("/customer");
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const toggleDisplayDeleteConfirm = () => setDeleteConfirmationIsOpen((prev) => !prev);

  const editCustomer = () => {
    setEditCustomerState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadCustomerDetail = async (updateCustomerDetail) => {
    setEditCustomerState((prevValue) => !prevValue);

    const updatedVehicleInfo = await Promise.all(
      updateCustomerDetail.vehicles.map(async (vehicle) => {
        const fetchVehicleInfo = await api("v1/vehicle/" + vehicle, "GET");
        let vehicleData = null;
        if (fetchVehicleInfo.data.success) {
          vehicleData = fetchVehicleInfo.data.data.vehicle;
        }
        return vehicleData;
      })
    );
    updateCustomerDetail.vehicles = updatedVehicleInfo;
    setSelectedCustomer(updateCustomerDetail);
  };

  console.log("editCustomerState", editCustomerState);
  console.log(selectedCustomer);
  return (
    selectedCustomer && (
      <div className="page partner-customer-single flex flex-column items-center w-full min-h-h-screen p-4 gap-8">
        {loading && <GlobalLoadingSpinner />}

        <ModalPopup
          showModal={editCustomerState}
          handleShowModal={editCustomer}
          popupTitle={"Edit Customer"}
          popupContent={<CustomerForm customer={selectedCustomer} callback={reloadCustomerDetail} />}
          cancelButtonText=""
          confirmButtonText=""
          confirmCallbackAction=""
        />

        <div className="pageTitle flex flex-col text-center sm:w-full sm:flex-row sm:justify-between">
          <h1>
            Customer | {selectedCustomer.firstName} {selectedCustomer.lastName}
          </h1>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        {selectedCustomer && (
          <div className="flex justify-center flex-wrap gap-5 w-full">
            <div className="flex flex-column max-w-[500px] flex-wrap w-full self-start">
              <BasicUserInfo user={selectedCustomer} />

              {sessionStorage.getItem("selectedPortal") == "Administrator" && (
                <div className="actionItems flex gap-4">
                  <Button
                    sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                    className="self-start m-4"
                    variant="contained"
                    onClick={editCustomer}
                  >
                    Edit Customer Profile
                  </Button>
                  <Button
                    className="self-start bg-red-600 m-4"
                    variant="contained"
                    color="error"
                    onClick={() => toggleDisplayDeleteConfirm()}
                  >
                    Delete Customer
                  </Button>
                </div>
              )}
              <ModalPopup
                showModal={deleteConfirmationIsOpen}
                handleShowModal={toggleDisplayDeleteConfirm}
                popupTitle={"Delete Customer"}
                popupContent={
                  <div>
                    Are you sure you want to delete customer:{" "}
                    <strong>
                      <em>
                        {selectedCustomer.firstName} {selectedCustomer.lastName}
                      </em>
                    </strong>
                    ?
                  </div>
                }
                cancelButtonText={"Cancel"}
                confirmButtonText={"Delete"}
                confirmCallbackAction={handleDeleteCustomerConfirm}
              />



            </div>

            <RedemptionHistory vehicles={selectedCustomer.vehicles} />
            {

              selectedCustomer.vehicles.map((vehicle) => {
                return (
                  vehicle && vehicle.activeSubscriptions?.length > 0 &&
                  <div className="subscriptions-of-vehicle flex flex-column self-start box-content shadow-sm border p-2">
                    <h4>Vehicle:  {vehicle.rego} ({vehicle.vin})</h4>
                    <div className="flex flex-wrap gap-5">
                      {vehicle.activeSubscriptions.map((subscription) => {
                        return (
                          <div className="flex flex-column justify-center items-center cursor-pointer border p-2 hover:bg-sky-100 hover:ring-sky-500" onClick={() => { navigate(`/subscription/${subscription._id}`) }}>
                            <span className={`${subscription.active ? 'bg-teal-500' : 'bg-rose-500'
                              } text-white border rounded px-1 flex justify-center self-end`}>{subscription.active ? 'Active' : 'Not Active'}</span>
                            <img src={subscription?.qrcode} />
                            <p>{subscription._id}</p>
                          </div>
                        );

                      })}
                    </div>

                  </div>
                )
              })

            }
          </div>
        )}
      </div>
    )
  );
};

export default CustomerSingle;
