import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { TextField } from "@mui/material";
import { api } from "../../utils/api";
import TransactionTable from "./TrarnsactionTable";
import { useBasicSearch } from "../../hooks/useBasicSearch";

const Transaction = () => {
  const [transactionItems, setTransactionItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTransactionForm, setShowTransactionForm] = useState(false);
  const { filteredData, search, setSearch } = useBasicSearch(transactionItems);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      // const response = await api("v1/transaction", "GET");

      let response;
      switch (sessionStorage.getItem("selectedPortal")) {
        case "Administrator":
          response = await api("v1/transaction", "GET");
          break;
        case "Partner":
          let idPartnerCompany = sessionStorage.getItem("selectedCompany");
          response = await api(`v1/partner/company/${idPartnerCompany}/transactions`, "GET");
          break;
        default:
          response = null;
          break;
      }

      if (response.data.success) {
        /*
        const updatedTransactions = await Promise.all(
          response.data.data.allTransactions.map(async (transaction) => {
            let updatedCustomer = null;
            const fetchCustomerInfo = await api("v1/customer/" + transaction.customer, "GET");
            if (fetchCustomerInfo.data.success) {
              const customer = fetchCustomerInfo.data.data.customer;
              updatedCustomer = {
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email,
                phone: customer.phone,
                id: customer._id
              };
            }

            let updatedProduct = null;
            const fetchProductInfo = await api("v1/product/" + transaction.product, "GET");
            if (fetchProductInfo.data.success) {
              const product = fetchProductInfo.data.data.product;
              updatedProduct = { name: product.name, _id: product._id };
            }
            return {
              ...transaction,
              customer: updatedCustomer,
              product: updatedProduct
            };
          })
        );
        setTransactionItems(updatedTransactions);
        */
        setTransactionItems(response.data.data.allTransactions);

      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const triggerTransactionListUpdate = (transaction) => {
    console.log("New/Updated Transaction: ", transaction);
    setShowTransactionForm(false);
    setLoading(false);
    fetchTransactions();
  };

  return (
    <div className="page transaction flex w-full flex-column items-center p-4">
      {loading && <GlobalLoadingSpinner />}
      <div className="pageTitle flex flex-col w-full text-center lg:flex-row lg:text-left lg:justify-between mb-4">
        <h2>Transactions</h2>
        <div>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ marginRight: "6px", width: "400px" }}
            variant="outlined"
            size="small"
            placeholder="Search for a transaction"
          />
        </div>
      </div>
      <div className="flex w-full">
        {/* <div className="pageActions">
              <Button variant="contained" onClick={() => setShowTransactionForm(true)}>
                Add New Transaction
              </Button>
              <ModalPopup
                showModal={showTransactionForm}
                handleShowModal={() => setShowTransactionForm(false)}
                popupTitle="Add New Transaction Option"
                popupContent={<TransactionForm callback={triggerTransactionListUpdate} />}
              />
            </div> */}
        {filteredData.length > 0 && <TransactionTable transactions={filteredData} />}
      </div>
    </div>
  );
};

export default Transaction;
