import { Chip, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../utils/api"; // Import your API module here
import StarIcon from "@mui/icons-material/Star";
// import { List, ListItem } from "material-ui";

const MySubscriptionDetails = ({ subscription }) => {
    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }

    const navigate = useNavigate();
    const [redemptionCount, setRedemptionCount] = useState(0); // Corrected from square brackets to parentheses

    useEffect(() => {
        const fetchRedemptionCount = async () => { // Added async keyword here
            if (subscription.product.type === 'package') {
                try {
                    const redemptionCount = await api(`v1/subscription/${subscription._id}/redemptions`, "GET"); // Assuming you need to make a GET request
                    if (redemptionCount && redemptionCount.data.success) {
                        setRedemptionCount(redemptionCount.data.data.redemptions.length);
                    }
                } catch (error) {
                    console.error("API error:", error);
                }
            }
        };

        fetchRedemptionCount(); // Call the async function
    }, [subscription]); // Add 'subscription' as a dependency to avoid infinite re-renders

    return (
        <div className="flex flex-column gap-4 items-start w-full justify-center items-center pt-2">
            <Typography variant='h4' fontWeight={500} sx={{ color: 'black' }}>{subscription.product.name}</Typography>
            <Typography variant='body1' fontWeight={500} sx={{ color: 'grey' }}>{subscription.product.description}</Typography>
            <img src={subscription?.qrcode} width={250} className="border-1 shadow-2xl rounded-3xl" />
            <Typography className="uppercase" variant='body1' fontWeight={500} sx={{ color: 'purple', bgcolor: '#e0e0e0', padding: '.5em;' }}>{subscription?._id}</Typography>
            <div className="flex gap-2 items-center">
                <Chip sx={{ fontSize: '16px' }} label={subscription.active ? "Active" : "Inactive"} color={subscription.active ? "success" : "default"} />
                Expires: {formatDate(subscription.validUntilDateUTC)}
            </div>
            {
                subscription.product.type === "package" && (
                    <div className="flex items-center text-lg">
                        <StarIcon fontSize="large" style={{ color: "#faaf00", verticalAlign: "middle" }} />&nbsp;{redemptionCount} of {" "}
                        {subscription.product.packageCount} redemptions used
                    </div>
                )
            }
            <div className="how-to-redeem-instruction-wrap flex flex-column w-full">
                <Typography fontSize={16} fontWeight={500}>How to Redeem</Typography>
                <List sx={{ listStyle: "decimal", pl: 4 }}>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        <ListItemText primary="Find a participating location" component="span" />
                    </ListItem>

                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        <ListItemText primary="A staff member will identify your vehicle and scan your QR code" component="span" />
                    </ListItem>

                    <ListItem sx={{ display: "list-item", p: 0 }}>
                        <ListItemText primary="Enjoy your the rest of your day" component="span" />
                    </ListItem>

                </List>

            </div>
            {
                subscription.redemptions &&
                <div className="flex flex-column w-full text-xs">
                    {subscription.redemptions && <Typography fontSize={14} className="font-bold border-b-2">Redemption History</Typography>}
                    {subscription.redemptions.map((redemption) =>
                        redemption &&
                        <div className='py-1'>
                            <span>
                                {redemption?.redemptionDateUTC
                                    ? `${new Date(redemption.redemptionDateUTC).toLocaleTimeString()} on ${formatDate(redemption.redemptionDateUTC)}`
                                    : 'No expiry available'
                                }
                            </span>
                            <span> at <Link>{redemption.merchantStore.name}</Link></span>
                        </div>
                    )}
                </div>
            }
        </div >
    );
};

export default MySubscriptionDetails;
