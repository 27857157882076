import React from "react";
import { useNavigate } from "react-router-dom";
import NewEntityCardDisplay from "../../components/NewEntityCardDisplay";
import StoreIcon from "@mui/icons-material/Store";
import { CheckBox, DisabledByDefault } from "@mui/icons-material";

const PartnerCompanyList = ({ partners }) => {
  const navigate = useNavigate();
  console.log("partners", partners);
  return (
    <div className="flex flex-row flex-wrap gap-y-4 gap-x-6 justify-center lg:justify-normal">
      {partners.map((partner) =>
        !partner.active ? null : (
          <div key={partner._id} className="flex flex-column">
            {partner.autoGuruAffiliated && (
              <img
                className="absolute self-end w-6 h-6"
                src="https://cdn.autoguru.com.au/images/logos/otto.svg"
                alt="icon"
              />
            )}

            <div className="flex absolute gap-5">
              {!partner.enableInvoicing && <div className="p-1 text-white bg-red-500">Invoice Disabled</div>}
              {partner.partnerSubscription && <div className="p-1 text-white bg-blue-500">Subscribed</div>}
            </div>
            <NewEntityCardDisplay
              displayName={`${partner.name}`}
              email={partner.billing.email}
              onClick={() => navigate(`/partner/${partner._id}`)}
              customIcon={<StoreIcon />}
            />
          </div>
        )
      )}
    </div>
  );
};

export default PartnerCompanyList;
