import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { api } from "../../utils/api";
// import './AddPartnerCompanyForm.css';
import countriesData from "../../constants/countries.json";
import { FormControlLabel, Switch } from "@mui/material";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Company Name is required"),
  // partnerType: Yup.string().required("Partner Type is required"),
  billingPhone: Yup.string()
    .matches(/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/, "Invalid Australian phone number")
    .required("Phone number is required"),
  billingEmail: Yup.string().email("Invalid email address").required("Email is required"),
  // billingAddress: Yup.string().required('Billing Address is required'),
  billingAddress: Yup.object().shape({
    street: Yup.string().required("Street is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postcode: Yup.string().required("Postcode is required"),
    country: Yup.string().required("Country is required")
  })
});

const loadCountryOptions = async (inputValue) => {
  const filteredCountries = countriesData.filter((country) =>
    country.label.toLowerCase().includes(inputValue.toLowerCase())
  );
  // Return the filtered countries as options
  return filteredCountries;
};
const selectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "#9e9e9e",
    zIndex: 2
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3 // Higher zIndex for the dropdown menu
  })
};
const AddForm = ({ setShowForm, updateData, type, setLoading }) => {
  const [billingCountry, setBillingCountry] = useState({
    label: "Australia",
    value: "AU"
  }); // Add state for billing country
  const handleCountryChange = (selectedOption) => {
    setBillingCountry(selectedOption); // Set the selected country value to billingCountry state
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    console.log("setLoading true above");
    const dataToSend = {
      name: values.name,
      partnerType: values.partnerType,
      autoGuruAffiliated: values.autoGuruAffiliated,
      enableInvoicing: values.enableInvoicing,
      partnerSubscription: values.partnerSubscription,
      ddBankName: values.ddBankName,
      ddAccountName: values.ddAccountName,
      ddBsb: values.ddBsb,
      ddAccountNumber: values.ddAccountNumber,
      billing: {
        email: values.billingEmail,
        phone: values.billingPhone,
        address: {
          ...values.billingAddress,
          country: billingCountry // Set the billing country from the billingCountry state
        }
      }
    };
    setShowForm(false);
    try {
      // Make the API call with a POST request
      var endpoint = type == "partner" ? `v1/partner/company` : `v1/merchant`;
      const response = await api(endpoint, "POST", dataToSend);

      if (response.data.success) {
        if (response.data.data.newPartnerCompany) {
          console.log("Add success Resp", response.data.data.newPartnerCompany);
          updateData(response.data.data.newPartnerCompany);
        } else if (response.data.data.existingCompany) {
          alert("Partner Company already Exists, please enter different name or edit existing");
        }
      }
      // console.log(values);
      // Handle the response as needed
      // console.log(response);
    } catch (error) {
      // Handle any errors
      console.error("API error:", error);
    }
    setLoading(false);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        name: "",
        partnerType: "",
        autoGuruAffiliated: "",
        enableInvoicing: "",
        partnerSubscription: "",
        ddBankName: "",
        ddAccountName: "",
        ddBsb: "",
        ddAccountNumber: "",
        billingPhone: "",
        billingEmail: "",
        billingAddress: {
          street: "",
          city: "",
          state: "",
          postcode: "",
          country: "AU"
        }
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
          <div className="form-input-wrap form-group w-full flex flex-column gap-4">
            <div className="form-group w-full">
              <label className="label" htmlFor="name">
                {type === "partner" ? "Company Name" : "Merchant Name"}:
              </label>
              <Field name="name">
                {({ field }) => (
                  <TextField
                    {...field}
                    label={type === "partner" ? "Company Name" : "Merchant Name"}
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage name="name" component="span" className="error-message" />
            </div>

            {type === "partner" && (
              <div className="form-group w-full">
                <label className="label" htmlFor="partnerType">
                  Type:
                </label>
                <Field
                  name="partnerType"
                  as="select"
                  onChange={(e) => {
                    setFieldValue("partnerType", e.target.value); // Update partnerType value
                    setFieldValue("autoGuruAffiliated", false); // Set autoGuruAffiliated to false
                  }}
                >
                  <option value="">Select Partner Type</option>
                  <option value="Dealership">Dealership</option>
                  <option value="Workshop/Mechanic">Workshop/Mechanic</option>
                </Field>
                <ErrorMessage name="partnerType" component="span" className="error-message" />
              </div>
            )}

            {values.partnerType === "Workshop/Mechanic" && (
              <div className="form-group w-full">
                <label className="label" htmlFor="autoGuruAffiliated">
                  Is affiliated to AutoGuru:
                </label>
                <Field name="autoGuruAffiliated" type="checkbox">
                  {({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label={field.value ? "Yes" : "No"}
                    />
                  )}
                </Field>
                <ErrorMessage name="autoGuruAffiliated" component="span" className="error-message" />
              </div>
            )}

            {type === "partner" && (
              <div className="form-group w-full">
                <label className="label" htmlFor="enableInvoicing">
                  Enable Invoicing:
                </label>
                <Field name="enableInvoicing" type="checkbox">
                  {({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label={field.value ? "Yes" : "No"}
                    />
                  )}
                </Field>
                <ErrorMessage name="enableInvoicing" component="span" className="error-message" />
              </div>
            )}

            {values.enableInvoicing && values.partnerType && values.partnerType === "Workshop/Mechanic" && (
              <div className="form-group w-full">
                <label className="label" htmlFor="partnerSubscription">
                  Subscription:
                </label>
                <Field name="partnerSubscription" type="checkbox">
                  {({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} checked={field.value} />}
                      label={field.value ? "Yes" : "No"}
                    />
                  )}
                </Field>
                <ErrorMessage name="partnerSubscription" component="span" className="error-message" />
              </div>
            )}

            {values.partnerSubscription && values.partnerType && values.partnerType === "Workshop/Mechanic" && (
              <div className="w-full flex flex-column justify-between items-center gap-2 shadow-md p-5 shadow-teal-900">
                <h4>Debit Details</h4>
                <div className="form-group w-full">
                  {/* <label className="label" htmlFor="ddBankName">
                    Name of Bank:
                  </label> */}
                  <Field name="ddBankName">
                    {({ field }) => <TextField {...field} label="Name of Bank" variant="outlined" fullWidth />}
                  </Field>
                  <ErrorMessage name="ddBankName" component="span" className="error-message" />
                </div>
                <div className="form-group w-full">
                  {/* <label className="label" htmlFor="ddAccountName">
                    Name on Account:
                  </label> */}
                  <Field name="ddAccountName">
                    {({ field }) => <TextField {...field} label="Name on Account" variant="outlined" fullWidth />}
                  </Field>
                  <ErrorMessage name="ddAccountName" component="span" className="error-message" />
                </div>

                <div className="flex w-full gap-2">
                  <div className="form-group w-1/2">
                    {/* <label className="label" htmlFor="ddBsb">
                      BSB Number:
                    </label> */}
                    <Field name="ddBsb">
                      {({ field }) => <TextField {...field} label="BSB Number" variant="outlined" fullWidth />}
                    </Field>
                    <ErrorMessage name="ddBsb" component="span" className="error-message" />
                  </div>
                  <div className="form-group w-1/2">
                    {/* <label className="label" htmlFor="ddAccountNumber">
                      Account Number:
                    </label> */}
                    <Field name="ddAccountNumber">
                      {({ field }) => <TextField {...field} label="Account Number" variant="outlined" fullWidth />}
                    </Field>
                    <ErrorMessage name="ddAccountNumber" component="span" className="error-message" />
                  </div>
                </div>

              </div>
            )}


            <div className="w-full flex justify-between gap-2">
              <div className="form-group w-full">
                <label className="label" htmlFor="billingPhone">
                  Billing Phone:
                </label>
                <Field name="billingPhone">
                  {({ field }) => <TextField {...field} label="Phone" variant="outlined" fullWidth />}
                </Field>
                <ErrorMessage name="billingPhone" component="span" className="error-message" />
              </div>
              <div className="form-group w-full">
                <label className="label" htmlFor="billingEmail">
                  Billing Email:
                </label>
                <Field name="billingEmail">
                  {({ field }) => <TextField {...field} label="Billing Email Address" variant="outlined" fullWidth />}
                </Field>
                <ErrorMessage name="billingEmail" component="span" className="error-message" />
              </div>
            </div>

            <div className="w-full flex justify-between gap-2">
              <div className="form-group w-full">
                <label className="label" htmlFor="billingAddress.street">
                  Street Address:
                </label>
                <Field name="billingAddress.street">
                  {({ field }) => <TextField {...field} label="Street" variant="outlined" fullWidth />}
                </Field>
                <ErrorMessage name="billingAddress.street" component="span" className="error-message" />
              </div>
            </div>

            <div className="w-full flex justify-between gap-2">
              <div className="form-group w-full">
                <label className="label" htmlFor="billingAddress.city">
                  City:
                </label>
                <Field name="billingAddress.city">
                  {({ field }) => <TextField {...field} label="City" variant="outlined" fullWidth />}
                </Field>
                <ErrorMessage name="billingAddress.city" component="span" className="error-message" />
              </div>

              <div className="form-group w-full">
                <label className="label" htmlFor="billingAddress.state">
                  State:
                </label>
                <Field name="billingAddress.state">
                  {({ field }) => <TextField {...field} label="State" variant="outlined" fullWidth />}
                </Field>
                <ErrorMessage name="billingAddress.state" component="span" className="error-message" />
              </div>
            </div>

            <div className="w-full flex justify-between gap-2">
              <div className="form-group w-full">
                <label className="label" htmlFor="billingAddress.country">
                  Country:
                </label>
                <Field name="billingAddress.country">
                  {({ field }) => (
                    <AsyncSelect
                      {...field}
                      cacheOptions
                      defaultOptions
                      loadOptions={loadCountryOptions}
                      styles={selectStyles}
                      onChange={handleCountryChange}
                      value={billingCountry}
                    />
                  )}
                </Field>
                <ErrorMessage name="billingAddress.country" component="span" className="error-message" />
              </div>
              <div className="form-group w-full">
                <label className="label" htmlFor="billingAddress.postcode">
                  Postcode:
                </label>
                <Field name="billingAddress.postcode">
                  {({ field }) => <TextField {...field} label="Postcode" variant="outlined" fullWidth />}
                </Field>
                <ErrorMessage name="billingAddress.postcode" component="span" className="error-message" />
              </div>
            </div>
          </div>

          <div className="form-actions-wrap">
            <button type="submit" className={`bg-darkblue text-white rounded-md py-2 px-4`}>
              SUBMIT
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddForm;
