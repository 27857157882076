/*
Partner User Form Component, Used to render Add Partner User and Edit Partner user form
*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Box, Button, Modal, Typography, FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../../utils/api";
import countriesData from "../../../../constants/countries.json";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phone: Yup.string()
    .matches(/^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/, "Invalid Australian phone number")
    .required("Phone number is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  // address: Yup.object().shape({
  //   street: Yup.string().required("Street is required"),
  //   city: Yup.string().required("City is required"),
  //   state: Yup.string().required("State is required"),
  //   postcode: Yup.string().required("Postcode is required")
  //   // country: Yup.object().required("Country is required"),
  // }),
  partnercompanies: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required,
        value: Yup.string().required()
      })
    )
    .required("Company is required"),
  // role: Yup.string().required('Role is required'),
  active: Yup.boolean().required("Active field is required")
});

const PartnerUserForm = ({
  partnerUser, // if this is set we assume Edit User else Add User
  defaultCompany,
  callback
}) => {
  const [modalActive, setModalActive] = useState(true);
  const [country, setBillingCountry] = useState(
    partnerUser ? partnerUser.address.country : defaultCompany.billing.address.country
  );
  const selectedPortal = sessionStorage.getItem("selectedPortal") || null;
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser")) || null;

  // Add state for selected country
  const handleCountryChange = (selectedOption) => {
    setBillingCountry(selectedOption); // Set the selected country value to country state
    console.log("Set Country", country);
  };

  const [companyOptions, setCompanyOptions] = useState();
  const [partnerCompanies, setPartnerCompanies] = useState(
    partnerUser ? partnerUser.partnerCompanies : [{ label: defaultCompany.name, value: defaultCompany._id }]
  );
  const [role, setRole] = useState((partnerUser && partnerUser.role) || "");

  const getAllPartnerCompanies = async () => {
    try {
      const response = await api("v1/partner/company", "GET", null);
      if (response.data.success) {
        const allCompanies = response.data.data.allCompanies;
        const result = allCompanies.map((company) => ({
          value: company._id,
          label: company.name
        }));

        if (result) {
          setCompanyOptions(result);
          if (partnerUser) {
            setPartnerCompanies(partnerUser.partnercompanies);
          }
        }
      }
    } catch (error) {
      console.error("Error loading partner companies:", error);
    }
  };

  useEffect(() => {
    getAllPartnerCompanies();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    const dataToSend = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      address: {
        ...values.address,
        country: country
      },
      partnercompanies: partnerCompanies,
      role,
      active: values.active
    };
    console.log("Data to Send", dataToSend);
    try {
      // Make the API call with a PATCH request
      let response;
      if (partnerUser) {
        response = await api(`v1/user/${partnerUser._id}`, "PATCH", dataToSend);
      } else {
        response = await api(`v1/user`, "POST", dataToSend);
        // callback();
      }

      if (response.data.success) {
        console.log("Partner User Updated", response.data.data.partnerUser);
        if (partnerUser) {
          callback(response.data.data.partnerUser);
        } else {
          callback(response.data.data.newPartnerUser);
        }
      } else {
        response?.data?.message && alert(response.data.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }

    setSubmitting(false);
  };

  const loadRoles = async () =>
    // make this fetch from BE later
    [
      { label: "Sales", value: "sales" },
      { label: "Owner", value: "owner" },
      { label: "Principal", value: "principal" },
      { label: "General Manager", value: "general manager" }
    ];

  const loadCountryOptions = async (inputValue) => {
    const filteredCountries = countriesData.filter((country) =>
      country.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    // Return the filtered countries as options
    return filteredCountries;
  };

  const loadCompanyOptions = async (inputValue, callback) => {
    try {
      const filteredOptions = companyOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredOptions);
    } catch (error) {
      console.error("Error loading company options:", error);
      callback([]);
    }
  };

  const handleCompanyChange = (selectedOptions) => {
    console.log(selectedOptions);
    setPartnerCompanies(selectedOptions); // Extract and store the values of selected options
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#9e9e9e",
      zIndex: 2
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3 // Higher zIndex for the dropdown menu
    })
  };

  return (
    <Formik
      initialValues={{
        firstName: partnerUser ? partnerUser.firstName : "",
        lastName: partnerUser ? partnerUser.lastName : "",
        phone: partnerUser ? partnerUser.phone : "",
        email: partnerUser ? partnerUser.email : "",
        address: {
          street: partnerUser ? partnerUser.address.street : "",
          city: partnerUser ? partnerUser.address.city : "",
          state: partnerUser ? partnerUser.address.state : "",
          postcode: partnerUser ? partnerUser.address.postcode : "",
          country: partnerUser ? partnerUser.address.country : ""
        },
        partnercompanies: [],
        role: (partnerUser && partnerUser.role) || "",
        active: partnerUser?.active || true
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
        <div className="form-input-wrap flex gap-2 w-full">
          <div className="w-full flex gap-5">
            {selectedPortal == "Administrator" && (
              <div className="form-group w-full">
                <label className="label" htmlFor="company">
                  Company:
                </label>
                <Field name="company" className="z-2">
                  {({ field }) => (
                    <AsyncSelect
                      name="partnercompanies"
                      isMulti
                      {...field}
                      cacheOptions
                      defaultOptions={companyOptions}
                      loadOptions={loadCompanyOptions}
                      styles={selectStyles}
                      onChange={handleCompanyChange}
                      value={partnerCompanies}
                    />
                  )}
                </Field>
                <ErrorMessage name="company" component="span" className="error-message" />
              </div>
            )}
            {(selectedPortal == "Administrator" || currentUser?.role?.toLowerCase() != "sales") && (
              <div className="form-group w-full">
                <label className="label" htmlFor="role">
                  Role:
                </label>
                <Field name="role" className="z-2">
                  {({ field }) => (
                    <AsyncSelect
                      name="role"
                      {...field}
                      defaultOptions
                      loadOptions={loadRoles}
                      styles={selectStyles}
                      onChange={(selectedOption) => setRole(selectedOption.label)}
                      value={role ? { value: role, label: role } : ""}
                    />
                  )}
                </Field>
                <ErrorMessage name="role" component="span" className="error-message" />
              </div>
            )}
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="firstName">
                First Name:
              </label>
              <Field name="firstName">
                {({ field }) => <TextField {...field} label="First Name" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="firstName" component="span" className="error-message" />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="lastName">
                Last Name:
              </label>
              <Field name="lastName">
                {({ field }) => <TextField {...field} label="Last Name" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="lastName" component="span" className="error-message" />
            </div>
          </div>

          <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="email">
                Email Address:
              </label>
              <Field name="email">
                {({ field }) => <TextField {...field} label="Email Address" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="email" component="span" className="error-message" />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="phone">
                Phone:
              </label>
              <Field name="phone">
                {({ field }) => <TextField {...field} label="Phone" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="phone" component="span" className="error-message" />
            </div>
          </div>

          {/* <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.street">
                Street:
              </label>
              <Field name="address.street">
                {({ field }) => <TextField {...field} label="Street" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="address.street" component="span" className="error-message" />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="address.city">
                City:
              </label>
              <Field name="address.city">
                {({ field }) => <TextField {...field} label="City" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="address.city" component="span" className="error-message" />
            </div>
          </div> */}

          {/* <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.state">
                State:
              </label>
              <Field name="address.state">
                {({ field }) => <TextField {...field} label="State" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="address.state" component="span" className="error-message" />
            </div>

            <div className="form-group w-full">
              <label className="label" htmlFor="address.postcode">
                Postcode:
              </label>
              <Field name="address.postcode">
                {({ field }) => <TextField {...field} label="Postcode" variant="outlined" fullWidth />}
              </Field>
              <ErrorMessage name="address.postcode" component="span" className="error-message" />
            </div>
          </div> */}

          {/* <div className="w-full flex gap-5">
            <div className="form-group w-full">
              <label className="label" htmlFor="address.country">
                Country:
              </label>

              <Field name="address.country">
                {({ field }) => (
                  <AsyncSelect
                    name="address.country"
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCountryOptions}
                    styles={selectStyles}
                    onChange={handleCountryChange}
                    value={country}
                  />
                )}
              </Field>
              <ErrorMessage name="address.country" component="span" className="error-message" />
            </div>
          </div> */}
          {selectedPortal == "Administrator" && (
            <div className="form-group w-full">
              <label className="label" htmlFor="active">
                Active:
              </label>
              <Field name="active" type="checkbox">
                {({ field }) => (
                  <FormControlLabel
                    control={<Switch {...field} checked={field.value} />}
                    label={field.value ? "Active" : "Inactive"}
                  />
                )}
              </Field>
              <ErrorMessage name="active" component="span" className="error-message" />
            </div>
          )}
        </div>
        <div className="form-actions-wrap">
          <Button type="submit" variant="contained" color="primary" className="submit-button">
            Submit
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default PartnerUserForm;
