import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { COLORS } from "../../utils/colors";
import { api } from "../../utils/api";
import CustomSnackbar from "../../components/CustomSnackbar";


const TransactionTable = ({ transactions }) => {
  const currentPortal = sessionStorage.getItem("selectedPortal");

  const [snackbar, setSnackbar] = useState({ message: "", variant: "" });

  console.log('All Transactions:', transactions);
  const navigate = useNavigate();
  let counter = 1;
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState(transactions);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    // Update filteredTransactions whenever transactions or searchTerm changes
    const filtered = transactions.filter(transaction => {
      const { product, customer, createdOnUTC, partnerUser, active } = transaction;
      const searchString = searchTerm.toLowerCase();
      return (
        product.name.toLowerCase().includes(searchString) ||
        (customer && `${customer.firstName} ${customer.lastName}`.toLowerCase().includes(searchString)) ||
        (customer && customer.email.toLowerCase().includes(searchString)) ||
        (customer && customer.phone.toLowerCase().includes(searchString)) ||
        new Date(createdOnUTC).toLocaleDateString().includes(searchString) ||
        (partnerUser && `${partnerUser.firstName} ${partnerUser.lastName}`.toLowerCase().includes(searchString)) ||
        (active ? "Yes" : "No").toLowerCase().includes(searchString)
      );
    }).sort((a, b) => new Date(b.createdOnUTC) - new Date(a.createdOnUTC));
    setFilteredTransactions(filtered);
  }, [transactions, searchTerm]);

  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    const headers = ["#", "Product", "Customer", "Created", "Submitted By", "Active"];
    const rows = data.map((transaction, index) => {
      const { product, customer, createdOnUTC, partnerUser, active } = transaction;
      const customerInfo = customer
        ? `${customer.firstName} ${customer.lastName} | ${customer.email} | ${customer.phone}`
        : "";
      return [
        index + 1,
        product ? product.name : "",
        customerInfo,
        new Date(createdOnUTC).toLocaleDateString(),
        partnerUser ? `${partnerUser.firstName} ${partnerUser.lastName}` : "",
        active ? "Yes" : "No",
      ];
    });
    return [headers, ...rows].map(row => row.join(',')).join('\n');
  };

  // Function to handle export button click
  const handleExport = () => {
    const dataToExport = searchTerm ? filteredTransactions : transactions;
    const csvData = convertToCSV(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'transactions.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const deleteTransaction = async ({ transaction }) => {
    if (window.confirm('Do you want to delete the transaction?')) {
      const note = window.prompt('Please provide a reason for deletion', '');

      if (note != null) {
        if (note === '') {
          window.alert('Please provide a valid reason for deletion');
          setSnackbar({ variant: "error", message: `Delete Failed: Reason not provided.` });
          return false;
        } else {
          if (transaction.invoiceStatus === 'VOIDED') {
            if (!window.confirm('This invoice is already voided and credit notes will not be created. Click OK to delete record from portal without any changes to invoice in Xero or cancel to abort.')) {
              setSnackbar({ variant: "error", message: `Delete Aborted: Credit will be done manually.` });
              return;
            }
          }

          let applyCreditNote = 'no';

          if (transaction.invoiceStatus !== 'VOIDED') {
            if (window.confirm("Do you want to create the credit note? Please note this will be 100% credit to the dealer, if partial credit, select cancel and do the manual credit.")) {
              applyCreditNote = 'yes';
            }
          }

          const dataToSend = {
            idTransaction: transaction._id,
            note,
            applyCreditNote: applyCreditNote,
          };

          try {
            const response = await api("v1/transaction/deleteTransactionAndHandleInvoiceCredit", "POST", dataToSend);
            if (response.data.success) {
              // Update transactions state to remove the deleted transaction
              const updatedTransactions = transactions.filter(t => t._id !== transaction._id);
              setFilteredTransactions(updatedTransactions);
              setSnackbar({ variant: "success", message: `Transaction Deleted.` });
            }
          } catch (error) {
            console.error("Error creating credit note:", error);
            setSnackbar({ variant: "error", message: `Error creating credit note.` });

          }
        }
      } else {
        console.log("Note is null, aborting..");
        return false;
      }
    }
  };

  return (
    <>
      <TableContainer>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ marginBottom: 2 }}
        />
        <Button onClick={handleExport} variant="contained" sx={{ backgroundColor: COLORS.priorityBlue.hex, marginBottom: 2 }}>
          Export to CSV
        </Button>
        <Table
          sx={{ minWidth: 650, borderCollapse: "separate", "td, th": { borderBottom: "none" }, th: { fontSize: "18px" } }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Partner Company</TableCell>
              <TableCell>Submitted By</TableCell>
              <TableCell>Invoice Status</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTransactions.map((transaction) => {
              console.log(transaction);
              const { createdOnUTC, product, customer, partnerCompany, partnerUser, invoiceStatus, price, _id } = transaction;
              return (
                <TableRow
                  onClick={() => navigate(`/transaction/${transaction._id}`)}
                  key={transaction._id}
                  sx={{
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                    "&:hover": { cursor: "pointer" }
                  }}
                >
                  <TableCell scope="row">{counter++}</TableCell>
                  <TableCell scope="row">{product.name}</TableCell>
                  <TableCell scope="row">
                    {customer ? (
                      <>
                        {customer.firstName} {customer.lastName}
                        <br />
                        {customer.email}
                        <br />
                        {customer.phone}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell>{new Date(createdOnUTC).toLocaleDateString()}</TableCell>
                  <TableCell>{partnerCompany.name}</TableCell>
                  <TableCell>{partnerUser ? `${partnerUser.firstName} ${partnerUser.lastName}` : ''}</TableCell>
                  <TableCell>{invoiceStatus ?? ''}</TableCell>
                  <TableCell>${price ?? 0}</TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        size="small"
                        variant="contained"
                        sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                      >
                        View
                      </Button>
                      {currentPortal == 'Administrator' &&
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={(event) => {
                            event.stopPropagation();
                            deleteTransaction({ transaction });
                          }}
                        >
                          Delete
                        </Button>
                      }
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {snackbar && <CustomSnackbar {...snackbar} />}
    </>
  );
};

export default TransactionTable;
