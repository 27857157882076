import React from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { Button } from "@mui/material";
import { getAddressField, getReadableAddress } from "./utils";

const MerchantInfoWindow = ({ merchant }) => (
  <div className="flex flex-column p-1 text-left w-64">
    <Typography variant="h6" sx={{ textAlign: "center" }}>
      {merchant.name}
    </Typography>
    <Divider sx={{ my: "6px" }} />
    <span>{merchant.merchantAccountName}</span>
    <span>{getReadableAddress(merchant.address)}</span>
    <Divider sx={{ my: "6px" }} />
    {/* <Typography variant="subtitle1">Accepted Products</Typography>
    <div className="flex flex-row flex-wrap mt-2 gap-x-2 gap-y-1 ">
      {merchant.products.length > 0 ? (
        merchant.products.map((product) => product && product.label && <Chip key={product.value} label={product.label} />)
      ) : (
        <span>No products available</span>
      )}
    </div> */}
    <Button
      href={`https://www.google.com/maps/place/${getAddressField(Object.values(merchant.address))}`}
      target="_blank"
      variant="contained"
      size="small"
      fullWidth
      sx={{ marginTop: "12px" }}
    >
      View Location
    </Button>
  </div>
);

export default MerchantInfoWindow;
