import ModalPopup from "./ModalPopup";
import { useCookies } from 'react-cookie';
import React, { useState, useEffect } from "react";

function SelectPortalModal({ userPortals, setCurrentPortal }) {
    const handlePortalChange = (event) => {
        const selectedPortal = event.target.value;
        setCurrentPortal(selectedPortal);
        sessionStorage.setItem('selectedPortal', selectedPortal);
    };

    // Check if the sessionObject is already set when the component mounts
    useEffect(() => {
        if (sessionStorage.getItem('selectedPortal') && userPortals.includes(sessionStorage.getItem('selectedPortal'))) {
            setCurrentPortal(sessionStorage.getItem('selectedPortal'));
            sessionStorage.setItem('selectedPortal', sessionStorage.getItem('selectedPortal'));
        }
    }, [sessionStorage.getItem('selectedPortal'), userPortals]);

    const availablePortalsCount = userPortals.length;
    if (availablePortalsCount == 1) {
        setCurrentPortal(userPortals[0]);
        sessionStorage.setItem('selectedPortal', userPortals[0]);

    } else {
        console.log(userPortals[0]);

        return (
            <ModalPopup
                showModal={true}
                // handleShowModal={toggleDisplayDeleteConfirm}
                popupTitle={"Select the Portal for this Session"}
                popupContent={
                    <div className="flex flex-column justify-center flex-row items-center p-4">
                        <p>Looks like you have account for multiple portals, please select the portal you want to use for this session:</p>
                        <select onChange={handlePortalChange} className="text-2xl p-2">
                            <option key={null} value={null}>Select One</option>
                            {
                                userPortals.map((portalName) => (<option key={portalName} value={portalName}>{portalName}</option>))
                            }
                        </select>
                    </div>
                }
            // cancelButtonText={'Cancel'}
            // confirmButtonText={'Delete'}
            // confirmCallbackAction={handleDeleteProductConfirm}
            />
        );
    }



}
export default SelectPortalModal;