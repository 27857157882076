import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { makeStyles } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles(() => ({
  icon: {
    padding: 0,
    "& svg": {
      fontSize: 50
    }
  }
}));

const StoreStaff = ({ name, className = "", onClick }) => {
  const classes = useStyles();
  return (
    <div className={className + " flex w-[250px] shadow-sm p-1 rounded-md border-2 gap-2"} onClick={onClick}>
      <IconButton className={classes.icon}>{<PersonIcon />}</IconButton>
      <div className="flex flex-column justify-center">
        <span className="text-lg">{name}</span>
      </div>
    </div>
  );
};

export default StoreStaff;
