import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { api } from '../../utils/api';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the required components from Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ChartDataLabels
);

export const fetchRedemptionData = async (dataSource, appliedFilter) => {
    console.log("APPLIEd Filter RCU.js", appliedFilter);
    const endpoint = dataSource === 'redemptions' ? 'v1/redemption/search' : 'v1/transaction/search';
    const response = await api(endpoint, "POST", { query: appliedFilter });
    return response.data.data.searchResult;
};

export const countRedemptionsByMerchantAccount = (redemptionData) => {
    const redemptionByMerchantAccount = {};
    redemptionData.forEach(sale => {
        const merchantName = sale.merchantStore?.merchantAccount?.name || 'Unknown';
        if (!redemptionByMerchantAccount[merchantName]) {
            redemptionByMerchantAccount[merchantName] = { count: 0, totalPrice: 0 };
        }
        redemptionByMerchantAccount[merchantName].count += 1;
        redemptionByMerchantAccount[merchantName].totalPrice += parseInt(sale.price) || 0;
    });
    return redemptionByMerchantAccount;
};

export const countRedemptionsByMerchantStore = (redemptionData, merchantAccount = null) => {
    const redemptionByMerchantStore = {};
    redemptionData.forEach(sale => {
        if (!merchantAccount || sale.merchantStore?.merchantAccount?.name === merchantAccount) {
            const storeName = sale.merchantStore?.name || 'Unknown';
            if (!redemptionByMerchantStore[storeName]) {
                redemptionByMerchantStore[storeName] = { count: 0, totalPrice: 0 };
            }
            redemptionByMerchantStore[storeName].count += 1;
            redemptionByMerchantStore[storeName].totalPrice += parseInt(sale.price) || 0;
        }
    });
    return redemptionByMerchantStore;
};

export const countRedemptionsByProduct = (redemptionData, merchantStore) => {
    const redemptionByProduct = {};
    redemptionData.forEach(sale => {
        if (sale.merchantStore?.name === merchantStore) {
            const productName = sale.product?.name || 'Unknown';
            if (!redemptionByProduct[productName]) {
                redemptionByProduct[productName] = { count: 0, totalPrice: 0 };
            }
            redemptionByProduct[productName].count += 1;
            redemptionByProduct[productName].totalPrice += parseInt(sale.price) || 0;
        }
    });
    return redemptionByProduct;
};
