import ModalPopup from "./ModalPopup";
import { useCookies } from 'react-cookie';
import React, { useState, useEffect } from "react";

function SelectCompanyModal({ setCurrentCompany }) {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    let userCompanies = currentUser ? (currentUser.partnercompanies ?? null) : null;

    const handleCompanyChange = (event) => {
        const selectedCompany = event.target.value;
        setCurrentCompany(selectedCompany);
        sessionStorage.setItem('selectedCompany', selectedCompany);
    };

    // Check if the sessionObject is already set when the component mounts
    useEffect(() => {
        if (sessionStorage.getItem('selectedCompany') && (userCompanies && userCompanies.includes(sessionStorage.getItem('selectedCompany')))) {
            console.log("Company is selected: ", sessionStorage.getItem('selectedCompany'));
            setCurrentCompany(sessionStorage.getItem('selectedCompany'));
        }
    }, [sessionStorage.getItem('selectedCompany'), userCompanies]);

    const availableCompaniesCount = userCompanies?.length || 0;
    if (availableCompaniesCount == 1) {
        setCurrentCompany(userCompanies[0].value);
        sessionStorage.setItem('selectedCompany', userCompanies[0].value);

    } else {
        if (availableCompaniesCount < 1 || sessionStorage.getItem('selectedCompany')) {
            console.log("availableCompaniesCount", availableCompaniesCount);
            console.log("selected in session", sessionStorage.getItem('selectedCompany'));
            console.log("Not Partner or Company already selected", sessionStorage.getItem('selectedCompany'));
            return;
        }
        else {
            console.log("User associated with multiple companies: ", userCompanies);
            return (
                <ModalPopup
                    showModal={true}
                    // handleShowModal={toggleDisplayDeleteConfirm}
                    popupTitle={"Select the Company for this Session"}
                    popupContent={
                        <div className="flex flex-column justify-center flex-row items-center p-4">
                            <p>Looks like you are associated to multiple companies, please select the company you want to use for this session:</p>
                            <select onChange={handleCompanyChange} className="text-2xl p-2">
                                <option key={null} value={null}>Select One</option>
                                {
                                    currentUser.partnercompanies.map((companyObj) => (<option key={companyObj.value} value={companyObj.value}>{companyObj.label}</option>))
                                }
                            </select>
                        </div>
                    }
                // cancelButtonText={'Cancel'}
                // confirmButtonText={'Delete'}
                // confirmCallbackAction={handleDeleteProductConfirm}
                />
            );
        }
    }



}
export default SelectCompanyModal;