import React from "react";
import Image1 from "../../assets/images/empty-state-images/empty-state1.png";
import GroupTherapy from "../../assets/images/empty-state-images/group-therapy.png";
import IdeaMoney from "../../assets/images/empty-state-images/ideamoney_1.png";
import Franchises from "../../assets/images/empty-state-images/franchise.png";
import CloudComputing from "../../assets/images/empty-state-images/cloud-computing.png";
import Vehicle from "../../assets/images/empty-state-images/vehicle.png";
import Cashback from "../../assets/images/empty-state-images/cashback_2.png";

export const emptyStateMapping = {
  admin: {
    img: Image1,
    alt: "No admins image"
  },
  products: {
    img: Image1,
    alt: "No products image"
  },
  customers: {
    img: GroupTherapy,
    alt: "No customers image"
  },
  partnerCompanies: {
    img: IdeaMoney,
    alt: "No partner companies image."
  },
  merchantAccounts: {
    img: Franchises,
    alt: "No merchant accounts image."
  },
  merchantUsers: {
    img: Franchises,
    alt: "No merchant users image."
  },
  merchantStores: {
    img: Franchises,
    alt: "No merchant stores image."
  },
  partnerUsers: {
    img: CloudComputing,
    alt: "No partner users image."
  },
  vehicles: {
    img: Vehicle,
    alt: "No vehicles image."
  },
  partnerPricing: {
    img: Cashback,
    alt: "No partner pricings found."
  }
};

export const Fallback = {
  img: Image1,
  alt: "No data found."
}
