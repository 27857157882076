import React from "react";
import { useNavigate } from "react-router-dom";
const UserList = ({ userList, selectedCompany = false, merchant = false }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {merchant &&
        userList.map((user) => (
          <div
            key={user._id}
            className="w-1/4 p-4 cursor-pointer partnerCompanyUserListItem"
            // onClick={() => handleUserClick(user._id)}
            onClick={() => {
              selectedCompany
                ? navigate("/partner/" + selectedCompany._id + "/user/" + user._id)
                : navigate(`/merchant/${merchant._id}/user/${user._id}`);
            }}
          >
            <h2 className="text-2xl font-bold">
              {user.firstName} {user.lastName}
            </h2>
            <div className="flex flex-column gap-2 inline-flex">
              <a href="javascript:void(0);">{user.email}</a>
              {/* <a href={"mailto:"+ user.email}>{user.email}</a> */}
              <a href="javascript:void(0);">{user.phone}</a>
              {/* <a href={"tel:"+user.phone}>{user.phone}</a> */}
              {/* <div>
                {user.address.street}, {user.address.city},{" "}
                {user.address.postcode} {user.address.state}{" "}
                {user.address.country.label}
              </div> */}
            </div>
          </div>
        ))}
    </div>
  );
};

export default UserList;
