import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import RedemptionForm from "./Forms/RedemptionForm";
import { useNavigate } from "react-router-dom";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { DATA_GRID_STYLES } from "../../components/BasicUserInfo";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { COLORS } from "../../utils/colors";
import ModalImage from "react-modal-image";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const RedemptionSingle = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [editRedemptionState, setEditRedemptionState] = useState(false);

  const [selectedRedemption, setSelectedRedemption] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
  const [isVerifiedLoading, setIsVerifiedLoading] = useState(false);

  const fetchRedemptionDetails = async () => {
    try {
      const response = await api("v1/redemption/" + params.idRedemption, "GET", null);
      if (response.data.success) {
        // const vehicle = await api(`v1/vehicle/${response.data.data.redemption.vehicle}`, "GET");
        // const product = await api(`v1/product/${response.data.data.redemption.product}`, "GET");

        const redemptionImage = response.data.data.redemption.image
          ? await api(`v1/fetchFileFromCloud/url/${encodeURIComponent(response.data.data.redemption.image)}`, "GET")
          : null;

        // const merchantStore = response.data.data.redemption.merchantStore
        //   ? await api(`v1/merchantStore/${response.data.data.redemption.merchantStore}`, "GET")
        //   : null;
        // const merchantUser = response.data.data.redemption.merchantUser
        //   ? await api(`v1/merchantUser/${response.data.data.redemption.merchantUser}`, "GET")
        //   : null;
        const invoiceDetails = response.data.data.redemption.invoiceID
          ? await api(`v1/redemption/${params.idRedemption}/invoiceDetails`, "GET")
          : null;

        invoiceDetails && await api(`v1/redemption/${params.idRedemption}/updateInvoiceStatus`, "PATCH", { invoiceStatus: invoiceDetails.data.data.invoiceDetails[0].Status })

        // response.data.data.redemption.vehicle = vehicle.data.data.vehicle;
        // response.data.data.redemption.product = product.data.data.product;
        // response.data.data.redemption.merchantStore = merchantStore ? merchantStore.data.data.merchantStore : null;
        // response.data.data.redemption.merchantUser = merchantUser ? merchantUser.data.data.merchantUser : null;

        response.data.data.redemption.image = redemptionImage ? redemptionImage.data.data.url : null;
        response.data.data.redemption.invoiceDetails = invoiceDetails
          ? invoiceDetails.data.data.invoiceDetails[0]
          : null;

        setSelectedRedemption(response.data.data.redemption);
        console.log(response.data.data.redemption);

        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  // const getCurrentUser = async () => {
  //   const currUser = JSON.parse(sessionStorage.getItem("currentUser"));
  //   const response = await api("v1/user/" + currUser._id, "GET", null);
  // };

  useEffect(() => {
    fetchRedemptionDetails();
  }, []);

  const handleDeleteRedemptionConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/redemption/" + selectedRedemption._id, "DELETE", null);
      if (response.data.success) {
        window.location.replace("/redemption");
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editRedemption = () => {
    setEditRedemptionState((prevValue) => !prevValue);
  };

  const reloadRedemptionDetail = (updateRedemptionDetail) => {
    setEditRedemptionState((prevValue) => !prevValue);
    setSelectedRedemption(updateRedemptionDetail);
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

    return formattedDate;
  }

  const handleChange = async (e) => {
    setIsVerifiedLoading(true);

    const response = await api(`v1/redemption/verify`, "POST", {
      idRedemption: selectedRedemption._id,
      status: true
    });

    if (response.data.success) {
      setSelectedRedemption({ ...selectedRedemption, verified: true });
    }

    setIsVerifiedLoading(false);
  };

  return (
    selectedRedemption && (
      <div className="page partner-user-single w-full flex flex-column items-center min-h-h-screen p-4 gap-8">
        {loading && <GlobalLoadingSpinner />}
        <div className="pageTitle flex flex-col text-center sm:w-full sm:flex-row sm:justify-between">
          <h1>Redemption Details</h1>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
        <div className="flex w-full justify-start">
          {selectedRedemption.verified ? (
            <div>
              <IconButton>
                <CheckBoxIcon sx={{ color: "green" }} />
              </IconButton>
              <span>Verified</span>
            </div>
          ) : isVerifiedLoading ? (
            <CircularProgress />
          ) : (
            sessionStorage.getItem("selectedPortal") == "Administrator" && (
              <FormControlLabel
                value={selectedRedemption.verified}
                control={<Switch />}
                label={selectedRedemption.verified ? "Verified" : <span className="font-semibold">Verify</span>}
                disabled={selectedRedemption.verified}
                onChange={handleChange}
              />
            )
          )}
        </div>
        {/* Redemption Single Content */}
        <div className="w-full">
          <div
            className={`
                        vehicle-single
                        max-w-[580px]
                        rounded-lg
                        ${DATA_GRID_STYLES}
                    `}
          >
            {/* <h2>{selectedRedemption.name}</h2> */}

            <span>Product</span>
            <span>
              <a href="javascript:void(0);" onClick={() => navigate("/product/" + selectedRedemption.product._id)}>
                <span>
                  Name: <strong> {selectedRedemption.product.name}</strong>
                </span>
                <br />
                <span>
                  Type: <strong> {selectedRedemption.product.type}</strong>
                </span>
                <br />
                <span>
                  Duration: <strong> {selectedRedemption.product.subscriptionDuration} month(s)</strong>
                </span>
              </a>
            </span>

            <span>
              Vehicle
              <ModalImage small={selectedRedemption.image} large={selectedRedemption.image} alt="Vehicle Image" />
            </span>

            <span>
              <a href="javascript:void(0);" onClick={() => navigate("/vehicle/" + selectedRedemption.vehicle._id)}>
                <span>
                  Rego: <strong> {selectedRedemption.vehicle.rego} </strong>
                </span>
                <br />
                <span>
                  Vin: <strong> {selectedRedemption.vehicle.vin}</strong>
                </span>
                <br />
                <span>
                  Make: <strong>{selectedRedemption.vehicle.make} </strong>
                </span>
                <br />
                <span>
                  Model: <strong> {selectedRedemption.vehicle.model}</strong>
                </span>
                <br />
                <span>
                  Model Year: <strong>{selectedRedemption.vehicle.modelYear} </strong>
                </span>
                <br />
                <span>
                  Color: <strong> {selectedRedemption.vehicle.color}</strong>
                </span>
                <br />
                <span>
                  Age: <strong>{selectedRedemption.vehicle.age} </strong>
                </span>
              </a>
            </span>
            {/* <p>
                                <strong> <a href={"/vehicle/" + selectedRedemption.vehicle._id}> {selectedRedemption.vehicle.rego}  ({selectedRedemption.vehicle.vin}) </a> </strong>
                                </p> */}

            {selectedRedemption.merchantStore && (
              <>
                <span>Merchant Store </span>
                <span>
                  <a
                    href="javascript:void(0);"
                    onClick={() =>
                      navigate(
                        "/merchant/" +
                        selectedRedemption.merchantStore.merchantAccount._id +
                        "/store/" +
                        selectedRedemption.merchantStore._id
                      )
                    }
                  >
                    <strong> {selectedRedemption.merchantStore.name} </strong>
                  </a>
                </span>
              </>
            )}

            {selectedRedemption.merchantUser && (
              <>
                <span>Merchant User </span>
                <span>
                  <a
                    href="javascript:void(0);"
                    onClick={() =>
                      navigate(
                        "/merchant/" +
                        selectedRedemption.merchantUser.merchantAccount +
                        "/user/" +
                        selectedRedemption.merchantUser._id
                      )
                    }
                  >
                    <strong>
                      {" "}
                      {selectedRedemption.merchantUser.firstName +
                        " " +
                        selectedRedemption.merchantUser.lastName +
                        " (" +
                        selectedRedemption.merchantUser.email +
                        ")"}{" "}
                    </strong>
                  </a>
                </span>
              </>
            )}
            <span>Redemption Date (UTC) </span>
            <span>{formatDate(selectedRedemption.redemptionDateUTC)}</span>
            {/* <div className="flex flex-nowrap items-center justify-between w-full border-b-[2px]">
                            <p>Redemptions: </p>
                            <ul className="font-bold">
                                {selectedRedemption.redemptions && selectedRedemption.redemptions.map((val, index) => { return <li>{formatDate(val)}</li> })}
                            </ul>
                        </div> */}

            {/* <div className="flex flex-nowrap items-center justify-between w-full border-b-[2px]">
                            <p>Created On: </p>
                            <p> <strong> {formatDate(selectedRedemption.createdOnUTC)} </strong></p>
                        </div> */}
            <span>Active </span>
            <span>{selectedRedemption.active ? "Yes" : "No"}</span>
            <span>Invoice ID </span>
            <span>{selectedRedemption.invoiceDetails?.InvoiceID ?? ""}</span>
            <span>Invoice Status </span>
            <span>{selectedRedemption.invoiceDetails?.Status ?? "Verify to Generate Bill"}</span>
            <span>Invoice Amount </span>
            <span>${selectedRedemption.invoiceDetails?.Total ?? "0"}</span>
          </div>

          {sessionStorage.getItem("selectedPortal") == "Administrator" && (
            <div>
              <div className="actionItems flex gap-4">
                <Button
                  className="self-start m-4"
                  sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                  variant="contained"
                  onClick={editRedemption}
                >
                  Edit Redemption
                </Button>
                <Button
                  className="self-start bg-red-600 m-4"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    toggleDisplayDeleteConfirm(true);
                  }}
                >
                  Delete Redemption
                </Button>
              </div>
            </div>
          )}
        </div>

        {/* // Edit Modal HTML */}

        <ModalPopup
          showModal={editRedemptionState}
          handleShowModal={editRedemption}
          popupTitle={"Edit Redemption"}
          popupContent={
            <RedemptionForm
              selectedRedemption={selectedRedemption}
              callback={reloadRedemptionDetail}
              closeModal={editRedemption}
            />
          }
          cancelButtonText=""
          confirmButtonText=""
          confirmCallbackAction=""
        />

        {/* Delete Redemption Confimation Modal */}
        <ModalPopup
          showModal={deleteConfirmationIsOpen}
          handleShowModal={toggleDisplayDeleteConfirm}
          popupTitle={"Delete Redemption"}
          popupContent={
            <div>
              Are you sure you want to delete this redemption for Vehicle
              <strong>
                <em> {selectedRedemption.vehicle.rego + " ( Product:" + selectedRedemption.product.name + ")"} </em>
              </strong>
              ?
            </div>
          }
          cancelButtonText={"Cancel"}
          confirmButtonText={"Delete"}
          confirmCallbackAction={handleDeleteRedemptionConfirm}
        />
      </div>
    )
  );
};

export default RedemptionSingle;
