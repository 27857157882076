import { React, useState, useEffect } from "react";
import { api } from "../../../../../utils/api";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ModalPopup from "../../../../../components/ModalPopup";

import GlobalLoadingSpinner from "../../../../../components/GlobalLoadingSpinner";
import MerchantStoreInfoTable from "../../../../../components/MerchantStoreInfoTable";
import MerchantStoreForm from "../../../../../components/Forms/MerchantStoreForm";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { COLORS } from "../../../../../utils/colors";
import StoreStaff from "./StoreStaff";
import MerchantStorePricingForm from "../../../../MerchantStorePricing/Forms/MerchantStorePricingForm";

const MerchantStoreSingle = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [editMerchantStoreState, setEditMerchantStoreState] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
  const [showMerchantStorePricingForm, setShowMerchantStorePricingForm] = useState(false);

  useEffect(() => {
    fetchStoreDetails();
  }, []);

  const fetchStoreDetails = async () => {
    console.log("params: ", params);
    try {
      const storeResponse = await api(`v1/merchant/${params.idMerchant}/store/${params.idStore}`, "GET", null);
      if (storeResponse.data.success) {
        const selectedStore = storeResponse.data.data.merchantStore;

        // Fetch pricing details from the separate endpoint
        const pricingResponse = await api(`v1/merchantStorePricing/store/${params.idStore}`, "GET", null);
        if (pricingResponse.data.success) {
          // const pricingDetails = pricingResponse.data.data; // Adjust this based on the structure of the response
          // selectedStore.pricing = pricingDetails.merchantStorePricings; // Assuming 'pricing' is the key for pricing details

          const merchantStorePricingOptions = pricingResponse.data.data.merchantStorePricings;
          const updatedMerchantStorePricingOptions = await Promise.all(
            merchantStorePricingOptions.map(async (merchantStorePricingOption) => {
              try {
                const productResponse = await api(`v1/product/${merchantStorePricingOption.product}`, "GET");
                if (productResponse.data.success) {
                  return {
                    ...merchantStorePricingOption,
                    product: productResponse.data.data.product.name
                  };
                }
              } catch (error) {
                console.error("API error:", error);
              }
            })
          );
          selectedStore.pricing = updatedMerchantStorePricingOptions;
        }

        setSelectedStore(selectedStore);
        setLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const handleDeleteStoreConfirm = async () => {
    setLoading(true);
    try {
      const response = await api(`v1/merchant/${params.idMerchant}/store/${params.idStore}`, "DELETE", null);
      if (response.data.success) {
        window.location.replace("/merchant/" + params.idMerchant);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const handleDeleteStoreCancel = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };
  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editMerchantStore = () => {
    setEditMerchantStoreState((prevValue) => !prevValue);
    // setOpenModal(true);
  };

  const reloadStoreDetail = (updateCompanyDetail) => {
    setEditMerchantStoreState((prevValue) => !prevValue);
    setSelectedStore(updateCompanyDetail);
  };

  const fieldsToDisplay = [{ key: "name", label: "Staff Name", className: "font-bold" }];
  console.log("editMerchantStoreState", editMerchantStoreState);
  return (
    selectedStore && (
      <div className="page merchant-store-single flex flex-column items-center min-h-h-screen p-4 gap-8 w-full">
        {loading && <GlobalLoadingSpinner />}
        <ModalPopup
          showModal={editMerchantStoreState}
          handleShowModal={editMerchantStore}
          popupTitle={"Edit Merchant Store"}
          popupContent={
            <MerchantStoreForm
              merchant={{ _id: params.idMerchant }}
              merchantStore={selectedStore}
              fetchStoreDetails={fetchStoreDetails}
              handleDisplayModal={editMerchantStore}
            />
          }
          cancelButtonText=""
          confirmButtonText=""
          confirmCallbackAction=""
        />

        <div className="w-full flex flex-row justify-between items-center">
          <div>
            <h2 className="font-bold">{selectedStore.name}</h2>
          </div>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        <div className="flex flex-row gap-2 w-full justify-between items-start">
          {selectedStore && (
            <div className="w-full border p-4">
              <h5 className="mb-3">Store Information</h5>
              <MerchantStoreInfoTable data={selectedStore} />
              <div className="actionItems flex gap-4">
                <Button
                  sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                  className="self-start m-4"
                  variant="contained"
                  onClick={editMerchantStore}
                >
                  Edit Merchant Store
                </Button>
                {sessionStorage.getItem("selectedPortal") == "Administrator" && (
                  <Button
                    className="self-start bg-red-600 m-4"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      toggleDisplayDeleteConfirm(true);
                    }}
                  >
                    Delete Merchant Store
                  </Button>
                )}
              </div>
              <ModalPopup
                showModal={deleteConfirmationIsOpen}
                handleShowModal={toggleDisplayDeleteConfirm}
                popupTitle={"Delete Store"}
                popupContent={
                  <div>
                    Are you sure you want to delete store:
                    <strong>
                      <em>{selectedStore.name}</em>
                    </strong>
                    ?
                  </div>
                }
                cancelButtonText={"Cancel"}
                confirmButtonText={"Delete"}
                confirmCallbackAction={handleDeleteStoreConfirm}
              />
            </div>
          )}
          {selectedStore && (
            <div className="merchantStore_staffs w-full border p-4 flex flex-column gap-2 cursor-pointer">
              <h5>Store Staff</h5>
              {selectedStore.staff.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {selectedStore.staff.map((staff) => (
                    <StoreStaff
                      name={staff.label}
                      onClick={() => navigate(`/merchant/${selectedStore.merchantAccount_id}/user/${staff.value}`)}
                    />
                  ))}
                </div>
              ) : (
                <span className="pt-2">No Staff</span>
              )}
            </div>
          )}
          {selectedStore && (
            <div className="merchantStore_pricings w-full border p-4 flex flex-column gap-2 cursor-pointer">
              <h5>Available Pricings</h5>
              {sessionStorage.getItem("selectedPortal") == "Administrator" && (
                <div className="merchantStore_pricings_addPricing">
                  <Button
                    sx={{ backgroundColor: COLORS.priorityBlue.hex }}
                    variant="contained"
                    onClick={() => setShowMerchantStorePricingForm(true)}
                  >
                    Add New Pricing
                  </Button>
                  <ModalPopup
                    showModal={showMerchantStorePricingForm}
                    handleShowModal={() => setShowMerchantStorePricingForm(false)}
                    popupTitle="Add New Store Pricing Option"
                    popupContent={
                      <MerchantStorePricingForm
                        callback={fetchStoreDetails}
                        cancelModal={() => {
                          setShowMerchantStorePricingForm(false);
                        }}
                        onlyStore={selectedStore._id}
                      />
                    }
                  />
                </div>
              )}
              {selectedStore.pricing?.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {selectedStore.pricing.map((pricing) => (
                    <div
                      className="flex flex-col shadow-md p-4 rounded-md border-1 w-[450px] hover:cursor-pointer "
                      onClick={() => navigate(`/merchantStorePricing/${pricing._id}`)}
                    >
                      <div className="grid grid-cols-2 gap-x-4">
                        <span className="font-semibold">Product</span>
                        <span>{pricing.product}</span>
                        <span className="font-semibold">Price</span>
                        <span>${pricing.price}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <span className="pt-2">No Pricing Added Yet</span>
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default MerchantStoreSingle;
