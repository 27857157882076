import React, { useState, useEffect } from "react";
import { useZxing } from "react-zxing";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { api } from "../../../utils/api";
import GlobalLoadingSpinner from "../../../components/GlobalLoadingSpinner";
import VehicleImageUpload from "./VehicleImageUpload";
import Compressor from "compressorjs";

const RedemptionScanner = ({ callback, closeModal }) => {
  const [scanResult, setScanResult] = useState("");
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState("qrcode");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [vehicleImage, setVehicleImage] = useState(null);
  const [redemptionData, setRedemptionData] = useState({
    vehicle: null,
    product: null
  });

  const { ref } = useZxing({
    onDecodeResult(scanResult) {
      setScanResult(scanResult.getText());
    }
  });

  const handleVideoPlaying = () => setIsLoadingVideo(false);

  const handleSubmit = async (result) => {
    setError("");
    setIsSubmitting(true);
    const subscriptionResponse = await api(`v1/subscription/${result}`, "GET");

    if (subscriptionResponse.data.success) {
      const { active, vehicle, product } = subscriptionResponse.data.data.subscription;
      if (!active) {
        setError("This subscription is not active.");
      } else {
        setStep("file-upload");
        setRedemptionData({
          vehicle,
          product
        });
      }
    } else {
      setError(`There was an issue with finding subscription: ${result}`);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const video = document.querySelector("video");
    if (video) {
      video.addEventListener("playing", handleVideoPlaying);
      return () => {
        video.removeEventListener("playing", handleVideoPlaying);
      };
    }
  }, []);

  useEffect(() => {
    scanResult && handleSubmit(scanResult);
  }, [scanResult]);

  const handleVehicleUpload = async (e) => {
    e.preventDefault();
    setError("");

    if (!vehicleImage) {
      alert("You must upload image of the Vehicle to redeem.");
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("vehicle", redemptionData.vehicle._id);
    formData.append("product", redemptionData.product._id);
    formData.append("merchantStore", sessionStorage.getItem("selectedMerchantStore"));
    formData.append("merchantUser", JSON.parse(sessionStorage.getItem("currentUser"))._id);
    formData.append("redemptionDateUTC", new Date());
    formData.append("active", true);
    formData.append("vehicleImage", vehicleImage);

    const redemptionResponse = await api(`v1/redemption`, "POST", formData, {
      "Content-Type": "multipart/form-data" // Ensure proper headers for form data
    });

    if (redemptionResponse.data.success) {
      setSuccess("Redeemed Successfully!");
      setTimeout(() => {
        closeModal();
        callback();
      }, 2000);
    } else {
      setError(redemptionResponse.data.message);
    }

    setIsSubmitting(false);
  };

  const handleVehicleChange = (e) => {
    const { files } = e.target;
    if (files[0].type.startsWith("image/") == false) {
      alert("You must upload image of the Vehicle to redeem.");
      e.target.value = null;
      return;
    }
    new Compressor(files[0], {
      quality: 0.8,
      maxHeight: 700,
      maxWidth: 700,
      success: (compressedResult) => {
        setVehicleImage(compressedResult);
      }
    });
  };

  const ErrorMessage = () =>
    error && (
      <>
        <br />
        <span style={{ color: "red" }}>{error}</span>
      </>
    );

  return (
    <div>
      {isSubmitting && <GlobalLoadingSpinner />}
      {step === "qrcode" ? (
        <div className="text-center">
          <span className="w-full text-lg mb-2">Step 1 – Scan QR Code</span>
          <ErrorMessage />
          {isLoadingVideo && (
            <div style={{ textAlign: "center" }}>
              <span>Video is loading. Please wait.</span>
            </div>
          )}
          <video ref={ref} />
          <Divider sx={{ my: "1rem" }}>or enter Subscription ID manually</Divider>
          <div className="flex">
            <TextField
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              id="outlined-basic"
              label="Enter Subscription ID..."
              variant="outlined"
              fullWidth
            />
            <Button
              size="small"
              sx={{ marginLeft: "0.5rem" }}
              variant="contained"
              onClick={() => {
                handleSubmit(inputValue);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : step === "file-upload" ? (
        <div className="text-center">
          {success ? (
            <span className="font-semibold text-lg" style={{ color: "green" }}>
              {success}
            </span>
          ) : (
            <>
              <span>Step 2 – Upload Image of Vehicle</span>
              <ErrorMessage />
              <div className="mt-2">
                <VehicleImageUpload handleChange={handleVehicleChange} handleSubmit={handleVehicleUpload} />
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default RedemptionScanner;
