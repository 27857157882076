import { React, useEffect, useState } from "react";
import "./style.css";
import GlobalLoadingSpinner from "../../../components/GlobalLoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../utils/api";
import { Modal, Box, Button, Typography } from "@mui/material";
import ModalPopup from "../../../components/ModalPopup";
import EditForm from "../../../components/Forms/EditForm";
import MerchantInfo from "./merchantInfo";
import MerchantUsers from "./merchantUsers";
import MerchantStores from "./merchantStores";
import MerchantUserForm from "../../../components/Forms/MerchantUserForm";
import MerchantStoreForm from "../../../components/Forms/MerchantStoreForm";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const MerchantAccountSingle = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState(null);
  const [merchantUsers, setMerchantUsers] = useState(null);
  const [merchantStores, setMerchantStores] = useState(null);
  const [merchantUserLoading, setMerchantUserLoading] = useState(null);
  const [merchantStoreLoading, setMerchantStoreLoading] = useState(null);
  const [activeTab, setActiveTab] = useState("info");
  const [displayModal, handleDisplayModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const merchantId = params.id;

  const getMerchant = async () => {
    //get id from url
    try {
      const response = await api(`v1/merchant/${merchantId}`, "GET");
      if (response.data.success) {
        // console.log("merchant info ", response.data.data);
        setMerchant(response.data.data.merchant);
      }
      let users = await getMerchantUsers();
      setMerchantUsers(users);
      console.log("users: ", users);
      let stores = await getMerchantStores();
      setMerchantStores(stores);
      setMerchantUserLoading(false);
      setMerchantStoreLoading(false);
    } catch (error) {
      console.error("API error:", error);
    }
    if (document.location.search.indexOf("?tab=") != -1) {
      const currentUrl = new URLSearchParams(document.location.search);
      const activeTab = currentUrl.get("tab");
      handleTabChange(activeTab);
    }
    setLoading(false);
  };

  const getMerchantUsers = async () => {
    setMerchantUserLoading(true);
    let users = [];
    const userResponse = await api(`v1/merchant/${merchantId}/user`, "GET");
    if (userResponse.data.success) {
      console.log("users: ", userResponse.data.data.merchantUsers);
      users = userResponse.data.data.merchantUsers;
    }
    return users;
  };

  const getMerchantStores = async () => {
    setMerchantStoreLoading(true);
    let stores = [];
    const storeResponse = await api(`v1/merchant/${merchantId}/store`, "GET");
    if (storeResponse.data.success) {
      console.log("stores: ", storeResponse.data.data.merchantStores);
      stores = storeResponse.data.data.merchantStores;
    }
    return stores;
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    window.history.replaceState(null, "", window.location.pathname + "?tab=" + tab);
  };

  const handleMerchantDelete = async () => {
    console.log("merchantId: ", merchantId);
    try {
      const response = await api(`v1/merchant/${merchantId}`, "DELETE");
      if (response.data.success) {
        alert(response.data.message);
        handleDisplayModal(false);
        navigate("/merchant");
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  useEffect(() => {
    if (loading) {
      getMerchant();
    }
  }, [loading]);

  return (
    merchant && (
      <div className="page flex w-full flex-column items-center p-4">
        <div className="w-full flex flex-row justify-between items-center">
          <div>
            <h1 className="font-semibold">{merchant.name}</h1>
          </div>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: "1px solid grey" }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>

        <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
          <div className="content">
            <div className="flex flex-column items-end">
              <div className="w-full overflow-auto">
                <div className="mt-4">
                  <div className="flex">
                    <button
                      className={`mr-2 p-2 ${activeTab === "info" ? "bg-darkblue text-white" : ""}`}
                      onClick={() => handleTabChange("info")}
                    >
                      Merchant Information
                    </button>
                    <button
                      className={`mr-2 p-2 ${activeTab === "users" ? "bg-darkblue text-white" : ""}`}
                      onClick={() => handleTabChange("users")}
                    >
                      Merchant Users
                    </button>
                    <button
                      className={`p-2 ${activeTab === "stores" ? "bg-darkblue text-white" : ""}`}
                      onClick={() => handleTabChange("stores")}
                    >
                      Merchant Stores
                    </button>
                  </div>
                  {loading ? (
                    <GlobalLoadingSpinner />
                  ) : activeTab === "info" ? (
                    <MerchantInfo
                      merchant={merchant}
                      setModalTitle={setModalTitle}
                      setModalContent={setModalContent}
                      handleDisplayModal={handleDisplayModal}
                    />
                  ) : activeTab === "users" ? (
                    merchantUserLoading == true ? (
                      <GlobalLoadingSpinner />
                    ) : (
                      <MerchantUsers
                        merchant={merchant}
                        merchantUsers={merchantUsers}
                        merchantUserLoading={merchantUserLoading}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        handleDisplayModal={handleDisplayModal}
                      />
                    )
                  ) : (
                    activeTab === "stores" &&
                    (merchantStoreLoading == true ? (
                      <GlobalLoadingSpinner />
                    ) : (
                      <MerchantStores
                        merchant={merchant}
                        merchantStores={merchantStores}
                        merchantStoreLoading={merchantStoreLoading}
                        setModalTitle={setModalTitle}
                        setModalContent={setModalContent}
                        handleDisplayModal={handleDisplayModal}
                      />
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalPopup
          showModal={displayModal}
          handleShowModal={() => handleDisplayModal(!displayModal)}
          popupTitle={modalTitle}
          popupContent={
            modalContent == "editMerchant" ? (
              <EditForm
                id={merchantId}
                setShowForm={(data) => handleDisplayModal(data)}
                setLoading={(data) => {
                  setLoading(data);
                }}
                updateData={(data) => setMerchant(merchant)}
                type={"merchant"}
                currentValues={merchant}
              />
            ) : modalContent == "deleteMerchant" ? (
              <div>
                Are you sure you want to delete{" "}
                <strong>
                  <em>{merchant.name}</em>
                </strong>
                ?
              </div>
            ) : modalContent == "addMerchantUser" ? (
              <MerchantUserForm
                merchant={merchant}
                handleDisplayModal={() => handleDisplayModal(!displayModal)}
                getMerchantUsers={getMerchantUsers}
                setMerchantUsers={setMerchantUsers}
                setMerchantUserLoading={setMerchantUserLoading}
              />
            ) : modalContent == "addMerchantStore" ? (
              <MerchantStoreForm
                merchant={merchant}
                handleDisplayModal={() => handleDisplayModal(!displayModal)}
                getMerchantStores={getMerchantStores}
                setMerchantStores={setMerchantStores}
                setMerchantStoreLoading={setMerchantStoreLoading}
              />
            ) : (
              modalContent == "addNewStore" && <></>
            )
          }
          cancelButtonText={
            modalContent == "editMerchant"
              ? null
              : modalContent == "deleteMerchant"
                ? "Cancel"
                : modalContent == "addNewStore" && null
          }
          confirmButtonText={
            modalContent == "editMerchant"
              ? null
              : modalContent == "deleteMerchant"
                ? "Confirm Delete"
                : modalContent == "addNewStore" && null
          }
          confirmCallbackAction={
            modalContent == "editMerchant"
              ? null
              : modalContent == "deleteMerchant"
                ? handleMerchantDelete
                : modalContent == "addNewStore" && null
          }
        />
      </div>
    )
  );
};

export default MerchantAccountSingle;
