import axios from "axios";
import { api } from "../../utils/api";

export const getReadableAddress = (fields) => Object.values(fields).join(", ");

export const getAddressField = (fields) =>
  fields.reduce((prev, curr) => {
    const trimmedCurr = curr.replaceAll(" ", "+").replaceAll("/", "+");
    return prev.concat(trimmedCurr, "+");
  }, "");

export const getGeocodeAddress = (fields) =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(getAddressField(fields))}&key=${
    process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY
  }`;

export const accessUsersLocation = (success, error) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log("Geolocation not supported");
  }
};

export const getAllAvailableProducts = (merchants) => {
  const uniqueProducts = {}; // Using an object for efficient lookup
  const result = [];

  if (merchants) {
    merchants.forEach((merchant) => {
      if (merchant.products && merchant.products.length > 0) {
        merchant.products.forEach((product) => {
          if (product && product.value && !uniqueProducts[product.value.value]) {
            uniqueProducts[product.value.value] = true;
            result.push(product);
          }
        });
      }
    });
  }
  return result;
};

export const matchSearchToMerchant = (search, merchant) => {
  const lowercasedSearch = search.toLowerCase();
  return (
    merchant.products.some((product) => product?.label && product?.label.toLowerCase().includes(lowercasedSearch)) ||
    merchant.name.toLowerCase().includes(lowercasedSearch) ||
    getReadableAddress(merchant.address).toLowerCase().includes(lowercasedSearch)
  );
};

export const matchMerchantProducts = (merchant, productList) =>
  merchant.products.some((product) => product && productList.some((p) => p.value == product.value.value));

export const getMerchantsWithCoordinates = async (merchants) => {
  const result = await Promise.all(merchants.map((merchant) => getMerchantAddress(merchant)));
  return result.filter((merchant) => merchant !== null);
};

const getMerchantAddress = async (merchant) => {
  const { address } = merchant;
  const { street, city, state, postcode, country } = address;
  const resp = await axios(getGeocodeAddress([street, city, state, postcode, country]));
  const { results } = resp.data;
  return results && results.length > 0
    ? {
        ...merchant,
        coordinates: results[0].geometry.location
      }
    : null;
};

export const groupMerchantStoresByAccount = (merchants) =>
  merchants.reduce((prev, curr) => {
    if (prev.hasOwnProperty(curr.merchantAccount)) {
      return { ...prev, [curr.merchantAccount]: [...prev[curr.merchantAccount], curr._id] };
    } else {
      return { ...prev, [curr.merchantAccount]: [curr._id] };
    }
  }, {});

export const getMerchantStoreAccountNames = async (merchants) => {
  const result = await Promise.all(
    merchants.map(async (merchant) => {
      const response = await api(`v1/merchant/${merchant.merchantAccount}`, "GET");
      if (response.data.success) {
        return {
          ...merchant,
          merchantAccountName: response.data.data.merchant.name
        };
      } else {
        return { ...merchant };
      }
    })
  );
  return result;
};

export const getMerchantStoresWithProducts = async (merchants) => {
  const result = await Promise.all(
    merchants.map(async (merchant) => {
      const result = await Promise.all(
        merchant.products.map(async (p) => {
          if (p.value) {
            const resp = await api(`v1/product/${p.value}`);
            return resp.data.success
              ? {
                  value: p,
                  label: resp.data.data.product.name
                }
              : null;
          }
        })
      );
      return {
        ...merchant,
        products: result
      };
    })
  );
  return result;
};
