import { React, useState, useEffect } from "react";
import { api } from "../../utils/api";
import { Box, Modal, Typography, TextField, Button } from "@mui/material";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import AddForm from "../../components/Forms/AddForm";
import NewEntityCardDisplay from "../../components/NewEntityCardDisplay";
import StoreIcon from "@mui/icons-material/Store";
import { useNavigate } from "react-router-dom";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import EmptyState from "../EmptyStates/EmptyState";

const MerchantAccount = () => {
  const [currentUser] = useState(JSON.parse(sessionStorage.getItem("currentUser")));
  const [showAddPartnerCompanyForm, setShowForm] = useState(false);
  const [merchantAccounts, setMerchantAccounts] = useState([]);
  const [loading, setLoading] = useState(true);

  const { search, setSearch, filteredData } = useBasicSearch(merchantAccounts);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("loadingL ", loading);
    fetchMerchantAccounts();
  }, [loading]);

  const fetchMerchantAccounts = async () => {
    try {
      let response;
      if (sessionStorage.getItem("selectedPortal") == "Merchant" && currentUser.merchantAccount) {
        response = await api(`v1/merchant/${currentUser.merchantAccount}`, "GET");
        if (response.data.success) {
          setMerchantAccounts([response.data.data.merchant]);
        }
      } else {
        response = await api("v1/merchant", "GET");
        if (response.data.success) {
          setMerchantAccounts(response.data.data.allMerchants);
        }
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateMerchantAccounts = (newData) => {
    setMerchantAccounts([...merchantAccounts, newData]);
  };

  const AddNewMerchant = () =>
    sessionStorage.getItem("selectedPortal") == "Administrator" ? (
      <button className={`bg-darkblue text-white rounded-md py-2 px-4`} onClick={() => setShowForm(true)}>
        ADD NEW MERCHANT
      </button>
    ) : null;

  return (
    <div className="page w-full flex flex-column items-center p-8 gap-4">
      <div className="pageTitle flex flex-col w-full text-center xl:flex-row lg:text-left lg:justify-between lg:mb-8">
        {sessionStorage.getItem("selectedPortal") == "Administrator" && <h1>Merchant Accounts</h1>}
        {sessionStorage.getItem("selectedPortal") == "Merchant" && <h1>My Merchant Account</h1>}
        {sessionStorage.getItem("selectedPortal") == "Administrator" && (
          <div className="pageActions">
            <TextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ width: "340px" }}
              placeholder="Search for a merchant"
            />
            <AddNewMerchant />
          </div>
        )}
      </div>
      <Modal
        open={showAddPartnerCompanyForm}
        onClose={() => setShowForm(false)}
        aria-labelledby="modal-modal-title"
        aria-description="modal-modal-description"
      >
        <Box className="modalContent">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Merchant
          </Typography>
          <AddForm
            setShowForm={setShowForm}
            updateData={(data) => updateMerchantAccounts(data)}
            type={"merchant"}
            setLoading={(data) => setLoading(data)}
          />
        </Box>
      </Modal>
      {loading ? (
        <GlobalLoadingSpinner />
      ) : (
        <div className="w-full flex justify-center">
          {merchantAccounts.length === 0 ? (
            <EmptyState type="merchantAccounts" text="No Merchant Accounts." action={<AddNewMerchant />} />
          ) : filteredData.length === 0 ? (
            <EmptyState type="merchantAccounts" text="No Merchant Accounts match your search." />
          ) : (
            <div className="flex flex-row flex-wrap w-full gap-y-4 gap-x-6 justify-center lg:justify-normal">
              {filteredData.map((account) => (
                <NewEntityCardDisplay
                  key={account._id}
                  displayName={account.name}
                  email={account.billing.email}
                  onClick={() => navigate(`/merchant/${account._id}`)}
                  customIcon={<StoreIcon />}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MerchantAccount;
