import React, { useState, useEffect } from "react";
import { api } from "../../../utils/api";
// import { Button } from "@mui/material";
import ModalPopup from "../../../components/ModalPopup";
import EditPartnerCompanyForm from "../Forms/EditPartnerCompanyForm";
import { DATA_GRID_STYLES } from "../../../components/BasicUserInfo";
import { COLORS } from "../../../utils/colors";
import { FaRegCaretSquareRight } from "react-icons/fa";
import DatePicker from "react-datepicker";

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CompanyBillingDetail = ({ selectedCompany, reloadCompanyDetail }) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [editPartnerCompanyState, setEditPartnerCompany] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
  const [currentUser, setcurrentUser] = useState(JSON.parse(sessionStorage.getItem("currentUser")) || null);
  const [selectedPortal, setselectedPortal] = useState(sessionStorage.getItem("selectedPortal") || null);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  const [invoiceDate, setInvoiceDate] = useState(new Date());

  const editPartnerCompany = () => {
    console.log("Updating Status", editPartnerCompanyState);
    setEditPartnerCompany((prevValue) => !prevValue);
    setOpenModal(true);
  };

  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };
  const handleDeleteCompanyCancel = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };
  const handleDeleteCompanyConfirm = async () => {
    setLoading(true);
    try {
      const response = await api("v1/partner/company/" + selectedCompany._id, "DELETE", null);
      if (response.data.success) {
        window.location.replace("/partner");
      }
    } catch (error) {
      console.error("API error:", error);
    }
    toggleDisplayDeleteConfirm();
    setLoading(false); //once api call is finished.
  };

  const refreshUpdatedContent = (data) => {
    editPartnerCompany();
    reloadCompanyDetail(data);
  };




  const handleConfirm = async () => {
    const invoiceAmount = parseFloat(amount);
    const startDate = invoiceDate ? invoiceDate.toISOString().split('T')[0] : ''; // Convert date to YYYY-MM-DD


    if (!isNaN(invoiceAmount) && invoiceAmount > 0) {
      setLoading(true);
      console.log("Generating recurring invoice for amount:", invoiceAmount);

      // Make API call or further processing here
      const dataToSend = {
        partnerCompanyId: selectedCompany._id,
        invoiceAmount: invoiceAmount,
        startDate: startDate,
      };

      try {
        // Make the API call with a POST request
        const response = await api("v1/partner/createRepeatingInvoice", "POST", dataToSend);

        if (response.data.success) {
          setLoading(false);
          setDialogOpen(false);
          setError('');
          alert(response.data.message);
        } else {
          response?.data?.message && alert(response.data.message);
        }
      } catch (error) {
        // Handle any errors
        console.error("API error:", error);
      }



      setLoading(false);
      setDialogOpen(false);
      setError('');
    } else {
      setError('Invalid amount entered. Please enter a valid number greater than 0.');
    }
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };





  return (
    <div>
      <ModalPopup
        showModal={editPartnerCompanyState}
        handleShowModal={editPartnerCompany}
        popupTitle="Edit Company Information"
        popupContent={
          <EditPartnerCompanyForm
            partnerCompany={selectedCompany}
            reloadCompanyDetail={refreshUpdatedContent}
            editPartnerCompany={editPartnerCompany}
          />
        }
      />

      <div className="mt-4 flex flex-column gap-4 max-w-[800px]">
        <div>
          <h3 className="text-lg font-bold">Billing Details</h3>

          {/* Show notice if company is setup to have subscription but does not have repeating subscription created yet. */}
          {selectedCompany.enableInvoicing && selectedCompany.partnerSubscription && !selectedCompany.repeatingInvoiceID &&
            <div class="w-full bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
              <p class="font-bold">NOTICE</p>
              <p>Repeating invoice for this company has not been created yet.</p>
              <p>Either remove subscription or generate a repeating invoice using the button below.</p>
              {!selectedCompany.ddBankName && <span>Make sure to enter Banking Details prior to generating repeating invoice</span>}
            </div>
          }

          <div className={DATA_GRID_STYLES}>
            <span className="font-semibold">Email: </span>
            <span className="font-semibold">
              <a href={"mailto:" + selectedCompany.billing.email}>{selectedCompany.billing.email}</a>{" "}
            </span>

            <span className="font-semibold">Phone: </span>
            <span className="font-semibold">
              <a href={"tel:" + selectedCompany.billing.phone}>{selectedCompany.billing.phone} </a>
            </span>

            <span className="font-semibold">Invoicing: </span>
            <span className="font-semibold">
              {selectedCompany.enableInvoicing ? 'Enabled' : 'Disabled'}
            </span>

            {selectedCompany.enableInvoicing && selectedCompany.partnerSubscription && (
              <>


                <span className="font-semibold">Direct Debit Information:



                </span>
                <span className="flex flex-column">
                  <span className="font-semibold">Bank: {selectedCompany.ddBankName || "-"}</span>
                  <span className="font-semibold">Account Name: {selectedCompany.ddAccountName || "-"}</span>
                  <span className="font-semibold">BSB: {selectedCompany.ddBsb || "-"}</span>
                  <span className="font-semibold">Account Number: {selectedCompany.ddAccountNumber || "-"}</span>
                </span>
                {selectedCompany.repeatingInvoiceDetails &&
                  <span className="flex flex-column gap-1 col-span-2">
                    <h3>Repeating Invoice Details:</h3>
                    <div className={DATA_GRID_STYLES}>
                      <span> Reference: </span>
                      <span> {selectedCompany.repeatingInvoiceDetails[0].reference}</span>

                      <span> Issued Date: </span>
                      <span> {new Date(selectedCompany.repeatingInvoiceDetails[0].date).toLocaleDateString()}</span>


                      <span> Status: </span>
                      <span> {selectedCompany.repeatingInvoiceDetails[0].status}</span>

                      <span> Total: </span>
                      <span> ${selectedCompany.repeatingInvoiceDetails[0].total}</span>

                      <span> Amount Paid: </span>
                      <span> ${selectedCompany.repeatingInvoiceDetails[0].amountPaid}</span>

                      <span> Amount Due: </span>
                      <span> ${selectedCompany.repeatingInvoiceDetails[0].amountDue}</span>

                    </div>
                  </span>
                }
                <span className="justify-self-center col-span-2 border-0">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setDialogOpen(true)}
                  >
                    Create Repeating Invoice
                  </Button>
                </span>

                <Dialog
                  open={isDialogOpen}
                  onClose={handleCancel}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle className="bg-gray-100 text-gray-900">Create Recurring Invoice</DialogTitle>
                  <DialogContent>
                    <p>Please enter the amount to generate a repeating invoice for this Company. The invoice will repeat every month.</p>
                    <div class="w-full bg-purple-100 border-l-4 border-purple-500 text-white-700 p-0" role="alert">
                      <em>Please enter the amount inclusive of GST</em>
                    </div>
                    <div className="mb-4">
                      <TextField
                        autoFocus
                        margin="dense"
                        id="amount"
                        label="Enter Amount (Including GST)"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        helperText={error}
                        error={!!error}
                      />

                    </div>
                    <div className="mb-4">
                      <p>Select the date you want to start invoicing</p>
                      <DatePicker
                        variant="inline"
                        dateFormat="yyyy-MM-dd"
                        id="date-picker-invoiceDate"
                        margin="normal"
                        label="From date"
                        placeholderText="YYYY-MM-dd"
                        selected={invoiceDate}
                        onChange={(date) => setInvoiceDate(date)}
                        minDate={new Date()} // Set minimum date to today
                      />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                      Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>

              </>
            )}

          </div>

        </div>
        <div>
          <h3 className="text-lg font-bold">Billing Address</h3>
          <div className={DATA_GRID_STYLES}>
            <span>STREET</span>
            <span>{selectedCompany.billing.address.street || "-"}</span>
            <span>CITY</span>
            <span>{selectedCompany.billing.address.city || "-"}</span>
            <span>STATE</span>
            <span>{selectedCompany.billing.address.state || "-"}</span>
            <span>POSTCODE</span>
            <span>{selectedCompany.billing.address.postcode || "-"}</span>
            <span>COUNTRY</span>
            <span>{selectedCompany.billing.address.country.label || "-"}</span>
          </div>
        </div>
        {selectedPortal == "Administrator" && (
          <div className="flex gap-x-2">
            <Button
              sx={{ backgroundColor: COLORS.priorityBlue.hex }}
              variant="contained"
              size="large"
              onClick={editPartnerCompany}
            >
              Edit Billing Details
            </Button>
            <Button
              className="self-start bg-red-600"
              variant="contained"
              color="error"
              size="large"
              onClick={toggleDisplayDeleteConfirm}
            >
              Delete Company
            </Button>
          </div>
        )}
      </div>

      <ModalPopup
        showModal={deleteConfirmationIsOpen}
        handleShowModal={handleDeleteCompanyCancel}
        popupTitle={"Delete Partner Company"}
        popupContent={
          <div>
            Are you sure you want to delete{" "}
            <strong>
              <em>{selectedCompany.name}</em>
            </strong>
            ?
          </div>
        }
        cancelButtonText="Cancel"
        confirmButtonText="Confirm Delete"
        confirmCallbackAction={handleDeleteCompanyConfirm}
      />
    </div >
  );
};

export default CompanyBillingDetail;

