import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
// import EditPartnerCompanyForm from "./Forms/EditPartnerCompanyForm";

import PartnerUser from "./PartnerUser";
import PartnerPricing from "./PartnerPricing";
import CompanyBillingDetail from "./CompanyBillingDetail";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TransactionTable from "../Transaction/TrarnsactionTable";

const PartnerCompanySingle = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(true);

  const [editPartnerCompanyState, setEditPartnerCompany] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState();
  const [partnerTransactions, setPartnerTransactions] = useState(null);
  const [activeTab, setActiveTab] = useState("info");
  const [showPartnerUsersList, setshowPartnerUsersList] = useState(true); // New state variable to track the view
  const [showAddPartnerForm, setShowAddPartnerForm] = useState(false); // New state variable to track the view
  const [showProductForm, setShowProductForm] = useState(false); // New state variable to track the view
  const [triggerUserListUpdate, setTriggerUserListUpdate] = useState(false);
  const [triggerProductListUpdate, setTriggerProductListUpdate] = useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await api("v1/partner/company/" + params.id, "GET", null);
        if (response.data.success) {
          // console.log(response.data.data.partnerCompany);
          setSelectedCompany(response.data.data.partnerCompany);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchCompanyDetails();

    if (document.location.search.indexOf("?tab=") != -1) {
      const currentUrl = new URLSearchParams(document.location.search);
      const activeTab = currentUrl.get("tab");
      handleTabChange(activeTab);
    }
  }, []);

  useEffect(() => {
    const fetchTransactionsOfCompany = async () => {
      try {
        const response = await api(`v1/partner/company/${selectedCompany._id}/transactions`, "GET", null);
        if (response.data.success) {
          // console.log(response.data.data.partnerCompany);
          setPartnerTransactions(response.data.data.allTransactions);
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    selectedCompany && fetchTransactionsOfCompany();


  }, [selectedCompany]);


  const handleTabChange = (tab) => {
    setLoading(true);
    setActiveTab(tab);
    window.history.replaceState(null, "", window.location.pathname + "?tab=" + tab);
    if (tab === "partnerUsers") {
      // setshowPartnerUsersList(true);
    }
    setLoading(false);
  };
  const handleUserListUpdate = () => {
    // Define the logic to update the state or trigger a re-render of the PartnerUserList component in the PartnerCompanyList component
    // For example, if you're using state management with useState, update a state value that triggers a re-render:
    setTriggerUserListUpdate((prevValue) => !prevValue);
  };
  const handleProductListUpdate = () => {
    setTriggerProductListUpdate((prevValue) => !prevValue);
  };
  const toggleDisplayDeleteConfirm = () => {
    setDeleteConfirmationIsOpen(!deleteConfirmationIsOpen);
  };

  const editPartnerCompany = () => {
    console.log("Updating Status", editPartnerCompanyState);
    setEditPartnerCompany((prevValue) => !prevValue);
    setOpenModal(true);
  };

  const reloadCompanyDetail = (updateCompanyDetail) => {
    // console.log(updateCompanyDetail);
    console.log("Updating Status", editPartnerCompanyState);
    setEditPartnerCompany((prevValue) => !prevValue);
    setSelectedCompany(updateCompanyDetail);
  };

  const tabSections = [
    {
      id: "info",
      label: "Company Information",
      content: <CompanyBillingDetail selectedCompany={selectedCompany} reloadCompanyDetail={reloadCompanyDetail} />
    },
    {
      id: "partnerUsers",
      label: "Partner Users",
      content: <PartnerUser selectedCompany={selectedCompany} />
    },
    {
      id: "pricingOptions",
      label: "Available Pricing Options",
      content: <PartnerPricing selectedCompany={selectedCompany} />
    },
    {
      id: "partnerTransactions",
      label: "Partner Transactions",
      content: partnerTransactions && <TransactionTable transactions={partnerTransactions} />
    }
  ];

  if (selectedCompany) {
    return (
      <div className="page partnercompany single flex flex-column w-full items-center min-h-h-screen p-4 gap-2">
        {loading && <GlobalLoadingSpinner />}
        <div className="w-full flex flex-row justify-between items-center">
          <div className="flex">
            {/* <h1>Partner Company</h1> */}
            <h2 className="font-bold">{selectedCompany.name}</h2>
            {selectedCompany.autoGuruAffiliated &&
              <img
                className="w-6 h-6"
                src="https://cdn.autoguru.com.au/images/logos/otto.svg"
                alt="icon"
              />
            }

          </div>
          <div>
            <IconButton onClick={() => navigate(-1)} sx={{ border: '1px solid grey' }}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
        <div className="page-content min-h-h-screen flex flex-column justify-start w-full">
          <div className="flex flex-column items-end">
            {selectedCompany && (
              <div className="w-full overflow-auto">
                {tabSections && (
                  <div className="mt-4">
                    <div className="flex">
                      {tabSections.map((tab) => (
                        <button
                          className={`mr-2 p-2 ${activeTab === tab.id ? `bg-darkblue text-white` : ""}`}
                          onClick={() => handleTabChange(tab.id)}
                        >
                          {tab.label}
                        </button>
                      ))}
                    </div>
                    {tabSections.map((tab) => activeTab == tab.id && tab.content)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default PartnerCompanySingle;
