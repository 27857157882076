import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

const MerchantStoreInfoTable = ({ data }) => {
  const navigate = useNavigate();
  const store = data;
  return (
    <div>
      {/* <div className="billingDetailsWrap flex items-center gap-4">
                <span className="font-semibold">Email: </span>
                <span className="font-semibold">
                    <a href={"mailto:" + store.email}>{store.email}</a>{" "}
                </span>
            </div> */}

      <div className="billingDetailsWrap flex items-center gap-4">
        <span className="font-semibold">Name: </span>
        <span className="font-semibold">
          <a href="javascript:void(0)">{store.name} </a>
        </span>
      </div>

      <div className="billingDetailsWrap flex items-center gap-4">
        <span className="font-semibold">Billing Address: </span>
        <div>
          <p>{store.address.street}</p>
          <p>{store.address.city}</p>
          <p>{store.address.state}</p>
          <p>{store.address.postcode}</p>
          <p>{store.address.country}</p>
        </div>
      </div>

      <div className="billingDetailsWrap flex items-center gap-4">
        <span className="font-semibold">Merchant Account: </span>
        <span className="font-semibold">
          <a
            className="text-blue-500 cursor-pointer"
            onClick={() => navigate(`/merchant/${store.merchantAccount._id}`)}
          >
            {store.merchantAccount.name}
          </a>
        </span>
      </div>
    </div>
  );
};

export default MerchantStoreInfoTable;
