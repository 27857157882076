import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button, TextField } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import VehicleForm from "./Forms/VehicleForm";
import { api } from "../../utils/api";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import AddIcon from "@mui/icons-material/Add";
import VehicleList from "./VehicleList";
import { COLORS } from "../../utils/colors";
import EmptyState from "../EmptyStates/EmptyState";

const Vehicle = () => {
  const [vehicleItems, setVehicle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showVehicleForm, setShowVehicleForm] = useState(false);

  const { filteredData, search, setSearch } = useBasicSearch(vehicleItems);

  useEffect(() => {
    fetchVehicles();
  }, [sessionStorage.getItem("currentUser")]);

  const fetchVehicles = async () => {
    try {
      let endpoint = null;
      const currentUser = JSON.parse(sessionStorage.getItem("currentUser")) ?? null;
      switch (sessionStorage.getItem("selectedPortal")) {
        case "Administrator":
          endpoint = "v1/vehicle";
          break;
        case "Partner":
          endpoint = `v1/partner/company/${sessionStorage.getItem("selectedCompany")}/vehicles`;
          break;
        case "Merchant":
          const selectedMerchantStore = sessionStorage.getItem("selectedMerchantStore");
          if (selectedMerchantStore) {
            endpoint = `v1/merchantStore/${selectedMerchantStore}/vehicles`;
          }
          break;
        case "Customer":
          endpoint = "v1/vehicle/customer/" + currentUser._id;
          break;
        default:
          break;
      }

      const response = endpoint ? await api(endpoint, "GET") : null;
      if (!response) {
        return;
      }
      if (response.data.success) {
        setVehicle(response.data.data.allVehicles);

      }
      /*
      if (response.data.success) {
        const updatedVehicles = await Promise.all(
          response.data.data.allVehicles.map(async (vehicle) => {
            let updatedCustomers = null;
            const fetchCustomerInfo = await api("v1/customer/" + vehicle.customer, "GET");
            if (fetchCustomerInfo.data.success) {
              const customer = fetchCustomerInfo.data.data.customer;
              console.log("HHHH:  ", customer);
              updatedCustomers = [
                { name: customer.firstName + " " + customer.lastName },
                { phone: customer.phone },
                { email: customer.email },
                { _id: customer._id },
                { address: customer.address }
              ];
            }

            const productIds = vehicle.products; // Assuming vehicle.products is an array of product IDs
            // Fetch and map the product information using the product IDs
            const productPromises = productIds.map(async (productId) => {
              const fetchProductInfo = await api("v1/product/" + productId._id, "GET");
              if (fetchProductInfo.data.success) {
                const product = fetchProductInfo.data.data.product;
                return {
                  _id: product._id,
                  name: product.name
                };
              } else {
                return null;
              }
            });

            const updatedProducts = await Promise.all(productPromises);
            console.log("PRODUCT INFO FETCHE", updatedProducts);

            return {
              ...vehicle,
              customer: updatedCustomers,
              products: updatedProducts
            };
          })
        );

        // setVehicle(response.data.data.allVehicles);
        setVehicle(updatedVehicles);
      }
      */
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
    }
  };

  const triggerVehicleListUpdate = (vehicle) => {
    setShowVehicleForm(false);
    setLoading(false);
    fetchVehicles();
  };

  const AddNewVehicle = () =>
    sessionStorage.getItem("selectedPortal") == "Administrator" ? (
      <Button
        sx={{ backgroundColor: COLORS.priorityBlue.hex }}
        variant="contained"
        onClick={() => {
          setSearch("");
          setShowVehicleForm(true);
        }}
        startIcon={<AddIcon />}
      >
        Add New Vehicle
      </Button>
    ) : null;

  return (
    <div className="page vehicle w-full flex flex-column items-center p-4 gap-y-8">
      <div className="flex pageTitle flex-col items-center md:flex-row md:w-full md:justify-between">
        <h1>Vehicle List</h1>
        <div className="flex flex-col gap-y-2 items-center md:flex-row md:gap-x-2">
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: "250px" }}
            placeholder="Search for a vehicle"
          />
          {sessionStorage.getItem("selectedPortal") == "Administrator" && (
            <div>
              <AddNewVehicle />
              <ModalPopup
                showModal={showVehicleForm}
                handleShowModal={() => setShowVehicleForm(false)}
                popupTitle="Add New Vehicle"
                popupContent={
                  <VehicleForm callback={triggerVehicleListUpdate} closeModal={() => setShowVehicleForm(false)} />
                }
              />
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <GlobalLoadingSpinner />
      ) : vehicleItems.length === 0 ? (
        <EmptyState type="vehicles" text="Adding a vehicle is easy" action={<AddNewVehicle />} />
      ) : filteredData.length === 0 ? (
        <EmptyState type="vehicles" text="No vehicles match your search" />
      ) : (
        filteredData.length > 0 && <VehicleList vehicles={filteredData} />
      )}
    </div>
  );
};

export default Vehicle;
