import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { React, useEffect, useState } from "react";

/** A basic Ssnackbar component to render on the page. 
 *  The parent component simply passes down the variant type
 *  e.g. "success" or "failure" and an associated message.
*/
const CustomSnackbar = ({ variant, message }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!!variant && !!message) setOpen(true);
  }, [variant, message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={variant} sx={{ width: "100%" }}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default CustomSnackbar;
