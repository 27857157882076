import React, { useState } from 'react';
// import Button from '@mui/material/Button';
import CustomButton from './Button';

let email = '';
function subscribeFormSubmit(event){
    event.preventDefault();
    alert ('Handle what to do with email. Submitted Email: '+email);
}
function subscribeFormInputChange(event){
    console.log(event.target.value);
    email = event.target.value;
}

function NewsletterWidget(config){
  let widgetSettings = config.settings;

    return(
        <form onSubmit={subscribeFormSubmit}>
            <div className={widgetSettings.className +' newsletterSubscribeWidget flex justify-center items-center flex-wrap'}>
                
                {
                    widgetSettings.title && (
                        <h3 className='newsletterwidgetHeading subHeading py-4'>{widgetSettings.title}</h3>
                    )
                }
                <div className='subscribeWidgetInnerSection flex md:flex-column items-center justify-evenly flex-wrap gap-4 w-full min-h-[20vh]'>

                
                    {
                        widgetSettings.introText && (
                            <div className='newsletterwidgetText font-bold min-w-[320px]'>{widgetSettings.introText}</div>
                        )
                    }
                    
                    <input type='email' className='min-w-[320px] min-w-[320px]' placeholder='Enter your email' name='email' onChange={subscribeFormInputChange}></input>
                    <input type='submit' className='submitButton bg-black text-white max-w-[264px] w-50 rounded-2xl text-xl font-bold py-3] min-w-[320px]' value={widgetSettings.buttonText} />
                    
                </div>
            </div>
        </form>
    );
        

}
export default NewsletterWidget