import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { Box, Button, FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import { api } from "../../../utils/api";

const InviteCodeForm = ({ selectedInviteCode, callback, cancelModal }) => {
    const today = new Date();
    const [customerOptions, setCustomerOptions] = useState([]);
    const [customer, setCustomer] = useState('');

    const getAllCustomers = async () => {
        try {
            const response = await api("v1/customer", "GET", null);
            if (response.data.success) {
                const allCustomers = response.data.data.allCustomers;
                const result = allCustomers.map((customer) => ({
                    value: customer._id,
                    label: customer.firstName + " " + customer.lastName + " (" + customer.phone + ")",
                }));

                if (result) {
                    setCustomerOptions(result);
                    console.log("Set Options for Customer Options", result);
                    if (selectedInviteCode) {
                        const selectedCustomer = result.find(
                            (customer) => customer._id === selectedInviteCode.customer.value
                        );
                        setCustomer(selectedCustomer);
                    }
                }
            }
        } catch (error) {
            console.error("Error loading partner companies:", error);
        }
    };

    useEffect(() => {
        getAllCustomers();
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("Submitted Data", values);
        const dataToSend = {
            customer: Object(customer.value),
            code: values.code,
            active: values.active,
        };
        console.log("Data to Send", dataToSend);
        if (Object.keys(customer).length === 0) {
            // Having issue with YUP validation will use this method for now.
            alert("Required Fields not selected for customer");
            return false;
        }
        try {
            let response;
            if (selectedInviteCode) {
                // Edit InviteCode Option
                response = await api(`v1/inviteCode/${selectedInviteCode._id}`, "PATCH", dataToSend);
            } else {
                // Add InviteCode Option
                response = await api(`v1/inviteCode`, "POST", dataToSend);
            }

            if (response.data.success) {
                var updatedData = null;

                if (selectedInviteCode) {
                    console.log("InviteCode Updated: ", response.data.data.inviteCode);
                    updatedData = response.data.data.inviteCode;
                } else {
                    if (response.data.data.newInviteCode) {
                        console.log("New InviteCode Created: ", response.data.data.newInviteCode);
                        updatedData = response.data.data.newInviteCode;
                    } else {
                        alert(response.data.message);
                    }
                }
                callback(updatedData);

            } else {
                response?.data?.message && alert(response.data.message);
            }
        } catch (error) {
            console.error("API error:", error);
        }

        setSubmitting(false);
    };



    const loadCustomerOptions = async (inputValue) => {
        try {
            const filteredOptions = customerOptions.filter((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            return filteredOptions;
        } catch (error) {
            console.error("Error loading Customer options:", error);
            return [];
        }
    };

    const handleCustomerChange = (selectedOptions) => {
        if (selectedOptions) {
            setCustomer(selectedOptions);
        } else {
            setCustomer(null);
        }
    };


    const selectStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "white",
            borderColor: "#9e9e9e",
            zIndex: 2,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 3,
        }),
    };


    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .nullable()
            .required("Invite Code is required"),
    });


    return (
        <Formik
            initialValues={{
                customer: selectedInviteCode ? selectedInviteCode.customer?._id : '',
                code: selectedInviteCode ? selectedInviteCode.code : '',
                active: selectedInviteCode ? selectedInviteCode.active : false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form className="form w-full flex flex-column justify-between priorityoneform form-inviteCode min-h-[20vh]">
                    <div className="form-input-wrap flex gap-2 w-full">
                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="customer">
                                        Customer:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="customer">
                                        {({ field }) => (
                                            <AsyncSelect
                                                name="customer"
                                                {...field}
                                                cacheOptions
                                                defaultOptions={customerOptions}
                                                loadOptions={loadCustomerOptions}
                                                styles={selectStyles}
                                                onChange={handleCustomerChange}
                                                value={customer}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="customer"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="code">
                                        Invitation Code:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="code">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Invite Code"
                                                variant="outlined"
                                                fullWidth
                                                onKeyUp={(event) => { event.target.value = event.target.value.toUpperCase() }}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="code"
                                        component="span"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex gap-5">
                            <div className="flex flex-row justify-between form-group w-full">
                                <div className="w-full">
                                    <label className="label" htmlFor="active">
                                        Active:
                                    </label>
                                </div>

                                <div className="w-full flex-column">
                                    <Field name="active" type="checkbox">
                                        {({ field }) => (
                                            <FormControlLabel
                                                control={<Switch {...field} checked={field.value} />}
                                                label={field.value ? "Active" : "Inactive"}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="active"
                                        component="div"
                                        className="error-message"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <Button variant="contained" onClick={cancelModal}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained">
                            Submit
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default InviteCodeForm;
