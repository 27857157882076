import React from "react";
import { useNavigate } from "react-router-dom";



const EntityCardDisplay = ({ data, route, fields, onCardClick }) => {
  const navigate = useNavigate();
  console.log(data);
  const renderField = (item, value) => {
    if (Array.isArray(value)) {
      return (
        <div className="flex flex-column gap-2">
          <span className="font-bold">{item.label}:</span>
          <ul className="gap-1 flex flex-column">
            {value.map((val, index) => (
              // typeof val == 'string' ? <li key={index}>{displayText(val) /* val */}</li> : renderField(val)
              <li key={index}>{renderField(item, val)}</li>
            ))}
          </ul>
        </div>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <div className="flex flex-column box-content p-1">
          {/* <span className="font-bold">{item.label}</span> */}

          {Object.entries(value).map(([key, val], index) => (
            key != '_id' && <div className="flex place-content-between w-full" key={index}><div className="capitalize">{key}: </div>{renderField(key, val)}</div>
          ))}

        </div>
      );
    } else {
      return (
        <div className={item.className + " flex place-content-between gap-2"}>
          <span className="font-bold">{item.label}   </span>
          {displayText(value)}
        </div>
      );
    }

  };


  function isDateString(str) {
    const date = new Date(str);
    return !isNaN(date) && date.toString() !== 'Invalid Date';
  }


  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

    return formattedDate;
  }

  function isDateString(str) {
    return /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}.\d{3}Z)?$/.test(str);
  }

  const displayText = (outputText) => {
    if (typeof outputText === 'boolean') {
      outputText = outputText ? 'Yes' : 'No';
    } else if (isDateString(outputText)) {
      outputText = formatDate(outputText);
    }
    // console.log("Returning: " + outputText);
    return outputText;
  }


  const handleClick = (id) => {
    if (onCardClick) {
      onCardClick(id);
    } else if (route) {
      navigate(route + id);
      // window.location.href = route + id;
    }
  };

  return (
    <div className="flex flex-wrap gap-4 justify-center w-full">
      {data.map((item) => (
        item ? (
          <div
            key={item._id}
            className={`entityListItem flex basis-0 min-w-[400px] flex-column flex-wrap rounded-lg bg-purple-100 cursor-pointer p-5 ${!item.active ? 'border-2 border-[#ff0000] opacity-80' : ''}`}
            onClick={() => handleClick(item._id)}
          >
            {fields.map((field) => {
              const value = field.key.split(".").reduce((obj, key) => obj?.[key], item);
              return (
                <div key={field.key}>
                  {renderField(field, value)}
                </div>
              );
            })}
          </div>
        ) : null
      ))}
    </div>
  );
};

export default EntityCardDisplay;
