import React from "react";
import { PuffLoader } from "react-spinners";

function GlobalLoadingSpinner() {
  const loading = true;
  //   const [loading, setLoading] = useState(true);

  return (
    <div
      className="
        loadingModal 
        flex 
        flex-column 
        bg-sky-500/20 
        fixed 
        top-0 
        left-0 
        z-[1301] 
        justify-center 
        items-center 
        h-screen 
        w-screen"
    >
      <PuffLoader
        color="purple"
        loading={loading}
        aria-label="Loading Spinner"
        size={100}
      />
    </div>
  );
}

export default GlobalLoadingSpinner;
