import React from "react";
import { Button } from "@mui/material";
import UserList from "../../../../components/UserList";
import { COLORS } from "../../../../utils/colors";
import EmptyState from "../../../EmptyStates/EmptyState";

const MerchantUsers = ({ merchant, merchantUsers, setModalTitle, setModalContent, handleDisplayModal }) => {
  const AddMerchantUser = () => (
    <Button
      sx={{ backgroundColor: COLORS.priorityBlue.hex }}
      variant="contained"
      onClick={() => {
        setModalTitle("Add Merchant User");
        setModalContent("addMerchantUser");
        handleDisplayModal(true);
      }}
    >
      Add New User
    </Button>
  );

  return merchantUsers.length === 0 ? (
    <div className="w-full flex justify-center py-8">
      <EmptyState type="merchantUsers" text="Adding merchant users is easy." action={<AddMerchantUser />} />
    </div>
  ) : (
    <div className="mt-4 flex flex-column gap-4">
      <div className="flex flex-row w-full">
        <AddMerchantUser />
      </div>
      <UserList userList={merchantUsers} merchant={merchant} />
    </div>
  );
};
export default MerchantUsers;
