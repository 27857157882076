import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { api } from "../../utils/api";
// import './AddPartnerCompanyForm.css';
import countriesData from "../../constants/countries.json";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Company Name is required"),
  billingPhone: Yup.string()
    .matches(
      /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
      "Invalid Australian phone number"
    )
    .required("Phone number is required"),
  billingEmail: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // billingAddress: Yup.string().required('Billing Address is required'),
  billingAddress: Yup.object().shape({
    street: Yup.string().required("Street is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postcode: Yup.string().required("Postcode is required"),
    country: Yup.string().required("Country is required"),
  }),
});

const loadCountryOptions = async (inputValue) => {
  const filteredCountries = countriesData.filter((country) =>
    country.label.toLowerCase().includes(inputValue.toLowerCase())
  );
  // Return the filtered countries as options
  return filteredCountries;
};
const selectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "#9e9e9e",
    zIndex: 2,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3, // Higher zIndex for the dropdown menu
  }),
};
const EditForm = ({
  id,
  setShowForm,
  updateData,
  type,
  setLoading,
  currentValues,
}) => {
  const [billingCountry, setBillingCountry] = useState({
    label: "Australia",
    value: "AU",
  }); // Add state for billing country
  const handleCountryChange = (selectedOption) => {
    setBillingCountry(selectedOption); // Set the selected country value to billingCountry state
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    console.log("setLoading true above");
    const dataToSend = {
      name: values.name,
      billing: {
        email: values.billingEmail,
        phone: values.billingPhone,
        address: {
          ...values.billingAddress,
          country: billingCountry, // Set the billing country from the billingCountry state
        },
      },
    };

    try {
      // Make the API call with a POST request
      // var endpoint = type == "partner" ? `v1/partner/company/${id}` : `v1/merchant/${id}`;

      var endpoint = '';
      switch (type) {
        case 'partner':
          endpoint = `v1/partner/company/${id}`;
          break;

        case 'merchant':
          endpoint = `v1/merchant/${id}`;
          break;

        case 'customer':
          endpoint = `v1/customer/${id}`;
          break;

        case 'admin':
          endpoint = `v1/administrator/${id}`;
          break;

        default:
          break;
      }
      const response = await api(endpoint, "PATCH", dataToSend);

      if (response.data.success) {
        if (response.data.data.newPartnerCompany) {
          console.log(
            "Update Partner success Resp",
            response.data.data.newPartnerCompany
          );
          updateData(response.data.data.newPartnerCompany);
        } else if (response.data.data.merchant) {
          console.log(
            "Update Merchant success Resp",
            response.data.data.merchant
          );
          updateData(response.data.data.merchant);
        } else if (response.data.data.customer) {
          console.log(
            "Update Merchant success Resp",
            response.data.data.customer
          );
          updateData(response.data.data.customer);
        } else if (response.data.data.existingCompany) {
          alert(
            "Partner Company already Exists, please enter different name or edit existing"
          );
        }
      }
      // console.log(values);
      // Handle the response as needed
      // console.log(response);
    } catch (error) {
      // Handle any errors
      console.error("API error:", error);
    }
    setShowForm(false);
    setLoading(false);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        name: currentValues.name,
        billingPhone: currentValues.billing.phone,
        billingEmail: currentValues.billing.email,
        billingAddress: {
          street: currentValues.billing.address.street,
          city: currentValues.billing.address.city,
          state: currentValues.billing.address.state,
          postcode: currentValues.billing.address.postcode,
          country: currentValues.billing.address.country.label,
        },
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="form w-full flex flex-column justify-between priorityoneform min-h-[20vh]">
        <div className="form-input-wrap form-group w-full flex flex-column gap-4">
          <div className="form-group w-full pt-1">
            <Field name="name">
              {({ field }) => (
                <TextField
                  {...field}
                  label={type == "partner" ? "Company Name" : "Merchant Name"}
                  variant="outlined"
                  fullWidth
                />
              )}
            </Field>
            <ErrorMessage
              name="name"
              component="span"
              className="error-message"
            />
          </div>

          <div className="w-full flex justify-between gap-2">
            <div className="form-group w-full">
              <Field name="billingPhone">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="billingPhone"
                component="span"
                className="error-message"
              />
            </div>
            <div className="form-group w-full">
              <Field name="billingEmail">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Billing Email Address"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="billingEmail"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex justify-between gap-2">
            <div className="form-group w-full">
              <Field name="billingAddress.street">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Street"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="billingAddress.street"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex justify-between gap-2">
            <div className="form-group w-full">
              <Field name="billingAddress.city">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="City"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="billingAddress.city"
                component="span"
                className="error-message"
              />
            </div>

            <div className="form-group w-full">
              <Field name="billingAddress.state">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="State"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="billingAddress.state"
                component="span"
                className="error-message"
              />
            </div>
          </div>

          <div className="w-full flex justify-between gap-2">
            <div className="form-group w-full">
              <Field name="billingAddress.country">
                {({ field }) => (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadCountryOptions}
                    styles={selectStyles}
                    onChange={handleCountryChange}
                    value={billingCountry}
                  />
                )}
              </Field>
              <ErrorMessage
                name="billingAddress.country"
                component="span"
                className="error-message"
              />
            </div>
            <div className="form-group w-full">
              <Field name="billingAddress.postcode">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Postcode"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </Field>
              <ErrorMessage
                name="billingAddress.postcode"
                component="span"
                className="error-message"
              />
            </div>
          </div>
        </div>

        <div className="form-actions-wrap">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="submit-button"
          >
            Update
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default EditForm;
