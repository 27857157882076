import { React, useEffect, useState } from "react";
import GlobalLoadingSpinner from "../../components/GlobalLoadingSpinner";
import { Button } from "@mui/material";
import ModalPopup from "../../components/ModalPopup";
import ProductForm from "./Forms/ProductForm";
import { api } from "../../utils/api";
import ProductList from "./ProductList";
import { TextField } from "@mui/material";
import { useBasicSearch } from "../../hooks/useBasicSearch";
import { COLORS } from "../../utils/colors";
import EmptyState from "../EmptyStates/EmptyState";

const Product = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showProductForm, setShowProductForm] = useState(false);
  const { filteredData, search, setSearch } = useBasicSearch(products);

  useEffect(() => {
    fetchProducts();
  }, [loading]);

  const fetchProducts = async () => {
    try {
      const response = await api("v1/product", "GET");
      if (response.data.success) {
        setProducts(response.data.data.allProducts);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    setLoading(false);
  };

  const triggerProductListUpdate = (product) => {
    console.log("New/Updated Product: ", product);
    setShowProductForm(false);
    setLoading(false);
    fetchProducts();
  };

  const AddNewProduct = () => (
    <Button
      sx={{ backgroundColor: COLORS.priorityBlue.hex }}
      variant="contained"
      onClick={() => setShowProductForm(true)}
    >
      Add New Product
    </Button>
  );

  return (
    <div className="page product w-full flex flex-column items-center p-4">
      <div className="pageTitle mb-4 flex flex-col w-full text-center lg:flex-row lg:text-left lg:justify-between">
        <h1>Products</h1>
        <div>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ marginRight: "6px", width: "350px" }}
            variant="outlined"
            size="small"
            placeholder="Search for a product"
          />
          {sessionStorage.getItem("selectedPortal") == "Administrator" && (
            <>
              <AddNewProduct />
              <ModalPopup
                showModal={showProductForm}
                handleShowModal={() => setShowProductForm(false)}
                popupTitle="Add New Product"
                popupContent={<ProductForm callback={triggerProductListUpdate} />}
              />
            </>
          )}
        </div>
      </div>
      {loading ? (
        <GlobalLoadingSpinner />
      ) : (
        <div className="w-full flex justify-center">
          {products.length === 0 ? (
            <EmptyState type="products" text="You have no products available" action={<AddNewProduct />} />
          ) : filteredData.length === 0 ? (
            <EmptyState type="products" text="No products match the search" />
          ) : (
            <ProductList products={filteredData} />
          )}
        </div>
      )}
    </div>
  );
};

export default Product;
