import React from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../utils/colors";
import { Button } from "@mui/material";

const PromotionCard = ({ _id, title, code, comment, type, value, expiresOnUTC, products, active }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-between border border-1 shadow-sm w-[600px] p-4">
      <div className="grid grid-cols-2 mb-4">
        <span className="font-semibold text-lg mb-4">Title</span>
        <span className="font-semibold text-lg mb-4">{title}</span>
        <span className="font-semibold">Code</span>
        <span>{code}</span>

        {comment && (
          <>
            <span className="font-semibold">Comment</span>
            <span>{comment}</span>
          </>
        )}
        <span className="font-semibold">Promotion Type</span>
        <span>{type}</span>

        <span className="font-semibold">Promotion Value</span>
        <span>${value}</span>

        <span className="font-semibold mb-4">Promotion Expiry Date</span>
        <span>{expiresOnUTC ? new Date(expiresOnUTC).toLocaleDateString() : "n/a"}</span>

        <span className="font-semibold">Products</span>
        <span>
          {products.map((product) => (
            <ul className="pl-0 mb-0 cursor-pointer " onClick={() => navigate(`/product/${product._id}`)}>
              {product.name}
            </ul>
          ))}
        </span>

        <span className="font-semibold">Active</span>
        <span className="font-semibold">{active ? "YES" : "NO"}</span>
      </div>
      <Button
        onClick={() => navigate(`/promotion/${_id}`)}
        sx={{ backgroundColor: COLORS.priorityBlue.hex }}
        variant="contained"
      >
        View Details
      </Button>
    </div>
  );
};

export default PromotionCard;
