import React from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import NewEntityCardDisplay from "../../components/NewEntityCardDisplay";

const CustomerList = ({ customers }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row flex-wrap gap-y-4 gap-x-6 justify-center lg:justify-normal">
      {customers.map((customer) => (
        <NewEntityCardDisplay
          className={!customer.active ? "border-2 border-[#ff0000] opacity-80" : ""}
          key={customer._id}
          displayName={`${customer.firstName} ${customer.lastName}`}
          email={customer.email}
          onClick={() => navigate(`/customer/${customer._id}`)}
          customIcon={<PersonIcon />}
        />
      ))}
    </div>
  );
};

export default CustomerList;
