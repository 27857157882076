import React from "react";
import * as Images from "../themes/images";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import DatePicker from 'sassy-datepicker';
import moment from "moment";
import { TimeSelection } from "../components";
import { maxHeight } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    border: '2px solid #001571',
    boxShadow: 24,
    p: 3.5,
    maxWidth: window.innerWidth > 820 ? "55%" : "95%",
    maxHeight: window.innerHeight > 700 ? "80%" : "90%" ,
    borderRadius: "6px",
};

class BundleModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: true,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleSkipBundle = this.handleSkipBundle.bind(this);
    }

    handleClose(){
        this.props.handleBundleModal();
    }

    handleSkipBundle(){
        this.props.handleBundleDecline();
    }

    handleApplyBundle(){
        this.props.handleBundleAccept(this.props.bundleDetails);
    }

    componentDidMount(){
        // console.log("show bundle modal: ", this.props.bundleDetails)
    }

    render() {
        return (
            <Modal
                open={this.state.open}
                onClose={() =>  this.handleClose()}
                aria-labelledby="Bundle Modal"
                aria-describedby="Service Bundle requirements have been met"
                className="bundleModal popupModal"
            >
                <Box sx={style}>
                    <h2 className="modalTitle">Congratulations!</h2>
                    <p className="modalDesc">{this.props.bundleDetails.bundle.description}</p>
                    <div className="bundleImageSection">
                        {/* <img src={Images.washDetailIco} className="bundleImage" /> */}
                    </div>
                    <p className="bundleName">{this.props.bundleDetails.bundle.name}</p>
                    <div>
                        <p className="bundlePrice"> From <span className='originalPrice'>${this.props.bundleDetails.bundle.originalPrice}</span> 
                        {" "}${ this.props.bundleDetails.bundle.salePrice } </p>
                    </div>
                    <div className="buttonSection">
                        <button className="blueBorderButton" onClick={() => this.handleSkipBundle()}> I don't want the savings </button>
                        <button className="lightBlueButton" onClick={() => this.handleApplyBundle()}> Claim this offer </button>
                    </div>
                </Box>
            </Modal>
        )
    }
}

export default BundleModal;